import { resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slot_render = _resolveComponent("slot-render")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.body])
    }, [
      _createVNode(_component_a_table, _mergeProps({
        columns: _ctx.renderedColumns,
        "data-source": _ctx.dataSource,
        pagination: _ctx.pagination,
        loading: _ctx.localData && _ctx.localData.loading
      }, _ctx.attrs, _toHandlers(_ctx.eventHandlers)), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (s, name) => {
          return {
            name: name,
            fn: _withCtx((props) => [
              _createVNode(_component_slot_render, {
                s: s(props)
              }, null, 8, ["s"])
            ])
          }
        })
      ]), 1040, ["columns", "data-source", "pagination", "loading"])
    ], 2)
  ]))
}