import _ from 'lodash'
import * as Utils from './utils'
import { useDict } from '@/plugins/dict'
import { ALL_KEY } from '@/common/constants'
import { summaryTableCurrencyFormatterArguments } from '@/components/air8/utils/configShortcut'
import { Ref, ref, unref } from 'vue'
import { removeKeyField } from '../utils/componentUtils'
import { Form } from 'ant-design-vue'
import { useLocal } from '@/plugins/locale'

export function isCurrenyDictionary (currency: string) {
  const { m } = useDict()
  const currencyList = m.t('CURRENCY')
  return _.findIndex(currencyList, { value: currency }) !== -1
}

const TYPE_CONFIG: any = {
  date: (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'tz-date' : 'Air8Label',
      formatter: 'date',
      ...item,
      ...Utils.addRequiredRules(item, 'string')
    }
  },
  input: (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'a-input' : 'Air8Label',
      ...item,
      ...Utils.addRequiredRules(item, 'string')
    }
  },
  'input-number': (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8InputNumber' : 'Air8Label',
      formatter: 'number',
      ...item,
      ...Utils.addRequiredRules(item, 'number')
    }
  },
  'input-money': (item: any, data: any, options: any) => {
    let currency = _.get(item, 'currency')
    if (!_.isNil(currency) && !isCurrenyDictionary(currency)) {
      currency = _.get(data, currency)
    }
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8Money' : 'Air8MoneyLabel',
      ...item,
      currency,
      ...Utils.addRequiredRules(item, 'number')
    }
  },
  select: (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? (!_.get(item, 'dictName') ? 'Air8Select' : 'Air8DictionarySelect') : 'Air8Label',
      allowClear: true,
      ...item,
      ...Utils.addRequiredRules(item),
      getPopupContainer: options.getPopupContainer
    }
  },
  'select-other': (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: 'Air8SelectOther',
      editable: Utils.isEditableItem(item),
      ..._.omit(item, ['rules']),
      selectName: item.name,
      rules: _.get(Utils.addRequiredRules(item, 'string'), 'rules'),
      selectRules: _.get(Utils.addRequiredRules(item, 'string'), 'rules'),
      getPopupContainer: options.getPopupContainer
    }
  },
  radio: (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8RadioGroup' : 'Air8Label',
      ...item,
      ...Utils.addRequiredRules(item)
    }
  },
  checkbox: (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8CheckboxGroup' : 'Air8Label',
      ...item,
      ...Utils.addRequiredRules(item)
    }
  },
  'select-buyer': (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8CompanyCodeNameSelect' : 'Air8Label',
      category: 'buyer',
      filterBySearch: true,
      fullOption: true,
      fullLabel: true,
      ...item,
      ...Utils.addRequiredRules(item, 'string'),
      getPopupContainer: options.getPopupContainer
    }
  },
  'select-supplier': (item: any, data: any, options: any) => {
    return {
      $type: 'Air8FormItem',
      type: Utils.isEditableItem(item) ? 'Air8CompanyCodeNameSelect' : 'Air8Label',
      category: 'supplier',
      filterBySearch: true,
      fullOption: true,
      fullLabel: true,
      ...item,
      ...Utils.addRequiredRules(item, 'string'),
      getPopupContainer: options.getPopupContainer
    }
  }
}

function isDisplayFormItem (config: any, options: any) {
  // from country
  let targetFromCountry = _.get(config, 'fromCountry')
  let sourceFromCountry = _.get(options, 'fromCountry')
  if (sourceFromCountry !== ALL_KEY && (!_.isEmpty(targetFromCountry) || _.isNumber(targetFromCountry))) {
    if (!_.isArray(targetFromCountry)) {
      targetFromCountry = _.castArray(targetFromCountry)
    }
    sourceFromCountry = `${sourceFromCountry}`
    return _.chain(targetFromCountry)
      .map(item => `${item}`)
      .includes(sourceFromCountry)
      .value()
  }

  return true
}

export function getGroupItem (config: any, lang: any, data?: Ref<any> | any, options?: Ref<any> | any) {
  if (!isDisplayFormItem(config, unref(options))) {
    return undefined
  }

  config = setConfigsEqValue(data, config)

  const formItemType = TYPE_CONFIG[_.get(config, 'type')]
  if (_.isNil(formItemType)) {
    return { $type: 'div' }
  }
  return formItemType({
    $if: Utils.isVisiable(config),
    $arrayIndex: _.get(config, 'orderBy'),
    ..._.omit(config, ['type', 'label', 'orderBy']),
    label: lang(_.get(config, 'label'))
  }, unref(data), unref(options))
}

function setConfigsEqValue (data: any, configs: any) {
  const isEmptyValue = _.isEmpty(_.get(configs, 'expression.field')) && !_.isNumber(_.get(configs, 'expression.field'))
  if (_.get(configs, 'expression.express') !== 'eq' || isEmptyValue) {
    return configs
  }

  const configData = _.cloneDeep(configs)
  if (_.get(data, _.get(configs, 'expression.field')) === _.get(configs, 'expression.value')) {
    return {
      ..._.omit(configs, ['expression']),
      ..._.get(configData, 'expression.if') || {}
    }
  } else {
    return {
      ..._.omit(configs, ['expression']),
      ..._.get(configData, 'expression.else') || {}
    }
  }
}

// date/input/input-number/input-money/select/radio/checkbox/select-buyer
function getColumnPropType (column: any, data: Ref<any[]> | any[]) {
  const type = _.get(column, 'type')
  if (type === 'date') {
    return {
      $propType: 'date'
    }
  } else if (type === 'input-number') {
    return {
      $propType: 'number'
    }
  } else if (type === 'input-money') {
    const result: any = {
      $propType: 'currency'
    }
    const currency = _.get(column, 'currency')
    if (isCurrenyDictionary(currency)) {
      result.$formatterArgumentFields = currency
    } else {
      result.$text = {
        $FformatterArguments: summaryTableCurrencyFormatterArguments(data, currency)
      }
    }
    return result
  } else {
    return {
      $propType: 'input'
    }
  }
}

function getColumnEdit (column: any, formItem: any) {
  if (!Utils.isEditableItem(column)) {
    return undefined
  }
  const type = _.get(column, 'type')
  const result = _.clone(formItem)
  if (type === 'input-money') {
    const currency = _.get(column, 'currency')
    if (!_.isNil(currency) && !isCurrenyDictionary(currency)) {
      result.currency = undefined
      result.$Fcurrency = (rowScope: any) => {
        return _.get(rowScope.record, currency)
      }
    }
  }
  return result
}

function assign (target: any, targetField: string, source: any, sourceField: string) {
  if (_.isNil(target) || _.isEmpty(targetField) || _.isNil(source) || _.isEmpty(sourceField)) {
    return
  }
  if (!_.has(source, sourceField)) {
    return
  }
  _.set(target, targetField, _.get(source, sourceField))
}

export function getColumn (column: any, lang: any, data?: Ref<any[]> | any[], options?: any) {
  const formItem = getGroupItem(_.omit(column, ['$edit', '$text']), lang, undefined, options)
  if (_.isNil(formItem)) {
    return undefined
  }

  let result: any = {
    ..._.omit(column, ['type', 'name', 'label', 'dictName', 'orderBy']),
    title: lang(_.get(column, 'label'))
  }

  assign(result, 'dataIndex', column, 'name')
  assign(result, '$dictName', column, 'dictName')
  assign(result, '$arrayIndex', column, 'orderBy')

  assign(result, '$if', formItem, '$if')
  assign(result, '$rules', formItem, 'rules')
  assign(result, '$editable', formItem, 'editable')

  if (!_.isNil(column.$query)) {
    const $if = _.get(formItem, '$if')
    const $query = column.$query
    result.$F$query = () => {
      return $if ? $query : false
    }
    result.$query = undefined
  }

  result = _.merge(getColumnPropType(column, data || []),
    { $edit: getColumnEdit(column, formItem) },
    result)
  return result
}

function isPlainObjectOrArray (obj: any) {
  return _.isPlainObject(obj) || _.isArray(obj)
}

export function deepMerge (object: any, source: any, arrayId: string): any {
  if (_.isNil(object)) {
    return source
  }
  if (_.isNil(source)) {
    return object
  }
  if (!isPlainObjectOrArray(object) || !isPlainObjectOrArray(source)) {
    return source
  }
  return _.mergeWith(object, source, (obj: any, src: any) => {
    if (_.isArray(obj) && _.isArray(src)) {
      obj = _.map(obj, (value, index) => ({ index, value }))
      const objSize = _.size(obj)
      src = _.map(src, (value, index) => ({ index: index + objSize, value }))
      const objCombinationSrc = _.map(obj, (objItem: any) => {
        if (_.isNil(objItem.value)) {
          return objItem
        }
        let filter: any = () => true
        if (_.isPlainObject(objItem.value)) {
          const id = _.get(objItem.value, arrayId)
          if (_.isNil(id)) {
            return objItem
          }
          filter = (srcItem: any) => {
            return !srcItem.checked && _.isPlainObject(srcItem.value) && id === _.get(srcItem.value, arrayId)
          }
        } else {
          filter = (srcItem: any) => {
            return !srcItem.checked && _.isEqual(objItem.value, srcItem.value)
          }
        }
        const found: any = _.find(src, filter)
        if (_.isNil(found)) {
          return objItem
        }
        found.checked = true
        return {
          index: found.index,
          value: deepMerge(objItem.value, found.value, arrayId)
        }
      })

      const srcCombinationObj = _.filter(src, (srcItem: any) => {
        return !srcItem.checked
      })

      return _.chain(objCombinationSrc)
        .concat(srcCombinationObj)
        .sortBy(['index'])
        .map('value')
        .value()
    } else {
      return deepMerge(obj, src, arrayId)
    }
  })
}

function getRulePlaceholder (column: any) {
  if (_.isNil(column.placeholder) && !_.isEmpty(column.title)) {
    const isSelectComponent = _.includes(['Air8DictionarySelect', 'ASelect', 'Air8CategorySelect', 'Air8CheckboxGroup'], _.get(column, '$edit.type'))
    const { t } = useLocal()
    const placeholder = isSelectComponent ? t('common.please_select', column.title) : t('common.please_input', column.title)
    return placeholder
  }
  return column.placeholder || ''
}

export function getTableForm (dataSource: any, columnsConfig: any) {
  dataSource = unref(dataSource)
  columnsConfig = unref(columnsConfig)
  return _.map(dataSource, (record) => {
    const modelRef = ref(_.cloneDeep(record))
    const ruleRef = ref(_.reduce(columnsConfig, (result, column) => {
      if (column.dataIndex && column.$rules) {
        const rules = _.map(column.$rules, (rule) => {
          return {
            ...rule,
            placeholder: getRulePlaceholder(column)
          }
        })
        _.set(result, [column.dataIndex], rules)
      }
      return result
    }, {} as any))

    _.each(_.keys(ruleRef.value), (name) => {
      if (_.has(modelRef.value, name)) {
        return
      }
      _.set(modelRef.value, name, undefined)
    })
    return Form.useForm(modelRef, ruleRef)
  })
}
