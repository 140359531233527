<template>
  <div class="air8-ocr-upload">
    <Air8UploadDoneCard
      v-show="isShowDoneCard"
      class="air8-ocr-upload__card"
      :file="file"
      :title="doneTitle"
      :description="doneDescription"
      @remove="handleRemoveFile"
    >
    </Air8UploadDoneCard>

    <Air8OcrUploadCard
      class="air8-ocr-upload__card"
      v-show="isShowUploadCard"
      ref="ocrUploadCardRef"
      :file-list="internalFileList"
      :type="type"
      :ocr-type="ocrType"
      :title="uploadTitle"
      :description="uploadDescription"
      :maxlength="1"
      :schemaCode="schemaCode"
      :loadingMessage="loadingMessage"
      @update:file="handleUploadFile"
      @update:ocr-result-list="handleUpdateOcrResultList"
      @update:ocr-result="handleUpdateOcrResult"
      @file-error="handleFileError"
      @ocr-error="handleOcrError"
      @ocr-success="handleOcrSuccess"
      @update:loading="handleUpdateLoading"
    >
    </Air8OcrUploadCard>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import _ from 'lodash'
import Air8UploadDoneCard from './OcrUploadDoneCard.vue'
import Air8OcrUploadCard from './OcrUploadCard.vue'

export default defineComponent({
  name: 'Air8OcrUpload',
  components: {
    Air8UploadDoneCard,
    Air8OcrUploadCard
  },

  props: {
    file: Object,
    type: Array,
    ocrType: Array,
    uploadTitle: String,
    uploadDescription: String,
    doneTitle: String,
    doneDescription: String,
    loadingMessage: String,
    schemaCode: String,
    debug: Boolean
  },

  emits: ['update:file', 'update:ocr-result-list', 'update:ocr-result', 'file-error', 'ocr-success', 'ocr-error', 'update:loading'],

  setup (props, { emit }) {
    const ocrUploadCardRef = ref()
    const isShowUploadCard = computed(() => {
      return _.isNil(props.file)
    })
    const isShowDoneCard = computed(() => {
      return !isShowUploadCard.value
    })

    const internalFileList = computed(() => {
      if (_.isNil(props.file)) {
        return undefined
      }
      return [props.file]
    })

    function handleRemoveFile () {
      ocrUploadCardRef.value.remove(0)
    }

    function handleUploadFile (e) {
      emit('update:file', e)
    }

    function handleUpdateOcrResultList (e) {
      emit('update:ocr-result-list', e)
    }

    function handleUpdateOcrResult (e) {
      emit('update:ocr-result', e)
    }

    function handleFileError (e) {
      emit('file-error', e)
    }

    function handleOcrError (e) {
      emit('ocr-error', e)
    }

    function handleOcrSuccess (e) {
      emit('ocr-success', e)
    }

    function ocrFile (schemaCode) {
      return ocrUploadCardRef.value.ocrAllFiles(schemaCode)
    }

    function handleUpdateLoading (e) {
      emit('update:loading', e)
    }

    return {
      ocrUploadCardRef,
      isShowDoneCard,
      isShowUploadCard,
      internalFileList,
      handleRemoveFile,
      handleUploadFile,
      handleUpdateOcrResultList,
      handleUpdateOcrResult,
      handleFileError,
      handleOcrError,
      handleOcrSuccess,
      ocrFile,
      handleUpdateLoading
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-ocr-upload__card {
  :deep(.ant-card) {
    text-align: center;
    height: 260px;
    max-height: 260px;
    min-height: 260px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-card-body {
      padding: 14px;
      .ant-card-meta-title {
        color: inherit;
      }
      .ant-card-meta-description {
        color: inherit;
      }
    }
    &:hover {
      border-color: @color-primary;
      color: @color-primary;
    }
  }
}
</style>
