<template>
  <div class="air8-hover">
    <div class="air8-hover__body">
      <slot name="default"></slot>
    </div>
    <div class="air8-hover__mask">
      <slot name="mask"></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Air8Hover'
})
</script>
<style lang="less" scoped>
.air8-hover {
  position: relative;
  .air8-hover__mask {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
  }

  .air8-hover__body {
    width: 100%
  }

  &:hover .air8-hover__mask {
    display: flex;
  }
}
</style>
