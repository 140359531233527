<template>
  <div
    data-locale-ignore="true"
    :class="$style['locale-tools']"
  >
    <table
      :class="[$style.table]"
      ref="tableRef"
    >
      <thead>
        <tr>
          <th style="width: 72px;">Seq</th>
          <th>Key</th>
          <th>Chinese</th>
          <th>English</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, i) in renderedRows"
          :key="i"
        >
          <td>{{i + 1}}</td>
          <td>{{row.key}}</td>
          <td
            contenteditable
            @input="e => handleInputLocale(row, '_zhCN', e.target.textContent)"
            :class="[isDifferentLocale(row, 'zhCN') ? $style.diff : '', isEmptyLocale(row, 'zhCN') ? $style.isEmptyLocale : '']"
            :title="row.zhCN"
          >{{row._zhCN || row.zhCN}}</td>
          <td
            contenteditable
            @input="e => handleInputLocale(row, '_enUS', e.target.textContent)"
            :class="[isDifferentLocale(row, 'enUS') ? $style.diff : '', isEmptyLocale(row, 'enUS') ? $style.isEmptyLocale : '']"
            :title="row.enUS"
          >{{row._enUS || row.enUS}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { flatLocale, mergeToRows, exportRows, download } from '@/utils/locale'
import { pull, push, fetchStandard } from '@/api/locale'
import _ from 'lodash'
import { useLocal } from '@/plugins/locale'

export default defineComponent({
  name: 'LocaleTools',
  props: {
    localeKey: String,
    localeValue: String,
    namespace: String
  },

  setup (props, { emit }) {
    const { lang } = useLocal()

    let local = null
    let localMap = null
    let originZhCNDataSource = null
    let originEnUSDataSOurce = null
    const rows = ref([])

    function filterLocale (rows) {
      const languageField = lang() === 'zh-CN' ? 'zhCN' : 'enUS'
      return _.filter(rows, (row) => {
        if (!_.isEmpty(props.localeKey)) {
          return row.key === props.localeKey
        } else if (!_.isEmpty(props.localeValue)) {
          return isMatchLocaleValue(props.localeValue, row[languageField], row.key)
        } else {
          return false
        }
      })
    }

    const renderedRows = computed(() => {
      return filterLocale(rows.value)
    })

    async function loadLocal () {
      const data = await fetchStandard(props.namespace)
      local = data
      localMap = flatLocale(local)
    }

    async function loadDb () {
      rows.value = []
      const all = [pull(props.namespace, 'zh-CN'), pull(props.namespace, 'en-US')]
      const [zh, en] = await Promise.all(all)
      originZhCNDataSource = zh
      originEnUSDataSOurce = en
      const zhCN = JSON.parse(_.get(zh, 'value', '{}'))
      const enUS = JSON.parse(_.get(en, 'value', '{}'))
      rows.value = mergeToRows(localMap, Object.assign({}, localMap, flatLocale(zhCN)), flatLocale(enUS))
    }

    function handleInputLocale (row, key, value) {
      row[key] = value
    }

    function isDifferentLocale (row, key) {
      const copyKey = '_' + key
      if (!row[copyKey]) return false
      return row[copyKey] !== row[key]
    }

    function isEmptyLocale (row, key) {
      return !row[key]
    }

    function isMatchLocaleValue (target, source, sourceKey) {
      target = _.trim(target)
      source = _.trim(source)
      if (/^([_A-Za-z0-9]+\.)+[_A-Za-z0-9]+$/g.test(target)) {
        return target === sourceKey
      }

      if (/\{\d+\}/g.test(source)) {
        source = source.replace(/./g, (p0) => {
          return `[${p0}]`
        })
        source = source.replace(/\[\{\](\[\d\])+\[\}\]/g, (p0) => {
          return '.*'
        })
        return new RegExp(`^${source}$`).test(target)
      }

      if (/(:|：)$/g.test(target)) {
        return target.substr(0, target.length - 1) === source
      }

      return target === source
    }

    function saveLocale () {
      const list = exportRows(rows.value)
      const ret = [
        {
          langKey: 'zh-CN',
          id: originZhCNDataSource?.id,
          namespace: props.namespace,
          value: JSON.stringify(list[0])
        },
        {
          langKey: 'en-US',
          id: originEnUSDataSOurce?.id,
          namespace: props.namespace,
          value: JSON.stringify(list[1])
        }
      ]
      push(ret)
    }

    onMounted(async () => {
      if (_.isEmpty(props.localeKey) && _.isEmpty(props.localeValue)) {
        return
      }

      await loadLocal()
      await loadDb()
    })

    return {
      renderedRows,
      handleInputLocale,
      isDifferentLocale,
      isEmptyLocale,
      saveLocale
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';
.locale-tools {
  overflow: scroll;
  max-height: 500px;
  position: relative;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-radius: 4px;
  border: 1px solid #e2e2e2;

  th {
    border: 1px solid #e2e2e2;
    padding: 8px 8px;
  }

  td {
    border: 1px solid #efefef;
    padding: 4px 8px;
    word-break: break-all;

    &.diff {
      color: red;
    }

    &.isEmptyLocale {
      background-color: darkgray;
    }
  }
}

.file {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
</style>
