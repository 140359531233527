<template>
  <a-checkbox-group
    v-bind="internalProps"
    :options="internalOptions"
    :value="value"
  >
  </a-checkbox-group>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { useDict } from '@/plugins/dict'
const { m } = useDict()

export default defineComponent({
  name: 'Air8CheckboxGroup',
  aliasName: 'a8-d-checkbox-group',
  inheritAttrs: false,
  props: {
    value: [Boolean, Number, String, Object],
    dictName: String
  },

  computed: {
    internalProps () {
      return this.$attrs
    },

    internalOptions () {
      let options = this.$attrs.options
      if (_.isNil(options) && !_.isEmpty(this.dictName)) {
        options = m.t(this.dictName)
      }
      return _.map(options, (item) => {
        if (_.isString(item)) {
          return { label: item, value: item }
        } else {
          return item
        }
      })
    }
  }
})
</script>
