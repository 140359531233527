<template>
  <air8-popconfirm class="air8-delete-link-button"
    :title="tip"
    @confirm="handleConfirm"
  >
    <a-button
      type="link"
      size="small"
      v-bind="buttonProps"
    >
      <slot>{{ $t('common.delete')}}</slot>
    </a-button>
  </air8-popconfirm>
</template>
<script>
import { defineComponent } from 'vue'
import { t } from '@/config/locale'
import Air8Popconfirm from '@/components/air8/containers/popconfirm/index.vue'
import { removeKeyField } from '@/components/air8/utils/componentUtils'

export default defineComponent({
  name: 'Air8DeleteLinkButton',

  inheritAttrs: false,

  components: {
    Air8Popconfirm
  },

  props: {
    tip: {
      type: String,
      default: () => {
        return t('common.del_confirm')
      }
    }
  },

  computed: {
    buttonProps () {
      return removeKeyField(this.$attrs, /^(on.+)$/g)
    }
  },

  methods: {
    handleConfirm () {
      this.$emit('click')
    }
  }
})
</script>
<style lang="less" scoped>
.air8-delete-link-button {
  display: inline-block
}
</style>
