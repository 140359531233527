import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ant-table-thead" }
const _hoisted_2 = {
  key: 0,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("table", {
      class: _normalizeClass(["ant-table ant-table-bordered", _ctx.$style.table])
    }, [
      _createElementVNode("thead", _hoisted_1, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (x, i) => {
            return (_openBlock(), _createElementBlock("th", {
              key: `th_${i}`
            }, [
              (typeof x.title === 'string')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass({'table_required': x.required})
                  }, _toDisplayString(x.title), 3))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(x.title), { key: 1 }))
            ]))
          }), 128))
        ])
      ]),
      _createVNode(_component_draggable, {
        modelValue: _ctx.list,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.list = $event)),
        group: "table",
        "item-key": "_uid",
        animation: "300",
        tag: "tbody",
        class: "ant-table-tbody",
        disabled: _ctx.disabled,
        options: {sort: true}
      }, {
        item: _withCtx(({ element, index}) => [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (x) => {
              return (_openBlock(), _createElementBlock("td", {
                style: _normalizeStyle(`width:${x.width}px`),
                key: `item_${index}_${x.dataIndex}`
              }, [
                _renderSlot(_ctx.$slots, x.dataIndex, {
                  record: element,
                  index: index
                }, () => [
                  _createTextVNode(_toDisplayString(x.customRender ? x.customRender({ text: element[x.dataIndex], index, record: element }) : element[x.dataIndex]), 1)
                ])
              ], 4))
            }), 128))
          ])
        ]),
        _: 3
      }, 8, ["modelValue", "disabled"])
    ], 2),
    (_ctx.list.length < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_empty, { image: _ctx.simpleImage }, null, 8, ["image"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}