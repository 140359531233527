import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container])
  }, [
    _createVNode(_component_a_range_picker, {
      dropdownClassName: _ctx.$style.picker,
      value: _ctx.value,
      format: _ctx.format,
      open: _ctx.picker,
      mode: ['month', 'month'],
      onChange: _ctx.onChange,
      onPanelChange: _ctx.onPanelChange,
      disabledDate: _ctx.disabledDate,
      onOpenChange: _ctx.openChange
    }, {
      renderExtraFooter: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "text",
          onClick: _ctx.onOk
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.ok')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["dropdownClassName", "value", "format", "open", "onChange", "onPanelChange", "disabledDate", "onOpenChange"]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.footer])
    }, [
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.onQuery
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_SearchOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('common.query')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        size: "small",
        onClick: _ctx.onReset
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.reset')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2)
  ], 2))
}