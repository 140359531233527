<template>
  <c0 :config="config"></c0>
</template>
<script>
import { computed, defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8LayoutDetail',

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Object,
      default: () => ({})
    },
    wrapBrackets: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { slots }) {
    const config = computed(() => {
      return {
        $type: 'Air8ColumnLayout',
        formLayout: 'horizontal',
        $children: _.map(props.columns, (column) => {
          return {
            type: 'Air8Label',
            label: column.label,
            modelValue: _.get(props.dataSource, column.prop),
            formatter: column.formatter ? (v) => {
              return column.formatter(v, props.dataSource)
            } : undefined,
            wrapBrackets: props.wrapBrackets,
            $slots: {
              addonBefore: column.slot ? _.get(slots, `${column.prop}-addonBefore`) : undefined
            }
          }
        })
      }
    })

    return {
      config
    }
  }
})
</script>
