<script>
import { defineComponent, h, resolveComponent } from 'vue'

export default defineComponent({
  name: 'Air8DynamicTextarea',
  aliasName: 'a8-d-textarea',

  inheritAttrs: false,

  render () {
    return h(resolveComponent('a-textarea'), this.$attrs, this.$slots)
  }
})
</script>
