<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import BuilderFactory from '@/components/air8/builder/BuilderFactory'

export default defineComponent({
  name: 'Air8DynamicConfigComp',
  aliasName: 'a8-d-c0',

  props: {
    config: [String, Function, Object, Array],
    data: Object
  },

  render () {
    const scope = { data: this.data }
    const builder = BuilderFactory.getInstance('DynamicConfigBuilder', this.config, scope)

    return (
      <c0 config={builder.currentConfig}></c0>
    )
  }
})
</script>
