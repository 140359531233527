import _ from 'lodash'
import { VNode, RendererNode, RendererElement } from 'vue'
import { getComponentName, removeKeyField } from '../../utils/componentUtils'
import BaseBuilder from '../BaseBuilder'
import { MAP_DATE_TYPE_FIELD_TYPE, MAP_FIELD_TYPE_PROP_TYPE, FIELD_TYPE } from '../constants'

export default class DynamicTableInnerColumnsBuilder extends BaseBuilder {
  public render (): VNode<RendererNode, RendererElement, { [key: string]: any }>[] | undefined {
    return undefined
  }

  preInitConfig (currentConfig:any):any {
    return currentConfig
  }

  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    const result = _.chain(currentConfig)
      .filter(columnConfig => this.isColumnDisplay(columnConfig))
      .map((columnConfig, index) => this.initColumn(columnConfig, index))
      .value()

    return result
  }

  isColumnDisplay (columnConfig:any): boolean {
    return !_.isNil(columnConfig) && this.getComponentIf(columnConfig, this.getParentScope())
  }

  initColumn (columnConfig:any, index:number): any {
    columnConfig = _.clone(columnConfig)

    if (this.isSequenceColumn(columnConfig)) {
      return columnConfig
    }
    if (this.isActionColumn(columnConfig)) {
      return columnConfig
    }

    this.dealDefaultField(columnConfig)
    columnConfig = this.dealField(columnConfig)
    return columnConfig
  }

  isSequenceColumn (columnConfig:any): boolean {
    return columnConfig.key === this.getRootScope().sequenceKey
  }

  isActionColumn (columnConfig:any): boolean {
    return columnConfig.key === this.getRootScope().actionKey
  }

  getFieldProps (columnConfig: any) {
    let field = _.get(columnConfig, 'field')
    if (!_.isNil(field)) {
      return field
    }
    field = _.omit(columnConfig, [
      'align',
      'ellipsis',
      'colSpan',
      'dataIndex',
      'defaultFilteredValue',
      'filterDropdown',
      'filterDropdownVisible',
      'filtered',
      'filteredValue',
      'filterIcon',
      'filterMultiple',
      'filters',
      'fixed',
      'key',
      'customRender',
      'sorter',
      'sortOrder',
      'sortDirections',
      'title',
      'width',
      'customCell',
      'customHeaderCell',
      'onFilter',
      'onFilterDropdownVisibleChange',
      'slots'
    ])
    field = removeKeyField(field, new RegExp('^$[^F]'))
    return field
  }

  getFieldType (columnConfig: any) {
    const config = this.getFieldProps(columnConfig)
    const type = getComponentName(config.type)
    if (!_.isNil(type)) {
      return type
    }
    if (!_.isEmpty(config.dictName)) {
      return FIELD_TYPE.SELECT
    }
    if (!_.isEmpty(config.category)) {
      return FIELD_TYPE.SELECT
    }
    return _.get(MAP_DATE_TYPE_FIELD_TYPE, config.dataType)
  }

  dealDefaultField (columnConfig: any) {
    _.defaults(columnConfig, {
      dataIndex: columnConfig.name,
      key: columnConfig.name,
      title: columnConfig.label,
      $propType: _.get(MAP_FIELD_TYPE_PROP_TYPE, this.getFieldType(columnConfig))
    })
  }

  dealField (columnConfig: any) {
    const field = {
      hideLabel: true,
      tinyError: true,
      $type: 'Air8DynamicFormItem',
      ...this.getFieldProps(columnConfig)
    }

    columnConfig.$editable = !!field.editable

    columnConfig.$edit = _.defaults(columnConfig.$edit || {}, {
      ...field,
      editable: true
    })

    columnConfig.$text = _.defaults(columnConfig.$text || {}, {
      ...field,
      editable: false
    })

    return _.omit(columnConfig, _.keys(field))
  }
}
