import _ from 'lodash';
import BigDecimal from '@/utils/big.js';
import {watch, provide, reactive, nextTick, shallowRef} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { numberFormatter } from '@/common/formatter'

import {useAuth} from '@/plugins/auth/src';

const checkNaN = (value, cb) => {
    let val = parseFloat(value);
    return isNaN(val) ? value : cb(val);
};

const EMPTY_VAL = '-', COLON = ':', DEFAULT_TYPE = 'Input', T_VALIDATION_PREFIX = 'common.validation',
    LANG_CN = 'zh-CN', required = true, disabled = true,
    REGEXP = {
        amount: /^\d+(\.\d{1,2})?$/,
        natural: /^\d+$/,
        percent: /^\d{1,2}(\.\d{1,2})?$|^100(\.00?)?$/,
        currency: /(\d)(?=(?:\d{3})+$)/g,
        pInteger: /^[1-9]\d*$/,
    },
    RULE_REQUIRED = {
        message: '${placeholder}',
        required,
        whitespace: true,
    },
    Empty = {
        val: EMPTY_VAL,
        is(v){
            return v === '' || v === undefined || v === null;
        },
        parse(v, cb = _.identity) {
            return this.is(v) ? this.val : cb(v);
        },
        convert(v, cb = _.identity) {
            return this.is(v) ? v : cb(v);
        },
    },
    Field = {
        COLON,
        DEFAULT_TYPE,
        required,
        disabled,
        country: {
            showSearch: true,
            optionFilterProp: 'label',
        },
        Input: {
            suffix: {
                day: {addonAfter: ({t}) => t('common.day')},
                percent: {addonAfter: '%'},
            },
        },
        LIST: {
            index: {
                name: 'index',
                type: 'Index',
                label: 'common.seq',
                width: 110,
            },
            action: {
                name: 'action',
                type: 'Action',
                label: 'common.action',
                width: 120,
                columnProps: {
                    class: 'action',
                    fixed: 'right',
                    ellipsis: false,
                },
            },
        },
    },
    Rules = {
        Date: {
            required: {
                ...RULE_REQUIRED,
                type: 'object',
            },
        },
        HolidayDate: {
            required: {
                ...RULE_REQUIRED,
                type: 'string',
            },
        },
        Number: {
          required: {
            ...RULE_REQUIRED,
            type: 'number'
          },
        },
        required: RULE_REQUIRED,
        url: {
            type: 'url',
            message: `${T_VALIDATION_PREFIX}.url`,
        },
        email: {
            type: 'email',
            message: `${T_VALIDATION_PREFIX}.email`,
        },
        amount: {
            pattern: REGEXP.amount,
            message: `${T_VALIDATION_PREFIX}.number`,
        },
        amountJPY: {
            pattern: REGEXP.natural,
            message: `${T_VALIDATION_PREFIX}.integer`,
        },
        percent: {
            pattern: REGEXP.percent,
            message: `${T_VALIDATION_PREFIX}.percent`,
        },
        natural: {
            pattern: REGEXP.natural,
            message: `${T_VALIDATION_PREFIX}.natural`,
        },
        pInteger: {
            pattern: REGEXP.pInteger,
            message: `${T_VALIDATION_PREFIX}.positiveInteger`,
        },
        init(rules, field, {t}){
            const {label, props: {placeholder} = {}} = field, props = {t, label, placeholder};
            return _.map(rules, rule => this.initRule(rule, props));
        },
        initRule(rule, props = {}){
            const {message, ...rest} = rule, {t} = props;
            return {...rest, ...props, message: t(message)};
        },
    },
    Utils = {
        Observable: class Observable {

            constructor(){
                this.listener = new Set();
            }

            clear(){
                this.listener.clear();
            }

            notify(){
                for (var fn of this.listener){
                    fn.apply(undefined, arguments);
                }
            }

            subscribe(fn){
                this.listener.add(fn);
                return () => this.listener.delete(fn);
            }

        },
        getSelectLabel: (options, value) => _.get(_.find(options, {value}), 'label', EMPTY_VAL),
        createPromiseTask: (() => {

            class Task {

                constructor(){
                    this.list = [];
                    this.count = 1;
                }

                add(task){
                    const {list, count} = this;
                    list.push(task);
                    list.length <= count && this.run();
                }

                run(){
                    const [task] = this.list;
                    task && task().finally(() => {
                        this.list.shift();
                        this.run();
                    });
                }

            }

            return () => new Task();
        })(),
    },
    Filters = {
        decimal: (value, precision = 2) => checkNaN(value, () => new BigDecimal(value).toFixed(precision)),
        percent: (value, precision = 2, factor = 100) => checkNaN(value, () => new BigDecimal(value).mul(factor).toFixed(precision)),
        currency: (v = '') => {
            // let [n, d = []] = v.toString().split('.');
            // return [n.replace(REGEXP.currency, '$1,')].concat(d).join('.');
            return numberFormatter(v)
        }
    };

const useBack = () => {
        const {meta} = useRoute(), router = useRouter(), {path} = _.get(meta, 'page.parent');
        return () => router.push({path});
    },
    useData = (loader = () => Promise.reject('loader undefined')) => {
        const data = shallowRef({}), id = loader.length ? _.get(useRoute(), 'params.id') : undefined;
        loader(id).then(r => data.value = r);
        return data;
    },
    useType = () => {
        const user = useUser(), TYPES = {
            BUYER: 'buyer',
            FUNDER: 'funder',
            COMPANY: 'director',
            SUPPLIER: 'supplier',
        };
        return _.get(TYPES, user.companyRole, TYPES.COMPANY);
    },
    useUser = () => _.get(useAuth().auth.principle, 'user', {}),
    useAmount = ({t}) => {
        /*
        * 金额类的数据存储及显示
        * 	显示/输入两位，存储两位；XTS的订单单位价格是四位，显示及存储为四位
        * 	日元不需要显示小数
        * */
        const amount = reactive({}),
            setAmount = v => {
                const isJPY = v === 'JPY';
                amount.isJPY = isJPY;
                amount.precision = isJPY ? 0 : 2;
            },
            watchFields = (fields) => {
                //rules reactive
                const amounts = _.map(fields, f => {
                    const {rules = [], itemProps = {}} = f;
                    return Object.assign(f, {itemProps: toProxy(itemProps, {rules: shallowRef([...rules, Rules.amount])})});
                });
                //change rules
                watch(() => amount.isJPY, v => {
                    const rule = Rules.initRule(v ? Rules.amountJPY : Rules.amount, {t});
                    _.each(amounts, f => {
                        const {rules = [], itemProps} = f;
                        //concat rules
                        itemProps.rules = [...rules, rule];
                        //trigger validate
                        nextTick().then(() => _.invoke(itemProps.ref, 'value.onFieldChange'));
                    });
                });
            };
        provide('amount', amount);
        return [amount, setAmount, watchFields];
    },
    useLoading = (len = 1) => {

        const state = reactive({loading: false, name: undefined}),
            setState = (isLoading, name) => {
                state.name = name;
                state.loading = isLoading;
            },
            end = () => setState(false);

        return _.map(_.times(len), name => ({
            end,
            start(p, _name = name){
                setState(true, _name);
                p instanceof Promise && p.catch(_.noop).finally(end);
            },
            props: {
                get loading(){
                    return name === state.name;
                },
                get disabled(){
                    return state.loading && !this.loading;
                },
            },
        }));
    },
    useActions = (Actions, data) => {

        const actions = _.reduce(Actions, (r, v, id) => {
                const {check = () => true} = v;
                return r.concat(check(data) ? Object.assign(v, {id}) : []);
            }, []),
            render = () => actions.map(({click, render}) => render(click));

        return {render, actions};

    },
    setBank = (Code, Name, api) => {
        const user = useUser(), key = 'swiftCode', label = LANG_CN === user.language ? 'combBankNameLocal' : 'combBankName';
        Object.assign(Name, {render: ({label}) => <span title={label} >{label}</span>});
        _.each([[Code, Name], [Name, Code, {label}]], ([field, relate, search = {}]) => {
            Object.assign(field, {
                props: {
                    onSearch: api,
                    filterOption: false,
                    optionLabelProp: 'children',
                    onSelect: (item, {rowId}) => _.invoke(relate.setViews, rowId, item),
                },
                search: {key, value: key, ...search},
            });
        });
    },
    toProxy = (source = {}, proxy) => _.reduce(proxy, (r, v, k) => Object.defineProperty(r, k, {
        get: () => v.value,
        set: val => v.value = val,
        enumerable: true,
    }), source),
    forwardRef = (_ref, expose, instance = {}) => {
        expose(instance);
        watch(_ref, r => Object.assign(instance, r));
    },
    findFields = (tag, cb) => findFieldByIds(tag, Object.keys(tag).join(''), cb),
    findFieldByIds = (tag, ids = '', cb = _.noop) => {
        const fieldMap = {}, fields = ids.split('').map(id => fieldMap[id] = _.cloneDeep(tag[id]));
        cb(fieldMap, fields);
        return fields;
    };

export {
    COLON,
    EMPTY_VAL,
    Empty,
    Field,
    Rules,
    Utils,
    Filters,
    useBack,
    useData,
    useType,
    useUser,
    useAmount,
    useLoading,
    useActions,
    setBank,
    toProxy,
    forwardRef,
    findFields,
    findFieldByIds,
}
