
import { computed, defineComponent } from 'vue'
import { EyeOutlined, DownloadOutlined, FilePdfFilled } from '@ant-design/icons-vue'
import { ossPreview } from '@/common/oss'
import { downUploadFileAll } from '@/api/finance-order'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'file-list',
  props: {
    fileList: {
      type: Array,
      required: true
    },
    batchFileTitle: {
      type: String,
      default: t('common.downloadBatch')
    },
    showIcon: Boolean
  },
  emits: ['update:fileList', 'handleManualRemove', 'handleRemove'],
  components: { FilePdfFilled, EyeOutlined, DownloadOutlined },
  setup (props) {
    const handlePreview = (f:any) => {
      ossPreview(f)
    }
    const extNameList = computed(() => {
      const res:string[] = []
      props.fileList.forEach((i:any) => {
        const name = i?.name?.split('.')
        res.push(name?.[name.length - 1]?.toUpperCase())
      })
      return res
    })

    const handleDownload = (f:any) => {
      const list = props.fileList.map((item:any) => item.objectKey || item.uid)
      downUploadFileAll(list, props.batchFileTitle)
    }

    return {
      extNameList,
      handlePreview,
      handleDownload
    }
  }
})
