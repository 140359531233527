import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePdfFilled = _resolveComponent("FilePdfFilled")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_slot_extra = _resolveComponent("slot-extra")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (f, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: f.uid,
        class: _normalizeClass([_ctx.$style.file, _ctx.$style[f.status]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.file_content)
        }, [
          _createVNode(_component_FilePdfFilled, {
            class: _normalizeClass(_ctx.$style.preview_icon)
          }, null, 8, ["class"]),
          _createElementVNode("span", {
            title: f.name,
            class: _normalizeClass(_ctx.$style.file_name)
          }, _toDisplayString(f.name), 11, _hoisted_1),
          (f.status === 'done')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!['ZIP', 'RAR'].includes(_ctx.extNameList[i]))
                  ? (_openBlock(), _createBlock(_component_EyeOutlined, {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.icon_size),
                      onClick: _withModifiers(($event: any) => (_ctx.handlePreview(f)), ["stop"])
                    }, null, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_DownloadOutlined, {
                  onClick: _withModifiers(($event: any) => (_ctx.handleDownload(f)), ["stop"]),
                  class: _normalizeClass([_ctx.$style.download_icon, _ctx.$style.icon_size])
                }, null, 8, ["onClick", "class"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.handleCanDelete(f.response?.data.groupKey))
                  ? (_openBlock(), _createBlock(_component_DeleteOutlined, {
                      key: 0,
                      style: {"marginLeft":"12px"},
                      class: _normalizeClass(_ctx.$style.icon_size),
                      onClick: _withModifiers(($event: any) => (_ctx.handleDelete(f)), ["stop"])
                    }, null, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_slot_extra, {
                  data: f.response?.data
                }, null, 8, ["data"])
              ], 64))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.showTime && f.response?.data?.createTime)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.dateTip)
            }, _toDisplayString(_ctx.$t('common.uploadTime')) + "： " + _toDisplayString(_ctx.$tz.f(f.response?.data?.createTime, 'D')), 3))
          : _createCommentVNode("", true)
      ], 2))
    }), 128)),
    (!_ctx.fileList.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.emptyBox)
        }, [
          _createVNode(_component_a_empty, { image: _ctx.simpleImage }, null, 8, ["image"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}