<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import formatter from '@/components/air8/formatter'
import Label from './Label.vue'
export default defineComponent({
  name: 'Air8MoneyLabel',
  aliasName: 'a8-d-currency-label',

  extends: Label,

  computed: {
    internalFormatters () {
      const list = []
      list.push((value) => {
        return formatter.currency(value, this.currency)
      })

      const formatterFunction = this.formatterFunction
      if (!_.isNil(formatterFunction)) {
        list.push(formatterFunction)
      }
      return list
    }
  }
})
</script>
