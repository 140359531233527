import _ from 'lodash'
import TableInnerColumnsBuilder from './TableInnerColumnsBuilder'
import { unref } from 'vue'
export default class ExportTableInnerColumnsBuilder extends TableInnerColumnsBuilder {
  getComponentExportIf (config: any, parentScope: any) {
    if (_.isNil(config)) {
      return false
    }
    return this.getFunctionValue(unref(config.$exportIf), parentScope) !== false
  }

  isColumnDisplay (columnConfig:any): boolean {
    if (_.isNil(columnConfig)) {
      return false
    } else if (!_.isNil(columnConfig.$exportIf)) {
      return this.getComponentExportIf(columnConfig, this.getParentScope())
    } else {
      return this.getComponentIf(columnConfig, this.getParentScope())
    }
  }

  dealEditField (columnConfig:any) {
    // only for override
  }

  dealTitleField (columnConfig: any) {
    // only for override
  }

  isEditable (): boolean {
    return false
  }
}
