import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import frontGuard from './front-guard'
import postGuard from './post-guard'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(frontGuard)
router.afterEach(postGuard)

export default router
