import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_air8_delete_link_button = _resolveComponent("air8-delete-link-button")!
  const _component_notice_vue = _resolveComponent("notice-vue")!
  const _component_upload_list = _resolveComponent("upload-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['air8-file-upload_header'])
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(_ctx.$style['air8-file-upload_header-title'])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['air8-file-upload_header-action'])
      }, [
        _renderSlot(_ctx.$slots, "upload", {}, () => [
          _createVNode(_component_a_upload, {
            multiple: "",
            ref: "uploadRef",
            name: _ctx.name,
            disabled: _ctx.ifDisable,
            customRequest: _ctx.ossRequest,
            "file-list": _ctx.fileList,
            "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => (_ctx.fileList = $event)),
            "before-upload": _ctx.handleBeforeUpload,
            onChange: _ctx.handleChange,
            accept: _ctx.type
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                size: "small",
                type: "link",
                class: _normalizeClass(_ctx.$style['icon-btn']),
                disabled: _ctx.ifDisable
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_svg_icon, { icon: "upload" })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('common.upload')), 1)
                ]),
                _: 1
              }, 8, ["class", "disabled"])
            ]),
            _: 1
          }, 8, ["name", "disabled", "customRequest", "file-list", "before-upload", "onChange", "accept"])
        ]),
        (_ctx.isDelete)
          ? (_openBlock(), _createBlock(_component_air8_delete_link_button, {
              key: 0,
              class: _normalizeClass([_ctx.$style['btn-delete'], _ctx.$style['icon-btn']]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteAll()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_svg_icon, { icon: "delete" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.upload.delete')), 1)
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.tips)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style['air8-file-upload_notice'])
        }, [
          _createVNode(_component_notice_vue),
          _createTextVNode(" " + _toDisplayString(_ctx.tips), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_upload_list, _mergeProps({
      "file-list": _ctx.fileList,
      "onUpdate:file-list": _cache[2] || (_cache[2] = ($event: any) => (_ctx.fileList = $event))
    }, _ctx.$attrs, {
      onHandleManualRemove: _ctx.uploadRef?.handleManualRemove,
      onHandleRemove: _ctx.uploadRef?.handleRemove
    }), null, 16, ["file-list", "onHandleManualRemove", "onHandleRemove"])
  ]))
}