import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
    return _renderSlot(_ctx.$slots, "default", { row: row }, () => [
      _createVNode(_component_a_select_option, {
        value: row.key
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(row.value), 1)
        ]),
        _: 2
      }, 1032, ["value"])
    ])
  }), 256))
}