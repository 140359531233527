export const PROP_TYPE = {
  INPUT: 'input',
  DATE: 'date',
  ENUM: 'enum',
  CURRENCY: 'currency',
  NUMBER: 'number',
  CUSTOMER: 'customer',
  PERCENT: 'percent',
  NORMAL_NUMBER: 'normalNumber'
}

export const DATA_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  INTEGER: 'integer',
  FLOAT: 'float',
  DATE: 'date',
  ARRAY: 'array'
}

export const FIELD_TYPE = {
  TEXT: 'A8DText',
  SELECT: 'A8DSelect',
  RADIO_GROUP: 'A8DRadioGroup',
  TEXTAREA: 'A8DTextarea',
  NUMBER: 'A8DNumber',
  NORMAL_NUMBER: 'A8DNormalNumber',
  CURRENCY: 'A8DCurrency',
  PERCENT: 'A8DPercent',
  DATE: 'A8DDate',
  EXCHANGE: 'A8DExchange',
  TABLE: 'A8DTable'
}

export const MAP_FIELD_TYPE_PROP_TYPE = {
  [FIELD_TYPE.SELECT]: PROP_TYPE.ENUM,
  [FIELD_TYPE.NUMBER]: PROP_TYPE.NUMBER,
  [FIELD_TYPE.NORMAL_NUMBER]: PROP_TYPE.NORMAL_NUMBER,
  [FIELD_TYPE.CURRENCY]: PROP_TYPE.CURRENCY,
  [FIELD_TYPE.PERCENT]: PROP_TYPE.PERCENT,
  [FIELD_TYPE.DATE]: PROP_TYPE.DATE,
  [FIELD_TYPE.RADIO_GROUP]: PROP_TYPE.ENUM
}

export const MAP_DATE_TYPE_FIELD_TYPE = {
  [DATA_TYPE.STRING]: FIELD_TYPE.TEXT,
  [DATA_TYPE.NUMBER]: FIELD_TYPE.NUMBER,
  [DATA_TYPE.INTEGER]: FIELD_TYPE.NUMBER,
  [DATA_TYPE.FLOAT]: FIELD_TYPE.NUMBER,
  [DATA_TYPE.DATE]: FIELD_TYPE.DATE
}

export const FILTER_TYPE = {
  [PROP_TYPE.INPUT]: 'input',
  [PROP_TYPE.DATE]: 'date',
  [PROP_TYPE.ENUM]: 'enum',
  [PROP_TYPE.CURRENCY]: 'input',
  [PROP_TYPE.NUMBER]: 'input',
  [PROP_TYPE.CUSTOMER]: 'input',
  [PROP_TYPE.PERCENT]: 'input',
  [PROP_TYPE.NORMAL_NUMBER]: 'number'
}
