<template>
  <div>
    <a-card
      v-show="isShowLoadingCard"
      class="air8-ocr-upload__loading-card"
    >
      <div class="air8-ocr-upload__loading-card-title">
        <a-space>
          <a-spin />
          <span>
            {{ loadingMessage }}
          </span>
        </a-space>
      </div>
    </a-card>
    <a-upload
      v-show="isShowUploadCard"
      class="air8-ocr-upload__upload"
      ref="uploadRef"
      :showUploadList="false"
      :accept="acceptType"
      :multiple="multiple"
      :customRequest="uploadFileRequest"
      :beforeUpload="beforeUpload"
      v-model:file-list="sFileList"
      @change="handleChange"
    >
      <a-card class="air8-ocr-upload__upload-card">
        <template #cover>
          <div class="air8-ocr-upload__upload-card-upload">
            <PlusOutlined />
          </div>
        </template>
        <a-card-meta>
          <template
            #title
            v-if="internalTitle"
          >
            <span class="air8-ocr-upload__upload-card-title">{{ internalTitle }}</span>
          </template>
          <template
            #description
            v-if="internalDescription"
          >
            <span class="air8-ocr-upload__upload-card-description">{{ internalDescription }}</span>
          </template>
        </a-card-meta>
      </a-card>
    </a-upload>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { ossRequestWithLoading } from '@/common/oss'
import _ from 'lodash'
import { useLocal } from '@/plugins/locale'
import { notification } from 'ant-design-vue'
import { getOcrResult } from '@/api/ocr'

export default defineComponent({
  name: 'Air8OcrUploadList',
  components: {
    PlusOutlined
  },

  props: {
    fileList: Array,
    type: {
      type: Array,
      default: () => ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC', 'XLS', 'XLSX', 'ZIP', 'MSG']
    },
    ocrType: {
      type: Array,
      default: () => ['JPG', 'JPEG', 'BMP', 'PNG', 'PDF', 'MSG']
    },
    title: String,
    description: String,
    schemaCode: String,
    maxlength: {
      type: Number,
      default: 20
    },
    loadingMessage: String,
    multiple: Boolean
  },

  emits: ['update:file', 'update:file-list', 'update:ocr-result-list', 'update:ocr-result', 'file-error', 'ocr-success', 'ocr-error', 'update:loading'],

  setup (props, { emit }) {
    const { t } = useLocal()

    const uploadRef = ref()
    const uploadFileStatus = ref()
    const ocrLoading = ref(false)
    const internalFileList = ref()
    const sFileList = ref()
    const ocrResult = ref({})

    const isShowLoadingCard = computed(() => {
      return uploadFileStatus.value === 'uploading' || ocrLoading.value
    })

    watch(isShowLoadingCard, () => {
      emit('update:loading', isShowLoadingCard.value)
    }, { immediate: true })

    const isShowUploadCard = computed(() => {
      return !isShowLoadingCard.value && (_.isNil(props.maxlength) || props.maxlength > _.size(internalFileList.value))
    })

    const acceptType = computed(() => {
      return _.join(_.map(props.type, (value) => `.${value}`), ',')
    })

    function updateOcrResultByFileList () {
      ocrResult.value = _.pick(ocrResult.value, _.map(internalFileList.value, 'uid'))
    }

    watch(() => props.fileList, () => {
      internalFileList.value = props.fileList || []
      sFileList.value = _.clone(props.fileList) || []

      updateOcrResultByFileList()
    }, { immediate: true })

    function addFile (file) {
      file.createTime = new Date()
      if (_.isNil(internalFileList.value)) {
        internalFileList.value = [file]
      } else {
        internalFileList.value.push(file)
      }
      emit('update:file', file)
      emit('update:file-list', internalFileList.value)
    }

    function removeFile (file) {
      _.pull(internalFileList.value, file)
      emit('update:file', undefined)
      emit('update:file-list', internalFileList.value)
    }

    async function handleChange ({ file }) {
      if (file.status === 'done') {
        await doOcr(file)
        addFile(file)
      } else if (file.status === 'removed') {
        removeOcr(file)
        removeFile(file)
      } else if (file.status === 'error') {
        emit('file-error')
      }
      uploadFileStatus.value = file.status
    }

    async function ocrFile (file, schemaCode) {
      if (_.isEmpty(schemaCode)) {
        // NO OCR
        return
      }
      const fileName = _.toLower(file.name)
      const isOcrSupportType = _.findIndex(props.ocrType, (type) => {
        return _.endsWith(fileName, `.${_.toLower(type)}`)
      }) !== -1
      if (!isOcrSupportType) {
        return
      }
      const result = await getOcrResult({ fileKey: _.get(file, 'response.data.objectKey'), schemaCode })
      return _.get(result, 'extractData')
    }

    async function doOcr (file) {
      updateOcrResultByFileList()

      ocrLoading.value = true
      try {
        const result = await ocrFile(file, props.schemaCode)
        if (!_.isNil(result)) {
          _.set(ocrResult.value, file.uid, result)
          emit('ocr-success', _.get(ocrResult.value, file.uid))
          emit('update:ocr-result-list', _.values(ocrResult.value))
          emit('update:ocr-result', _.clone(ocrResult.value))
        }
      } catch (e) {
        emit('ocr-error', e)
      }
      ocrLoading.value = false
    }

    function getString (text, index, defaultText) {
      if (_.isEmpty(text)) {
        return defaultText
      } else if (_.isArray(text)) {
        return _.nth(text, index % _.size(text))
      } else {
        return text
      }
    }

    async function ocrAllFiles (schemaCode) {
      const allPromise = _.map(internalFileList.value, (file, index) => {
        return ocrFile(file, getString(schemaCode, index)).catch(e => undefined)
      })

      const resultList = await Promise.all(allPromise)
      return _.flatten(resultList)
    }

    async function removeOcr (file) {
      updateOcrResultByFileList()
      if (!_.has(ocrResult.value, file.uid)) {
        return
      }
      delete ocrResult.value[file.uid]
      emit('update:ocr-result-list', _.values(ocrResult.value))
      emit('update:ocr-result', _.clone(ocrResult.value))
    }

    function beforeUpload (file, fileList) {
      if (file.type === 'application/x-zip-compressed' && file.size > 100 * 1024 * 1024) {
        notification.error({ message: t('common.upload.zip_maxsize_tip') })
        return false
      }
      if (file.type !== 'application/x-zip-compressed' && file.size > 20 * 1024 * 1024) {
        notification.error({ message: t('common.upload.file_maxsize_tip') })
        return false
      }
      if (internalFileList.value.length + fileList.length > props.maxlength) {
        if (fileList[fileList.length - 1].uid === file.uid) {
          notification.error({ message: t('common.upload.file_limit_tip', String(props.maxlength)) })
        }
        return false
      }

      uploadFileStatus.value = undefined
      return true
    }

    function uploadFileRequest (options) {
      ossRequestWithLoading(options, false)
    }

    const internalTitle = computed(() => {
      return props.title || t('common.ocr.upload')
    })

    const internalDescription = computed(() => {
      return props.description || t('common.ocr.supported_types', _.join(props.type, ', '))
    })

    function remove (index) {
      uploadRef.value.handleRemove(_.nth(internalFileList.value, index))
    }

    return {
      uploadRef,
      acceptType,
      handleChange,
      beforeUpload,
      uploadFileRequest,
      isShowLoadingCard,
      isShowUploadCard,
      internalFileList,
      uploadFileStatus,
      sFileList,
      ocrResult,
      internalTitle,
      internalDescription,
      remove,
      ocrAllFiles
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-ocr-upload__upload-card {
  text-align: center;
  cursor: pointer;

  :deep(.ant-card-cover) {
    .anticon {
      color: @color-primary;
    }
  }

  .air8-ocr-upload__upload-card-upload {
    padding-top: 32px;
    padding-bottom: 18px;

    .anticon {
      font-size: 80px;
    }
  }
}

.air8-ocr-upload__loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.air8-ocr-upload__upload {
  :deep(.ant-upload) {
    width: 100%;
  }
}
</style>
