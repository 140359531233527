<template>
  <a-popover :get-popup-container="getPopupContainer">
    <template v-slot:content>
      <slot name="content"></slot>
    </template>
    <a-button
      v-bind="$attrs"
      @click="handleClick"
      :loading="loading"
    >
      <slot></slot>
    </a-button>
  </a-popover>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8PopoverButton',
  props: {
    loading: Boolean
  },

  emits: ['click'],

  methods: {
    getPopupContainer () {
      return _.get(document.getElementsByClassName('air8-popup__container'), '0')
    },

    handleClick () {
      this.$emit('click')
    }
  }
})
</script>
