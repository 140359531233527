import { computed, reactive } from 'vue'
import { PageData } from '@/common/page-data'

export function createSorter (code: string, pageData?: PageData<any>) {
  if (!pageData) return {}
  const getOrder = (code: string) => {
    if (pageData.params?.sorter?.prop !== code) return false
    else {
      if (pageData.params?.sorter?.order === 'asc') return 'ascend'
      if (pageData.params?.sorter?.order === 'desc') return 'descend'
      return false
    }
  }
  const sorter = {
    sorter: true,
    sortOrder: computed(() => getOrder(code))
  }
  return sorter
}
