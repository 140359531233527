<script>
import { defineComponent } from 'vue'
import { Form } from 'ant-design-vue'

export default defineComponent({
  extends: Form,
  name: 'Air8SimpleForm',
  aliasName: 'i-form',
  props: {
    layout: {
      type: String,
      default: 'none',
      validator (value) {
        return ['horizontal', 'inline', 'vertical', 'none'].includes(value)
      }
    }
  },

  setup (props, ctx) {
    return Form.setup(props, ctx)
  }
})
</script>
