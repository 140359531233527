<template>
  <c0 :config="config"></c0>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import _ from 'lodash'
import { matchDate, matchString, matchValue } from '@/utils/match'
import { useTimeZone } from '@/plugins/time-zone'
import * as NumberUtils from '@/utils/number'
export default defineComponent({
  name: 'Air8LocalSearchListPage',
  inheritAttrs: false,

  props: {
    data: [Function, Array],
    sortField: [String, Array],
    sortOrderAsc: {
      type: Boolean,
      default: true
    },
    reloadWhenDataChange: {
      type: Boolean,
      default: true
    },
    showPage: {
      type: Boolean,
      default: true
    }
  },

  setup (props, { attrs, slots }) {
    const { tz } = useTimeZone()
    const searchListPage = ref()

    function getFilters (filtersParams, filterFieldNames, replaceStarMark) {
      return _.reduce(searchListPage.value.getTableRealColumns(), (result, column) => {
        const field = column.dataIndex
        let queryField
        if (_.isString(filterFieldNames)) {
          filterFieldNames = _.castArray(filterFieldNames)
        }
        for (const filterFieldName of filterFieldNames) {
          queryField = _.get(column, filterFieldName)
          if (!_.isNil(queryField)) {
            break
          }
        }
        if (_.isNil(queryField)) {
          queryField = column.dataIndex
        }
        switch (column.$propType) {
          case 'date':
            if (!_.isEmpty(_.get(filtersParams, `${queryField}Begin`)) || !_.isEmpty(_.get(filtersParams, `${queryField}End`))) {
              result.push((value) => {
                return matchDate(_.get(value, field),
                  _.get(filtersParams, `${queryField}Begin`),
                  _.get(filtersParams, `${queryField}End`)
                )
              })
            }
            break
          case 'enum':
            if (!_.isNil(_.get(filtersParams, queryField)) && _.get(filtersParams, queryField) !== '') {
              result.push((value) => {
                return matchValue(_.get(value, field), _.get(filtersParams, queryField))
              })
            }
            break
          default:
            if (!_.isEmpty(_.get(filtersParams, queryField))) {
              const queryFields = _.split(queryField, ',')
              result.push((value) => {
                let filterValue = _.get(filtersParams, queryField)
                if (replaceStarMark) {
                  filterValue = _.replace(filterValue, /\*(.*)/g, '$1')
                  filterValue = _.replace(filterValue, /(.*)\*/g, '$1')
                }
                if (_.size(queryFields) === 1) {
                  return matchString(_.get(value, field), filterValue)
                }
                return _.some(queryFields, (valueField) => {
                  return matchString(_.get(value, valueField), filterValue)
                })
              })
            }
        }
        return result
      }, [])
    }

    function dealQueryData (params, dataChain) {
      const queryFilters = getFilters(params, ['$query.prop', '$queryName'])
      if (!_.isEmpty(queryFilters)) {
        dataChain = dataChain.filter((value) => {
          return _.every(queryFilters, (fun) => fun(value))
        })
      }
      return dataChain
    }

    function dealSortByProp (dataChain) {
      if (props.sortField) {
        dataChain = dataChain.sortBy(_.castArray(props.sortField))
        if (!props.sortOrderAsc) {
          dataChain = dataChain.reverse()
        }
      }
      return dataChain
    }

    function dealFilterData (params, dataChain) {
      if (!_.isNil(params.filters)) {
        const filterParams = _.reduce(params.filters, (result, item) => {
          if (_.isArray(item.value) && _.get(item.value, '0._isAMomentObject')) {
            result[`${item.name}Begin`] = tz.f(_.get(item.value, '0'), 'D')
            result[`${item.name}End`] = tz.f(_.get(item.value, '1'), 'D')
          }
          result[item.name] = item.value
          return result
        }, {})
        const filters = getFilters(filterParams, '$filter.field', true)
        dataChain = dataChain.filter((value) => {
          return _.every(filters, (fun) => fun(value))
        })
      }
      return dataChain
    }

    function getFilterListResult (params, dataChain, start, end) {
      if (!_.isNil(params.filterFields)) {
        const filters = getFilters(params.filterFields, '$filter.field', true)
        dataChain = dataChain.filter((value) => {
          return _.every(filters, (fun) => fun(value))
        })
      }

      if (params.filterByField) {
        dataChain = dataChain.map(params.filterByField)
          .union()
          .map((v) => ({ [params.filterByField]: v }))
        start.value = 0
        end.value = undefined
      }
      return dataChain
    }

    function dealSorter (params, dataChain) {
      if (params.sorter) {
        dataChain = dataChain.sortBy(_.castArray(_.get(params.sorter, 'prop')))
        if (_.get(params.sorter, 'order') === 'desc') {
          dataChain = dataChain.reverse()
        }
      }
      return dataChain
    }

    function dealSumer (params, dataChain, start, end) {
      if (params.amountSumByField) {
        const sumResult = dataChain.value()
        const summary = NumberUtils.sumList(sumResult, params.amountSumByField)
        dataChain = _.chain({ totalAmount: summary }).castArray()
        start.value = 0
        end.value = undefined
      }
      return dataChain
    }

    async function internalSelectedQuery (params) {
      const start = ref((params.page - 1) * params.size)
      const end = ref(start.value + params.size)

      let data = props.data || []
      if (_.isFunction(data)) {
        data = await props.data(params)
      }

      let dataChain = _.chain(data)
      dataChain = dealQueryData(params, dataChain)
      dataChain = dealSortByProp(dataChain)
      dataChain = dealFilterData(params, dataChain)
      dataChain = getFilterListResult(params, dataChain, start, end)
      dataChain = dealSorter(params, dataChain)
      dataChain = dealSumer(params, dataChain, start, end)
      const rows = dataChain.value()

      if (!props.showPage) {
        start.value = undefined
        end.value = undefined
      }

      return {
        total: _.size(rows),
        rows: _.slice(rows, start.value, end.value),
        page: params.page,
        size: params.size
      }
    }

    const config = computed(() => {
      return {
        $type: 'Air8SearchListPage',
        ref: searchListPage,
        ...attrs,
        showPage: props.showPage,
        query: internalSelectedQuery,
        $slots: slots
      }
    })

    return {
      config,
      searchListPage
    }
  },

  watch: {
    data () {
      if (!this.reloadWhenDataChange) {
        return
      }
      this.reload()
    }
  },

  methods: {
    reload () {
      return this.searchListPage.reload()
    },

    initLoad () {
      return this.searchListPage.initLoad()
    },

    resetParams () {
      return this.searchListPage.resetParams()
    },

    getTable () {
      return this.searchListPage.getTable()
    },

    getDataTable () {
      return this.searchListPage.getDataTable()
    },

    getTableForm () {
      return this.searchListPage.getTableForm()
    },

    getTableRealColumns () {
      return this.searchListPage.getTableRealColumns()
    },

    isDataSourceEmpty () {
      return this.getDataTable().isDataSourceEmpty
    }
  }
})
</script>
