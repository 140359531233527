<template>
  <div>
    <div v-if="isHideDueDate">-</div>
    <div v-else
      :class="{ [$style['air8-due-date']]: true, [$style['air8-due-date__readonly']]: !editable }">
      <air8-form-item
        ref="dueDateFormItem"
        :type="inputComp"
        :name="dueDateFromName"
        :label="$t('page.org.company.blDuteDateFrom')"
        :hide-label="true"
        :rules="internalRulesForm"
        @blur="handleBlurPrefix"
        :show-diff-tag="showDiffTag"
      >
      </air8-form-item>
      <span v-if="editable" :class="$style['air8-due-date__equal']"> - </span>
      <span v-else :class="$style['air8-due-date__equal']"> -- </span>
      <air8-form-item
        ref="dueDateToItem"
        :type="inputComp"
        :name="dueDateToName"
        :label="$t('page.org.company.blDuteDateTo')"
        :hide-label="true"
        :rules="interanlRuleTo"
        @blur="handleBlurSuffix"
        :show-diff-tag="showDiffTag"
      >
      </air8-form-item>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { injectForm } from '@/components/air8/utils/componentUtils'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8DueDateFormTo',
  props: {
    dueDateFromName: String,
    dueDateToName: String,
    rulesPrefix: Array,
    rulesSuffix: Array,
    isDueDateRange: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    showDiffTag: Boolean
  },

  emits: ['blur'],

  setup (props, { emit }) {
    const form = injectForm()
    const model = form.model
    const isEmptyDate = computed(() => false)

    const internalRulesForm = computed(() => {
      if (_.isNil(props.rulesPrefix)) {
        return []
      } else {
        return props.rulesPrefix
      }
    })

    const interanlRuleTo = computed(() => {
      if (_.isNil(props.rulesSuffix)) {
        return []
      } else {
        return props.rulesSuffix
      }
    })

    const inputComp = computed(() => {
      if (props.editable) {
        return 'a-input'
      } else {
        return 'Air8Label'
      }
    })
    const isHideDueDate = computed(() => {
      return !props.editable && !props.isDueDateRange
    })

    function handleBlurPrefix () {
      emit('blur')
    }

    function handleBlurSuffix () {
      emit('blur')
    }

    return {
      inputComp,
      internalRulesForm,
      isHideDueDate,
      interanlRuleTo,
      isEmptyDate,
      handleBlurPrefix,
      handleBlurSuffix,
      ...props
    }
  }
})
</script>
<style lang="less" module>
.air8-due-date {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  & > :global(.air8-form-item) {
    min-width: 160px;
    flex: 1 1 0;
  }
  & > :global(.air8-form-item .ant-form-item-control) {
      max-width: calc(100% - 5px) !important;
  }

  & > :global(span) {
    padding-top: 5px;
  }

  .air8-due-date__equal {
    margin: 0 3px;
  }
  &.air8-due-date__readonly {
    flex-wrap: wrap;
    margin-bottom: 16px;
    :global(.air8-form-item) {
      flex: none;
      margin-bottom: 0px;
      :global(.ant-form-item-control){
        max-width: calc(100% - 0px) !important;
      }
    }
  }
}
</style>
