<template>
  <div class="compose-multi-select-table">
    <air8-collapse-inner-card
      :title="addTitle"
      :expand="addExpand"
    >
      <template v-slot:subTitle>
        <slot name="addSubTitle"></slot>
      </template>
      <air8-search-list-page
        ref="addTable"
        :rowKey="rowKey"
        :nested="true"
        nested-type="none"
        :query="addQuery"
        :columns="internalAddColumns"
        :table-row-actions="internalAddTableRowActions"
        :summary="addSummary"
        :pageSizeOptions="['5', '10', '20', '50', '100']"
        :row-selection="true"
        :disabled-selection="value"
        v-model:selection="internalAddTableSelection"
        @query="handleAddTableQuery"
        :query-col-span="addQueryColSpan"
        :default-query="{ size: 5 }"
        :filterable="addFilterable"
        :sorterable="addSorterable"
        :sumerable="addSumerable"
        :scroll="internalAllScroll"
      >
        <template v-slot:extra>
          <div class="compose-multi-select-table__extra">
            <a-button
              type="primary"
              @click="handleClickBatchAdd"
            >{{ textBatchAdd }}</a-button>
            <slot name="extraAdd"></slot>
          </div>
        </template>
      </air8-search-list-page>
    </air8-collapse-inner-card>

    <air8-inner-card
      :title="selectedTitle"
      v-if="displaySelectedTable"
    >
      <template v-slot:subTitle>
        <slot name="selectedSubTitle"></slot>
      </template>
      <air8-local-search-list-page
        ref="selectedTable"
        :rowKey="rowKey"
        :nested="true"
        nested-type="none"
        :data="value"
        :columns="internalSelectedColumns"
        :table-row-actions="internalSelectedTableRowActions"
        :show-page="false"
        :summary="selectedSummary"
        :row-selection="true"
        v-model:selection="internalSelectedTableSelection"
        :editable="true"
        @query="handleSelectedTableQuery"
        :scroll="internalSelectedScroll"
        :query-col-span="selectedQueryColSpan"
        :filterable="selectedFilterable"
        :sorterable="selectedSorterable"
        :sumerable="selectedSumerable"
      >
        <template v-slot:extra>
          <div class="compose-multi-select-table__extra">
            <a-button
              type="primary"
              @click="handleClickBatchDelete"
            >{{ textBatchDelete }}</a-button>
            <slot name="extraSelected"></slot>
          </div>
        </template>
      </air8-local-search-list-page>
    </air8-inner-card>
  </div>
</template>
<script>
import { defineComponent, ref, computed, unref, reactive } from 'vue'
import { PageData } from '@/common/page-data'
import { t } from '@/config/locale'
import _ from 'lodash'
import { removeKeyField } from '../../utils/componentUtils'
import { sumList } from '@/utils/number'
import { message } from 'ant-design-vue'
import { matchDate, matchString, matchValue } from '@/utils/match'

export default defineComponent({
  name: 'ComposeMultiSelectTable',

  props: {
    rowKey: String,
    addTitle: String,
    textBatchAdd: String,
    messageNoAdd: String,
    addQuery: {
      type: [PageData, Function]
    },
    addColumns: {
      type: Array,
      default: () => []
    },
    addSummary: [String, Object],
    addQueryColSpan: Number,
    textDetail: {
      type: String,
      default: () => t('common.detail')
    },
    textAdd: {
      type: String,
      default: () => t('common.add')
    },
    selectedTitle: String,
    textBatchDelete: String,
    messageNoDelete: String,
    selectedColumns: {
      type: Array,
      default: () => []
    },
    selectedSummary: [Array, Object],
    selectedQueryColSpan: Number,
    textDelete: {
      type: String,
      default: () => t('common.delete')
    },
    value: {
      type: Array,
      default: () => []
    },
    sortField: [String, Array],
    sortOrderAsc: {
      type: Boolean,
      default: true
    },
    afterAdd: Function,
    addExpand: {
      type: Boolean,
      default: true
    },
    addFilterable: {
      type: Boolean,
      default: false
    },
    addSorterable: {
      type: Boolean,
      default: false
    },
    addSumerable: {
      type: Boolean,
      default: false
    },
    selectedFilterable: {
      type: Boolean,
      default: false
    },
    selectedSorterable: {
      type: Boolean,
      default: false
    },
    selectedSumerable: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:value', 'click:detail'],

  setup () {
    const internalAddTableSelection = ref([])
    const internalSelectedTableSelection = ref([])

    return {
      internalAddTableSelection,
      internalSelectedTableSelection
    }
  },

  computed: {
    internalAddColumns () {
      return [
        ...this.addColumns,
        { key: 'action', width: 120, fixed: 'right' }
      ]
    },

    internalSelectedColumns () {
      return [
        ...this.selectedColumns,
        { key: 'action', width: 120, fixed: 'right' }
      ]
    },

    internalAddTableRowActions () {
      return [
        {
          $children: this.textDetail,
          $FonClick: (rowScope) => {
            return () => this.$emit('click:detail', rowScope)
          }
        },
        {
          $visible: ({ record }) => {
            return !this.isSelected(record)
          },
          $children: this.textAdd,
          $FonClick: (rowScope) => {
            return () => this.handleClickAdd2SelectedTable(rowScope)
          }
        }
      ]
    },

    internalSelectedTableRowActions () {
      return [
        {
          $children: this.textDelete,
          $FonClick: (rowScope) => {
            return () => this.handleClickDeleteSelectedItem(rowScope)
          }
        }
      ]
    },

    internalAllScroll () {
      return {
        x: 'auto',
        y: 284
      }
    },

    internalSelectedScroll () {
      return 'auto'
    },

    displaySelectedTable () {
      return !_.isEmpty(this.value)
    }
  },

  methods: {
    isSelected (item) {
      if (_.isNil(item)) {
        return false
      }

      return _.findIndex(this.value, (child) => {
        if (_.isEmpty(this.rowKey)) {
          return _.isEqual(removeKeyField(child, '_'), item)
        } else {
          return _.isEqual(_.get(child, this.rowKey), _.get(item, this.rowKey))
        }
      }) !== -1
    },

    updateSelectedTable (value) {
      this.$emit('update:value', value)
      this.$nextTick().then(() => {
        if (this.$refs.selectedTable) {
          this.$refs.selectedTable.reload()
        }
      })
    },

    clearAddTableSelected () {
      this.internalAddTableSelection = []
    },

    clearSelectedTableSelected () {
      this.internalSelectedTableSelection = []
    },

    handleClickAdd2SelectedTable (rowScope) {
      const item = removeKeyField(rowScope.record, '_')
      if (this.isSelected(item)) {
        return
      }

      const value = this.afterAdd2SelectedTable(_.cloneDeep([item]))

      this.updateSelectedTable(_.concat(this.value, value))
      this.clearAddTableSelected()
    },

    handleClickDeleteSelectedItem (rowScope) {
      this.updateSelectedTable(_.difference(this.value, [rowScope.record]))
    },

    handleClickBatchAdd () {
      let value = this.internalAddTableSelection
      if (_.isEmpty(value)) {
        message.warning(this.messageNoAdd)
        return
      }

      value = _.filter(value, (item) => {
        item = removeKeyField(item, '_')
        return !this.isSelected(item)
      })

      value = this.afterAdd2SelectedTable(_.cloneDeep(value))

      this.updateSelectedTable(_.concat(this.value, value))
      this.clearAddTableSelected()
    },

    handleClickBatchDelete () {
      const value = this.internalSelectedTableSelection
      if (_.isEmpty(value)) {
        message.warning(this.messageNoDelete)
        return
      }

      this.updateSelectedTable(_.difference(this.value, value))
      this.clearSelectedTableSelected()
    },

    handleAddTableQuery () {
      this.clearAddTableSelected()
    },

    handleSelectedTableQuery () {
      this.clearSelectedTableSelected()
    },

    afterAdd2SelectedTable (value) {
      if (_.isFunction(this.afterAdd)) {
        return this.afterAdd(value)
      }
      return value
    },

    reset () {
      _.invoke(this.$refs.addTable, 'resetParams')
      _.invoke(this.$refs.addTable, 'reload')
      this.clearAddTableSelected()
    },

    getForm () {
      return _.invoke(this.$refs.selectedTable, 'getTableForm')
    }
  }
})
</script>
<style lang="less" scoped>
.compose-multi-select-table__extra {
  margin: 12px 0px;
}
</style>
