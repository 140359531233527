<template>
  <air8-popover-button
    class="air8-btn-warning"
    @click="handleClick"
  >
    <template v-slot:content>
      <span :class="$style['air8-warning-popover-button__tip']">
        <ExclamationCircleOutlined />
        {{ tip }}
      </span>
    </template>
    <slot></slot>
  </air8-popover-button>
</template>
<script>
import { defineComponent } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'Air8WarningPopoverButton',
  components: {
    ExclamationCircleOutlined
  },

  props: {
    tip: String
  },

  emits: ['click'],

  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';

.air8-warning-popover-button__tip {
  :global(.anticon) {
    color: @color-warning;
    margin-right: 8px;
  }
}
</style>
