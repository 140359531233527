<script>
import { defineComponent } from 'vue'
import CompanyReceiveAccount from '../companyReceiveAccount/index.vue'
import BankUtils from '@/components/air8/utils/BankUtils'
import _ from 'lodash'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'FunderPaymentAccount',

  extends: CompanyReceiveAccount,

  props: {
    title: {
      type: String,
      default: () => t('common.component.funderPaymentAccount.title')
    }
  },

  computed: {
    internalBankAccounts () {
      return _.chain(_.get(this.internalCompany, 'companyRoleFunder.companyFunderBankAccountDtos', []))
        .filter((account) => {
          return BankUtils.isBankEnabled(account) && _.includes(_.split(account.currency, ','), this.currency)
        }).map((account) => {
          return this.mappingBankAccount({
            ...account,
            currency: this.currency
          })
        }).value()
    }
  }
})
</script>
