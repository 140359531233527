<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { mergeClassName, getComponentAttribute, getByKeyField } from '../../utils/componentUtils'
export default defineComponent({
  name: 'Air8ColumnLayout',

  aliasName: 'T5',

  props: {
    cols: {
      type: Number,
      default: 3
    },
    formLayout: {
      type: String,
      default: 'vertical'
    },
    align: String,
    gutter: {
      type: [Number, Object, Array],
      default: 24
    },
    justify: String,
    wrap: Boolean
  },

  computed: {
    colSpan () {
      return parseInt(24 / this.cols)
    },

    formLayoutClass () {
      return `ant-form-${this.formLayout}`
    },

    innerClass () {
      return mergeClassName(getComponentAttribute(this.$attrs, 'class'),
        getComponentAttribute(this.$attrs, 'className'),
        `${this.formLayoutClass} air8-column-layout`)
    }
  },

  methods: {
    removeCommentChildNodes () {
      let children = this.$slots.default()
      if (_.size(children) === 1 && _.isSymbol(_.get(children, '0.type')) && _.get(children, '0.key') === '_default') {
        children = _.get(children, '0.children')
      }

      children = _.map(children, (child) => {
        if (_.isSymbol(_.get(child, 'type')) || (_.get(child, 'key') === '_default')) {
          return _.get(child, 'children')
        }
        return child
      })

      return _.filter(_.flatten(children), (comp) => {
        return !_.isNil(comp) && !_.isSymbol(comp.type)
      })
    }
  },

  render () {
    const childComponents = this.removeCommentChildNodes()
    return (
      <a-row class={this.innerClass} type="flex" align={this.align} gutter={this.gutter} justify={this.justify} >
        {
          _.map(childComponents, (child) => {
            let childColProps = getByKeyField(_.assign({}, _.get(child, 'component.ctx.$attrs'), _.get(child, 'props')), /^((layoutCol)|(layout-col)).*/)
            childColProps = _.mapKeys(childColProps, (value, key) => {
              return _.camelCase(_.replace(_.camelCase(key), 'layoutCol', ''))
            })
            const childColSpan = _.get(childColProps, 'span')
            let span = this.colSpan
            if (!_.isNil(childColSpan)) {
              span = childColSpan < 0 ? undefined : childColSpan
            }
            childColProps.span = span

            return <a-col {...childColProps} >{child}</a-col>
          })
        }
      </a-row>
    )
  }
})
</script>
<style lang="less" scoped>
.air8-card__subTitle .air8-column-layout {
  /deep/ .ant-form-item-label {
    label {
      color: #081e4280;
    }
  }
}

.ant-form-vertical.air8-column-layout {
  /deep/ .air8-form-item .ant-form-item-label > label::after {
    display: inherit;
  }
}
.tz-date-style{
  :global(.ant-calendar-picker){
    width: 100%;
  }
}
</style>
