

import { computed, defineComponent, ref } from 'vue'
import { useModal } from '@/hooks/use-modal'
import _ from 'lodash'
import { removeAllKeyField } from '@/components/air8/utils/componentUtils'

export default defineComponent({
  name: 'Air8BackendConfigureDialog',
  inheritAttrs: false,
  setup (props, { attrs }) {
    const { visible, open, params, complete, cancel } = useModal()
    const loading = ref(false)
    const config = computed(() => _.get(params.value, 'config'))
    const scope = computed(() => _.get(params.value, 'scope'))
    const onOk = computed(() => _.get(params.value, 'onOk'))
    const title = computed(() => _.get(params.value, 'title'))

    const intrenalAttrs = computed(() => {
      return removeAllKeyField(attrs, 'on')
    })

    function handleClickCancel () {
      cancel()
    }

    async function handleClickOk () {
      loading.value = true
      try {
        const result = await onOk.value()
        if (result !== false) {
          complete(true)
        }
      } catch (e) {
      }
      loading.value = false
    }

    return {
      handleClickCancel,
      handleClickOk,
      open,
      loading,
      visible,
      intrenalAttrs,
      config,
      scope,
      title
    }
  }
})
