
import { computed, defineComponent, onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { LayoutFormContext, useFormInject } from '../layout-form/use-form'

export default defineComponent({
  name: 'form-input',
  props: {
    prop: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  setup (props: any) {
    const { hide, set, form } = useFormInject() as LayoutFormContext
    const visible = computed(() => !hide(props.prop))
    onMounted(() => { set(props.prop, true) })
    onUnmounted(() => { set(props.prop, false) })

    const wrapEle = ref<HTMLDivElement>()
    const labelEle = ref<HTMLDivElement>()

    const updateStyle = () => {
      const width = (labelEle.value as HTMLDivElement).offsetWidth
      const input = (wrapEle.value as HTMLDivElement).querySelector('input') as HTMLInputElement
      if (input) input.style.paddingLeft = width + 'px'
    }

    // onMounted(() => {
    //   updateStyle()
    // })

    onUpdated(() => {
      updateStyle()
    })

    return {
      form,
      visible,
      wrapEle,
      labelEle
    }
  }
})

