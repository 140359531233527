import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "upload-list-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePdfFilled = _resolveComponent("FilePdfFilled")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_slot_extra = _resolveComponent("slot-extra")!
  const _component_CheckOutlined = _resolveComponent("CheckOutlined")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (f, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: f.uid,
        class: _normalizeClass([_ctx.$style.file, _ctx.$style[f.status]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.file_content)
        }, [
          _createVNode(_component_FilePdfFilled, {
            class: _normalizeClass(_ctx.$style.preview_icon)
          }, null, 8, ["class"]),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.file_name)
          }, _toDisplayString(f.name), 3),
          (f.status === 'done')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!['ZIP', 'RAR'].includes(_ctx.extNameList[i]))
                  ? (_openBlock(), _createBlock(_component_EyeOutlined, {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.icon_size),
                      onClick: ($event: any) => (_ctx.handlePreview(f))
                    }, null, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_DownloadOutlined, {
                  onClick: ($event: any) => (_ctx.handleDownload(f)),
                  class: _normalizeClass([_ctx.$style.download_icon, _ctx.$style.icon_size])
                }, null, 8, ["onClick", "class"])
              ], 64))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.icons)
        }, [
          (_ctx.showTime && f.response?.data?.createTime)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('common.uploadTime')) + "： " + _toDisplayString(_ctx.$tz.f(f.response?.data?.createTime, 'D')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.handleCanDelete(f.response?.data))
                  ? (_openBlock(), _createBlock(_component_DeleteOutlined, {
                      key: 0,
                      style: {"marginLeft":"12px"},
                      class: _normalizeClass(_ctx.$style.icon_size),
                      onClick: ($event: any) => (_ctx.handleDelete(f))
                    }, null, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_slot_extra, {
                  data: f.response?.data
                }, null, 8, ["data"]),
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.$style.status_icon, _ctx.$style.icon_size])
                }, [
                  (f.status === 'done')
                    ? (_openBlock(), _createBlock(_component_CheckOutlined, { key: 0 }))
                    : (f.status === 'error')
                      ? (_openBlock(), _createBlock(_component_CloseOutlined, { key: 1 }))
                      : (f.status === 'uploading')
                        ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 2 }))
                        : _createCommentVNode("", true)
                ], 2)
              ], 64))
            : _createCommentVNode("", true)
        ], 2)
      ], 2))
    }), 128)),
    (!_ctx.fileList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_empty, { image: _ctx.simpleImage }, null, 8, ["image"])
        ]))
      : _createCommentVNode("", true)
  ]))
}