<template>
  <div>
    <c0
      :config="config"
      :scope="{ ...$attrs, ...$props }"
    ></c0>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'
import { downUploadFileAll } from '@/api/finance-order'

export default defineComponent({
  name: 'UploadList',
  props: {
    title: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object,
      default: () => ({})
    },
    batchFileTitle: {
      type: String,
      default: ''
    },
    disabledBatchDownload: {
      type: Boolean
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    diffFn: {
      type: Function
    },
    showDiffTag: Boolean
  },
  setup (props) {
    const downloadInfo = computed(() => { // filter file obj
      const fileAll = {}
      Object.keys(props.title).forEach(k => {
        const value = _.get(props.modelValue, k)
        if (Array.isArray(value)) {
          fileAll[k] = value
        }
      })
      return fileAll
    })

    const itemsList = computed(() => { // get Attachment information
      return downloadInfo.value && _.reduce(downloadInfo.value, (result, value) => {
        return result.concat(value)
      }, [])
    })

    function handleClickDownload () { // download
      if (!_.isEmpty(itemsList.value)) {
        const fileList = _.map(itemsList.value, (item) => item.objectKey)
        downUploadFileAll(fileList, props.batchFileTitle)
      }
    }

    function getConfig () {
      return {
        $type: 'Air8AutoCard',
        title: t('common.attachment_info'),
        $slots: props?.disabledBatchDownload ? {} : {
          extra: {
            $type: 'a-button',
            $if: !props.editable,
            $Fdisabled: () => _.isEmpty(itemsList.value),
            size: 'sm',
            onClick: handleClickDownload,
            $children: t('common.downloadBatch')
          }
        },
        $F$children: () => _.chain(Object.keys(downloadInfo.value))
          .map((v) => {
            return {
              $type: 'upload',
              value: _.get(downloadInfo.value, v),
              tip: false,
              title: _.get(props.title, v),
              isEdit: props.editable,
              allowDeleteHistory: props.allowDelete,
              diffFn: props.diffFn,
              showDiffTag: props.showDiffTag
            }
          }).value()
      }
    }

    const config = getConfig()

    return {
      config,
      downloadInfo,
      itemsList
    }
  }
})
</script>
