import _ from 'lodash'
import { removeAllKeyField } from '@/components/air8/utils/componentUtils'

type DifferenceObjectOption = {
  ids?: (string | string[])[]
  isNilEqualEmpty?: boolean,
  findProps?: (string | RegExp)[],
  equalProps?: (string | RegExp)[],
  clean?: boolean,
  exactMatchIds?: (string | string[])[]
}

const DEFAULT_DIFFERRENCE_OBJECT_OPTION: DifferenceObjectOption = {
}

function getDiffPropValue (obj: any, field?: string) {
  if (_.isNil(obj)) {
    return undefined
  }
  return _.get(obj, `${DIFF_PROP_PREFIX}${_.isEmpty(field) ? '' : field}`)
}

function setDiffPropValue (obj: any, field: string | undefined, value: any) {
  if (_.isNil(obj)) {
    return
  }
  _.set(obj, `${DIFF_PROP_PREFIX}${_.isEmpty(field) ? '' : field}`, value)
}

function deleteDiffPropValue (obj: any, field: string | undefined) {
  field = `${DIFF_PROP_PREFIX}${_.isEmpty(field) ? '' : field}`
  if (!_.has(obj, field)) {
    return
  }
  delete obj[field]
}

function assignDiffPropValue (obj: any, field: string | undefined, value: any) {
  if (_.isNil(obj)) {
    return
  }
  let diffValue = getDiffPropValue(obj, field)
  if (_.isNil(diffValue)) {
    diffValue = _.assign(diffValue, value)
  } else {
    diffValue = value
  }
  setDiffPropValue(obj, field, diffValue)
}

function setDiffPrev (obj: any, field: string, value: any) {
  setDiffPropValue(obj, field, { prev: value })
}

function setDiffNew (obj: any) {
  assignDiffPropValue(obj, undefined, { new: true })
}

function setDiffChange (obj: any) {
  assignDiffPropValue(obj, undefined, { change: true })
}

const DIFF_PROP_PREFIX = '__air8_diff__'
export class DifferenceObject {
  private target: any
  private other: any
  private option: DifferenceObjectOption
  constructor (target: any, other: any, option?: DifferenceObjectOption) {
    this.target = target
    this.other = other
    this.option = option || {}
    this.option.findProps = this.convertProps(this.option.findProps)
    this.option.equalProps = this.convertProps(this.option.equalProps)
  }

  private convertProps (props: (string | RegExp)[] | undefined) {
    if (_.isEmpty(props)) {
      return props
    }

    return _.map(props, (prop) => {
      if (!_.isString(prop)) {
        return prop
      }
      prop = prop.replace(/\*\.([^.])+$/g, '*')
        .replaceAll('*', '\\d+')
        .replaceAll('.', '\\.')
      return `^${prop}(\\..*)?$`
    })
  }

  public isDiffPropKey (field: string) {
    if (_.isEmpty(field)) {
      return false
    }

    return field.startsWith(DIFF_PROP_PREFIX)
  }

  public static getDiffPropValue (obj: any, field?: string) {
    return getDiffPropValue(obj, field)
  }

  public static setDiffPropValue (obj: any, field: string | undefined, value: any) {
    setDiffPropValue(obj, field, value)
  }

  private setDiffPrev (obj: any, field: string, value: any, path: string) {
    if (!this.isMatchFindProps(path)) {
      return
    }
    setDiffPrev(obj, field, value)
  }

  private setDiffNew (obj: any, path: string) {
    if (!this.isMatchFindProps(path)) {
      return
    }
    setDiffNew(obj)
  }

  private setDiffChange (obj: any, path: string) {
    if (!this.isMatchFindProps(path)) {
      return
    }
    setDiffChange(obj)
  }

  private findMatchIdKey (parentPath: string, index: number, ids?: (string | string[])[]) {
    const prefix = _.isEmpty(parentPath) ? '' : `${parentPath}.`
    const currentPath = `${prefix}${index}`

    const idPath = _.find(ids, (item: string | string[]) => {
      let id = _.nth(_.castArray(item), 0)
      if (_.isEmpty(id)) {
        return false
      }

      id = (id as string)
        .replace(/\*\.([^.])+$/g, '*')
        .replaceAll('*', '\\d+')
        .replaceAll('.', '\\.')
      const regExp = new RegExp(`^${id}$`)
      return !_.isNil(currentPath.match(regExp))
    })

    if (_.isEmpty(idPath)) {
      return undefined
    }

    function getField (path: string) {
      return _.nth(/\.([^.]+)$/g.exec(path as string), 1)
    }

    if (_.isArray(idPath)) {
      return _.map(idPath, (item) => getField(item))
    } else {
      return getField(idPath as string)
    }
  }

  private isMatchFindProps (path: string) {
    if (_.isEmpty(path) || _.isEmpty(this.option?.findProps)) {
      return true
    }

    const isMatched = _.some(this.option?.findProps, (propRegExp: RegExp) => {
      return !_.isNil(path.match(propRegExp))
    })
    return isMatched
  }

  private isMatchEqualFieldProps (path: string) {
    if (_.isEmpty(path) || _.isEmpty(this.option?.equalProps)) {
      return false
    }

    const isMatched = _.some(this.option?.equalProps, (propRegExp: RegExp) => {
      return !_.isNil(path.match(propRegExp))
    })
    return isMatched
  }

  private getMatchIdObject (parentPath: string, index: number, obj: any) {
    const idKey = this.findMatchIdKey(parentPath, index, this.option.ids)
    if (_.isNil(idKey)) {
      return undefined
    }
    return _.pick(obj, _.castArray(idKey) as string[])
  }

  private getExactMatchIdObject (parentPath: string, index: number, obj: any) {
    const idKey = this.findMatchIdKey(parentPath, index, this.option.exactMatchIds)
    if (_.isNil(idKey)) {
      return undefined
    }
    return _.pick(obj, _.castArray(idKey) as string[])
  }

  private findDiffArray (target: any, other: any, parentPath: string) {
    if (_.isEmpty(target)) {
      return true
    }
    _.each(target, (targetItem: any, index: number) => {
      if (_.isNil(targetItem)) {
        return
      }
      const currentPath = `${_.isEmpty(parentPath) ? '' : `${parentPath}.`
        }${index}`
      // find id
      let otherItem = _.get(other, index)
      if (!_.isNil(this.findMatchIdKey(parentPath, index, this.option.exactMatchIds))) {
        const exactMatchIdObject = this.getExactMatchIdObject(parentPath, index, targetItem)
        if (_.isEmpty(exactMatchIdObject)) {
          this.setDiffNew(targetItem, currentPath)
          return
        }
        otherItem = _.find(other, exactMatchIdObject)
        if (otherItem !== _.get(other, index)) {
          this.setDiffChange(targetItem, currentPath)
        }
        this.findDiffValue(targetItem, otherItem, currentPath)
        return
      }

      const idObject = this.getMatchIdObject(parentPath, index, targetItem)
      if (!_.isEmpty(idObject)) {
        otherItem = _.find(other, idObject)

        if (otherItem !== _.get(other, index)) {
          this.setDiffChange(targetItem, currentPath)
        }
      }
      this.findDiffValue(targetItem, otherItem, currentPath)
    })
    return false
  }

  private findDiffObject (target: any, other: any, parentPath: string) {
    if (!_.isPlainObject(other)) {
      this.setDiffNew(target, parentPath)
      return true
    }

    _.chain(target)
      .keysIn()
      .filter((key: string) => !this.isDiffPropKey(key))
      .each((key: string) => {
        const targetValue = _.get(target, key)
        const otherValue = _.get(other, key)

        const currentPath = `${_.isEmpty(parentPath) ? '' : `${parentPath}.`
          }${key}`
        if (this.findDiffValue(targetValue, otherValue, currentPath)) {
          this.setDiffPrev(target, key, otherValue, currentPath)
        }
      })
      .value()

    _.chain(other)
      .keysIn()
      .filter((key: string) => !this.isDiffPropKey(key))
      .difference(_.keys(target))
      .each((key: string) => {
        const otherValue = _.get(other, key)
        if (!this.isEqual(undefined, otherValue)) {
          const currentPath = `${_.isEmpty(parentPath) ? '' : `${parentPath}.`
            }${key}`
          this.setDiffPrev(target, key, otherValue, currentPath)
        }
      })
      .value()

    return false
  }

  public isEqual (target: any, other: any) {
    if (_.isEqual(target, other)) {
      return true
    }
    if (!this.option.isNilEqualEmpty) {
      return false
    }
    return (_.isNil(target) && other === '') || (_.isNil(other) && target === '')
  }

  private findDiffValue (target: any, other: any, currentPath = '') {
    if (this.isEqual(target, other) || _.isNil(target)) {
      return false
    }

    if (this.isMatchEqualFieldProps(currentPath)) {
      return true
    } else if (_.isArray(target)) {
      return this.findDiffArray(target, other, currentPath)
    } else if (_.isPlainObject(target)) {
      return this.findDiffObject(target, other, currentPath)
    }

    return true
  }

  public execute () {
    this.findDiffValue(this.target, this.other)
    return this.target
  }
}

export function findDiffProp (target: any, other: any, ids?: (string | string[])[] | DifferenceObjectOption, isNilEqualEmpty = false, findProps?: string[], equalProps?: string[]) {
  let option = ids
  if (!_.isPlainObject(option)) {
    option = { ids: ids, isNilEqualEmpty, findProps, equalProps } as DifferenceObjectOption
  }
  option = option as DifferenceObjectOption
  if (option.clean) {
    removeAllKeyField(target, DIFF_PROP_PREFIX)
  }
  const diffObj = new DifferenceObject(target, other, option)
  return diffObj.execute()
}

export function clearUnnecessaryField (data: any[] | any) {
  return removeAllKeyField(data, DIFF_PROP_PREFIX)
}

function field2Fields (field: any | any[]) {
  if (_.isNil(field)) {
    return undefined
  }

  if (_.isString(field)) {
    field = _.split(field, '.')
  }
  return _.castArray(field)
}

function getFieldObject (obj: any, field: any | any[]) {
  const fields = _.initial(field2Fields(field))
  if (!_.isEmpty(fields)) {
    obj = _.get(obj, fields)
  }
  return obj
}

function getLastField (field: any | any[]) {
  return _.last(field2Fields(field))
}

export function isDiffNew (obj: any, field?: any | any[]) {
  obj = getFieldObject(obj, field)
  return _.get(getDiffPropValue(obj), 'new') === true
}

export function isDiffChange (obj: any) {
  return _.get(getDiffPropValue(obj), 'change') === true
}

export function getDiffValue (obj: any, field: any | any[]) {
  if (_.isNil(field)) {
    return undefined
  }
  return getDiffPropValue(
    getFieldObject(obj, field),
    getLastField(field)
  )
}

export function findDiffPropByField (target: any, other: any, field: string, option?: DifferenceObjectOption) {
  if (!_.has(target, field) && !_.has(other, field)) {
    // field not exists
    return target
  }

  const targetValueParent = getFieldObject(target, field)
  const lastField = getLastField(field)
  let targetValue = _.cloneDeep(_.get(targetValueParent, lastField))
  const otherValue = _.get(other, field)

  if (_.isPlainObject(targetValue) || _.isArray(targetValue)) {
    if (_.get(option, 'clean') === true) {
      targetValue = clearUnnecessaryField(targetValue)
    }
    const diffObj = new DifferenceObject(targetValue, otherValue, option)

    const result = diffObj.execute()
    if (!_.isEqual(result, _.get(target, field))) {
      _.set(target, field, result)
    }
    return target
  }

  const diffObj = new DifferenceObject(targetValue, otherValue, option)
  if (!diffObj.isEqual(targetValue, otherValue)) {
    setDiffPrev(targetValueParent, lastField, otherValue)
  } else {
    deleteDiffPropValue(targetValueParent, lastField)
  }

  return target
}
