<template>
  <a-button
    v-bind="$attrs"
    @click="handleClick"
  >
    <slot></slot>
    <c0 :config="internalModalDialogConfig"></c0>
  </a-button>
</template>
<script>
import { defineComponent, computed, ref, h, nextTick } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'Air8ModalButton',

  props: {
    beforeClick: Function,
    beforeConfirm: Function,
    dialogTitle: String,

    dialogWidth: {
      type: [Number, String],
      default: 412
    },

    dialogContext: String,
    destroyOnClose: Boolean,
    maskClosable: {
      type: Boolean,
      default: true
    }
  },

  emits: ['click'],

  setup (props, { emit, slots }) {
    const modalDialogVisible = ref(false)

    function getChildText (text) {
      return _.map(_.split(text, '\\n'), (text) => {
        if (text) {
          return h('p', text)
        } else {
          return ''
        }
      })
    }

    function callValidateFunction (validator) {
      if (_.isFunction(validator)) {
        return validator()
      } else {
        return true
      }
    }

    const internalModalDialogConfig = {
      $if: computed(() => {
        if (props.destroyOnClose) {
          return modalDialogVisible.value
        } else {
          return true
        }
      }),
      $type: 'a-modal',
      $Mvisible: modalDialogVisible,
      width: computed(() => props.dialogWidth),
      title: computed(() => props.dialogTitle),
      maskClosable: computed(() => props.maskClosable),
      onOk: () => {
        return handleConfirm()
      },
      onCancel: () => {
        modalDialogVisible.value = false
      },
      $children: () => {
        if (_.isNil(slots.dialogContext)) {
          return getChildText(props.dialogContext)
        }
        return slots.dialogContext()
      }
    }

    async function handleClick () {
      const result = await callValidateFunction(props.beforeClick)
      if (!result) {
        return
      }
      modalDialogVisible.value = true
    }

    async function handleConfirm () {
      const result = await callValidateFunction(props.beforeConfirm)
      if (!result) {
        return
      }
      modalDialogVisible.value = false
      if (props.destroyOnClose) {
        await nextTick()
      }
      emit('click')
    }

    return {
      handleClick,
      internalModalDialogConfig
    }
  }
})
</script>
<style lang="less" scoped>
.air8-modal-button {
  display: inline-block;
}
</style>
