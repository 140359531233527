import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.val,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.val = $event)),
    placeholder: _ctx.$t('common.select_placeholder'),
    options: _ctx.data,
    onChange: _ctx.change
  }, null, 8, ["value", "placeholder", "options", "onChange"]))
}