<script>
import { defineComponent, h, resolveComponent } from 'vue'
import { removeKeyField } from '@/components/air8/utils/componentUtils'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8Popconfirm',
  aliasName: 'T6',

  props: {
    minWidth: {
      type: [Number, String]
    }
  },

  computed: {
    inputProps () {
      return removeKeyField(this.$attrs, /^(class|style)/g)
    },

    getPopupContainerProps () {
      return {
        getPopupContainer: () => {
          return _.get(document.getElementsByClassName('air8-popup__container'), '0', this.$refs.container)
        }
      }
    },
    internalMinWidth () {
      return _.isNumber(this.minWidth) ? this.minWidth + 'px' : this.minWidth
    }
  },

  render () {
    return (
      <div ref="container">
        {
          h(resolveComponent('a-popconfirm'), {
            ...this.inputProps,
            ...this.getPopupContainerProps,
            overlayClassName: this.$style['air8-popconfirm'],
            overlayStyle: {
              minWidth: this.internalMinWidth
            }
          }, this.$slots)
        }
      </div>
    )
  }
})
</script>
<style lang="less" module>
// popconfirm may add to other component
.air8-popconfirm {
  min-width: 220px;
}
</style>
