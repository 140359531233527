
import { computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { RemoteDataCatalog, search, retrieve } from '@/config/remote-data'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'search-select',
  props: {
    name: {
      type: String as PropType<RemoteDataCatalog>,
      required: true
    },
    value: {
      required: true
    },
    labelInValue: {
      type: Boolean,
      default: true
    },
    defaultOptions: {
      type: Array
    },
    full: {
      type: Boolean
    },
    params: {
      type: Object
    }
  },
  emits: ['update:value', 'blur'],
  setup (props, context) {
    const state = reactive<any>({
      data: [...(props.defaultOptions || [])],
      fetching: false
    })

    const val = computed<any>({
      get: () => props.value,
      set: (v: any) => {
        console.log(v)
        context.emit('update:value', v)
      }
    })

    // watch(val, () => {
    //   if (val.value && !state.fetching && state.data.length < 1) { // 初始化默认值
    //     retrieve(props.name, val.value).then(v => {
    //       if (v) state.data.push(v)
    //     })
    //   }
    // }, { immediate: true })

    const fetchData = debounce((keyword: string) => {
      state.data = []
      state.fetching = true
      search(props.name, keyword, undefined, props.params).then(data => {
        const ret = (data || []).map((v: any) => {
          return {
            label: v.name,
            value: v.code,
            data: v
          }
        })
        state.data = [...(props.defaultOptions || []), ...ret]
      }).finally(() => {
        state.fetching = false
      })
    }, 350)

    return {
      ...toRefs(state),
      fetchData,
      val
    }
  }
})
