<template>
  <c0
    :config="internalConfig"
    :scope="scope"
  ></c0>
</template>
<script>
import { computed, defineComponent, ref, markRaw, provide } from 'vue'
import _ from 'lodash'
import { useLocal } from '@/plugins/locale/index'

export default defineComponent({
  name: 'Air8BackendConfigure',

  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    scope: Object,
    lang: Object,
    model: Object,
    fromCountry: String,
    getPopupContainer: Function
  },

  setup (props) {
    const { t, lang } = useLocal()
    const formRef = ref()

    const TYPE_CONFIG = {
      group: (item) => {
        return {
          ref: formRef,
          ..._.omit(item, ['type']),
          $type: 'Air8BackendConfigureGroup',
          model: props.model,
          fromCountry: props.fromCountry,
          getPopupContainer: props.getPopupContainer
        }
      },
      table: (item) => {
        return {
          ref: formRef,
          ..._.omit(item, ['type']),
          $type: 'Air8BackendConfigureTable',
          model: props.model,
          fromCountry: props.fromCountry,
          getPopupContainer: props.getPopupContainer
        }
      }
    }

    const type = computed(() => {
      return _.get(props.config, 'type')
    })

    const internalConfig = computed(() => {
      const itemType = _.get(TYPE_CONFIG, type.value)
      if (_.isNil(itemType)) {
        return { $type: 'div' }
      }
      return itemType(props.config)
    })

    const form = computed(() => {
      return formRef.value.form
    })

    function validate (...args) {
      return _.invoke(formRef.value, 'validate', ...args)
    }

    function validateFields (...args) {
      return _.invoke(formRef.value, 'validateFields', ...args)
    }

    function scrollToField (...args) {
      return _.invoke(formRef.value, 'scrollToField', ...args)
    }

    function resetFields (...args) {
      return _.invoke(formRef.value, 'resetFields', ...args)
    }

    function clearValidate (...args) {
      return _.invoke(formRef.value, 'clearValidate', ...args)
    }

    function language (key, ...args) {
      const l = _.get(props.lang, lang())
      if (!_.has(l, key)) {
        return t(key, ...args)
      }
      const template = _.template(_.get(l, key).replaceAll(/(\{[^{}]+\})/g, (match) => {
        return '$' + match
      }))
      return template(args)
    }

    provide('lang', language)

    return {
      formRef,
      internalConfig,
      form,
      validate,
      validateFields,
      scrollToField,
      resetFields,
      clearValidate,
      language
    }
  }
})
</script>
