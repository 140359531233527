import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container])
  }, [
    _createVNode(_component_a_checkbox_group, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (row, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([_ctx.$style.item]),
            key: i
          }, [
            _createVNode(_component_a_checkbox, {
              value: row.key
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.value), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.footer])
    }, [
      _createVNode(_component_a_button, {
        size: "small",
        onClick: _ctx.onReset
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.reset')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.onQuery
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.ok')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2)
  ], 2))
}