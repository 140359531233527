<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'
import CategoryFactory from './category/CategoryFactory'
import { matchString } from '@/utils/match'

const cacheInitFunction = {}

export default defineComponent({
  name: 'Air8CategorySelect',

  aliasName: 'C4',

  props: {
    value: [Array, Object, String, Number],
    showSearch: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    category: String,
    filterBySearch: Boolean,
    mode: String,
    hasAll: Boolean,
    optionValueAll: {
      type: String,
      default: 'ALL'
    },
    optionValueExclude: {
      type: String,
      default: 'ALL'
    },
    getPopupContainer: Function
  },

  emits: ['search', 'dropdownVisibleChange'],

  data () {
    return {
      options: [],
      loading: false,
      isSearchTextContainAllText: false
    }
  },

  created () {
    this.initGetOptions()
  },

  computed: {
    debounceGetOptions () {
      if (this.filterBySearch) {
        return _.debounce(this.getOptions, 350, { leading: true })
      } else {
        return () => undefined
      }
    },

    isClear () {
      return this.value === undefined
    },

    isMultiple () {
      return this.mode === 'multiple' || this.mode === 'tag'
    }
  },

  watch: {
    value () {
      if (this.isClear) {
        this.debounceGetOptions()
      }
    }
  },

  methods: {
    async initGetOptions () {
      this.loading = true
      try {
        let cacheFunction = _.get(cacheInitFunction, this.category)
        if (_.isNil(cacheFunction)) {
          cacheFunction = _.debounce(() => CategoryFactory.getInstance(this.category).getOptions(), 350, { leading: true })
          _.set(cacheInitFunction, this.category, cacheFunction)
        }
        const options = await cacheFunction()
        this.dealOptions(_.cloneDeep(options), true)
      } catch (e) {
        this.options = []
        console.error(e)
      }
      this.loading = false
    },

    async getOptions (params) {
      this.loading = true
      try {
        const options = await CategoryFactory.getInstance(this.category).getOptions(params)
        const isSearchTextContainAllText = matchString(this.$t('common.all'), params)
        this.dealOptions(options, isSearchTextContainAllText)
      } catch (e) {
        this.options = []
        console.error(e)
      }
      this.loading = false
    },

    dealOptions (options, isSearchTextContainAllText) {
      this.isSearchTextContainAllText = isSearchTextContainAllText
      if (!_.isArray(options)) {
        options = []
      }
      this.options = options
    }
  },

  render () {
    const $slots = _.clone(this.$slots)
    if (this.loading && _.isNil($slots.notFoundContent)) {
      $slots.notFoundContent = () => {
        return (
          <a-spin size="small" />
        )
      }
    }

    return h(resolveComponent('Air8Select'), {
      ...this.$attrs,
      getPopupContainer: this.getPopupContainer,
      showSearch: this.showSearch,
      allowClear: this.allowClear,
      mode: this.mode,
      value: this.value,
      options: this.options,
      notFoundContent: this.loading ? undefined : null,
      localSearch: !this.filterBySearch,
      filterOption: this.filterBySearch ? false : undefined,
      hasAll: this.hasAll && this.isSearchTextContainAllText,
      optionValueAll: this.optionValueAll,
      optionValueExclude: this.optionValueExclude,
      onSearch: this.showSearch ? (value) => {
        this.debounceGetOptions(value)
        this.$emit('search', value)
      } : undefined,
      onDropdownVisibleChange: (open) => {
        if (open && this.isMultiple) {
          this.debounceGetOptions()
        }
        this.$emit('dropdownVisibleChange', open)
      }
    }, $slots)
  }
})
</script>
