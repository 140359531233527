import _ from 'lodash'
export function array2Object (array: any[], keyField: string, valueField: string) {
  if (_.isEmpty(array)) {
    return {}
  }

  return _.reduce(array, (result:any, item:any) => {
    result[_.get(item, keyField)] = _.get(item, valueField)
    return result
  }, {} as any)
}

export function initObjectByPath (obj: any, path: string | string[]) {
  if (_.isEmpty(path)) {
    return obj
  }

  if (_.isString(path)) {
    path = _.split(path, '.')
  }

  function isNumberKey (key: any) {
    return !_.isNil(key) && !_.isNaN(Number(key))
  }

  function hasIn (obj: any, path: any) {
    if (_.isEmpty(path)) {
      return !_.isNil(obj)
    }
    return _.hasIn(obj, path)
  }

  function setValue (obj: any, path: any, value: any) {
    if (_.isEmpty(path)) {
      return value
    }
    return _.set(obj, path, value)
  }

  const initObj = _.reduce(path, (result, key, index) => {
    const prevPath = _.take(path, index)
    if (!hasIn(result, prevPath)) {
      result = setValue(result, prevPath, isNumberKey(key) ? [] : {})
    }
    return result
  }, obj)
  return initObj
}

export function mergeArray (target: any[], source: any[], idFields: string[] | string, overrideFields: string[] | string, overrideFieldsNotInTarget = true, keepSourceDataNotFound = false) {
  if (_.isEmpty(source)) {
    return target
  }

  if (_.isString(idFields) && !_.isEmpty(idFields)) {
    idFields = _.castArray(idFields)
  }

  function sameObjCompare (targetObj: any, sourceObj: any) {
    if (_.isNil(targetObj) && _.isNil(sourceObj)) {
      return true
    }
    if (_.isEqual(targetObj, sourceObj)) {
      return true
    }
    if (_.isNil(targetObj) || _.isNil(sourceObj)) {
      return false
    }

    if (_.isEmpty(idFields)) {
      return false
    }

    return _.isEqual(_.pick(targetObj, idFields), _.pick(sourceObj, idFields))
  }

  let result = _.map(target, (targetObj) => {
    const found = _.find(source, (sourceObj) => sameObjCompare(targetObj, sourceObj))
    if (_.isNil(found)) {
      return targetObj
    }

    targetObj = targetObj || {}

    return { ...(overrideFieldsNotInTarget ? found : {}), ...targetObj, ..._.pick(found, overrideFields) }
  })

  if (keepSourceDataNotFound) {
    const notFoundArrayInSource = _.differenceWith(source, target, sameObjCompare)
    if (!_.isEmpty(notFoundArrayInSource)) {
      result = _.concat(result, notFoundArrayInSource)
    }
  }

  return result
}

export function isDeepEqual (target: any, object: any): boolean {
  if (_.isEqual(target, object)) {
    return true
  }
  if (_.isPlainObject(target)) {
    if (!_.isPlainObject(target)) {
      return false
    }
    return _.every(_.keys(target), (key: string) => {
      return isDeepEqual(_.get(target, key), _.get(object, key))
    })
  } else if (_.isArray(target)) {
    if (!_.isArray(target)) {
      return false
    }
    return _.every(target, (item: any, index: number) => {
      return isDeepEqual(item, _.get(object, index))
    })
  }
  return false
}
