import { App } from 'vue'
import DataTable from './data-table/index.vue'
import LocalTable from './local-table/index.vue'
import LayoutForm from './layout-form/index.vue'
import FormInput from './form-input/index.vue'
import FormSelect from './form-select/index.vue'
import FormSelectMultiple from './form-select-multiple/index.vue'
import FormSearchSelect from './form-search-select/index.vue'
import FormDate from './form-date/index.vue'
import DictIterator from './dict-iterator/index.vue'
import SectionTitle from './section-title/index.vue'
import SearchSelect from './search-select/index.vue'
import LayoutDetail from './layout-detail/index.vue'
import DictSelect from './dict-select/index.vue'
import LayoutBlock from './layout-block/index.vue'
import Upload from './upload/index.vue'
import UploadPicture from './upload/upload-picture.vue'
import BatchImport from './batch-import/index.vue'
import CompanySelect from './search-select/company-select.vue'
import InputNumber from './input-number/index.vue'
import InfoList from './info-list/index.vue'
import DbsTable from './dbs-table/index.vue'
import DataDetail from './data-detail/index.vue'
import DragTable from './drag-table/index.vue'
import TzDate from './tz-date/index.vue'
import HolidayDate from './holiday-date/index.vue'
import UploadGroup from './upload-group/index.vue'
import UploadTable from './upload-table/index.vue'
import DropdownSelect from './dropdown-select/index.vue'
import Air8 from './air8/index'
import EmptyData from './empty-data/index.vue'

const components = {
  DataTable,
  LocalTable,
  LayoutForm,
  FormInput,
  FormSelect,
  FormSelectMultiple,
  FormDate,
  DictIterator,
  SectionTitle,
  SearchSelect,
  FormSearchSelect,
  LayoutDetail,
  DictSelect,
  LayoutBlock,
  Upload,
  UploadPicture,
  BatchImport,
  CompanySelect,
  InputNumber,
  InfoList,
  DbsTable,
  DataDetail,
  DragTable,
  TzDate,
  HolidayDate,
  UploadGroup,
  UploadTable,
  DropdownSelect,
  ...Air8,
  EmptyData
}

export default function installer (app: App) {
  Object.entries(components).forEach(([k, v]) => {
    app.component(k, v)
  })
}
