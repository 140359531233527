import _ from 'lodash'
import { instanceofVNode } from '../utils/componentUtils'
export const CONDITION_EXPRESS_REGEXP = '^(.+)\\|(.+)$'

export default class ConditionExpress {
  public dealConditionExpress (config: any, expressScope: any):any {
    if (_.isNil(config)) {
      return config
    }

    const result = _.chain(config)
      .castArray()
      .map((item) => {
        return this.dealSingleConditionExpress(item, expressScope)
      })
      .value()
    if (_.isArray(config)) {
      return result
    } else {
      return _.head(result)
    }
  }

  private dealSingleConditionExpress (config: any, expressScope: any): any {
    if (!_.isPlainObject(config) || instanceofVNode(config)) {
      return config
    }

    const conditionExpressFields = _.keys(config).filter(key => {
      return this.isIncludeConditionExpress(key)
    })

    const conditionExpressConfig = _.reduce(conditionExpressFields, (result:any, key: string) => {
      if (this.testCondition(key, expressScope)) {
        result[this.getConditionField(key)] = config[key]
      }
      return result
    }, {})

    return _.assign(_.omit(config, conditionExpressFields), conditionExpressConfig)
  }

  private testCondition (key: string, scope: any): boolean {
    const express = this.getConditionExpress(key)

    const initParamsStr = _.chain(_.keysIn(scope))
      .map((key) => {
        if (key === 'class') {
          key = 'className'
        }
        return `let ${_.camelCase(key)}=scope["${key}"];`
      })
      .join('')
      .value()

    try {
      // eslint-disable-next-line no-eval
      return eval(`${initParamsStr} ${express}`)
    } catch (e) {
      console.error(e)
      return false
    }
  }

  private isIncludeConditionExpress (key: string): boolean {
    return new RegExp(CONDITION_EXPRESS_REGEXP, 'g').test(key)
  }

  private getConditionField (key: string): string {
    return _.get(new RegExp(CONDITION_EXPRESS_REGEXP, 'g').exec(key), '1')
  }

  private getConditionExpress (key: string): string {
    return _.get(new RegExp(CONDITION_EXPRESS_REGEXP, 'g').exec(key), '2')
  }
}
