import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_air8_table = _resolveComponent("air8-table")!
  const _component_air8_inner_card = _resolveComponent("air8-inner-card")!
  const _component_air8_form_item = _resolveComponent("air8-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_air8_auto_card = _resolveComponent("air8-auto-card")!

  return (_openBlock(), _createBlock(_component_air8_auto_card, {
    title: _ctx.$t('common.audit_info'),
    class: "approval-flow"
  }, {
    default: _withCtx(() => [
      (_ctx.editable)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_a_steps, {
              labelPlacement: "vertical",
              current: _ctx.currentStep,
              class: "approval-flow__steps"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepsText, (row, index) => {
                  return (_openBlock(), _createBlock(_component_a_step, { key: index }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(row.title), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["current"]),
            _createVNode(_component_air8_inner_card, {
              title: _ctx.$t('common.audit_log')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_air8_table, {
                  bordered: "",
                  columns: _ctx.internalApprovalTableColumns,
                  model: _ctx.internalApprovalTableDataSource,
                  pagination: false,
                  "use-sequence": true,
                  editable: false
                }, null, 8, ["columns", "model"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_air8_inner_card, {
              title: _ctx.$t('common.approval_comment')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  ref: "commentForm",
                  model: _ctx.comment
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_air8_form_item, {
                      name: "comment",
                      label: _ctx.$t('common.approval_comment'),
                      type: "a-textarea",
                      "show-count": "",
                      maxlength: 500,
                      rules: _ctx.commentRules
                    }, null, 8, ["label", "rules"])
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }, 8, ["title"])
          ], 64))
        : (_openBlock(), _createBlock(_component_air8_table, {
            key: 1,
            bordered: "",
            columns: _ctx.internalApprovalTableColumns,
            model: _ctx.internalApprovalTableDataSource,
            pagination: false,
            "use-sequence": true,
            editable: false
          }, null, 8, ["columns", "model"]))
    ]),
    _: 1
  }, 8, ["title"]))
}