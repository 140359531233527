import { inject, InjectionKey, onMounted, provide, ref, ToRef } from 'vue'

export interface LayoutFormContext {
  set: (control: any, set: boolean) => void

  hide: (control: any) => boolean

  form: ToRef<Record<string, any>>

  getProps: () => any
}

const layoutFormInjectKey: InjectionKey<LayoutFormContext> = Symbol.for('layout-form:set') as InjectionKey<LayoutFormContext>

export const useFormProvide = (props: any) => {
  const controls = ref<any[]>([])

  const expand = ref<boolean>(false)

  const form = ref<Record<string, any>>({ ...(props.params || {}) })

  provide(layoutFormInjectKey, {
    set: (control: any, set: boolean) => {
      if (set && !controls.value.includes(control)) controls.value.push(control)
      else {
        const idx = controls.value.findIndex(v => v === control)
        if (idx >= 0) controls.value.splice(idx, 1)
      }
    },
    hide: (control: any) => {
      const idx = controls.value.findIndex(v => v === control)
      if (idx < 0) return true
      if (!expand.value) return idx > ((24 / props.span) + 1)
      return false
    },
    form,
    getProps: () => {
      return props
    }
  } as LayoutFormContext)
  return { controls, expand, form }
}

export const useFormInject = () => {
  return inject(layoutFormInjectKey)
}
