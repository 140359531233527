import { PageData } from '@/common/page-data/index'
import { computed, createVNode, ref, Ref, VNode } from '@vue/runtime-core'
import InputControl from './controls/input/index.vue'
import EnumControl from './controls/enum/index.vue'
import DateControl from './controls/date/index.vue'
import MonthControl from './controls/month/index.vue'
import CheckboxControl from './controls/checkbox/index.vue'
import NumberControl from './controls/number/index.vue'

export type FilterType = 'input' | 'enum' | 'date' | 'checkbox' | 'month' | 'number'

export interface Filter {
  filterDropdown: VNode
  onFilterDropdownVisibleChange: (v: boolean) => any,
  filterDropdownVisible: any
}

const map: Record<FilterType, any> = {
  input: InputControl,
  enum: EnumControl,
  date: DateControl,
  checkbox: CheckboxControl,
  month: MonthControl,
  number: NumberControl
}

export function createFilter (type: FilterType, code: string, pageData?: PageData<any>, dictName?: string, field?: string, local?: boolean, props: any = {}): Filter {
  if (!pageData) return {} as any
  const filterDropdownVisible = ref(false)
  const filtered = computed(() => {
    return (pageData.params?.filters || []).some((v: any) => v.name === code)
  })
  const fn = (v: boolean) => { filterDropdownVisible.value = !!v }
  const ret = {
    filterDropdown: createVNode(map[type], { pageData, code, visible: filterDropdownVisible, dictName, field, local: !!local, ...props }),
    onFilterDropdownVisibleChange: fn,
    filterDropdownVisible,
    filtered
  }
  return ret
}
