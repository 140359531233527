import { App } from 'vue'
import store from '@/store'
import { keyBy } from 'lodash'
import { useAuth } from '../auth/src'

const m = (name: string, hasAll?: boolean) => {
  const options = store.getters['dictionary/options']
  let ret = options(name)
  if (hasAll) {
    const lang = localStorage.getItem('_lang') || 'en-US'
    ret = ([{ key: '', value: lang === 'en-US' ? 'All' : '所有' }]).concat(ret)
  }
  return ret
}

m.a = (name: string) => {
  const options = store.getters['dictionary/options']
  let ret = options(name)
  const lang = useAuth().auth?.principle?.user?.language
  ret = ([{ key: '', value: lang === 'en-US' ? 'All' : '所有' }]).concat(ret)
  return ret
}

m.t = (name: string, hasAll?: boolean) => {
  return (m(name, hasAll) || []).map((v: any) => ({ value: v.key, label: v.value }))
}

m.f = (name: string, code: string) => {
  return (m(name) || []).find((v: any) => v.key === String(code))?.value || code
}

m.fd = (name: string, code: string, defaultText?: string) => {
  return (m(name) || []).find((v: any) => String(v.key) === String(code))?.value || defaultText || code
}

export const useDict = () => {
  return { m }
}

export default function (app: App) {
  app.config.globalProperties.$m = m
}
