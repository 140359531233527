import _ from 'lodash'
import SearchListPage from './pages/search-list-page/index.vue'
import DetailPage from './pages/detail-page/index.vue'
import ActionPage from './pages/action-page/index.vue'
import EditPage from './pages/edit-page/index.vue'
import ViewPage from './pages/view-page/index.vue'
import ApprovalEditPage from './pages/approval-edit-page/index.vue'
import ApprovalPage from './pages/approval-page/index.vue'
import ApprovalViewPage from './pages/approval-view-page/index.vue'
import Air8LocalSearchListPage from './pages/local-search-list-page/index.vue'

import Card from './containers/card/index.vue'
import InnerCard from './containers/card/InnerCard.vue'
import ThirdInnerCard from './containers/card/ThirdInnerCard.vue'
import Air8AutoCard from './containers/card/AutoCard.vue'
import ColumnLayout from './containers/column-layout/index.vue'
import LayoutDetail from './containers/column-layout/LayoutDetail.vue'
import Popconfirm from './containers/popconfirm/index.vue'
import NoneCard from './containers/card/NoneCard.vue'
import CollapseInnerCard from './containers/card/CollapseInnerCard.vue'

import ConfigComp from './components/configComp/index.vue'
import FormItem from './components/form/FormItem.vue'
import Label from './components/form/Label.vue'
import DictionarySelect from './components/form/DictionarySelect.vue'
import CategorySelect from './components/form/CategorySelect.vue'
import RadioGroup from './components/form/RadioGroup.vue'
import Table from './components/table/index.vue'
import InputNumber from './components/form/InputNumber.vue'
import ExchangeRate from './components/form/ExchangeRate.vue'
import DueDateFormTo from './components/form/DueDateFormTo.vue'
import Money from './components/form/Money.vue'
import Popoverbutton from './components/popoverButton/index.vue'
import WrapBraketsText from './components/wrapBracketsText/index.vue'
import LocaleLabel from './components/localeLabel/index.vue'
import LocaleFormItem from './components/localeFormItem/index.vue'
import LocaleTable from './components/localeTable/index.vue'
import SimpleForm from './components/form/SimpleForm.vue'
import StringInputNumber from './components/form/StringInputNumber.vue'
import StringMoney from './components/form/StringMoney.vue'
import WarningPopoverButton from './components/popoverButton/warningPopoverButton.vue'
import ModalButton from './components/modalButton/index.vue'
import Air8MoneyLabel from './components/form/MoneyLabel.vue'
import AutoTooltip from './components/autoTooltip/index.vue'
import InfoIconTip from './components/infoIconTip/index.vue'
import Tag from './components/tag/index.vue'
import DeleteLinkButton from './components/button/DeleteLinkButton.vue'
import Air8MultiLine from './components/multiLine/index.vue'
import InfoProgress from './components/progress/index.vue'
import Air8Tooltip from './components/tooltip/index.vue'
import Air8Select from './components/form/Select.vue'
import Air8CheckboxGroup from './components/form/CheckboxGroup.vue'
import Air8SelectOther from './components/form/SelectOther.vue'
import Air8CompanyCodeNameSelect from './components/form/CompanyCodeNameSelect.vue'

import CompanyReceiveAccount from './compose/companyReceiveAccount/index.vue'
import CompanyReceiveAccountList from './compose/companyReceiveAccountList/index.vue'
import FunderPaymentAccountList from './compose/funderPaymentAccountList/index.vue'
import Remark from './compose/remark/index.vue'
import UploadList from './compose/uploadList/index.vue'
import TableFileList from './compose/tableFileList/index.vue'
import ApprovalFlow from './compose/approvalFlow/ApprovalFlow.vue'
import FunderPaymentAccount from './compose/funderPaymentAccount/index.vue'
import GroupActions from './compose/groupActions/groupActions.vue'
import ApprovalActions from './compose/groupActions/approvalActions.vue'
import ComposeMultiSelectTable from './compose/compose-multi-select-table/index.vue'
import UploadTree from './compose/uploadList/UploadTree.vue'

import DynamicConfigComponent from './backendDynamic/components/configComp/index.vue'
import DynamicGroup from './backendDynamic/components/group/index.vue'
import DynamicFormItem from './backendDynamic/components/formItem/index.vue'
import DynamicTable from './backendDynamic/components/table/index.vue'
import DynamicUploadList from './backendDynamic/components/upload/index.vue'
import DynamicText from './backendDynamic/components/formItem/Text.vue'
import DynamicTextarea from './backendDynamic/components/formItem/Textarea.vue'
import DynamicDate from './backendDynamic/components/formItem/Date.vue'

import Air8BackendConfigure from './backendConfig/index.vue'
import Air8BackendConfigureGroup from './backendConfig/Group.vue'
import Air8BackendConfigureTable from './backendConfig/Table.vue'
import Air8BackendConfigureDialog from './backendConfig/Dialog.vue'

import OcrUploadList from './ocr/OcrUploadList.vue'
import OcrUploadFixList from './ocr/OcrUploadFixList.vue'
import FileUploadList from './ocr/FileUploadList.vue'

const componentList = [
  SearchListPage, DetailPage, ActionPage, EditPage, ViewPage, ApprovalEditPage, ApprovalPage, ApprovalViewPage,
  Air8LocalSearchListPage,
  Card, InnerCard, ThirdInnerCard, Air8AutoCard, ColumnLayout, Popconfirm, NoneCard, CollapseInnerCard,
  LayoutDetail,
  ConfigComp, FormItem, Label, DictionarySelect, CategorySelect, Money, Popoverbutton, WrapBraketsText, LocaleLabel, LocaleFormItem, LocaleTable,
  Table, RadioGroup, InputNumber, ExchangeRate, SimpleForm, StringInputNumber, StringMoney, WarningPopoverButton, ModalButton,
  Air8MoneyLabel, AutoTooltip, InfoIconTip, DeleteLinkButton, Tag, Air8MultiLine, InfoProgress, Air8Tooltip, Air8Select, Air8CheckboxGroup,
  Air8SelectOther, Air8CompanyCodeNameSelect,
  CompanyReceiveAccount, Remark, UploadList, ApprovalFlow, FunderPaymentAccount, GroupActions, ApprovalActions, ComposeMultiSelectTable, TableFileList,
  UploadTree, DueDateFormTo, CompanyReceiveAccountList, FunderPaymentAccountList,
  DynamicConfigComponent, DynamicGroup, DynamicFormItem, DynamicTable, DynamicUploadList,
  DynamicText, DynamicTextarea, DynamicDate,
  Air8BackendConfigure, Air8BackendConfigureGroup, Air8BackendConfigureTable, Air8BackendConfigureDialog,
  OcrUploadList, OcrUploadFixList, FileUploadList
]

function getComponentName (name: string) {
  return _.upperFirst(_.camelCase(name))
}

const components = _.transform(componentList, (result:any, curr:any) => {
  result[getComponentName(curr.name)] = curr
  if (!_.isEmpty(curr.aliasName)) {
    _.chain(_.castArray(curr.aliasName))
      .each((aliasName) => {
        if (_.isEmpty(aliasName)) {
          return
        }
        result[getComponentName(aliasName)] = curr
      }).value()
  }
  return result
}, {})
export default components
