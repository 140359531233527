import { useHttp } from '@/plugins/http/src/index'
import { fromResp, toRequest, PageQueryParams, PageResponse } from '@/common/page-data'
import { download } from '@/utils/download'
import { ossUpload } from '@/common/oss'

export const downloadTemplate = (type: string) => {
  const { http } = useHttp()
  return http.get('/api/air8-company/international/downloadTemplate', { params: { type } })
}

export const queryCompanyList = (params: any) => {
  const { http } = useHttp()
  return http.post<Promise<any>>('/api/air8-company/backend/company/simpleSearch', params)
}
// 单据模板下载
export const templateDownload = (params:any) => {
  return download('/api/air8-financial-transaction/client/field/config/invoice/template/download', toRequest({ ...params, type: 'EXCEL' }), 'get')
}

// 单据模板查询
export const templateQuery = () => {
  const { http } = useHttp()
  return http.get('/api/air8-financial-transaction/client/field/config/invoice/template/query')
}

export const uploadFiles = (params: any) => {
  const { http } = useHttp()
  return http.post('/api/air8-financial-transaction/finance/invoice/client/importExcel', {}, { params: { fileKey: params?.objectKey } })
}
