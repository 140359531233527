<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'
import { mergeClassName } from '../../utils/componentUtils'

export default defineComponent({
  name: 'Air8LocaleFormItem',
  aliasName: ['iFormItem'],
  inheritAttrs: false,

  props: {
    label: String,
    wrapBrackets: Boolean
  },

  methods: {
    onFieldBlur () {
      return this.$refs.formItem.onFieldBlur()
    },
    onFieldChange () {
      return this.$refs.formItem.onFieldChange()
    },
    clearValidate () {
      return this.$refs.formItem.clearValidate()
    },
    resetField () {
      return this.$refs.formItem.resetField()
    }
  },

  render () {
    const slots = { ...this.$slots }
    if (!_.isNil(this.label)) {
      slots.label = () => h(resolveComponent('i-span'), {
        wrapBrackets: this.wrapBrackets
      }, () => this.label)
    }

    return h(resolveComponent('a-form-item'), {
      ...this.$attrs,
      class: mergeClassName(this.$attrs.class, {
        'air8-locale-form-item': true,
        'air8-locale-form-item__wrap-brackets': this.wrapBrackets
      }),
      ref: 'formItem'
    }, slots)
  }
})
</script>
<style lang="less" scoped>
.air8-locale-form-item__wrap-brackets {
  :deep(.ant-form-item-label label) {
    height: auto
  }
}
</style>
