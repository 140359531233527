
import { computed, defineComponent, PropType, provide, reactive, ref } from 'vue'
import { useFormProvide } from './use-form'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue'
import { PageData } from '@/common/page-data'
import { useForm } from 'ant-design-vue/lib/form'
import { useTimeZone } from '@/plugins/time-zone'
import { trim } from 'lodash'

export default defineComponent({
  name: 'layout-form',
  components: { CaretUpOutlined, CaretDownOutlined },
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>
    },
    nested: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    span: {
      type: Number as PropType<number>,
      default: 8
    },
    params: {
      type: Object
    },
    datetimeFields: {
      type: Array,
      default: () => []
    },
    showQueryBtns: {
      type: Boolean,
      default: true
    }

  },
  emits: ['reset', 'query'],
  setup (props, context) {
    const { expand, form, controls } = useFormProvide(props)

    const justify = computed(() => {
      if (props.nested) return 'start'
      const len = controls.value.length
      if (len % (24 / (props as any).span) === 0) return 'end'
      else return 'space-between'
    })

    const onToggle = () => { expand.value = !expand.value }

    const { resetFields, validate, validateInfos, mergeValidateInfo } = useForm(form, reactive({}))

    const onReset = () => {
      form.value = {}
      // resetFields()
    }

    const mapping = (params: Record<string, any> = {}) => {
      const ret: Record<string, any> = {}
      const { tz } = useTimeZone()
      Object.entries(params).forEach(([k, v]) => {
        if (Array.isArray(v) && isDate(v[0])) {
          const mode:string[] = ['S', 'E']
          ret[k] = v.map((w, i) => tz.s(w.toDate(), mode[i]))
          const T = props.datetimeFields.find(n => n === k) ? 'ss' : ''
          ret[k + 'Begin'] = tz.s(v[0].toDate(), T || 'S')
          ret[k + 'End'] = tz.s(v[1].toDate(), T || 'E')
        } else if (isDate(v)) {
          ret[k] = tz.s(v.toDate())
        } else {
          ret[k] = typeof v === 'string' ? v.trim() : v
        }
      })
      return ret
      function isDate (obj: any) {
        return Object.prototype.toString.call(obj) === '[object Object]' && obj?._isAMomentObject
      }
    }

    const getParams = () => {
      return mapping(Object.assign({}, form.value))
    }

    const onQuery = () => {
      const { pageData } = props
      if (pageData) pageData.load(getParams())
      context.emit('query', getParams())
    }

    const actionStyle = computed(() => {
      return {
        textAlign: props.nested ? 'left' : 'right'
      }
    })

    return {
      form,
      expand,
      onToggle,
      justify,
      onReset,
      onQuery,
      controls,
      actionStyle,
      getParams

    }
  }
})

