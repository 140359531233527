import { useTimeZone } from '@/plugins/time-zone'
import _ from 'lodash'

export function string2Date (date: string): Date | null {
  const { tz } = useTimeZone()
  if (_.isEmpty(date)) {
    return null
  }
  return tz.l(date)
}

export function matchDate (dateCompare:string, dateBegin:string, dateEnd:string): boolean {
  const beginDate: Date | null = string2Date(dateBegin)
  const endDate: Date | null = string2Date(dateEnd)
  const date: Date | null = string2Date(dateCompare)
  if (_.isNil(beginDate) && _.isNil(endDate)) {
    return true
  }
  if (_.isNil(date)) {
    return false
  }

  let result = true
  if (!_.isNil(beginDate)) {
    result = result && date.getTime() >= beginDate.getTime()
  }
  if (!_.isNil(endDate)) {
    result = result && date.getTime() <= endDate.getTime()
  }
  return result
}

export function matchString (str:string, matchString:string): boolean {
  matchString = _.trim(matchString)
  if (_.isEmpty(matchString)) {
    return true
  }
  if (_.isEmpty(str)) {
    return false
  }
  // support %
  matchString = matchString.replace(/(\$|\(|\)|\*|\+|\.|\[|\]|\?|\\|\^|\{|\}|\|)/g, '\\$1')
  matchString = matchString.replace(/%/g, '.*')
  const reg = new RegExp(matchString, 'i')
  return reg.test(str)
}

export function matchValue (value: any, matchValue: any) : boolean {
  if (_.isNil(matchValue)) {
    return true
  }
  if (_.isNil(value)) {
    return false
  }
  if (typeof (value) !== typeof (matchValue)) {
    return value + '' === matchValue + ''
  }
  return value === matchValue
}
