
import { defineComponent, onMounted, ref, reactive, computed, PropType, watch, Ref } from 'vue'
import { PageData, toRequest } from '@/common/page-data'
import { numberFormatter } from '@/common/formatter'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'sum-control',
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  components: { ExclamationCircleOutlined },
  setup (props) {
    const value = computed(() => {
      const ret = props.pageData.sumQueue.find((v: any) => v.prop === props.code)?.value
      if ([null, undefined].includes(ret as any)) return ''
      return numberFormatter(ret)
    })

    const loading = ref(false)

    const onSum = () => {
      const params = Object.assign({}, toRequest(props.pageData.params), { amountSumByField: props.code })
      loading.value = true
      props.pageData.queryFn(params as any).then(data => {
        const val = (data?.rows || [])[0]?.totalAmount
        const item = props.pageData.sumQueue.find((v: any) => v.prop === props.code)
        if (item) {
          item.value = val
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          props.pageData.sumQueue.push({ prop: props.code, value: val })
        }
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      value,
      onSum,
      loading
    }
  }
})
