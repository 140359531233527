import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c0 = _resolveComponent("c0")!
  const _component_data_table = _resolveComponent("data-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["air8-search-list-page__data-table-group", _ctx.$attrs.class])
  }, [
    _createVNode(_component_data_table, _mergeProps({
      ref: "table",
      sid: _ctx.sid,
      columns: _ctx.internalTableColumns,
      "page-data": _ctx.pageData,
      scroll: _ctx.scroll,
      title: _ctx.title
    }, _ctx.internalAttrs), _createSlots({ _: 2 }, [
      (!_ctx.$slots['header-title'])
        ? {
            name: "header-title",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.title), 1)
            ])
          }
        : undefined,
      (!_ctx.$slots.extra)
        ? {
            name: "extra",
            fn: _withCtx((scope) => [
              _createVNode(_component_c0, {
                builder: "SearchTablePageTableInnerExtraActionsBuilder",
                config: _ctx.extraActions,
                scope: {...scope, pageData: _ctx.pageData}
              }, null, 8, ["config", "scope"])
            ])
          }
        : undefined,
      (!_ctx.$slots[_ctx.actionKey])
        ? {
            name: _ctx.actionKey,
            fn: _withCtx((scope) => [
              (!scope.record._summary)
                ? (_openBlock(), _createBlock(_component_c0, {
                    key: 0,
                    builder: "TableInnerActions",
                    config: _ctx.tableRowActions,
                    scope: scope
                  }, null, 8, ["config", "scope"]))
                : _createCommentVNode("", true)
            ])
          }
        : undefined,
      _renderList(_ctx.slotKeys, (name) => {
        return {
          name: name,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(scope)))
          ])
        }
      })
    ]), 1040, ["sid", "columns", "page-data", "scroll", "title"])
  ], 2))
}