<template>
  <c0
    :config="config"
    :scope="{ ...$attrs, ...$props }"
  ></c0>
</template>
<script>
import { defineComponent, computed, markRaw } from 'vue'
import { queryCompanyDetail } from '@/api/company'
import _ from 'lodash'
import Rules from '@/components/air8/rules/index.js'
import BankUtils from '@/components/air8/utils/BankUtils'
import BankSelect from './bankSelect.vue'
import { t } from '@/config/locale'
import { message } from 'ant-design-vue'
import router from '@/router'

export default defineComponent({
  name: 'CompanyReceiveAccount',

  props: {
    value: Object,
    isCollection: {
      type: Boolean,
      default: false
    },
    company: [String, Object],
    currency: String,
    editable: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => [Rules.required]
    },
    useSequence: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: () => t('page.repayment.info.funderBank')
    },
    hide: Boolean
  },

  emits: ['update:value'],

  data () {
    return {
      internalCompany: undefined,
      debounceUpdateBankAccounts: _.debounce(this.updateBankAccounts, 100),
      currentBank: undefined,
      defaultBank: undefined,
      table: undefined,
      config: this.getConfig()
    }
  },

  computed: {
    companyAndCurrency () {
      return {
        company: this.company,
        currency: this.currency
      }
    },

    internalBankAccounts () {
      return _.chain(_.get(this.internalCompany, 'bankAccounts', []))
        .filter((account) => {
          return BankUtils.isBankEnabled(account) && _.includes(_.split(account.currency, ','), this.currency)
        }).map((account) => {
          return this.mappingBankAccount({
            ...account,
            currency: this.currency
          })
        }).value()
    },

    currentBankList () {
      if (_.isNil(this.currentBank)) {
        return this.editable ? [{}] : []
      } else {
        return [this.currentBank]
      }
    }
  },

  watch: {
    companyAndCurrency: {
      handler () {
        this.debounceUpdateBankAccounts()
      },
      immediate: true
    },

    value: {
      handler () {
        this.currentBank = this.mappingBankAccount(this.value)
        if (_.isUndefined(this.defaultBank) && !_.isEmpty(this.currentBank)) {
          this.defaultBank = this.currentBank
        }
      },
      immediate: true
    }
  },

  methods: {
    async queryBankAccountsByCompany (company) {
      this.internalCompany = undefined

      let companyCode = ''
      if (_.isString(company)) {
        companyCode = company
      } else {
        companyCode = _.get(company, 'companyBaseInfo.code')
      }

      if (!_.isEmpty(companyCode)) {
        this.internalCompany = await queryCompanyDetail(companyCode)
      }
    },

    async updateBankAccounts () {
      if (!this.editable || (_.isEmpty(this.company) && _.isEmpty(this.currency))) {
        return
      }
      await this.queryBankAccountsByCompany(this.company)
      this.setDefaultBankAccount()
    },

    handleUpdateValue (value) {
      if (!_.isEmpty(value)) {
        value = _.omit(value, ['_id', '_bankName'])
      }
      this.$emit('update:value', value)
    },

    isEmptyAccount (account) {
      return _.isEmpty(_.pick(account, ['accountName', 'bankName', 'bankNameEn', 'bankAccount', 'bankCode', 'cnaps', 'currency']))
    },

    generatorBankAccountId (account) {
      if (this.isEmptyAccount(account)) {
        return ''
      }
      return `${account.accountName}|${account.bankName}|${account.bankNameEn}|${account.bankAccount}|${account.bankCode}|${account.currency}`
    },

    mappingBankAccount (account) {
      if (this.isEmptyAccount(account)) {
        return account
      }
      let bankName = _.get(account, this.$lang.c('bankName', 'bankNameEn'))
      if (_.isEmpty(bankName)) {
        bankName = _.get(account, 'bankNameEn')
      }

      return {
        ...account,
        cnaps: account.cnaps ? account.cnaps : '',
        _id: this.generatorBankAccountId(account),
        _bankName: bankName
      }
    },

    selectFirstBankAccount () {
      this.handleChangeBank(_.get(this.internalBankAccounts, '0._id'))
    },

    setDefaultBankAccount () {
      const defaultBankValue = this.defaultBank
      this.defaultBank = null

      if (_.isEmpty(this.internalBankAccounts)) {
        this.handleChangeBank(undefined)
        return
      }

      if (!_.isEmpty(defaultBankValue) && _.findIndex(this.internalBankAccounts, (bank) => {
        return bank._id === _.get(defaultBankValue, '_id')
      }) !== -1) {
        return
      }

      this.selectFirstBankAccount()
    },

    handleChangeBank (value) {
      const found = _.find(this.internalBankAccounts, (account) => {
        return account._id === value
      })
      this.handleUpdateValue(found)

      if (!_.isEmpty(this.company) && !_.isEmpty(this.currency)) {
        this.$nextTick().then(() => {
          const form = this.getForm()
          if (form) {
            form.validate()
          }
        })
      }
    },

    generateFunderRecipientBankAccountEditor (label) {
      return {
        type: markRaw(BankSelect),
        name: '_id',
        optionLabelProp: label,
        optionValueProp: '_id',
        options: computed(() => this.internalBankAccounts),
        onChange: (event) => this.handleChangeBank(event),
        rules: this.rules,
        allowClear: true,
        showSearch: true
      }
    },

    lt (name, ...ags) {
      return this.$t('page.repayment.info.' + name, ...ags)
    },

    getForm () {
      return _.invoke(this.table, 'getForm')
    },

    getConfig () {
      const baseColumns = [
        {
          dataIndex: 'accountName',
          title: this.lt('columns.acName'),
          $edit: this.generateFunderRecipientBankAccountEditor('accountName')
        },
        {
          dataIndex: '_bankName',
          title: this.lt('columns.bankName'),
          $edit: this.generateFunderRecipientBankAccountEditor('_bankName')
        },
        {
          dataIndex: 'bankAccount',
          title: this.lt('columns.bankNo'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankAccount')
        },
        {
          dataIndex: 'bankCode',
          title: this.lt('columns.bankCode'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankCode')
        },
        {
          dataIndex: 'currency',
          title: this.lt('columns.bankCurrency'),
          $editable: false,
          $dictName: 'CURRENCY'
        }
      ]
      const collectionColumns = [
        {
          dataIndex: 'accountName',
          title: this.lt('columns.acName'),
          $edit: this.generateFunderRecipientBankAccountEditor('accountName')
        },
        {
          dataIndex: '_bankName',
          title: this.lt('columns.bankName'),
          $edit: this.generateFunderRecipientBankAccountEditor('_bankName')
        },
        {
          dataIndex: 'bankAccount',
          title: this.lt('columns.bankNo'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankAccount')
        },
        {
          dataIndex: 'bankCode',
          title: this.lt('columns.swiftCode'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankCode')
        },
        {
          dataIndex: 'cnaps',
          title: this.lt('columns.cnaps'),
          $edit: this.generateFunderRecipientBankAccountEditor('cnaps')
        },
        {
          dataIndex: 'currency',
          title: this.lt('columns.bankCurrency'),
          $editable: false,
          $dictName: 'CURRENCY'
        }
      ]
      return {
        $if: () => !this.hide,
        $type: 'Air8Card',
        title: computed(() => this.title),
        $children: [{
          $type: 'Air8Table',
          ref: (ref) => {
            this.table = ref
          },
          model: computed(() => this.currentBankList),
          editable: computed(() => this.editable),
          useSequence: computed(() => this.useSequence),
          pagination: false,
          columns: this.isCollection ? collectionColumns : baseColumns
        }]
      }
    }
  }
})
</script>
