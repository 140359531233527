<template>
  <c0 :config="internalConfig"></c0>
</template>
<script>
import { computed, defineComponent, ref, inject, useCssModule } from 'vue'
import _ from 'lodash'
import { getGroupItem, getColumn } from './GroupItem'
import * as Utils from './utils'

export default defineComponent({
  name: 'Air8BackendConfigureTable',

  inheritAttrs: false,

  props: {
    children: Array,
    model: Object,
    titleActions: Array,
    titleSummary: Array,
    fromCountry: String,
    getPopupContainer: Function
  },

  setup (props, { attrs }) {
    const lang = inject('lang')
    const formRef = ref()

    const dataSource = computed(() => {
      return _.get(props.model, 'list') || _.get(props.model, 'items') || []
    })

    const configOptions = computed(() => {
      return {
        fromCountry: props.fromCountry,
        getPopupContainer: props.getPopupContainer
      }
    })

    const css = useCssModule()

    const internalConfig = computed(() => {
      return {
        $if: isVisiable,
        $type: 'Air8AutoCard',
        title: lang(attrs.label),
        $slots: {
          subTitle: {
            $type: 'a-space',
            size: 64,
            $children: _.chain(props.titleSummary)
              .map((summary) => {
                const formItem = getGroupItem({
                  ...summary,
                  editable: false
                }, lang, props.model, configOptions)
                if (_.isNil(formItem)) {
                  return undefined
                }
                return {
                  $type: 'div',
                  class: css['config-table__subtitle'],
                  $arrayIndex: _.get(summary, 'orderBy'),
                  $children: [{
                    $type: 'span',
                    class: css['config-table__subtitle-label'],
                    $children: lang(_.get(summary, 'label'))
                  }, {
                    ...formItem,
                    $type: formItem.type,
                    modelValue: _.get(props.model, summary.name)
                  }]
                }
              })
              .filter((item) => !_.isNil(item))
              .value()
          },
          extra: _.map(props.titleActions, (action) => {
            return {
              $type: 'a-button',
              type: 'primary',
              ...action
            }
          })
        },
        $children: {
          ref: formRef,
          $type: 'Air8LocalSearchListPage',
          filterable: false,
          sorterable: false,
          sumerable: false,
          useSequence: true,
          nested: true,
          nestedType: 'none',
          requiredMark: true,
          ..._.omit(attrs, ['label', 'titleActions']),
          data: dataSource,
          columns: columnsConfig.value
        }
      }
    })

    const columnsConfig = computed(() => {
      return _.chain(props.children)
        .map((child) => {
          return getColumn(child, lang, dataSource, configOptions)
        })
        .filter((item) => !_.isNil(item))
        .value()
    })

    const isVisiable = computed(() => {
      return _.chain(columnsConfig.value)
        .filter((item) => {
          return _.get(item, 'visiableIgnore') !== true && Utils.isVisiable(item)
        })
        .size()
        .value() > 0
    })

    const form = computed(() => {
      return _.invoke(formRef.value, 'getTableForm')
    })

    function validate (...args) {
      if (!form.value) {
        return Promise.resolve(true)
      }
      return _.invoke(form.value, 'validate', ...args)
    }

    function validateFields (...args) {
      if (!form.value) {
        return Promise.resolve(true)
      }
      return _.invoke(form.value, 'validateFields', ...args)
    }

    function scrollToField (...args) {
      return _.invoke(form.value, 'scrollToField', ...args)
    }

    function resetFields (...args) {
      return _.invoke(form.value, 'resetFields', ...args)
    }

    function clearValidate (...args) {
      return _.invoke(form.value, 'clearValidate', ...args)
    }

    return {
      formRef,
      internalConfig,
      form,
      validate,
      validateFields,
      scrollToField,
      resetFields,
      clearValidate,
      columnsConfig,
      isVisiable
    }
  }
})
</script>
<style lang="less" module>
.config-table__subtitle {
  .config-table__subtitle-label::after {
    content: ':';
    margin-right: 6px;
  }
}
</style>
