import { useHttp } from '@/plugins/http/src/index'
import axios from 'axios'

export interface UploadOptions {
  onProgress: (event: { percent: number }) => void;
  onError: (event: Error, body?: any) => void;
  onSuccess: (body: any) => void
  data: any;
  filename: string;
  file: File;
  withCredentials: boolean;
  action: string;
  headers: any;
}

const loading = {
  set () {
    const box: any = document.querySelector('#app_main_content')
    let loadingDom: any = document.querySelector('#app_main_loading')
    if (!loadingDom) {
      loadingDom = document.createElement('div')
      loadingDom.id = 'app_main_loading'
      loadingDom.setAttribute('data-n', '1')
      loadingDom.style = 'z-index: 10086;background: rgba(255,255,255,.8);position:absolute;top: 92px;left: 0;right: 0;bottom: 0;display: flex;align-items: center;justify-content: center;'
      loadingDom.innerHTML = '<div class="ant-spin ant-spin-spinning"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span></div>'
      box.appendChild(loadingDom)
    } else {
      const i = loadingDom.getAttribute('data-n')
      loadingDom.setAttribute('data-n', Number(i) + 1)
    }
  },
  clear () {
    const loadingDom: any = document.querySelector('#app_main_loading')
    if (loadingDom) {
      const i = loadingDom.getAttribute('data-n')
      if (Number(i) > 1) loadingDom.setAttribute('data-n', Number(i) - 1)
      else {
        const box: any = document.querySelector('#app_main_content')
        box.removeChild(loadingDom)
      }
    }
  }
}

export function ossRequest (options: UploadOptions) {
  // TODO
  const { http } = useHttp()

  let abort: any

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e: any) => {
      e.percent = (e.loaded / e.total * 100 | 0)
      options.onProgress(e)
    },
    cancelToken: new axios.CancelToken(c => {
      abort = c
    }),
    timeout: 1000 * 60 * 5
  }

  const formData = new FormData()
  formData.append('file', options.file)
  loading.set()
  http.post('/api/air8-company/sysAttachment/upload', formData, config).then(data => {
    loading.clear()
    const ret = data
    options.onSuccess({
      name: ret?.fileName,
      data: ret,
      status: 'done',
      // thumbUrl: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      url: ret?.fileUrl
    })
  }).catch((e: any) => {
    loading.clear()
    options.onError(e)
  })
  return {
    abort: () => {
      if (abort) abort()
    }
  }
}

export function ossUpload (file: File) {
  const { http } = useHttp()
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 1000 * 60 * 5
  }
  const formData = new FormData()
  formData.append('file', file)
  return http.post('/api/air8-company/sysAttachment/upload', formData, config).then(data => {
    const ret = data
    return {
      name: ret?.fileName,
      data: ret,
      status: 'done',
      // thumbUrl: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      url: ret?.fileUrl,
      id: ret?.objectKey
    }
  })
}

export function resolveUrl (url: any) {
  return process.env?.VUE_APP_PREVIEW_URL + url
}

const getParams = (file:any) => {
  const isKey = typeof file === 'string'
  const objectKey = isKey ? file : file?.response?.data?.objectKey
  const isDms = isKey ? objectKey.startsWith('LFI') : file?.response?.data?.fileSource === 'DMS'
  const urlFn = isDms ? getDmsUrl : getUrl
  const url = isDms ? '/api/air8-company/sysAttachment/getDmsUrl' : '/api/air8-company/sysAttachment/getUrl'
  return { urlFn, objectKey, url }
}

const download = (data: any) => {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = data
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function ossDownload (file: any) {
  const { objectKey, url } = getParams(file)
  const { http } = useHttp()
  http.get(url, { params: { objectKey, preview: false } }).then(data => {
    download(data)
  })
}

export function getUrl (objectKey: any) {
  const { http } = useHttp()
  return http.get('/api/air8-company/sysAttachment/getUrl', { params: { objectKey, preview: true } })
}

export function getDmsUrl (objectKey: any) {
  const { http } = useHttp()
  return http.get('/api/air8-company/sysAttachment/getDmsUrl', { params: { objectKey, preview: true } })
}

export function ossPreview (file: any, resolve: any = true) {
  const { urlFn, objectKey } = getParams(file)
  urlFn(objectKey).then(data => {
    if (data.endsWith('.zip') || data.endsWith('.rar')) {
      return download(data)
    }
    window.open(resolve ? resolveUrl(data) : data)
  })
}

export function ossPreviewPicture (objectKey: string) {
  const { http } = useHttp()
  return http.get('/api/air8-company/sysAttachment/getUrl', { params: { objectKey, preview: true } })
}

// 通用-模板文件下载
export function downloadTemplate (type: string) {
  const { http } = useHttp()
  http.get('/api/air8-company/international/downloadTemplate', { params: { type } }).then(data => {
    console.log('template', data)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = data
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
