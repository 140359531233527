<template>
  <a-input-number
    :class="classNameProps"
    v-bind="$attrs"
    :formatter="internalFormatter"
    :parser="internalParser"
    :value="value"
    :min="min"
    :max="internalMax"
    :precision="internalPrecision"
    @update:value="handleUpdateValue"
    @blur="handleBlur"
    @change="handleChange"
    @focus="handleFocus"
    :placeholder="placeholder"
  ></a-input-number>
</template>
<script>
import { computed, defineComponent, watch, ref, reactive } from 'vue'
import { t } from '@/config/locale'
import * as NumberUtils from '@/utils/number'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import StringInputNumber from './StringInputNumber.vue'

export default defineComponent({
  name: 'Air8InputNumber',
  aliasName: 'a8-d-number',

  inheritAttrs: false,

  props: {
    value: [String, Number],
    max: {
      type: [String, Number],
      default: Number.MAX_VALUE
    },
    min: {
      type: [String, Number],
      default: 0
    },
    precision: {
      type: Number,
      default: 2
    },
    maxPrecision: {
      type: Number
    },
    integerNotPrecision: Boolean,
    placeholder: {
      type: String,
      default: () => t('common.input.placeholder')
    },

    isUseGroupSeperator: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: Number,
      default: 15
    },
    decimalLength: {
      type: Number,
      default: 6
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },

  emits: ['update:value', 'blur', 'change', 'focus', 'changeOnBlur'],

  setup (props, ctx) {
    const { emit } = ctx
    const internalPrecision = computed(() => {
      return props.precision
    })

    const inputNumberProps = reactive({})

    watch(() => props, () => {
      _.assign(inputNumberProps, {
        ...props,
        minPrecision: internalPrecision.value,
        maxPrecision: internalPrecision.value
      })
    }, { immediate: true, deep: true })

    const {
      isTyping,
      internalMaxLength: maxlength,
      internalIntegerLength: integerLength,
      internalDecimalLength: decimalLength,
      internalFormatValue2InputValue,
      internalParseInputValue2Value,
      getFinalValue
    } = StringInputNumber.setup(inputNumberProps, { ...ctx, emit: () => undefined }) // hide event

    const internalMax = computed(() => {
      let limit = Math.MAX_VALUE
      if (!_.isNil(maxlength.value)) {
        limit = new BigNumber(0)

        if (!_.isNil(integerLength.value)) {
          limit = new BigNumber(10).pow(integerLength.value)
        }

        if (!_.isNil(decimalLength.value)) {
          limit = limit.minus(new BigNumber(0.1).pow(decimalLength.value))
        }
      }

      return NumberUtils.min(NumberUtils.max(props.min, props.max), limit)
    })

    function internalFormatter (value) {
      return internalFormatValue2InputValue(value, !isTyping.value)
    }

    function internalParser (value) {
      return internalParseInputValue2Value(value)
    }

    function handleUpdateValue (value) {
      isTyping.value = true

      value = convertValue(value)
      emit('update:value', value)
    }

    const isChange = ref(false)

    function handleFocus () {
      emit('focus')
    }

    function handleBlur () {
      isTyping.value = false

      emit('blur')

      if (isChange.value) {
        isChange.value = false
        emit('changeOnBlur', props.value)
      }
    }

    function handleChange (value) {
      value = convertValue(value)
      if (
        (_.isNumber(value) && new BigNumber(value).eq(props.value)) ||
        _.isEqualWith(value, props.value)
      ) {
        return
      }

      emit('change', value)
      isChange.value = true
    }

    function convertValue (value) {
      const result = new BigNumber(getFinalValue(value))
      if (result.isNaN()) {
        return value
      } else {
        return result.toNumber()
      }
    }

    watch(() => props.precision, () => {
      const value = convertValue(props.value)
      if (value !== props.value) {
        emit('update:value', value)
        emit('change', value)
      }
    })

    const classNameProps = computed(() => {
      return {
        'air8-input-number': true,
        'air8-input-number__text-align-right': props.textAlign === 'right'
      }
    })

    function handleChangeOnBlur (value) {
      value = convertValue(value)
      emit('changeOnBlur', value)
    }

    return {
      internalFormatter,
      internalParser,
      internalMax,
      handleUpdateValue,
      handleFocus,
      handleBlur,
      handleChange,
      classNameProps,
      handleChangeOnBlur,
      internalPrecision
    }
  }
})
</script>
<style lang="less" scoped>
.air8-input-number {
  width: 100%;
}
.air8-input-number__text-align-right {
  :deep(.ant-input-number-input) {
    text-align: right;
  }
  &:hover:not(.ant-input-number-disabled) {
    padding-right: 22px;
  }
}
</style>
