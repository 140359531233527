
import { Empty } from 'ant-design-vue'
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from 'vue'

export default defineComponent({
  name: 'empty-data',
  props: {
  },
  setup (props, context) {
    console.log('Empty.PRESENTED_IMAGE_SIMPLE:', Empty.PRESENTED_IMAGE_SIMPLE)
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  }
})
