import _ from 'lodash'
import { instanceofType } from '../utils/componentUtils'

export type DynamicScriptObject = {
  function: string,
  '__air8_script__': boolean
}

export function isDynamicScript (obj: any) {
  return instanceofType(obj, ['function', '__air8_script__']) && _.get(obj, '__air8_script__') === true
}

const safeLodash: any = _.clone(_)
safeLodash.template = undefined

export default class DynamicScript {
  private script: string

  constructor (script: string) {
    this.script = script || ''
  }

  eval (scope: any) {
    const result = {
      __air8_script__result__: undefined,
      globalThis: undefined,
      window: undefined,
      self: undefined,
      parent: undefined,
      top: undefined,
      frames: undefined,
      document: undefined,
      location: undefined,
      alert: undefined,
      confirm: undefined,
      localStorage: undefined,
      sessionStorage: undefined,
      indexedDB: undefined,
      openDatabase: undefined,
      WebSocket: undefined,
      Worker: undefined,
      XMLHttpRequest: undefined,
      _: safeLodash,
      ...scope
    }

    const script = _.template(`<% __air8_script__result__ = (${this.script}) %>`)
    script(result)
    return result.__air8_script__result__
  }
}
