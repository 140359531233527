import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container])
  }, [
    _createVNode(_component_a_range_picker, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
      disabledDate: _ctx.disabledDate
    }, null, 8, ["value", "disabledDate"]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.footer])
    }, [
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.onQuery
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_SearchOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('common.query')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        size: "small",
        onClick: _ctx.onReset
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.reset')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2)
  ], 2))
}