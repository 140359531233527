<template>
  <div class="air8-inner-card">
    <section-title class="air8-inner-card__title">
      <template #title>
        <slot name="title">
          <i-span class="air8-inner-card__title-content">{{ this.internalTitle }}</i-span>
          <div class="air8-inner-card__subTitle">
            <slot name="subTitle">
            </slot>
          </div>
        </slot>
      </template>
      <template #extra>
        <slot name="extra"></slot>
      </template>
    </section-title>
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8InnerCard',

  aliasName: 'T4',

  props: {
    title: [String, Function]
  },

  computed: {
    internalTitle () {
      if (_.isFunction(this.title)) {
        return this.title()
      } else {
        return this.title
      }
    }
  }
})
</script>
<style lang="less" scoped>
.air8-inner-card {
  .air8-inner-card__title {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;

    & > :deep(div:first-child) {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
    .air8-inner-card__subTitle {
      margin: 0px 8px;
      display: inline-block;
      font-size: 14px;
      opacity: 0.8;
      white-space: normal;
      width: 100%;

      :deep(.air8-form-item.ant-form-item) {
        margin-bottom: 0px;
      }

      & > :deep(.air8-inner-card__subTitle-column-layout) {
        white-space: nowrap;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .air8-form-item {
          max-width: 50%;
          flex-wrap: nowrap;
          + .air8-form-item {
            margin-left: 8px;
          }
        }

        .ant-form-item-label {
          overflow: visible;
          display: flex;

          & > label {
            height: auto;
          }
        }

        .ant-form-item-control-input {
          min-height: auto;
          .air8-label {
            white-space: normal;
          }
        }
      }
    }
  }
}
</style>
