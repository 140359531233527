import { PageData } from '@/common/page-data'
import _ from 'lodash'
import { isVNode } from 'vue'
import { simpleCreateComponent } from './componentUtils'
import Air8Label from '../components/form/Label.vue'
import { t } from '@/config/locale'
import { message } from 'ant-design-vue'
import moment from 'moment'
import ExcelJs from 'exceljs/dist/exceljs.bare.js'
import FileSaver from 'file-saver/dist/FileSaver.min.js'
import BigNumber from 'bignumber.js'
import { sumList } from '@/utils/number'
import BuilderFactory from '../builder/BuilderFactory'

export declare type ExportOption = {
  summary?: any,
  useSequence?: boolean,
  sequenceKey?: string,
  actionKey?: string,
  scope?: any

  diabledDateSuffix?: boolean
}

const DEFAULT_EXPORT_OPTION = {
  useSequence: false,
  sequenceKey: '_seq',
  actionKey: 'action'
}

async function queryPageData (pageData: PageData<any>) {
  if (_.isNil(pageData)) {
    return []
  }

  pageData = _.cloneDeep(pageData)
  await pageData._load(Object.assign(pageData.params || {}, { page: 1, size: 20000, isExport: true }))
  return pageData.rows
}

function getCellText (column: any, scope: any) {
  let customRender = _.get(column, '$exportRender')
  if (_.isNil(customRender)) {
    customRender = _.get(column, 'customRender')

    if (_.isNil(customRender)) {
      return scope.text
    }
  }

  const renderResult = _.invoke({ customRender }, 'customRender', scope)
  if (_.isArray(renderResult) && !_.isEmpty(renderResult) && isVNode(_.get(renderResult, '0'))) {
    const vNode = _.get(renderResult, 0)
    if (_.get(vNode, 'type') === 'Air8Label') {
      vNode.type = Air8Label
      const textComp = simpleCreateComponent(vNode)
      return _.get(textComp, 'internalText')
    }
    return _.get(scope, 'text')
  } else {
    return renderResult
  }
}

function getRowData (record: any, index: number, columns: any[]) {
  return _.reduce(columns, (result: any, column: any) => {
    const field: string = _.get(column, 'dataIndex', _.get(column, 'key'))
    result[field] = getCellText(column, { text: _.get(record, field), record, index })
    return result
  }, {} as any)
}

function addExportDataSummary (data: any[], columns: any[], options?: ExportOption) {
  if (!options?.summary || _.isEmpty(data)) {
    return data
  }

  const summary = _.cloneDeep(options.summary)
  const firstColumns = _.head(columns)
  const field: string = _.get(firstColumns, 'dataIndex', _.get(firstColumns, 'key'))
  _.set(summary, field, _.get(summary, 'selection-column'))

  data = _.clone(data)
  data.push(summary)
  return data
}

async function getExportData (pageData: PageData<any>, columns: any[], options?: ExportOption) {
  let exportData = await queryPageData(pageData)
  exportData = addExportDataSummary(exportData, columns, options)

  const result = _.map(exportData, (record: any, index: number) => {
    return getRowData(record, index, columns)
  })

  return result
}

function dropAction (columns: any[]) {
  const actionNames = ['action', 'operation']
  const isKeyAction = (key :string) => actionNames.includes(key)
  if (isKeyAction(_.last(columns).key)) { columns = _.initial(columns) }
  return columns
}

function getExportDataHeader (columns: any[]) {
  columns = dropAction(columns)
  return _.reduce(columns, (result: any, column: any) => {
    let title: any = column.title
    if (_.isPlainObject(title)) {
      title = _.get(title, 'el.innerText')
      if (_.isEmpty(title) && _.get(column.title, 'type.name') === 'sum-control') {
        title = _.get(column.title, 'props.title')
      }
    } else if (_.isString(title)) {
      title = t(title)
    }
    const key = _.get(column, 'dataIndex', _.get(column, 'key'))
    let width = Number(column.width)
    if (Number.isNaN(width) || width === 0) {
      width = 160
    }

    result.push({
      header: title,
      key,
      width: width / 7
    })
    return result
  }, [] as any)
}

async function generateExcelFile (data: any[], fileName: string, headers: any[]): Promise<void> {
  const workbook = generateFormatterFile(data, headers, ['date'])

  const buffer = await workbook.xlsx.writeBuffer()
  const file = new File([buffer], fileName + '.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
  FileSaver.saveAs(file)
}

function cellAppendTab (data: any[]) {
  return _.map(data, (row:any, index:number) => {
    return _.mapValues(row, (cell:any) => {
      if (_.isString(cell)) {
        return `\t${cell}`
      } else {
        return cell
      }
    })
  })
}

async function generateCsvFile (data: any[], fileName: string, headers: any[]) {
  // append \t to data value, make sure date, big number display as string
  data = cellAppendTab(data)
  const workbook = generateFile(data, headers)

  const buffer = await workbook.csv.writeBuffer({ formatterOptions: { writeBOM: true } })
  const file = new File([buffer], fileName + '.csv', { type: 'text/csv' })
  FileSaver.saveAs(file)
}

function generateFile (data: any[], headers: any[]) {
  const workbook = new ExcelJs.Workbook()
  const sheet = workbook.addWorksheet()
  sheet.columns = headers
  sheet.addRows(data)

  const headerRow = sheet.getRow(1)
  headerRow.eachCell((cell: any) => {
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
  })

  return workbook
}

function fileNameAppendDate (fileName: string) {
  const date = moment(new Date().getTime()).format('YYYYMMDD')
  if (!_.isNil(fileName)) {
    return fileName + date
  } else {
    return fileName
  }
}

export async function exportExcel (pageData: PageData<any>, columns: any[], fileName: string, options?: ExportOption) {
  const hide = message.loading(t('common.exporting'))
  const data = await getExportData(pageData, columns, options)
  const headers = getFormatterExportDataHeader(columns)
  await generateExcelFile(data, options?.diabledDateSuffix ? fileName : fileNameAppendDate(fileName), headers)
  hide()
}

export async function exportCsv (pageData: PageData<any>, columns: any[], fileName: string, options?: ExportOption) {
  const hide = message.loading(t('common.exporting'))
  const data = await getExportData(pageData, columns, options)
  const headers = getExportDataHeader(columns)
  await generateCsvFile(data, fileNameAppendDate(fileName), headers)
  hide()
}

function getFormatterExportDataHeader (columns: any[]) {
  columns = dropAction(columns)
  return _.reduce(columns, (result: any, column: any) => {
    let title: any = column.title
    if (_.isPlainObject(title)) {
      title = _.get(title, 'el.innerText')
      if (_.isEmpty(title) && _.get(column.title, 'type.name') === 'sum-control') {
        title = _.get(column.title, 'props.title')
      }
    } else if (_.isString(title)) {
      title = t(title)
    }
    const key = _.get(column, 'dataIndex', _.get(column, 'key'))
    let width = Number(column.width)
    if (Number.isNaN(width) || width === 0) {
      width = 160
    }

    result.push({
      header: title,
      key,
      width: width / 7,
      $column: column
    })
    return result
  }, [] as any)
}

function generateFormatterFile (data: any[], headers: any[], ignoreTypes?: any[]) {
  const workbook = new ExcelJs.Workbook()
  const sheet = workbook.addWorksheet()
  sheet.columns = _.map(headers, (header: any) => _.omit(header, ['$column']))

  sheet.addRows(data)

  sheet.eachRow((row: any, rowNumber: number) => {
    if (rowNumber === 1) {
      return
    }
    row.eachCell((cell: any, colNumber: number) => {
      cell.alignment = { horizontal: 'left' } // default align left

      const columnDef: any = _.get(headers, [colNumber - 1, '$column'])
      if (_.isNil(columnDef) || _.includes(ignoreTypes, columnDef.$propType)) {
        return
      }

      switch (columnDef.$propType) {
        case 'date':
          formatCellDateType(cell)
          break
        case 'currency':
          formatCellCurrencyType(cell)
          break
        case 'number':
          formatCellNumberType(cell)
          break
        case 'normalNumber':
          formatCellNormalNumberType(cell)
          break
        case 'percent':
          formatCellPercentType(cell)
          break
      }
    })
  })

  const headerRow = sheet.getRow(1)
  headerRow.eachCell((cell: any) => {
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
  })

  return workbook
}

function isEmptyCell (cell: any) {
  return _.isNil(cell.value) || cell.value === '-' || cell.value === ''
}

function formatCellDateType (cell: any) {
  if (isEmptyCell(cell)) {
    return
  }

  const value = cell.value || ''
  let date = new Date(value.replace(/-/g, '/'))
  date = new Date(date.getTime() - (1000 * 60 * date.getTimezoneOffset()))
  cell.value = date
}

function getDecimalPlaces (str: string) {
  const result = str.match(/\.(\d+)/)
  return _.size(_.get(result, '1'))
}

function formatCellCurrencyType (cell: any) {
  cell.alignment = { horizontal: 'right' }
  if (isEmptyCell(cell)) {
    return
  }

  let value = cell.value
  if (_.isString(value)) {
    value = value.replace(/,/g, '')
  } else {
    value = value + ''
  }
  const dp = getDecimalPlaces(value)
  const number = new BigNumber(value)
  cell.value = number.toNumber()
  if (dp === 0) {
    cell.numFmt = '#,##0'
  } else {
    cell.numFmt = '#,##0.' + _.repeat('0', dp)
  }
}

function formatCellNumberType (cell: any) {
  cell.alignment = { horizontal: 'right' }
  if (isEmptyCell(cell)) {
    return
  }

  let value = cell.value
  if (_.isString(value)) {
    value = value.replace(/,/g, '')
  } else {
    value = value + ''
  }
  const dp = getDecimalPlaces(value)
  const number = new BigNumber(value)
  cell.value = number.toNumber()
  if (dp === 0) {
    cell.numFmt = '#,##0'
  } else {
    cell.numFmt = '#,##0.' + _.repeat('0', dp)
  }
}

function formatCellNormalNumberType (cell: any) {
  cell.alignment = { horizontal: 'right' }
  if (isEmptyCell(cell)) {
    return
  }

  let value = cell.value
  if (_.isString(value)) {
    value = value.replace(/,/g, '')
  } else {
    value = value + ''
  }
  const dp = getDecimalPlaces(value)
  const number = new BigNumber(value)
  cell.value = number.toNumber()
  if (dp === 0) {
    cell.numFmt = '0'
  } else {
    cell.numFmt = '0.' + _.repeat('0', dp)
  }
}

function formatCellPercentType (cell: any) {
  cell.alignment = { horizontal: 'left' }
  if (isEmptyCell(cell)) {
    return
  }

  let value = cell.value
  value = value.replace(/,/g, '').replace('%', '')
  const dp = getDecimalPlaces(value)
  const number = new BigNumber(value)
  cell.value = number.div(100).toNumber()
  if (dp === 0) {
    cell.numFmt = '0%'
  } else {
    cell.numFmt = '0.' + _.repeat('0', dp) + '%'
  }
}

async function generateFormatterExcelFile (data: any[], fileName: string, headers: any[]): Promise<void> {
  const workbook = generateFormatterFile(data, headers)

  const buffer = await workbook.xlsx.writeBuffer()
  const file = new File([buffer], fileName + '.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
  FileSaver.saveAs(file)
}

export async function exportFormatterExcel (pageData: PageData<any>, columns: any[], fileName: string) {
  const hide = message.loading(t('common.exporting'))
  const data = await getExportData(pageData, columns)
  const headers = getFormatterExportDataHeader(columns)
  await generateFormatterExcelFile(data, fileNameAppendDate(fileName), headers)
  hide()
}

function addExportDataFromArraySummary (data: any[], columns: any[], options?: ExportOption) {
  if (!options?.summary || _.isEmpty(data)) {
    return data
  }

  let summary = _.cloneDeep(options.summary)
  if (_.isArray(summary)) {
    summary = sumList(data, summary)
  }

  const firstColumns = _.head(columns)
  const field: string = _.get(firstColumns, 'dataIndex', _.get(firstColumns, 'key'))
  _.set(summary, field, t('common.summary'))
  _.set(summary, '_summary', true)

  data = _.clone(data)
  data.push(summary)
  return data
}

function getExportDataFromArray (array: any[], columns: any[], options?: ExportOption) {
  const exportData = addExportDataFromArraySummary(array, columns, options)

  const result = _.map(exportData, (record: any, index: number) => {
    return getRowData(record, index, columns)
  })

  return result
}

function attachDefault2Columns (useDefault = false, tableColumns: any[], findBy: any, defaultColumn: any, defaultPosition: number) {
  if (useDefault) {
    let findColumn = _.find(tableColumns, findBy)
    if (_.isNil(findColumn)) {
      findColumn = {}
      tableColumns.splice(defaultPosition, 0, findColumn)
    }

    _.defaults(findColumn, defaultColumn)
  }
}

function convertColumns (columns: any[], options?: ExportOption) {
  const useSequence = _.get(options, 'useSequence', DEFAULT_EXPORT_OPTION.useSequence)
  const sequenceKey = _.get(options, 'sequenceKey', DEFAULT_EXPORT_OPTION.sequenceKey)
  const actionKey = _.get(options, 'actionKey', DEFAULT_EXPORT_OPTION.actionKey)

  const scope = _.assign({ useSequence, sequenceKey, actionKey }, options?.scope)

  columns = _.clone(columns)
  attachDefault2Columns(
    useSequence,
    columns,
    { key: sequenceKey },
    {
      title: t('common.seq'),
      key: sequenceKey
    },
    0
  )

  const builder = BuilderFactory.getInstance('ExportTableInnerColumns', columns, scope)
  return builder.getCurrentConfig()
}

export async function exportExcelFromArray (array: any[], columns: any[], fileName: string, options?: ExportOption) {
  const hide = message.loading(t('common.exporting'))
  columns = convertColumns(columns)
  const data = getExportDataFromArray(array, columns, options)
  const headers = getFormatterExportDataHeader(columns)
  await generateExcelFile(data, fileNameAppendDate(fileName), headers)
  hide()
}
