<template>
  <c0
    class="air8-collapse-inner-card"
    :config="config"
    :scope="this"
  ></c0>
</template>
<script>
import { defineComponent, ref, watch, computed, useCssModule } from 'vue'
import { t } from '@/config/locale'
import _ from 'lodash'
export default defineComponent({
  name: 'Air8CollapseInnerCard',

  props: {
    title: [String, Function],
    expand: {
      type: Boolean,
      default: true
    },
    destroyContent: Boolean
  },

  emits: ['update:expand'],

  setup (props, { attrs, emit, slots }) {
    const innerExpand = ref(true)
    const css = useCssModule()

    function handleClickTitle () {
      innerExpand.value = !innerExpand.value
      emit('update:expand', innerExpand.value)
    }

    function getDefaultSlot () {
      if (props.destroyContent) {
        if (innerExpand.value) {
          return slots.default
        } else {
          return undefined
        }
      } else {
        return {
          $type: 'div',
          class: {
            [css['air8-collapse-inner-card__hide-content']]: !innerExpand.value
          },
          $children: slots.default
        }
      }
    }

    const config = computed(() => {
      return {
        $type: 'air8-inner-card',
        ...attrs,
        title: props.title,
        $slots: {
          ...slots,
          extra: [
            _.invoke(slots, 'extra'),
            {
              $type: 'a-button',
              class: css['air8-collapse-inner-card__collapse_button'],
              onClick: () => handleClickTitle(),
              type: 'link',
              $children: innerExpand.value ? t('common.collapse') : t('common.expand')
            }
          ],
          default: getDefaultSlot()
        }
      }
    })

    watch(() => props.expand, () => {
      innerExpand.value = props.expand
    }, {
      immediate: true
    })

    return {
      config,
      handleClickTitle
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';
.air8-collapse-inner-card__collapse_button {
  padding: 0px;
}

.air8-collapse-inner-card__hide-content {
  display: none;
}
</style>
