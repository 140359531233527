import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "air8-file-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_files_name_icon_vue = _resolveComponent("files-name-icon-vue")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_local_table = _resolveComponent("local-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_local_table, {
      class: _normalizeClass(_ctx.$style['air8-file-upload_table']),
      columns: _ctx.columns,
      data: _ctx.uploadList,
      customRow: _ctx.setRows
    }, {
      filesName: _withCtx(({record}) => [
        _createVNode(_component_a_form_item, {
          class: _normalizeClass(_ctx.$style['air8-file-upload_table-item'])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_files_name_icon_vue),
            _createElementVNode("span", null, _toDisplayString(record.name), 1)
          ]),
          _: 2
        }, 1032, ["class"])
      ]),
      action: _withCtx(({ record }) => [
        _createVNode(_component_a_button, {
          size: "small",
          type: "link",
          onClick: ($event: any) => (_ctx.handlePreview(record))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.upload.details')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          size: "small",
          type: "link",
          onClick: ($event: any) => (_ctx.handleDownload(record))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.upload.download')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          size: "small",
          type: "link",
          onClick: ($event: any) => (_ctx.handleDelete(record))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.upload.delete')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["class", "columns", "data", "customRow"])
  ]))
}