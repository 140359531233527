import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'

export default class InputBuilder extends DefaultBuilder {
  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    currentConfig = _.clone(currentConfig)

    const defaultConfig: any = {
      type: 'a-input',
      wrapBrackets: true
    }

    if (!_.isEmpty(currentConfig.dictName)) {
      defaultConfig.type = 'Air8DictionarySelect'
    }
    if (!_.isEmpty(currentConfig.category)) {
      defaultConfig.type = 'Air8CategorySelect'
      switch (currentConfig.category) {
        case 'bankName':
        case 'bankSwiftCode':
          defaultConfig.filterOption = false
          break
        case 'funder':
        case 'buyer':
        case 'supplier':
        case 'funderNoDataAuth':
          defaultConfig.fullOption = true
          defaultConfig.filterBySearch = true
      }
    }

    const rootScope = this.getRootScope()
    if (_.get(rootScope, 'status') === 'detail' && currentConfig.$customStatus !== true) {
      defaultConfig['type|status==="detail"'] = 'Air8Label'
      if (!_.isEmpty(currentConfig.currency)) {
        defaultConfig['type|status==="detail"'] = 'Air8MoneyLabel'
      }

      defaultConfig['rules|status==="detail"'] = []
      if (_.isNil(currentConfig.formatter) || currentConfig.formatter === '') {
        defaultConfig['formatter|status==="detail"'] = 'empty'
      }
    }

    if (currentConfig.type === 'Air8Label') {
      defaultConfig.formatter = 'empty'
    }

    currentConfig = _.assign(defaultConfig, currentConfig)
    return this.preInitConfig(currentConfig)
  }
}
