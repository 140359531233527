
import { computed, defineComponent, ref, watch } from 'vue'
import _ from 'lodash'
import { PageData } from '@/common/page-data'
import { t } from '@/config/locale'
import { DEFAULT_FORMAT } from '@/components/air8/formatter/cell'
import BuilderFactory from '../../builder/BuilderFactory'
import DataTable from './DataTable.vue'

export const FILTER_TYPE = {
  INPUT: 'input',
  ENUM: 'enum',
  DATE: 'date'
}

export const FORMATTER = DEFAULT_FORMAT

export default defineComponent({
  name: 'DataTableGroup',
  inheritAttrs: false,
  components: { DataTable },
  props: {
    sid: {
      type: String
    },
    title: {
      type: String
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    extraActions: {
      type: Array,
      default: () => []
    },
    tableRowActions: {
      type: Array,
      default: () => []
    },
    actionKey: {
      type: String,
      default: 'action'
    },
    pageData: {
      type: PageData
    },
    scroll: [String, Object],
    useSequence: Boolean,
    sequenceKey: {
      type: String,
      default: '_seq'
    },
    editable: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    sorterable: {
      type: Boolean,
      default: true
    },
    sumerable: {
      type: Boolean,
      default: true
    },
    requiredMark: Boolean
  },

  setup (props: any, compScope: any) {
    const table = ref()
    const tableSlots = computed(() => {
      if (table.value) {
        return table.value.$slots
      } else {
        return undefined
      }
    })
    const scope = computed(() => {
      return {
        ...props,
        ...compScope.attrs,
        slots: tableSlots.value,
        dataSource: _.get(props.pageData, 'rows')
      }
    })

    const defaultSequenceColumn = computed(() => {
      return {
        title: t('common.seq'),
        key: props.sequenceKey,
        width: 60,
        customRender: (rowScope: any) => {
          const text = rowScope.record[props.sequenceKey]
          if (_.isNil(text)) {
            return rowScope.index + 1
          } else {
            return text
          }
        }
      }
    })

    const defaultActionColumn = computed(() => {
      return {
        title: t('common.action'),
        key: props.actionKey,
        fixed: 'right',
        slots: {
          customRender: props.actionKey
        }
      }
    })

    const internalTableColumns = ref(getInternalTableColumns())

    function getInternalTableColumns () {
      let dataTableColumns = _.clone(props.tableColumns)
      attachDefault2Columns(props.useSequence, dataTableColumns, { key: props.sequenceKey }, defaultSequenceColumn.value, 0)
      attachDefault2Columns(!_.isEmpty(props.tableRowActions), dataTableColumns, { key: props.actionKey }, defaultActionColumn.value, dataTableColumns.length)
      dataTableColumns = convertTableColumns2DataTableColumns(dataTableColumns)
      return dataTableColumns
    }

    function convertTableColumns2DataTableColumns (columns: Array<any>) {
      const builder = BuilderFactory.getInstance('SearchTablePageTableInnerColumn', columns, scope)
      return builder.getCurrentConfig()
    }

    function attachDefault2Columns (useDefault: boolean, tableColumns: any[], findBy: any, defaultColumn: any, defaultPosition: number) {
      if (useDefault) {
        let findColumn = _.find(tableColumns, findBy)
        if (_.isNil(findColumn)) {
          findColumn = {}
          tableColumns.splice(defaultPosition, 0, findColumn)
        }

        _.defaults(findColumn, defaultColumn)
      }
    }

    watch([() => props.tableColumns, () => props.useSequence, () => props.sequenceKey, () => props.tableRowActions, () => props.actionKey], () => {
      internalTableColumns.value = getInternalTableColumns()
    })

    return {
      internalTableColumns,
      table
    }
  },

  computed: {
    slotKeys () {
      return _.keys(this.$slots)
    },

    internalAttrs () {
      return _.omit(this.$attrs, ['class'])
    }
  },

  methods: {
    getTable () {
      return this.getDataTable().getTable()
    },

    getDataTable (): any {
      return this.$refs.table
    },

    getForm () {
      return this.getDataTable().getForm()
    }
  }
})
