<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Air8DetailPage',
  aliasName: 'P2',
  props: {
    status: String,
    config: [Array, Object],
    modelValue: Object
  },

  methods: {
    getForm () {
      return this.$refs.form
    }
  },

  render () {
    return (
      <i-form ref="form" model={this.modelValue}>
        <c0 builder="main" config={this.config} scope={this}></c0>
      </i-form>
    )
  }
})
</script>
