
import _ from 'lodash'
import { defineComponent } from 'vue'
import ListView from './list.vue'

export default defineComponent({
  name: 'TableFileList',
  props: {
    modelValue: {
      type: [Object, Array],
      required: true
    },
    isShowIcon: Boolean
  },
  components: { ListView },
  computed: {
    list () {
      const result = _.isArray(this.modelValue) ? this.modelValue : this.getList
      return (result || []).map((file: any) => (
        { response: { data: file }, status: 'done', uid: file.objectKey, name: file.fileName }
      ))
    },
    getList () {
      if (_.isEmpty(this.modelValue)) {
        return []
      }
      return _.flatten(_.map(this.modelValue, (item) => item))
    },
    showIcon () {
      return this.isShowIcon
    }
  }
})
