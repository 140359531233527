
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { ossRequest } from '@/common/oss'
import { notification } from 'ant-design-vue'
import List from './list.vue'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'uploadGroup',
  props: {
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: 'file'
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: Number,
      default: 20
    },
    showList: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    accept: {
      type: Array
    },
    showTip: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number
    }
  },
  components: { List },
  setup (props, ctx) {
    const uploadRef = ref()

    const fileList = ref<any>([])

    const typeList = ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF', 'PDF', 'docx', 'doc', 'xls', 'xlsx', 'zip']

    const type = computed(() => {
      const list: any = props.accept || typeList
      const result = list.reduce((memo:string, current: string, index: number) => (memo += index ? (',.' + current) : ('.' + current)), '')
      return result
    })

    const handleBeforeUpload = (f: any, fl: any) => {
      if (f.type === 'application/x-zip-compressed' && f.size > 100 * 1024 * 1024) {
        notification.error({ message: t('common.upload.zip_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (f.type !== 'application/x-zip-compressed' && f.size > 20 * 1024 * 1024) {
        notification.error({ message: t('common.upload.file_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (fileList.value.length + fl.length > props.maxlength) {
        if (fl[fl.length - 1].uid === f.uid) {
          notification.error({ message: t('common.upload.file_limit_tip', String(props.maxlength)) })
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    }

    const handleChange = ({ file }:any) => {
      if (file.status === 'done') {
        const { response: { data } } = file
        // eslint-disable-next-line vue/no-mutating-props
        props.value && props.value.push(data)
      } else if (file.status === 'removed' && file?.response?.data) {
        const i = props.value && props.value.findIndex(({ objectKey }:any) => objectKey === file.response.data.objectKey)
        // eslint-disable-next-line vue/no-mutating-props
        i >= 0 && props.value && props.value.splice(i, 1)
      }
    }

    provide('upload', ctx.slots)

    watch(() => props.value, (val) => {
      fileList.value = (val || []).map((file:any) => ({ response: { data: file }, status: 'done', uid: file.objectKey, name: file.fileName }))
    }, { immediate: true, deep: true })

    return {
      uploadRef,
      fileList,
      ossRequest,
      type,
      handleBeforeUpload,
      handleChange
    }
  }
})
