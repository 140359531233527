import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!

  return (_openBlock(), _createBlock(_component_a_date_picker, _mergeProps({
    value: _ctx.date,
    onChange: _ctx.onChange,
    format: _ctx.format
  }, _ctx.$attrs), _createSlots({ _: 2 }, [
    (_ctx.$slots.dateRender)
      ? {
          name: "dateRender",
          fn: _withCtx((row) => [
            _renderSlot(_ctx.$slots, "dateRender", _normalizeProps(_guardReactiveProps(row)))
          ])
        }
      : undefined
  ]), 1040, ["value", "onChange", "format"]))
}