import { App, h, ref, watch } from 'vue'
import _ from 'lodash'
import { Modal } from 'ant-design-vue'
import { useLocal } from '../locale'
import LocaleTools from './localeTools.vue'
import { isProductionSystem } from '@/utils/env'
import { useAuth } from '../auth/src'

function findParent (el: HTMLElement, iteratee: any): HTMLElement | undefined {
  if (_.isNil(el)) {
    return undefined
  } else if (iteratee(el)) {
    return el
  } else if (_.isNil(el.parentElement)) {
    return undefined
  } else {
    return findParent(el.parentElement, iteratee)
  }
}

function testParent (el: HTMLElement, iteratee: any): boolean {
  if (_.isNil(el)) {
    return false
  } else if (iteratee(el)) {
    return true
  } else if (_.isNil(el.parentElement)) {
    return false
  } else {
    return testParent(el.parentElement, iteratee)
  }
}

function validateElement (e: MouseEvent) {
  const el = e.target as HTMLElement
  const tagName = _.lowerCase(el.tagName)

  const isIgnore = testParent(el, (element: HTMLElement) => {
    const data = element.dataset
    return data.localeIgnore === 'true'
  })
  if (isIgnore) {
    return false
  }

  const hasLocaleData = testParent(el, (element: HTMLElement) => {
    const data = element.dataset
    return !_.isNil(data.localeKey) || !_.isNil(data.localeValue)
  })
  if (hasLocaleData) {
    return true
  }

  if (['button', 'input', 'svg'].includes(tagName)) {
    return false
  }

  const allowComponent = testParent(el, (element: HTMLElement) => {
    return _.some(['button.ant-btn', '.ant-form-item'],
      (selector) => element.matches(selector))
  })
  if (allowComponent) {
    return true
  }

  const isIgnoreComponent = testParent(el, (element: HTMLElement) => {
    return _.some(['.ant-table', '.ant-calendar-picker-container', '.ant-select-dropdown', '.ant-dropdown'],
      (selector) => element.matches(selector))
  })
  if (isIgnoreComponent) {
    return false
  }

  return true
}

function validate (app: App, e: MouseEvent) {
  if (!e.ctrlKey) {
    return false
  }

  if (window.location.pathname === '/locale') {
    return false
  }

  if (!validateElement(e)) {
    return false
  }
  return true
}

function getEditModalProps (e: MouseEvent) {
  const el = e.target as HTMLElement

  const parent = findParent(el, (element: HTMLElement) => {
    const data = element.dataset
    return !_.isEmpty(data.localeKey) || !_.isEmpty(data.localeValue)
  })

  let key: any = ''
  let value: any = ''

  if (_.isNil(parent)) {
    value = el.innerText
  } else {
    const data = parent.dataset
    key = data.localeKey
    value = data.localeValue
    if (_.isEmpty(value)) {
      value = el.innerText
    }
  }

  return {
    namespace: 'admin',
    localeKey: _.trim(key),
    localeValue: _.trim(value)
  }
}

function showEditModal (e: MouseEvent) {
  const { t } = useLocal()
  const toolNode: any = h(LocaleTools, {
    ...getEditModalProps(e)
  })
  Modal.confirm({
    title: t('page.locale.label'),
    width: '50%',
    content: toolNode,
    onOk: () => {
      _.invoke(toolNode, 'component.proxy.saveLocale')
    }
  })
}

function useLocaleTools (app: App, e: MouseEvent) {
  if (validate(app, e)) {
    showEditModal(e)
    e.preventDefault()
    return false
  }
  return true
}

function watchUserAuthLoadComplete () {
  return new Promise((resolve) => {
    const stoper = function () {
      clearInterval(timer)
      resolve(true)
    }

    const clear = _.after(10, () => {
      stoper()
    })

    const timer = setInterval(() => {
      const { auth } = useAuth()
      if (!_.isNil(_.get(auth, 'principle.user'))) {
        stoper()
      }
      clear()
    }, 500)
  })
}

function isAdmin (user: any) {
  return _.get(user, 'userName') === process.env.VUE_APP_LOCALE_EDITOR_USERNAME &&
    _.get(user, 'email') === process.env.VUE_APP_LOCALE_EDITOR_EMAIL
}

function hasRole (user: any, role: string) {
  const userRoles = _.get(user, 'userRoles')
  const currentUserRoles = _.split(userRoles, ',')
  return _.includes(currentUserRoles, role)
}

function checkUserPermission () {
  const user = _.get(useAuth(), 'auth.principle.user')
  return !_.isEmpty(user) && (isAdmin(user) || hasRole(user, 'Super View Admin'))
}

// let catchIsUserLocaleTools = false
export function isUseLocaleTools () {
  return false
  // if (!catchIsUserLocaleTools) {
  //   catchIsUserLocaleTools = checkUserPermission()
  // }
  // return catchIsUserLocaleTools
}

// export default async function (app: App) {
//   await watchUserAuthLoadComplete()

//   if (!isUseLocaleTools()) {
//     return
//   }

//   function listener (e: MouseEvent) {
//     return useLocaleTools(app, e)
//   }

//   window.document.addEventListener('contextmenu', listener)
// }
