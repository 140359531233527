<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'
import { mergeClassName } from '../../utils/componentUtils'

export default defineComponent({
  name: 'Air8LocaleTable',
  aliasName: ['iTable'],
  inheritAttrs: false,

  props: {
    wrapBrackets: Boolean,
    columns: Array
  },

  computed: {
    warpRenderedColumns () {
      return _.map(this.columns, (column) => {
        column = _.clone(column)
        if (_.isString(column.title)) {
          const defaultTitle = column.title
          column.title = h(resolveComponent('i-span'), {
            wrapBrackets: this.wrapBrackets
          }, () => defaultTitle)
        }
        return column
      })
    }
  },

  methods: {
    getTable () {
      return _.get(this.$refs, 'table.$refs.table')
    }
  },

  render () {
    return h(resolveComponent('a-table'), {
      ...this.$attrs,
      class: mergeClassName(this.$attrs.class, this.$style['i-table']),
      columns: this.warpRenderedColumns,
      ref: 'table'
    }, this.$slots)
  }
})
</script>
<style lang="less" module>
.i-table {
  :global(.ant-table-placeholder) {
    :global(.ant-empty-normal) {
      // margin: 0px;

      :global(.ant-empty-description) {
        margin: 0px;
      }
    }
  }
  :global {
    .ant-table {
      .ant-table-tbody {
        td {
          word-break: break-all;
        }
      }
    }
  }
}
</style>
