import { useHttp } from '@/plugins/http/src/index'
import { flatLocale, splitPath } from '@/utils/locale'

export interface PushParams {
  value: string;
  namespace: 'admin' | 'client';
  langKey: 'zh-CN' | 'en-US';
  id?: number;
}

export function pull (namespace: 'admin' | 'client', lang: 'zh-CN' | 'en-US') {
  const { http } = useHttp()
  const params = {
    langKey: lang,
    namespace
  }
  return http.post('/api/air8-company/international/list', params).then(data => data && data[0])
}

export function push (params: PushParams[]) {
  const { http } = useHttp()
  return http.post('/api/air8-company/international/save', params)
}

export function fetchStandard (namespace: 'admin' | 'client') {
  const { http } = useHttp()
  const url = '/zh-CN.ts'
  return http.get(url).then(data => {
    const expr = (data?.data || '{}').replace('export default', 'return ')
    // eslint-disable-next-line no-new-func
    const fn = new Function(expr)
    return fn()
  })
}

export function loadLocale (lang: 'zh-CN' | 'en-US') {
  const { http } = useHttp()
  const all = [fetchStandard('client'), pull('client', lang).then(data => data?.value ? JSON.parse(data?.value) : {})]
  return Promise.all(all).then(([standard, locale]) => {
    console.log('locale----', standard, locale)
    if (lang === 'zh-CN') {
      return splitPath(Object.assign(flatLocale(standard), flatLocale(locale)))
    } else {
      return splitPath(locale)
    }
  })
}
