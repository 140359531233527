import { App } from 'vue'
import Http, { HttpOptions } from './Http'

let http: Http

export const useHttp = () => {
  return { http }
}

export default function install (app: App, options: HttpOptions) {
  http = new Http(app, options)
  app.config.globalProperties.$http = http
}
