const permMap = {
  // 'User Management': '/authority',
  'User Management.User Centre': '/user-center',
  'User Management.User Centre.Edit': '/user-center/edit',
  'User Management.User Centre.Change Password': '/user-center/change-ps',
  'User Management.User Account Management': ['/org', '/org/account'],
  'User Management.User Account Management.Create new user': '/org/account/add',
  'User Management.User Account Management.Edit': '/org/account/edit',
  'User Management.User Account Management.Send invitation': '/org/account/send-invite',
  'User Management.User Account Management.De-activate': '/org/account/disabled',
  'User Management.User Account Management.Re-activate': '/org/account/active',
  'User Management.User Account Management.Reset Password': '/org/account/reset-ps',
  'User Management.User Account Management.Export': '/org/account/export',
  'User Management.User Account Management.View': '/org/account/detail',
  'Company Management.Pre-transaction Questionnaire': '/org/info',
  'Company Management.Pre-transaction Questionnaire.View': '/org/info',
  'Company Management.Pre-transaction Questionnaire.Create': '/org/info/add-intent',
  'Company Management.Pre-transaction Questionnaire.Edit': '/org/info/edit-intent',
  'Company Management.Pricing Model': '/org/info',
  'Company Management.Pricing Model.View': '/org/info',
  'Company Management.Pricing Model.Accept/Reject': '/org/info/ar',
  // 交易
  Transaction: '/trading',
  'Transaction.PO Management': '/trading/purchase',
  'Transaction.PO Management.Create': '/trading/purchase/add',
  'Transaction.PO Management.Download template': '/trading/purchase/download-tmpl',
  'Transaction.PO Management.Batch upload': '/trading/purchase/bt-upload',
  'Transaction.PO Management.Batch delete/delete': '/trading/purchase/delete',
  'Transaction.PO Management.Export': '/trading/purchase/export',
  'Transaction.PO Management.Edit': ['/trading/purchase/edit', '/trading/purchase/draft', '/trading/purchase/update'],
  'Transaction.PO Management.Upload supporting doc': '/trading/purchase/upload',
  'Transaction.PO Management.View': '/trading/purchase/details',
  'Transaction.Invoice Management': '/trading/invoice',
  'Transaction.Invoice Management.Create': '/trading/invoice/add',
  'Transaction.Invoice Management.Download template': '/trading/invoice/download-tmpl',
  'Transaction.Invoice Management.Batch upload': '/trading/invoice/bt-upload',
  'Transaction.Invoice Management.Batch delete/delete': '/trading/invoice/delete',
  'Transaction.Invoice Management.Export': '/trading/invoice/export',
  'Transaction.Invoice Management.Edit': '/trading/invoice/edit',
  'Transaction.Invoice Management.Upload supporting doc': '/trading/invoice/upload',
  'Transaction.Invoice Management.View': '/trading/invoice/details',
  // 融资
  'Finance Request Management': '/finance',
  'Finance Request Management.Finance Request': '/finance/finance-order',
  'Finance Request Management.Finance Request.Create': '/finance/finance-order/add',
  'Finance Request Management.Finance Request.Edit': '/finance/finance-order/edit',
  'Finance Request Management.Finance Request.Withdraw': '/finance/finance-order/withdraw',
  'Finance Request Management.Finance Request.Update Sch ship date/request dd date': '/finance/finance-order/update',
  'Finance Request Management.Finance Request.Export': '/finance/finance-order/export',
  'Finance Request Management.Finance Request.View': '/finance/finance-order/detail',
  'Finance Request Management.Finance Request.Convert to ARP': '/finance/finance-order/arp',
  'Finance Request Management.Fee Adjustment': '/finance/costs',
  'Finance Request Management.Fee Adjustment.View': '/finance/costs/details',
  // DBS融资申请
  'Finance Request Management.Pool Finance Request': '/finance/dbsFinance',
  'Finance Request Management.Pool Finance Request.Create': '/finance/dbsFinance/request',
  'Finance Request Management.Pool Finance Request.Export': '/finance/dbsFinance/export',
  // 申请融资，账户管理
  'Finance Request Management.Air8 Pool Finance Request': '/finance/poolFactoring',
  'Finance Request Management.Air8 Pool Finance Request.Create': '/finance/poolFactoring/request',
  'Finance Request Management.Air8 Pool Finance Request.Export': '/finance/poolFactoring/export',
  'Finance Request Management.Account Balance': '/finance/account',
  'Finance Request Management.Account Balance.Export': '/finance/account/export',
  // 利息明细
  'Finance Request Management.Air8 Pool Interest Charge': '/finance/interest',
  'Finance Request Management.Air8 Pool Interest Charge.Export': '/finance/interest/export',

  'Contract Management': '/contract',
  'Contract Management.Contract Management': '/contract/info',
  'Contract Management.Contract Management (NOA)': '/contract/info',
  'Contract Management.Contract Management.Sign off/Accept': '/contract/info/signoff',
  'Contract Management.Contract Management.View': '',
  'Contract Management.Contract Management.Export': '/contract/info/export',
  'Home Page': '/home',
  'Company Management': '/org',
  'Company Management.Company Management': '/org/company',
  'Company Management.Company Management.View': '/org/company/detail',
  'Company Management.Company Management.Edit': '/org/company/edit',
  'Company Management.Company Management.View.Company file View': '/org/company/detail/attach',

  // 'Money Management': '/fund',
  // 'Money Management.Loan Management': '/fund/loan',
  // 'Money Management.Loan Batch Management': '/fund/batch',
  // 'Money Management.Balance Change Details': '/fund/overage',
  // 'Money Management.Balance Change Details.Details': '/fund/overage/detail',
  // 'Money Management.Supplier Balance Details': '/fund/supplier-detail',
  // 'Money Management.Supplier Balance Details.Details': ['/fund/supplier-detail/detail', '/fund/supplier-detail/balance'],
  // 'Money Management.Buyer Balance Details': '/fund/buyer-detail',
  // 'Money Management.Buyer Balance Details.Details': '/fund/buyer-detail/balance',
  // 'Money Management.Balance Account Management': '/fund/balance-management',
  // 'Money Management.Supplier Balance Management': '/fund/supplier-management',
  // 'Money Management.Buyer Balance Management': '/fund/buyer-management',
  'Financial Document Management': '/proof',
  'Financial Document Management.Subledger Account Management': '/proof/subledgers',
  'Financial Document Management.Bank Account Management': '/proof/bank',
  'Financial Document Management.Je Template Management': '/proof/templet',
  'Financial Document Management.Je Template Management.Details': '/proof/templet/detail',
  'Financial Document Management.Business Documents Summary': '/proof/collect',
  'Financial Document Management.E1 Report Management': '/proof/e_one',
  'Report management': '/report',
  'Report management.Financial Interest Statement': '/report/amortization',
  // 还款
  'Repayment management': '/repayment',
  'Repayment management.Buyer Payment Management': '/repayment/repaymentDetail',
  'Repayment management.Buyer Payment Management.Export': '/repayment/repaymentDetail/export',
  'Repayment management.Repayment management': '/repayment/management',
  'Repayment management.Repayment management.Export': '/repayment/management/export',
  'Repayment management.Repayment Detail Ledger': '/repayment/details',
  'Repayment management.Repayment Detail Ledger.Export': '/repayment/details/export',
  // 付款
  'Money Management': '/payment',
  'Money Management.Payment Management': '/payment/management',
  'Money Management.Payment Management.Export': '/payment/management/export',
  'Money Management.Financing Payment Details': '/payment/financing',
  'Money Management.Financing Payment Details.Export': '/payment/financing/export',
  'Money Management.Balance Payment Details': '/payment/release-refund',
  'Money Management.Balance Payment Details.Export': '/payment/release-refund/export',

  // 发票确权
  'Invoice confirmation management': '/confirmation',
  'Invoice confirmation management.Invoice confirmation List': '/confirmation/invoiceConfirmation',
  'Invoice confirmation management.Invoice confirmation List.Export': '/confirmation/invoiceConfirmation/export',
  'Invoice confirmation management.Invoice confirmation List.View': '/confirmation/invoiceConfirmation/detail',
  'Invoice confirmation management.Invoice confirmation List.Upload attachments': '/confirmation/invoiceConfirmation/upload',
  'Invoice confirmation management.Invoice confirmation List.Batch Upload attachments': '/confirmation/invoiceConfirmation/batch/upload',
  'Invoice confirmation management.deduction_report_list': '/confirmation/deduction-report',
  'Invoice confirmation management.deduction_report_list.export': '/confirmation/deduction-report/export'
}

const authorizedPerms = [
  { pid: '/realname' },
  { pid: '/realname/verify-bank' },
  { pid: '/realname/verify-money' },
  { pid: '/realname/verify-success' },
  { pid: '/realname/verify-Personal' },
  { pid: '/realname/personal-success' },
  { pid: '/realname/personal-error' },
  { pid: '/finance/finance-order/confirm' },
  { pid: '/information' },
  { pid: '/finance/interest' }
]

export function mapping (perms: any[]): any[] {
  const map = toMap(perms)
  const set = new Set<string>()
  perms.forEach((v: any) => {
    let cur = v
    const segs: string[] = [v?.name]
    while (cur?.parentPermissionId) {
      cur = map[cur?.parentPermissionId]
      if (!cur) break
      segs.splice(0, 0, cur?.name)
    }
    set.add(segs.join('.'))
  })
  const newSet = new Set<string>()
  set.forEach(v => {
    const item = (permMap as any)[v]
    if (!item) return
    if (Array.isArray(item)) {
      item.forEach(w => {
        newSet.add(w)
      })
    } else {
      newSet.add(item)
    }
  })
  console.log('set:', set)
  return [...Array.from(newSet).map(v => ({ pid: v })), ...authorizedPerms]

  function toMap (perms: any[]): Record<string, any> {
    const ret: Record<string, any> = {}
    perms.forEach((v: any) => {
      ret[v.id] = v
    })
    return ret
  }
}
