import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    type: "flex",
    gutter: 24
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (item, i) => {
        return (_openBlock(), _createBlock(_component_a_col, {
          span: 8,
          key: i
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.item])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.label])
              }, _toDisplayString(item.label) + " : ", 3),
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.text])
              }, _toDisplayString(_ctx.resolveText(item)), 3)
            ], 2)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}