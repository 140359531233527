import { App } from 'vue'
import { t } from '@/config/locale'
import store from '@/store'
import Locale from './locale.vue'
import _ from 'lodash'

const lang = () => {
  const locale = (store.state as any).app.locale
  return locale?.lang || 'en-US'
}

lang.c = (cnValue: string, enValue: string) => {
  return lang() === 'zh-CN' ? cnValue : enValue
}

const test = (key: string) => {
  if (_.isEmpty(key)) {
    return false
  }

  const locale = (store.state as any).app.locale
  return _.has(locale, key)
}

export const useLocal = () => {
  return { t, lang, test }
}

export default function (app: App) {
  app.config.globalProperties.$t = t
  app.config.globalProperties.$lang = lang
  app.config.globalProperties.$te = test
  app.component(Locale.name, Locale)
}
