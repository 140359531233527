import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.val,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.val = $event)),
    "show-search": "",
    allowClear: "",
    labelInValue: _ctx.labelInValue,
    placeholder: _ctx.$t('common.search_placeholder'),
    "not-found-content": _ctx.fetching ? undefined : null,
    "filter-option": false,
    options: _ctx.data,
    onSearch: _ctx.fetchData
  }, _createSlots({ _: 2 }, [
    (_ctx.full)
      ? {
          name: "option",
          fn: _withCtx(({value, label}) => [
            _createTextVNode(_toDisplayString(value) + " - " + _toDisplayString(label), 1)
          ])
        }
      : undefined,
    (_ctx.fetching)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ])
        }
      : undefined
  ]), 1032, ["value", "labelInValue", "placeholder", "not-found-content", "options", "onSearch"]))
}