import { useAuth } from './../auth/src/index'
import { AxiosRequestConfig } from 'axios'
import store from '@/store'
import { AppState } from '@/store/modules/app'
import HttpPlugin from './src'
import { App } from 'vue'
import Auth from '../auth/src/Auth'
import { Modal } from 'ant-design-vue'
import { t } from '@/config/locale'

const appId = process.env.VUE_APP_APP_ID

export default function (app: App) {
  app.use(HttpPlugin, {
    baseURL: '/',
    expiredHandler: function (url: string) {
      const auth = app.config.globalProperties.$auth
      if (auth) auth.toLogin(url, false)
    },
    requestHandler (cfg: AxiosRequestConfig) {
      const token = JSON.parse(localStorage.getItem('authentication') || '{}')?.token
      const sessionUserid = JSON.parse(localStorage.getItem('authentication-userid') || '{}')?.userid
      const { auth } = useAuth()
      const userid = auth?.username
      const lang = auth?.principle?.user?.language || localStorage.getItem('_lang') || 'en-US'
      if (sessionUserid && userid && sessionUserid !== userid) {
        Modal.info({
          title: () => t('common.tip'),
          content: t('common.login_invalid'),
          onOk () {
            auth.toLogin('', false)
          }
        })
        throw new Error('block:invalid')
      }
      const headers: any = { appId, token, 'x-language': lang }
      const backUrl = new URL(location.href).searchParams.get('backUrl')
      if (backUrl) {
        const role = new URL(backUrl).searchParams.get('__role')
        if (role) {
          headers.companyRole = role
        }
      }
      Object.assign(cfg.headers, headers)
    }
  })
}
