<template>
  <a-select
    v-bind="$attrs"
    :value="internalValue"
    @update:value="handleUpdateValue"
    option-filter-prop="label"
    :get-popup-container="getPopupContainer"
    :placeholder="placeholder"
  >
    <template v-for="item in internalOptions" :key="item.value">
      <a-select-option :value="item.value" :label="item.label">
        <span>{{ item.label }}</span>
      </a-select-option>
    </template>
  </a-select>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  props: {
    value: String,
    optionLabelProp: String,
    optionValueProp: String,
    options: {
      type: Array,
      default: () => []
    },
    placeholder: String,
    getPopupContainer: Function
  },
  emits: ['update:value'],

  computed: {
    internalOptions () {
      return _.chain(this.options)
        .map((account) => {
          return {
            value: _.get(account, this.optionValueProp),
            label: _.get(account, this.optionLabelProp)
          }
        })
        .filter(item => item.label)
        .value()
    },

    internalValue () {
      if (_.isEmpty(this.internalOptions)) {
        return undefined
      } else if (_.findIndex(this.internalOptions, { value: this.value }) === -1) {
        return undefined
      } else {
        return this.value
      }
    }
  },

  methods: {
    handleUpdateValue (value) {
      this.$emit('update:value', value)
    }
  }
})
</script>
