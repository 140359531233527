
import { defineComponent, ref, PropType, watch, Ref, computed } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { PageData } from '@/common/page-data'
import _ from 'lodash'

export default defineComponent({
  name: 'number-control',
  components: { SearchOutlined },
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    visible: {
      type: Object,
      required: true
    },
    allowNagive: {
      type: Boolean,
      value: false
    },
    precision: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const minValue: Ref<any> = ref(undefined)
    const maxValue: Ref<any> = ref(undefined)
    const value: Ref<any[]> = computed({
      get: () => [minValue.value, maxValue.value],
      set: (value) => {
        minValue.value = _.nth(value, 0)
        maxValue.value = _.nth(value, 1)
      }
    })

    const onReset = () => {
      value.value = [undefined, undefined]
      props.pageData.unfilter(props.code)
      props.visible.value = false
    }

    const onQuery = () => {
      if (value.value.length < 1) {
        onReset()
      } else {
        props.pageData.filter({ name: props.code, value: value.value })
        props.visible.value = false
      }
    }

    watch(props.visible as any, () => {
      if (props.visible.value) {
        value.value = props.pageData.params?.filters?.find(v => v.name === props.code)?.value || []
      }
    })

    const min = computed(() => {
      if (props.allowNagive) {
        return Number.NEGATIVE_INFINITY
      } else {
        return 0
      }
    })

    const minInputMinValue = computed(() => {
      return min.value
    })

    const maxIputMinValue = computed(() => {
      if (_.isNil(minValue.value) || minValue.value === '') {
        return min.value
      }
      return minValue.value
    })

    return {
      minValue,
      maxValue,
      onQuery,
      onReset,
      min,
      minInputMinValue,
      maxIputMinValue
    }
  },

  mounted () {
    console.log('min', this.min, this.allowNagive, this.precision)
  }
})
