
import { computed, defineComponent, useCssModule } from 'vue'
import { ossDownload, ossPreview } from '@/common/oss'
import _ from 'lodash'
import FilesNameIconVue from './files-name-icon.vue'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'file-list',
  props: {
    fileList: {
      type: Array,
      required: true
    }
  },
  emits: ['update:fileList', 'handleManualRemove', 'handleRemove'],
  components: { FilesNameIconVue },
  setup (props, { emit }) {
    const css = useCssModule()
    const ct = (i: string, ...args: any) => t('common.upload.' + i, ...args)
    const columns = [
      { title: ct('columns.name'), key: 'name', dataIndex: 'name', width: 80, slots: { customRender: 'filesName' } },
      { title: ct('columns.action'), key: 'action', width: 40, slots: { customRender: 'action' } }]
    const uploadList = computed(() => _.get(props, 'fileList', []))
    const handlePreview = (f:any) => {
      ossPreview(f)
    }

    const handleDownload = (f:any) => {
      ossDownload(f)
    }

    const handleDelete = (f:any) => {
      if (f.status !== 'done') {
        emit('handleManualRemove', f)
      }
      emit('handleRemove', f)
    }

    function setRows (record: any, index: any) {
      const styleObj = { style: { backgroundColor: '#fff' } }
      if (record.status === 'error') {
        _.set(styleObj, 'class', css['error-options'])
      }
      return styleObj
    }

    return {
      columns,
      setRows,
      uploadList,
      handlePreview,
      handleDownload,
      handleDelete
    }
  }
})
