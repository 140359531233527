
import { ossUpload } from '@/common/oss'
import { t } from '@/config/locale'
import { useHttp } from '@/plugins/http/src'
import { downloadFromUrl } from '@/utils/download'
import { message, notification } from 'ant-design-vue'
import { computed, defineComponent, PropType, provide, reactive, ref } from 'vue'

export default defineComponent({
  name: 'batch-import',
  props: {
    api: {
      type: String,
      required: true
    }
  },
  emits: ['upload'],
  setup (props, { emit }) {
    const uploading = ref<boolean>(false)

    const fileList = ref<any[]>([])

    const result = ref<any>(null)

    const visible = ref<boolean>(false)

    const stats = computed(() => {
      const fail = result.value?.errorNumber || 0
      const success = result.value?.successfulNumber || 0
      return {
        total: fail + success,
        fail,
        success
      }
    })

    const hasError = computed(() => {
      return stats.value.fail > 0
    })

    const upload = (file: any) => {
      console.log('file: ', file)
      const { http } = useHttp()
      uploading.value = true
      ossUpload(file).then(data => {
        return http.post(props.api, {}, { params: { fileKey: data?.id } })
      }).then(data => {
        result.value = data
        visible.value = true
      }).finally(() => {
        uploading.value = false
      })
    }

    const beforeUpload = (file: any) => {
      if (file.type === 'application/x-zip-compressed' && file.size > 100 * 1024 * 1024) {
        notification.error({ message: t('common.upload.zip_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (file.type !== 'application/x-zip-compressed' && file.size > 20 * 1024 * 1024) {
        notification.error({ message: t('common.upload.file_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      fileList.value = [file]
      setTimeout(() => {
        upload(file)
      })
      return false
    }

    const onCancel = () => {
      visible.value = false
      emit('upload')
    }

    const onOk = () => {
      if (result.value?.errorUrl && hasError.value) downloadFromUrl(result.value?.errorUrl)
      else onCancel()
    }

    return {
      fileList,
      beforeUpload,
      uploading,
      visible,
      onCancel,
      onOk,
      stats,
      result,
      hasError
    }
  }
})

