<template>
  <air8-action-page
    class="air8-approve-page"
    ref="actionPage"
    v-bind="$attrs"
    :status="status"
    :config="config"
    :model-value="modelValue"
    :init-function="initFunction"
  >
    <slot name="default"></slot>
    <approval-flow
      ref="approvalFlow"
      :current="approvalCurrentStep"
      :steps="approvalSteps"
      :approval-table-columns="approvalTableColumns"
      :approval-table-data-source="internalApprovalTableDataSource"
      :required="true"
      :value="internalComment"
      :onUpdate:value="handleUpdateComment"
    ></approval-flow>
    <air8-approval-actions
      :approval-pass-function="internalApprovalPassFunction"
      :approval-deny-function="internalApprovalDenyFunction"
      :approval-reject-text="approvalRejectText"
      :approval-reject-dialog-width="approvalRejectDialogWidth"
      :approval-reject-function="internalApprovalRejectFunction"
      :approval-before-function="internalApprovalBeforeFunction"
      :go-back="goBack"
    >
    </air8-approval-actions>
  </air8-action-page>
</template>
<script>
import { computed, defineComponent, ref, h, nextTick, useCssModule, watch, onMounted } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'
import { message, notification } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { WORKFLOW_APPROVAL_STATUS } from '@/common/constants'

export default defineComponent({
  name: 'Air8ApprovalPage',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: 'detail'
    },
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    approvalPassFunction: Function,
    approvalDenyFunction: Function,
    approvalRejectText: String,
    approvalRejectDialogWidth: [Number, String],
    approvalRejectFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    },
    approvalCurrentStep: Number,
    approvalSteps: Array,
    approvalTableColumns: Array,
    approvalTableDataSource: Array,
    comment: String,
    workflowParams: Object
  },

  emits: ['update:comment'],

  setup (props, { emit }) {
    const actionPage = ref()
    function getForm () {
      return actionPage.value.getForm()
    }
    const approvalFlow = ref()
    const commentRequired = ref(false)
    const internalComment = ref(props.comment)
    function handleUpdateComment (value) {
      internalComment.value = value
      emit('update:comment', internalComment.value)
    }

    async function validateWithForm (required) {
      commentRequired.value = required
      await nextTick()
      let result = false
      try {
        await approvalFlow.value.getForm().validate()
        result = true
      } catch (e) { }
      commentRequired.value = false
      return result
    }

    async function validateWithMessage (required) {
      if (required && _.isEmpty(_.trim(internalComment.value))) {
        message.warning(t('common.approval_comment_tip'))
        return false
      } else {
        return true
      }
    }

    function invokeFunction (method, ...params) {
      if (!_.isFunction(method)) {
        return
      }
      return method(...params)
    }

    function internalApprovalPassFunction () {
      const params = {
        ...approvalParams.value,
        reviewType: WORKFLOW_APPROVAL_STATUS.PASSED
      }
      return invokeFunction(props.approvalPassFunction, params)
    }

    function internalApprovalDenyFunction () {
      const params = {
        ...approvalParams.value,
        reviewType: WORKFLOW_APPROVAL_STATUS.REJECTED
      }
      return invokeFunction(props.approvalDenyFunction, params)
    }

    function internalApprovalRejectFunction () {
      const params = {
        ...approvalParams.value,
        reviewType: WORKFLOW_APPROVAL_STATUS.REFUSED
      }
      return invokeFunction(props.approvalRejectFunction, params)
    }

    function internalApprovalBeforeFunction (type) {
      return validateWithMessage(type !== WORKFLOW_APPROVAL_STATUS.PASSED)
    }

    function setLoading (loading) {
      _.set(actionPage.value, 'loading', loading)
    }

    const workflowInfo = ref(undefined)

    async function getWorkflowHistory () {
      setLoading(true)
      workflowInfo.value = undefined
      try {
        const params = _.clone(props.workflowParams)

        if (!_.isEmpty(params)) {
          _.set(params, 'ifQueryTask', 'YES')

          const result = await approvalFlow.value.getWorkflowHistory(params)
          workflowInfo.value = result
        }
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }
    watch(() => props.workflowParams, () => {
      getWorkflowHistory()
    })

    const internalApprovalTableDataSource = computed(() => {
      if (_.isNil(props.approvalTableDataSource)) {
        return _.get(workflowInfo.value, 'detailDTOS', [])
      } else {
        return props.approvalTableDataSource
      }
    })

    const approvalParams = computed(() => {
      return {
        approvalComments: internalComment.value,
        businessNo: _.get(props.workflowParams, 'businessNo'),
        nodeName: _.get(workflowInfo.value, 'currentNode'),
        taskId: _.get(workflowInfo.value, 'taskId')
      }
    })

    onMounted(() => {
      getWorkflowHistory()
    })

    return {
      actionPage,
      approvalFlow,
      commentRequired,
      internalComment,
      handleUpdateComment,
      internalApprovalTableDataSource,
      getForm,
      internalApprovalPassFunction,
      internalApprovalDenyFunction,
      internalApprovalRejectFunction,
      internalApprovalBeforeFunction
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';

.approval-page__approval-deny {
  :global(.anticon) {
    color: @color-warning;
    margin-right: 8px;
  }
}
</style>
