import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'
import { CONDITION_EXPRESS_REGEXP } from '../ConditionExpress'
import { removeKeyField } from '../../utils/componentUtils'
import DynamicScript, { isDynamicScript } from '../../backendDynamic/DynamicScript'

export default class DynamicConfigComponentBuilder extends DefaultBuilder {
  // preInitConfig (currentConfig:any):any {
  //   return _.cloneDeep(currentConfig)
  // }

  initConfig (currentConfig:any) {
    const result = this.iterProp(currentConfig)
    return result
  }

  private iterProp (config: any): any {
    if (_.isNil(config)) {
      return config
    } else if (_.isArray(config)) {
      return this.iterPropArray(config)
    } else if (_.isPlainObject(config)) {
      return this.iterPropObject(config)
    } else if (_.isFunction(config)) {
      return this.iterPropFunction(config)
    } else {
      return config
    }
  }

  private iterPropArray (config: any[]) {
    return _.chain(config)
      .map((item) => {
        return this.iterProp(item)
      })
      .value()
  }

  private iterPropObject (config: any) {
    config = this.doIterateePre(config)
    config = _.mapValues(config, (value, key) => {
      if (key.startsWith('$') && !_.includes(['$children', '$slots'], key)) {
        return value
      }
      return this.iterProp(value)
    })
    config = this.doIterateeAfter(config)
    return config
  }

  private iterPropFunction (config: any) {
    return config
  }

  private doIterateePre (config: any) {
    config = this.removeSepecialField(config)
    return config
  }

  private doIterateeAfter (config: any) {
    config = this.iterPropScriptFunction(config)
    config = this.iterPropNoClone(config)
    return config
  }

  // remove express
  private removeSepecialField (config: any) {
    // const regExp = new RegExp(`(^\\$[FM])|${CONDITION_EXPRESS_REGEXP}`, 'g')
    const regExp = new RegExp(CONDITION_EXPRESS_REGEXP, 'g')
    return removeKeyField(config, regExp)
  }

  private iterPropScriptFunction (config: any) {
    const rootScope = this.getRootScope()
    return _.reduce(config, (result: any, value, key) => {
      if (!isDynamicScript(value)) {
        return result
      }
      const script = new DynamicScript(value.function)
      result[`$F${key}`] = (currentScope: any) => {
        return script.eval({
          scope: currentScope,
          data: _.get(rootScope, 'data')
        })
      }
      delete result[key]

      return result
    }, config)
  }

  private iterPropNoClone (config: any) {
    const NO_CLONE_KEY_PREFIX = '$C'
    return _.reduce(config, (result: any, value, key) => {
      if (!key.startsWith(NO_CLONE_KEY_PREFIX)) {
        return result
      }
      result[key.substring(NO_CLONE_KEY_PREFIX.length)] = value
      delete result[key]
      return result
    }, config)
  }
}
