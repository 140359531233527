<template>
  <air8-input-number
    class="air8-money"
    v-bind="$attrs"
    :value="value"
    :min="min"
    :max="max"
    :precision="internalPrecision"
    :placeholder="placeholder"
    :decimal-length="2"
    @update:value="handleUpdateValue"
    @blur="handleBlur"
    @change="handleChange"
    @focus="handleFocus"
    @changeOnBlur="handleChangeOnBlur"
  ></air8-input-number>
</template>

<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'Air8Money',

  aliasName: 'a8-d-currency',

  inheritAttrs: false,

  props: {
    value: [String, Number],
    max: {
      type: [String, Number],
      default: Number.MAX_VALUE
    },
    min: {
      type: [String, Number],
      default: 0
    },
    placeholder: String,
    updateOnBlur: Boolean,
    currency: String
  },

  emits: ['update:value', 'blur', 'change', 'focus', 'changeOnBlur'],

  computed: {
    internalPrecision () {
      if (this.currency === 'JPY') {
        return 0
      } else {
        return 2
      }
    }
  },

  methods: {
    handleUpdateValue (e) {
      this.$emit('update:value', e)
    },

    handleBlur () {
      this.$emit('blur')
    },

    handleChange (e) {
      this.$emit('change', e)
    },

    handleFocus () {
      this.$emit('focus')
    },

    handleChangeOnBlur (e) {
      this.$emit('changeOnBlur', e)
    }
  }
})
</script>
