import { ComputedRef, reactive, Ref, unref } from 'vue'
import _, { result } from 'lodash'

export default class TableSelection {
  store: any = {}
  rowKey: Ref<any> | ComputedRef<any> | any
  props: any = {}
  disabledSelected
  changeFunction: any
  updateCheckboxPropsFunction: any
  constructor (rowKey: Ref<any> | ComputedRef<any> | any, disabledSelected?: Ref<any> | any, changeFunction?: any, updateCheckboxPropsFunction?: any) {
    this.rowKey = rowKey
    this.props = reactive({
      columnWidth: 40,
      fixed: true,
      selectedRowKeys: [] as any[],
      onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
        return this.onChange(selectedRowKeys, selectedRows)
      },
      getCheckboxProps: (record: any) => {
        return this.getCheckboxProps(record)
      }
    })
    this.disabledSelected = disabledSelected
    this.changeFunction = changeFunction
    this.updateCheckboxPropsFunction = updateCheckboxPropsFunction
  }

  onChange (selectedRowKeys: any[], selectedRows: any[]) {
    const disabledSelected = unref(this.disabledSelected)
    if (_.isFunction(disabledSelected)) {
      selectedRows = _.reduce(selectedRows, (result:any[], value: any) => {
        const isDisabled = disabledSelected(value, result)
        if (!isDisabled) {
          result.push(value)
        }
        return result
      }, [] as any[])

      selectedRowKeys = _.map(selectedRows, unref(this.rowKey))
    }

    this.props.selectedRowKeys = selectedRowKeys

    this.store = _.pick(this.store, selectedRowKeys)

    _.each(selectedRows, (row) => {
      const key = this.getKeyValue(row)
      if (!_.isNil(key)) {
        this.store[key] = row
      }
    })
    this.callChangeFunction()
    this.callUpdateCheckboxPropsFunction()
  }

  getKeyValue (row: Ref<any> | any) {
    const rowKey = unref(this.rowKey)
    if (_.isString(rowKey) && !_.isEmpty(rowKey)) {
      return _.get(row, rowKey)
    } else {
      return undefined
    }
  }

  getCheckboxProps (record: any) {
    let isDisabled = record._summary
    if (!isDisabled) {
      const disabledSelected = unref(this.disabledSelected)
      if (_.isFunction(disabledSelected)) {
        isDisabled = disabledSelected(record, this.values())
      } else {
        isDisabled = _.findIndex(disabledSelected, (item: any) => {
          return this.getKeyValue(record) === this.getKeyValue(item)
        }) !== -1
      }
    }

    return {
      disabled: isDisabled
    }
  }

  clear () {
    this.store = {}
    this.props.selectedRowKeys = []
    this.callChangeFunction()
    this.callUpdateCheckboxPropsFunction()
  }

  values () {
    return _.values(this.store)
  }

  updateSelection (selection: any[] | undefined) {
    selection = selection || []
    this.props.selectedRowKeys = _.map(selection, (item: any) => this.getKeyValue(item))
    this.store = _.reduce(selection, (result:any, item: any) => {
      const key = this.getKeyValue(item)
      result[key] = item
      return result
    }, {} as any)

    this.callUpdateCheckboxPropsFunction()
  }

  callChangeFunction () {
    if (_.isFunction(this.changeFunction)) {
      this.changeFunction(this.values())
    }
  }

  callUpdateCheckboxPropsFunction () {
    if (_.isFunction(this.updateCheckboxPropsFunction)) {
      this.updateCheckboxPropsFunction()
    }
  }
}
