import _ from 'lodash'
import { getCurrentInstance, h, resolveDynamicComponent, ref, unref, VNode, Ref, withDirectives, resolveDirective } from 'vue'
import BaseBuilder from '../BaseBuilder'
import { getByKeyField, instanceofVNode, removeKeyField } from '../../utils/componentUtils'

export default class DefaultBuilder extends BaseBuilder {
  public render (): VNode[] | undefined {
    if (_.isNil(this.currentConfig)) {
      return undefined
    }
    return _.chain(this.currentConfig)
      .castArray()
      .map((config) => {
        return this.renderComponent(config, this.getParentScope())
      })
      .filter((node:any) => {
        return !_.isNil(node)
      })
      .value() as VNode[]
  }

  private renderComponent (config: any, parentScope:any) : VNode | undefined {
    if (_.isNil(config)) {
      return undefined
    } else if (_.isFunction(config)) {
      return config(parentScope)
    } else if (instanceofVNode(config)) {
      return config
    } else if (_.isPlainObject(config)) {
      return this.renderDirectiveWithObjectConfig(this.renderComponentWithObjectConfig(config, parentScope), config, parentScope)
    } else {
      return config
    }
  }

  private getRenderComponentProps (config:any): any {
    config = removeKeyField(config)
    return _.mapValues(config, (value:any, key:string) => {
      if (key !== 'ref') {
        return unref(value)
      } else {
        return value
      }
    })
  }

  private renderComponentWithObjectConfig (config: any, parentScope:any): VNode | undefined {
    const vIf: any = this.getComponentIf(config, parentScope)
    if (vIf === false) {
      return undefined
    }

    let type:any = unref(config.$type)
    if (_.isEmpty(type)) {
      throw new Error(`unknow vue component(${type}))`)
    } else if (_.isString(type)) {
      const instance = getCurrentInstance()
      if (instance) {
        type = resolveDynamicComponent(type)
      }
    }

    if (_.isString(type)) {
      // HTML
      return h(type, this.getRenderComponentProps(config), this.getInstanceRender('main', config.$children, parentScope))
    } else {
      const compRef = ref()
      compRef.value = h(type, this.getRenderComponentProps(config), this.getSlots(config, compRef))

      return compRef.value
    }
  }

  private getSlots (config: any, compRef: Ref<any>) {
    let slots = _.clone(config.$slots)

    slots = _.reduce(slots, (result:any, value:any, key:any) => {
      const isFunctionKey = key.match(/^(\$F)+/g)
      let newValue: any
      if (_.isNil(value)) {
        newValue = () => undefined
      } else if (isFunctionKey) {
        newValue = (scope: any): any => {
          return this.getInstanceRender('main', _.invoke({ method: value }, 'method', scope), scope)
        }
      } else {
        newValue = (scope: any): any => {
          return this.getInstanceRender('main', value, scope)
        }
      }
      if (isFunctionKey) {
        const realKey = key.replaceAll(/^(\$F)+/g, '')
        if (!_.has(result, realKey)) {
          result[realKey] = newValue
        }
      } else {
        result[key] = newValue
      }
      return result
    }, {} as any)

    slots = _.defaults(slots, {
      default: _.isNil(config.$children) ? undefined : () => {
        return this.getInstanceRender('main', config.$children, _.get(compRef.value, 'component.ctx'))
      }
    })

    return slots
  }

  private renderDirectiveWithObjectConfig (content: VNode | undefined, config: any, parentScope: any): VNode | undefined {
    if (_.isNil(content)) {
      return content
    }

    const directive = _.chain(unref(_.get(config, '$directive')))
      .filter((item) => !_.isEmpty(item))
      .map((item: any) => {
        item = _.map(item, (prop: any) => {
          return unref(prop)
        })
        let type:any = item[0]
        if (_.isEmpty(type)) {
          throw new Error(`unknow vue directive(${type}))`)
        } else if (_.isString(type)) {
          const instance = getCurrentInstance()
          if (instance) {
            type = resolveDirective(type)
          }
        }
        item[0] = type
        return item
      }).value()

    if (_.isEmpty(directive)) {
      return content
    }

    return withDirectives(content, directive)
  }
}
