
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'section-title',
  props: {
    title: {
      type: String as PropType<string>
    },
    required: {
      type: Boolean
    }
  },
  setup (props, context) {
    return {
    }
  }
})
