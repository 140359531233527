import _ from 'lodash'
import { RendererElement, RendererNode, reactive, VNode } from 'vue'
import DefaultBuilder from './DefaultBuilder'

export default class CustomerGroupActionButtonBuilder extends DefaultBuilder {
  public render (): VNode<RendererNode, RendererElement, { [key: string]: any }>[] | undefined {
    return undefined
  }

  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    const result = _.chain(currentConfig)
      .map((buttonConfig, index) => this.initButton(buttonConfig))
      .value()
    return result
  }

  delay (timer: any) {
    if (!_.isNumber(timer)) {
      timer = 900
    }

    if (timer <= 0) {
      return
    }
    return new Promise((resolve) => {
      _.delay(() => resolve(true), timer)
    })
  }

  invokeFunction (method: any, ...params: any[]) {
    if (!_.isFunction(method)) {
      return
    }
    return method(...params)
  }

  invokeBeforeAction (config: any) {
    if (_.isNil(config.beforeAction)) {
      return true
    }

    return this.invokeFunction(config.beforeAction, config.key)
  }

  invokeAction (config: any) {
    return this.invokeFunction(config.action)
  }

  async invokeAfterAction (config: any) {
    if (_.isNil(config.afterAction)) {
      return
    }

    await this.delay(config.delayTime)
    this.invokeFunction(config.afterAction, config.key)
  }

  async doNoBeforeAction (config: any) {
    const result = await this.invokeAction(config)
    if (result === true) {
      await this.invokeAfterAction(config)
    }
  }

  async doAction (config: any) {
    const valid = await this.invokeBeforeAction(config)
    if (!valid) {
      return
    }
    await this.doNoBeforeAction(config)
  }

  initButton (config: any) :any {
    const buttonConfig = reactive(config)

    let defaultConfig: any = {
      $type: 'a-button',
      action: () => this.doAction(buttonConfig)
    }
    switch (buttonConfig.buttonType) {
      case 'modal':
        defaultConfig = {
          $type: 'Air8ModalButton',
          beforeClick: () => this.invokeBeforeAction(buttonConfig),
          action: () => this.doNoBeforeAction(buttonConfig)
        }
        break
      case 'warning':
        defaultConfig = {
          $type: 'Air8WarningPopoverButton',
          action: () => this.doAction(buttonConfig)
        }
        break
    }

    return {
      ...buttonConfig,
      ...defaultConfig
    }
  }
}
