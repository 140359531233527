<template>
  <a-input
    ref="inputRef"
    class="air8-string-input-number"
    :value="inputValue"
    :placeholder="placeholder"
    @update:value="handleUpdateInputValue"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="handleKeydown"
  ></a-input>
</template>
<script>
import { computed, defineComponent, ref, nextTick, watch } from 'vue'
import { t } from '@/config/locale'
import _ from 'lodash'
import * as NumberUtils from '@/utils/number'
import BigNumber from 'bignumber.js'

const NUMBER_FORMAT_OPTION = {
  // string to prepend
  prefix: '',
  // decimal separator
  decimalSeparator: '.',
  // grouping separator of the integer part
  groupSeparator: '',
  // primary grouping size of the integer part
  groupSize: 3,
  // secondary grouping size of the integer part
  secondaryGroupSize: 0,
  // grouping separator of the fraction part
  fractionGroupSeparator: ' ',
  // grouping size of the fraction part
  fractionGroupSize: 0,
  // string to append
  suffix: ''
}

export default defineComponent({
  name: 'Air8StringInputNumber',

  props: {
    value: [String, Number],
    max: [String, Number],

    min: [String, Number],

    minPrecision: Number,

    maxPrecision: Number,

    isUseGroupSeperator: Boolean,

    formatter: Function,

    parser: Function,

    integerNotPrecision: Boolean,

    placeholder: {
      type: String,
      default: () => t('common.input.placeholder')
    },

    maxlength: Number,

    decimalLength: Number
  },

  emits: ['update:value', 'blur', 'change', 'focus'],

  setup (props, { emit }) {
    const isTyping = ref(false)
    const inputRef = ref()
    const isAllowNagtiveNum = computed(() => {
      return _.isNil(props.min) || NumberUtils.lt(props.min, 0)
    })

    const decimalLength = computed(() => {
      if (_.isNil(props.decimalLength) || props.decimalLength < 0) {
        return undefined
      }
      return props.decimalLength
    })

    const maxlength = computed(() => {
      if (_.isNil(props.maxlength) || props.maxlength < 0) {
        return undefined
      }
      return _.max([props.maxlength, decimalLength.value + 1])
    })

    const integerLength = computed(() => {
      if (_.isNil(maxlength.value)) {
        return undefined
      } else if (_.isNil(decimalLength.value)) {
        return maxlength.value
      }
      return maxlength.value - decimalLength.value
    })

    const maxPrecision = computed(() => {
      if (_.isNil(props.maxPrecision) || props.maxPrecision < 0) {
        return decimalLength.value
      }
      return _.min([decimalLength.value, props.maxPrecision])
    })

    const minPrecision = computed(() => {
      if (_.isNil(props.minPrecision) || props.minPrecision < 0) {
        return undefined
      }
      return _.min([decimalLength.value, props.minPrecision])
    })

    function internalFormatNumber (value, isFullFormatter) {
      if (_.isNil(value)) {
        return value
      } else if (!_.isString(value)) {
        value = value + ''
      }

      const matchResults = /^(-?)(\d+)((\.)(\d*))?$/g.exec(value)
      if (_.isEmpty(matchResults)) {
        return value
      }

      let [match, sign = '', integerPart = '', allDecimal = '', decimalPoint = '', decimalPart = ''] = matchResults

      if (isFullFormatter) {
        const num = new BigNumber(sign + integerPart + allDecimal)
        const decimalPlaces = num.dp()
        let precision = decimalPlaces

        if (!props.integerNotPrecision || decimalPlaces !== 0) {
          precision = _.max([_.min([decimalPlaces, maxPrecision.value]), minPrecision.value])
        }

        return num.toFormat(precision, props.isUseGroupSeperator ? undefined : NUMBER_FORMAT_OPTION)
      } else {
        integerPart = integerPart.replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${props.isUseGroupSeperator ? ',' : ''}`)
        return sign + integerPart + decimalPoint + decimalPart
      }
    }

    function internalFormatValue2InputValue (value, isFullFormatter) {
      value = internalFormatNumber(value, isFullFormatter)

      if (_.isFunction(props.formatter)) {
        value = props.formatter(value)
      }
      return value
    }

    function removeNoneNumberChar (value) {
      return value.replace(new RegExp(`[^${isAllowNagtiveNum.value ? '-' : ''}\\d.]`, 'g'), '')
        .replace(/(^-[^-]*)-+/g, (match, p1) => {
          return p1
        })
        .replace(/(\.[^.]*)(\.+)/g, (match, p1) => {
          return p1
        })
    }

    function removeNumberOverLength (value) {
      let regText = '(-?\\d*)'

      if (_.isNil(maxPrecision.value)) {
        regText += '(\\.\\d{0,})?(.*)'
      } else if (maxPrecision.value === 0) {
        regText += '(\\.){0,0}(.*)'
      } else {
        regText += `(\\.\\d{0,${maxPrecision.value}})?(.*)`
      }

      const reg = new RegExp(`^${regText}$`, 'g')
      const matchResults = reg.exec(value)
      if (_.isEmpty(matchResults)) {
        return
      }

      const [match, integerPart = '', allDecimal = ''] = matchResults

      if (_.size(integerPart) > integerLength.value) {
        return integerPart.substring(0, integerLength.value)
      } else {
        return integerPart + allDecimal
      }
    }

    function internalParseInputValue2Value (value) {
      if (_.isFunction(props.parser)) {
        value = props.parser(value)
      }

      if (_.isNil(value)) {
        return null
      } else if (!_.isString(value)) {
        value += ''
      }

      value = removeNoneNumberChar(value)
      value = removeNumberOverLength(value)
      return value
    }

    function isEquals (target, source) {
      target = new BigNumber(target)
      source = new BigNumber(source)
      return target.eq(source)
    }

    const internalValue = ref(props.value)

    watch(() => props.value, () => {
      if (!isEquals(internalValue.value, props.value)) {
        internalValue.value = props.value
      }
    })

    const inputValue = computed(() => {
      return internalFormatValue2InputValue(internalValue.value, !isTyping.value)
    })

    function emitValue (value) {
      internalValue.value = value
      if (!isEquals(internalValue.value, props.value)) {
        emit('update:value', value)
        emit('change', value)
      }
    }

    function updateCursorPosition () {
      recordCursorPosition()
      nextTick(() => {
        recoverCursorPosition()
      })
    }

    function handleUpdateInputValue (value) {
      isTyping.value = true
      updateCursorPosition()
      const result = internalParseInputValue2Value(value)
      emitValue(result)
    }

    function getBigNumber (value) {
      if (!(value instanceof BigNumber)) {
        value = new BigNumber(value)
      }

      return value.isNaN() ? undefined : value
    }

    function getFinalValue (value) {
      const min = getBigNumber(props.min)
      let max = getBigNumber(props.max)
      if (!_.isNil(min) && !_.isNil(max) && max.lt(min)) {
        max = min
      }

      value = getBigNumber(value)

      if (_.isNil(value)) {
        return null
      } else if (!_.isNil(min) && NumberUtils.lt(value, min)) {
        value = min
      } else if (!_.isNil(max) && NumberUtils.gt(value, max)) {
        value = max
      }

      const decimalPlaces = value.dp()
      let currentPrecision = decimalPlaces
      if (props.integerNotPrecision && decimalPlaces === 0) {
        // do nothing
      } else if (decimalPlaces < minPrecision.value) {
        currentPrecision = minPrecision.value
      } else if (decimalPlaces > maxPrecision.value) {
        currentPrecision = maxPrecision.value
      }
      return value.toFormat(currentPrecision, BigNumber.ROUND_DOWN, NUMBER_FORMAT_OPTION)
    }

    function handleBlur () {
      isTyping.value = false

      const result = getFinalValue(props.value)
      emitValue(result)
      emit('blur')
    }

    function handleFocus () {
      emit('focus')
    }

    watch(() => _.pick(props, ['min', 'max', 'min', 'minPrecision', 'maxPrecision', 'parser', 'maxlength', 'decimalLength']),
      () => {
        const result = getFinalValue(props.value)
        emitValue(result)
      })

    const cursor = ref({ cursorStart: undefined, cursorEnd: undefined })
    const lastKeyCode = ref()

    function getInputEl () {
      return _.get(inputRef.value, 'input')
    }
    function recordCursorPosition () {
      try {
        const inputElem = getInputEl()
        cursor.value = {
          cursorStart: inputElem.selectionStart,
          cursorEnd: inputElem.selectionEnd,
          value: inputElem.value,
          cursorBefore: inputElem.value.substring(0, inputElem.selectionStart),
          cursorAfter: inputElem.value.substring(inputElem.selectionEnd)
        }
      } catch (e) { }
    }

    function recoverCursorPosition () {
      let { cursorStart: start, cursorEnd: end, value: inputValue, cursorBefore, cursorAfter } = cursor.value

      const inputElem = getInputEl()

      if (start === undefined || end === undefined || !inputElem) {
        return
      }

      const currentValue = inputElem.value
      if (currentValue === inputValue) {
        // do nothing
      } else if (lastKeyCode.value === 8 && currentValue.substring(0, cursorBefore.length) === cursorBefore) {
        // do nothing
      } else if (currentValue.substring(currentValue.length - cursorAfter.length) === cursorAfter) {
        end = currentValue.length - cursorAfter.length
        start = end
      }

      try {
        const currentStart = inputElem.selectionStart
        const currentEnd = inputElem.selectionEnd

        if (start !== currentStart || end !== currentEnd) {
          inputElem.setSelectionRange(start, end)
        }
      } catch (e) { }
    }

    function handleKeydown (e) {
      lastKeyCode.value = e.keyCode
    }

    return {
      inputValue,
      handleUpdateInputValue,
      handleBlur,
      handleFocus,
      inputRef,
      handleKeydown,
      internalFormatValue2InputValue,
      internalParseInputValue2Value,
      internalMaxLength: maxlength,
      internalIntegerLength: integerLength,
      internalDecimalLength: decimalLength,
      isTyping,
      getFinalValue
    }
  }
})
</script>
