import _ from 'lodash'
import { computed, h, isRef, ref, resolveComponent, resolveDynamicComponent, unref } from 'vue'

type vModelOption = { valueFieldMap?:any, assign?: boolean, empty2Nil?: boolean }

function revertObjectKeyValue (obj: any) {
  if (_.isNil(obj)) {
    return undefined
  }
  return _.reduce(obj, (result, value, key) => {
    result[value] = key
    return result
  }, {} as any)
}

function getSingleObjectByFieldMap (obj: any, fieldMap: any, allowNilValue?: boolean, allowAllKey?: boolean, empty2Nil?: boolean) {
  if (_.isNil(obj) && !allowNilValue) {
    return obj
  } else if (_.isEmpty(fieldMap)) {
    return _.clone(obj)
  }

  const target = _.reduce(fieldMap, (result, value, key) => {
    const objValue = _.get(obj, key)
    if (_.isNil(objValue) && !allowNilValue) {
      return result
    }
    result[value] = objValue
    return result
  }, {} as any)

  if (allowAllKey) {
    _.assign(target, _.omit(obj, _.keys(fieldMap)))
  }

  if (empty2Nil && _.isEmpty(target)) {
    return undefined
  }

  return target
}

function getObjectByFieldMap (obj: any, fieldMap: any, allowNilValue?: boolean, empty2Nil?: boolean) {
  if (_.isArray(obj)) {
    return _.map(obj, (item) => {
      return getSingleObjectByFieldMap(item, fieldMap, allowNilValue, false, empty2Nil)
    })
  } else {
    return getSingleObjectByFieldMap(obj, fieldMap, allowNilValue, false, empty2Nil)
  }
}

function getValue (data:any, field?:any) {
  const target = unref(data)
  if (_.isNil(field)) {
    return target
  } else {
    return _.get(target, field)
  }
}

function setValue (data:any, field:any, value:any) {
  if (_.isNil(field)) {
    if (isRef(data)) {
      data.value = value
    }
  } else {
    _.set(isRef(data) ? data.value : data, field, value)
  }
}

export function vModel (data: any, field?: any, option?: vModelOption):any {
  const valueFieldMap = _.get(option, 'valueFieldMap')
  const revertValueFieldMap = revertObjectKeyValue(valueFieldMap)
  const assign = !!_.get(option, 'assign')

  return computed({
    get: () => {
      const target = getValue(data, field)
      if (!_.isNil(valueFieldMap)) {
        return getObjectByFieldMap(target, valueFieldMap, false, !!_.get(option, 'empty2Nil'))
      }
      return target
    },
    set: (value) => {
      if (!_.isNil(valueFieldMap)) {
        value = getObjectByFieldMap(value, revertValueFieldMap, assign)
      }
      if (assign && !_.isNil(getValue(data, field))) {
        _.assign(getValue(data, field), value)
        return
      }
      setValue(data, field, value)
    }
  })
}

export function summaryTableCurrencyFormatterArguments (tableDataSource: any | any[], currencyField: any) {
  return (rowScope: any) => {
    return [summaryTableCurrency(tableDataSource, currencyField)(rowScope)]
  }
}

export function summaryTableCurrency (tableDataSource: any | any[], currencyField: any) {
  return (rowScope: any) => {
    if (_.get(rowScope.record, '_summary')) {
      const currencyList = _.chain(getValue(tableDataSource)).map(currencyField).uniq().value()
      return _.size(currencyList) === 1 ? _.nth(currencyList, 0) : undefined
    } else {
      return _.get(rowScope.record, currencyField)
    }
  }
}

export function debounceExecute (method: any, timer = 150, options?: any): any {
  let counter = 0
  const obj = { method }
  const debounceMethod = _.debounce(async () => {
    const result = _.invoke(obj, 'method', counter)
    if (result instanceof Promise) {
      await result
    }
    counter = 0
  }, timer, options)

  return function () {
    counter++
    debounceMethod()
  }
}

export function getTabs (children: any[], isClickTabAutoMounted = false, className: any = 'card', selectedTab?: any) {
  if (_.isEmpty(children)) {
    return
  }

  if (!isRef(selectedTab)) {
    selectedTab = ref(_.get(children, '0.key', 0))
  }

  return {
    $type: 'a-tabs',
    class: className,
    type: 'card',
    $MactiveKey: selectedTab,
    $children: _.map(children, (child:any, index) => {
      const key = _.get(child, 'key', index)
      let tabChild = child.children
      if (isClickTabAutoMounted) {
        tabChild = _.assign({
          $if: computed(() => selectedTab.value === key)
        }, tabChild)
      }

      let tabTitle = child.title
      if (_.isString(tabTitle)) {
        tabTitle = h(resolveComponent('i-span') as any, {}, { default: () => child.title })
      }

      return {
        $type: 'a-tab-pane',
        tab: tabTitle,
        key,
        $children: tabChild
      }
    })
  }
}

export function generateComponent (getConfig: any) {
  const comp:any = {
    props: {
      scope: Object
    },

    setup (props: any) {
      const config = getConfig(props.scope)
      return {
        config
      }
    },

    render (): any {
      return h(resolveDynamicComponent('c0') as any, {
        config: this.config
      })
    }
  }

  return comp
}
