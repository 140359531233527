import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, { class: "air8-list" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (x) => {
        return (_openBlock(), _createBlock(_component_a_descriptions_item, {
          key: x.key
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass({required: x.required})
            }, _toDisplayString(x.label), 3)
          ]),
          default: _withCtx(() => [
            (this.$slots[x.key])
              ? _renderSlot(_ctx.$slots, x.key, { key: 0 })
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(x.customRender({text: _ctx.data[x.key], record: _ctx.data})), 1)
                ], 64))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 3
  }))
}