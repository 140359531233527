import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container])
  }, [
    _createVNode(_component_a_upload, {
      "file-list": _ctx.fileList,
      "before-upload": _ctx.beforeUpload,
      showUploadList: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "primary",
          loading: _ctx.uploading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.import')), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    }, 8, ["file-list", "before-upload"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      title: _ctx.$t('common.import'),
      onOk: _ctx.onOk,
      onCancel: _ctx.onCancel,
      okText: _ctx.hasError ? _ctx.$t('common.import.download_report') : undefined
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('common.import.import_result', _ctx.stats.total, _ctx.stats.success, _ctx.stats.fail)), 1)
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk", "onCancel", "okText"])
  ], 2))
}