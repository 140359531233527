import { useHttp } from '@/plugins/http/src/index'
import { PageQueryParams, PageResponse } from '@/common/page-data'

export function updatePreference (key: string, value: any) {
  const { http } = useHttp()
  return http.post<any>('/api/air8-auth-center/user/preference/edit', { key, value: JSON.stringify(value) })
}

export function getPreference (key: string) {
  const { http } = useHttp()
  return http.get<any>('/api/air8-auth-center/user/preference/detail', { params: { key } }).then(data => JSON.parse(data?.valueLong || 'null'))
}
