import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'slot-render',
  props: {
    s: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    return () => h('span', {}, props.s)
  }
})
