
import { computed, defineComponent, onMounted, PropType } from 'vue'
import { LocalData } from '@/common/local-data'
import SlotRender from './slot-render'
import { useLocal } from '@/plugins/locale'
import { numberFormatter } from '@/common/formatter'

export default defineComponent({
  name: 'local-table',
  components: { SlotRender },
  props: {
    columns: {
      type: Array as PropType<any[]>,
      required: true
    },
    localData: {
      type: Object as PropType<LocalData<any>>
    },
    data: {
      type: Array
    },
    pagination: {
      type: [Boolean, Object]
    },
    summary: {
      type: [Function] as PropType<({ key }: any) => any>
    }
  },
  setup (props: any, context) {
    const { t } = useLocal()

    const attrs = {
      rowClassName: (record: any, i: number) => (i % 2 === 1 ? 'striped' : null),
      rowKey: (record: any, i: number) => 'table_' + i,
      bordered: true,
      ...context.attrs
    }

    const eventHandlers = {
    }

    const renderedColumns = computed(() => {
      return props.columns.map((v: any) => {
        const ret: any = Object.assign({}, v)
        if (!v?.slots) {
          const customRender = ({ text, record, index }: any) => {
            return (v.customRender ? v.customRender({ text, record, index }) : text) || '-'
          }
          ret.customRender = customRender
        }
        return ret
      })
    })

    const dataSource = computed(() => {
      const ret = (props.data || props.localData.rows || []).slice()
      if (props.summary && ret.length > 0) {
        const extra: any = {}
        renderedColumns.value.forEach((v: any) => {
          const k = v?.dataIndex || v?.key
          const r = props.summary({ key: k })
          if (r === undefined) {
            return
          }
          if (r === null) {
            return
          }
          if (r === true) {
            const num = ret.map((w: any) => w[k]).reduce((t: any, n: any) => {
              const e = [undefined, null, '']
              if (e.includes(t) && e.includes(n)) return null
              return Number(t || 0) + Number(n || 0)
            }, null)
            extra[k] = num === null ? undefined : num
          } else {
            extra[k] = r
          }
        })
        ret.push({
          __summary: true,
          ...extra,
          seq: t('common.summary')
        })
      }
      return ret
    })

    // onMounted(() => {
    //   props.localData.load({})
    // })

    return {
      attrs,
      eventHandlers,
      dataSource,
      renderedColumns
    }
  }
})
