import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!

  return _withDirectives((_openBlock(), _createBlock(_component_a_col, { span: _ctx.span }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.wrap]),
            ref: "wrapEle"
          }, [
            _createVNode(_component_a_select, _mergeProps({
              value: _ctx.form[_ctx.prop],
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form[_ctx.prop] = $event)),
              placeholder: _ctx.$t('common.placeholder_select', _ctx.label),
              allowClear: ""
            }, _ctx.$attrs, { onChange: _ctx.onChange }), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$m(_ctx.name, _ctx.hasAll), (row) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: row?.key,
                      key: row?.key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row?.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ])
              ]),
              _: 3
            }, 16, ["value", "placeholder", "onChange"]),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.label]),
              ref: "labelEle"
            }, _toDisplayString(_ctx.label), 3)
          ], 2)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["span"])), [
    [_vShow, _ctx.visible]
  ])
}