<template>
  <a-card class="air8-ocr-upload__done-card">
    <template #cover>
      <air8-hover>
        <template #mask>
          <a-space>
            <a-button
              ghost
              @click="handleClickPreview($event, file)"
            >
              <EyeOutlined /> {{ $t('common.ocr.preview') }}
            </a-button>
            <a-button
              ghost
              @click="handleClickDownload(file)"
            >
              <DownloadOutlined /> {{ $t('common.ocr.download') }}
            </a-button>
          </a-space>
        </template>
        <a-image
          v-if="fileImageSrc"
          :src="fileImageSrc"
          :preview="false"
        ></a-image>
        <component
          :is="fileIcon"
          v-else
        />
      </air8-hover>
    </template>
    <a-button
      shape="circle"
      class="air8-ocr-upload__done-card__close-btn"
      @click="handleRemoveFile"
    >
      <template #icon>
        <CloseOutlined />
      </template>
    </a-button>
    <a-card-meta>
      <template
        #title
        v-if="title"
      >
        <Air8AutoTooltip class="air8-ocr-upload__done-card-title" :value="title"></Air8AutoTooltip>
      </template>
      <template
        #description
        v-if="description"
      >
        <span class="air8-ocr-upload__upload-card-description">{{ description }}</span>
      </template>
    </a-card-meta>
  </a-card>
</template>
<script>

import { computed, defineComponent } from 'vue'
import { FilePdfFilled, EyeOutlined, FileFilled, FileExcelFilled, FileWordFilled, FileZipFilled, CloseOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import { ossPreview, ossDownload } from '@/common/oss'
import _ from 'lodash'
import Air8Hover from './Hover.vue'

function isPdf (type) {
  return type === 'application/pdf'
}

function isExcel (type) {
  return ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(type)
}

function isWord (type) {
  return ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'].includes(type)
}

function isImage (type) {
  return _.startsWith(type, 'image/')
}

function isZip (type) {
  return ['application/zip', 'application/x-zip-compressed'].includes(type)
}

export default defineComponent({
  name: 'Air8OcrUploadDoneCard',

  components: {
    Air8Hover,
    EyeOutlined,
    DownloadOutlined,
    CloseOutlined
  },

  props: {
    file: Object,
    title: String,
    description: String
  },

  setup (props, { emit }) {
    const fileImageSrc = computed(() => {
      if (_.isNil(_.get(props.file, 'originFileObj')) || !isImage(_.get(props.file, 'type'))) {
        return undefined
      }
      const blob = new Blob([_.get(props.file, 'originFileObj')], { type: _.get(props.file, 'type') })
      return URL.createObjectURL(blob)
    })

    const fileIcon = computed(() => {
      const type = _.get(props.file, 'type')
      if (isPdf(type)) {
        return FilePdfFilled
      } else if (isExcel(type)) {
        return FileExcelFilled
      } else if (isWord(type)) {
        return FileWordFilled
      } else if (isZip(type)) {
        return FileZipFilled
      }
      return FileFilled
    })

    function handleClickPreview (event) {
      if (props.debug && event.ctrlKey) {
        handleClickDebug()
        return
      }

      ossPreview(_.get(props.file, 'response.data.objectKey'))
    }

    function handleClickDebug () {
      // empty
    }

    function handleClickDownload (file) {
      ossDownload(_.get(file, 'response.data.objectKey'))
    }

    function handleRemoveFile () {
      emit('remove')
    }

    return {
      fileImageSrc,
      fileIcon,
      handleClickPreview,
      handleClickDownload,
      handleRemoveFile
    }
  }
})
</script>
<style lang="less" scoped>
.air8-ocr-upload__done-card {
  text-align: center;
  position: relative;
  margin-top: 16px;

  :deep(.ant-card-cover) {
    padding-bottom: 4px;
    padding-top: 4px;

    width: 100%;
    .air8-hover__body {
      .anticon {
        font-size: 80px;
        color: #5fd39b;
      }
    }
    .ant-image-img {
      height: 196px;
    }
  }

  :deep(.ant-card-meta) {
    .ant-card-meta-title {
      color: inherit;
    }
    .ant-card-meta-description {
      color: inherit;
    }
  }
}

.air8-ocr-upload__done-card__close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
}

.air8-ocr-upload__upload {
  :deep(.ant-upload) {
    width: 100%;
  }
}
</style>
