import { ref } from 'vue'

export const useModal = () => {
  const visible = ref<boolean>(false)

  const params = ref<any>(null)

  let resolveFn: (ret: any) => void, rejectFn: () => void

  let beforeOpenFn: (data: any) => Promise<any>

  let beforeCloseFn: (data?: any) => Promise<any>

  const complete = (ret: any) => {
    const handler = () => {
      visible.value = false
      if (resolveFn) resolveFn(ret)
    }
    if (beforeCloseFn) {
      beforeCloseFn(ret).then(handler)
    } else {
      handler()
    }
  }

  const cancel = () => {
    const handler = () => {
      visible.value = false
      if (rejectFn) rejectFn()
    }
    if (beforeCloseFn) {
      beforeCloseFn().then(handler)
    } else {
      handler()
    }
  }

  const open = (data: any) => {
    const handler = (args: any) => {
      return new Promise((resolve, reject) => {
        resolveFn = resolve
        rejectFn = reject
        params.value = args
        visible.value = true
      })
    }
    if (beforeOpenFn) {
      return beforeOpenFn(data).then(r => handler(r))
    } else {
      return handler(data)
    }
  }

  const beforeOpen = (fn: (data: any) => Promise<any>) => {
    beforeOpenFn = fn
  }

  const beforeClose = (fn: (data?: any) => Promise<any>) => {
    beforeCloseFn = fn
  }

  return {
    visible,
    params,
    complete,
    cancel,
    open,
    beforeOpen,
    beforeClose
  }
}
