<template>
  <span class="air8-auto-tooltip">
    <a-popover
      :get-popup-container="getPopupContainer"
      :content="value"
      v-model:visible="visible"
    >
      <span
        class="air8-auto-tooltip__text"
        ref="textDom"
      >{{ value }}</span>
    </a-popover>
  </span>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import _ from 'lodash'

const TOOLTIP_TYPE = {
  AUTO: 'auto',
  NO: 'no',
  ALWAYS: 'always'
}

export default defineComponent({
  name: 'Air8AutoTooltip',

  props: {
    value: [String, Number, Boolean, Object, Array],
    tooltipType: {
      type: [String, Boolean],
      default: true
    },
    getPopupContainer: {
      type: Function,
      default: function () {
        return _.get(document.getElementsByClassName('air8-popup__container'), '0')
      }
    }
  },

  setup (props) {
    const textDom = ref()
    const internalVisible = ref(false)

    function isShowTooltip () {
      if (props.tooltipType === true || props.tooltipType === TOOLTIP_TYPE.AUTO) {
        const span = textDom.value
        if (!span) {
          return false
        }
        return span.scrollWidth > span.offsetWidth
      } else {
        return props.tooltipType === TOOLTIP_TYPE.ALWAYS
      }
    }

    const visible = computed({
      get: () => {
        return internalVisible.value
      },
      set: (value) => {
        if (!isShowTooltip()) {
          value = false
        }
        internalVisible.value = value
      }
    })

    return {
      textDom,
      visible
    }
  }
})
</script>
<style lang="less" scoped>
.air8-auto-tooltip__text {
  word-break: keep-all;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
</style>
