
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'info-list',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => ({})
    }
  }
})

