import { getPreference, updatePreference } from '@/api/user-perference'

export interface Field {
  key: string;

  title: string;

  seq: number;

  checked: boolean;
}

export class ConfigField {
  sid!: string

  fields: Field[] = []

  updating = false

  loading = false

  sources: any[] = []

  constructor (sid: string) {
    this.sid = sid
  }

  update (row: Field, checked: boolean) {
    row.checked = checked
    this.push(this.fields).then(() => {
      // ignore
    }).catch(() => {
      row.checked = !checked
    })
  }

  reset () {
    this.push([]).then(() => {
      this.pull()
    })
  }

  sort (list: Field[] = []) {
    const ret = JSON.parse(JSON.stringify(list)).map((v: any, i: number) => {
      v.seq = i + 1
      return v
    })
    this.push(ret).then(() => {
      this.fields = ret
    })
  }

  pull (sources?: any[]) {
    if (!sources) sources = this.sources
    else this.sources = sources || []
    const s = sources || []
    this.loading = true
    getPreference(this.sid).then(value => {
      const map = new Map((value || []).filter((v: any) => !!v?.key).map((v: any) => [v.key, v]))
      this.fields = s.map(v => {
        const item = map.get(v.key || v.dataIndex) as Field
        return {
          key: v.key || v.dataIndex,
          title: v.title?.props?.title || v.title,
          checked: item ? !!item.checked : v.checked === undefined ? true : v.checked, // 接口无数据取默认个性化设置
          seq: item ? (item.seq || 0) : 0
        }
      }).sort((a: Field, b: Field) => a.seq - b.seq)
    }).catch(() => {
      this.fields = s.map(v => {
        return {
          key: v.key || v.dataIndex,
          title: v.title?.props?.title || v.title,
          checked: v.checked === undefined ? true : v.checked,
          seq: 0
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }

  push (fields: Field[]) {
    this.updating = true
    return updatePreference(this.sid, fields).finally(() => {
      this.updating = false
    })
  }
}
