
import { computed, defineComponent, nextTick, onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { useStore } from 'vuex'
import { LayoutFormContext, useFormInject } from '../layout-form/use-form'

export default defineComponent({
  name: 'form-date',
  props: {
    prop: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    showTime: {
      type: Object,
      default: null
    }
  },
  emits: ['change'],
  setup (props: any, context) {
    const { hide, set, form, getProps } = useFormInject() as LayoutFormContext
    const visible = computed(() => !hide(props.prop))
    onMounted(() => { set(props.prop, true) })
    onUnmounted(() => { set(props.prop, false) })

    const wrapEle = ref<HTMLDivElement>()
    const labelEle = ref<HTMLDivElement>()

    const updateStyle = () => {
      const width = (labelEle.value as HTMLDivElement).offsetWidth
      const input = (wrapEle.value as HTMLDivElement).querySelector('.ant-calendar-picker-input') as HTMLInputElement
      if (input) input.style.paddingLeft = width + 'px'
    }

    const dateFormat = computed(() => {
      const store = useStore()
      return store.state.app?.timeFormat
    })

    onUpdated(() => {
      updateStyle()
    })

    const onChange = () => {
      nextTick(() => {
        updateStyle()
        context.emit('change')
      })
    }

    return {
      form,
      visible,
      wrapEle,
      labelEle,
      dateFormat,
      ...getProps(),
      onChange
    }
  }
})

