<script>
import { defineComponent } from 'vue'
import CompanyReceiveAccountList from '../companyReceiveAccountList/index.vue'
import _ from 'lodash'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'FunderPaymentAccountList',

  extends: CompanyReceiveAccountList,

  props: {
    title: {
      type: String,
      default: () => t('page.repayment.repay.apply.bank.payment')
    },
    arrayName: {
      type: String,
      default: 'companyFunderBankAccountDtos'
    },
    collectBank: {
      type: Object,
      default: () => undefined
    }
  },

  watch: {
    collectBank () {
      const id = this.generatorBankAccountId(this.collectBank)
      const found = _.find(_.get(this.bankSourceList, `0.${this.arrayName}`), { _id: id }) || { companyCode: _.head(_.castArray(this.companyCodeList)) }
      this.handleUpdateValue(found, 0)
    }
  },

  methods: {
    checkoutDefaultItem (found, item, index) {
      let data = {}
      if (_.get(this.feeAmountList, index) !== _.get(this.fundSource, 'INTEREST')) {
        const id = this.generatorBankAccountId(this.collectBank)
        data = _.find(_.get(item, `${this.arrayName}`), { _id: id }) || { companyCode: _.head(_.castArray(this.companyCodeList)) }
      } else {
        data = _.get(item, `${this.arrayName}[0]`)
      }
      return _.get(found, '_ids') ? { ..._.get(item, `${this.arrayName}[0]`), id: found._ids } : data
    },

    async updateBankAccounts () {
      if (!this.editable || (_.isEmpty(this.companyCodeList) && _.isEmpty(this.currency))) {
        return
      }
      const companyCodeList = _.isString(this.companyCodeList) ? [this.companyCodeList] : (this.companyCodeList || [])
      await this.queryBankAccountsByCompanyList(companyCodeList)
      this.setDefaultBankAccount()
    }
  }
})
</script>
