<template>
  <span :class="internalClass">{{ value }}</span>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8WrapBracketsTextLabel',
  props: {
    value: String
  },

  computed: {
    internalClass () {
      return { 'air8-wrap-brakets-text-label__small-text': !_.isEmpty(this.value) && this.value.match(/^[(（]+[^()（）]*[)）]+$/g) }
    },

    internalTexts () {
      if (_.isEmpty(this.value)) {
        return [this.value]
      }

      const reg = /([^()（）]+)|([(（]+[^()（）]*[)）]+)/g
      const values = []
      do {
        const result = reg.exec(this.value)
        if (_.isNil(result)) {
          break
        }
        values.push(result[0])
      } while (true)

      if (_.isEmpty(values)) {
        return [this.value]
      } else {
        return values
      }
    }
  }
})
</script>
<style lang="less" scoped>
.air8-wrap-brakets-text-label__small-text {
  font-size: 12px;
}
</style>
