<template>
  <air8-form-item
    :name="name"
    type="Air8CategorySelect"
    :hide-label="true"
    :category="category"
    :filterBySearch="true"
    :fullOption="true"
    :fullLabel="true"
    v-bind="$attrs"
    :mode="null"
    @change="handleChange"
  >
  </air8-form-item>
</template>
<script>
import { defineComponent } from 'vue'
import { injectForm } from '@/components/air8/utils/componentUtils'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8CompanyCodeNameSelect',
  props: {
    name: String,
    category: String,
    nameField: String
  },

  emits: ['change'],

  setup (props, { emit }) {
    const form = injectForm()

    function handleChange (value, options) {
      _.set(form.model.value, props.nameField, _.get(options, 'label'))
      emit('change', value, options)
    }

    return {
      handleChange
    }
  }
})
</script>
