import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c0 = _resolveComponent("c0")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_a_modal, _mergeProps({
        key: 0,
        visible: _ctx.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = $event)),
        confirmLoading: _ctx.loading,
        maskClosable: false,
        title: _ctx.title
      }, _ctx.intrenalAttrs, {
        onCancel: _ctx.handleClickCancel,
        onOk: _ctx.handleClickOk
      }), {
        default: _withCtx(() => [
          _createVNode(_component_c0, {
            config: _ctx.config,
            scope: _ctx.scope
          }, null, 8, ["config", "scope"])
        ]),
        _: 1
      }, 16, ["visible", "confirmLoading", "title", "onCancel", "onOk"]))
    : _createCommentVNode("", true)
}