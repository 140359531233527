<script>
import { defineComponent, resolveComponent, h, ref, computed, watch, nextTick } from 'vue'
import _ from 'lodash'
import BuilderFactory from '../../builder/BuilderFactory'
import { injectForm, instanceofPageData, getComponentAttribute } from '@/components/air8/utils/componentUtils'
import { sumList } from '@/utils/number'
import { t } from '@/config/locale'
import TableSelection from '@/components/air8/utils/tableSelection'
import { isDiffNew } from '@/utils/diffUtils'
import { exportExcelFromArray } from '../../utils/exportUtils'
import { LocalData } from '@/common/local-data'
const EMPTY_PAGE_DATA = { list: [], pageNum: 1, pageSize: 1, total: 0 }

export default defineComponent({
  name: 'Air8Table',
  aliasName: 'C5',

  props: {
    model: Array,
    name: [String, Number, Array],
    editable: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    tableRowActions: {
      type: Array,
      default: () => []
    },
    useSequence: Boolean,
    sequenceKey: {
      type: String,
      default: '_seq'
    },
    rowKey: [String, Function],
    actionKey: {
      type: String,
      default: 'action'
    },
    width: {
      type: [Boolean, Number],
      default: true
    },
    height: {
      type: [Boolean, Number],
      default: true
    },
    pagination: {
      type: [Boolean, Object],
      default: true
    },
    pageData: Object,
    summary: {
      type: [Boolean, Object, String, Array, Function],
      default: false
    },
    wrapBrackets: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: [Boolean, Object],
      default: true
    },
    pageSizeOptions: {
      type: Array
    },
    rowSelection: [Boolean, Object],
    disabledSelection: [Array, Function],
    selection: Array,
    showDiffTag: Boolean,
    hasForm: {
      type: Boolean,
      default: true
    }
  },

  emits: ['change', 'update:selection'],

  setup (props, { emit }) {
    const form = injectForm()
    const table = ref()

    let idCounter = 0
    function generatorRowKey () {
      const id = ++idCounter
      if (id > Number.MAX_SAFE_INTEGER) {
        idCounter = 0
      }
      return 'air8-table_' + id
    }

    const internalModel = computed(() => {
      let model = props.model
      if (!_.isNil(props.pageData)) {
        model = props.pageData
      } else if (!_.isNil(props.name)) {
        model = _.get(form.model.value, props.name)
      }
      return model
    })

    const internalPageData = computed(() => {
      const model = internalModel.value
      if (_.isNil(model)) {
        return EMPTY_PAGE_DATA
      } else if (model instanceof LocalData) {
        return { list: model.rows, total: _.size(model) }
      } else if (!instanceofPageData(model)) {
        return { list: model, total: _.size(model) }
      } else {
        return model
      }
    })

    const isLocalDataSource = computed(() => {
      return !_.isNil(internalModel.value) && !instanceofPageData(internalModel.value)
    })

    const defaultPagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: total => t('common.total', total + ''),
      pageSizeOptions: _.get(props, 'pageSizeOptions') || ['20', '50', '100'],
      current: 1,
      pageSize: 20,
      total: 0
    }

    const localPagination = ref(defaultPagination)

    watch(
      () => props.pagination,
      () => {
        if (_.isPlainObject(props.pagination)) {
          localPagination.value = _.assign(
            {},
            defaultPagination,
            _.pick(props.pagination, [
              'showSizeChanger',
              'showQuickJumper',
              'showTotal',
              'pageSizeOptions',
              'current',
              'pageSize',
              'total'
            ])
          )
        }
      },
      { immediate: true }
    )

    watch(
      () => _.pick(internalPageData.value, ['total', 'pageNum', 'pageSize']),
      () => {
        localPagination.value = _.defaults(
          {
            current: internalPageData.value.pageNum,
            pageSize: internalPageData.value.pageSize,
            total: internalPageData.value.total
          },
          localPagination.value
        )
      },
      { immediate: true }
    )

    function handleChangeSelection (selection) {
      emit('update:selection', selection)
    }

    const tableSelection = new TableSelection(
      computed(() => props.rowKey),
      computed(() => props.disabledSelection),
      handleChangeSelection,
      updateCheckboxProps
    )
    watch(
      () => props.selection,
      (current, prev) => {
        if (current !== prev) {
          tableSelection.updateSelection(current)
        }
      },
      { immediate: true }
    )

    watch(
      () => props.disabledSelection,
      () => {
        updateCheckboxProps()
      }
    )

    function updateCheckboxProps () {
      if (table.value) {
        table.value.getTable().checkboxPropsCache = {}
        table.value.getTable().$forceUpdate()
      }
    }

    return {
      formModel: form.model,
      generatorRowKey,
      internalPageData,
      isLocalDataSource,
      localPagination,
      table,
      tableSelection
    }
  },

  computed: {
    defaultSequenceColumn () {
      return {
        title: this.$t('common.seq'),
        key: this.sequenceKey,
        width: this.summary && this.$lang() !== 'zh-CN' ? 85 : 60
      }
    },

    defaultActionColumn () {
      return {
        title: this.$t('common.action'),
        key: this.actionKey,
        slots: {
          customRender: this.actionKey
        }
      }
    },

    internalColumns () {
      const columns = _.clone(this.columns)
      this.attachDefault2Columns(this.useSequence, columns, { key: this.sequenceKey }, this.defaultSequenceColumn, 0)
      this.attachDefault2Columns(
        !_.isEmpty(this.tableRowActions),
        columns,
        { key: this.actionKey },
        this.defaultActionColumn,
        columns.length
      )
      const builder = BuilderFactory.getInstance('TableInnerColumns', columns, this.scope)
      return builder.getCurrentConfig()
    },

    exportColumns () {
      return _.map(this.internalColumns, column => {
        column = _.clone(column)
        if (column._defaultTitle) {
          column.title = column._defaultTitle
        }

        return column
      })
    },

    scope () {
      return { ...this.$attrs, ...this.$props, dataSource: this.internalData }
    },

    internalData () {
      return this.internalPageData.list
    },

    internalSummary () {
      if (!this.summary) {
        return undefined
      }
      const summary = {
        'selection-column': this.$t('common.summary'),
        _summary: true,
        __air8RowId: 'air8-table_summary'
      }

      if (this.summary === true) {
        return summary
      } else if (_.isString(this.summary)) {
        return _.defaults(summary, _.get(this.formModel, this.summary))
      } else if (_.isArray(this.summary)) {
        const result = sumList(this.internalData, this.summary)
        return _.defaults(summary, result)
      } else if (_.isFunction(this.summary)) {
        return _.defaults(summary, this.summary(this.internalData))
      } else {
        return _.defaults(summary, this.summary)
      }
    },

    internalPagnation () {
      if (!this.pagination) {
        return false
      }
      return this.localPagination
    },

    internalDataSource () {
      let dataSource = _.chain(this.internalData)
      if (dataSource.size().value() > 0 && !_.isNil(this.internalSummary)) {
        dataSource = dataSource.clone()
        if (this.isShowLocalPagenation) {
          const start = (this.internalPagnation.current - 1) * this.internalPagnation.pageSize
          const end = start + this.internalPagnation.pageSize
          dataSource = dataSource.slice(start, end)
        }
        dataSource = dataSource.push(this.internalSummary)
      }
      return dataSource.value()
    },

    internalScroll () {
      if (this.scroll === false) {
        return undefined
      } else if (this.scroll === true || _.isEmpty(this.scroll)) {
        const defaultY = _.size(this.internalDataSource) > 0 ? 'fit-content' : 0
        return {
          x: this.internalWidth,
          y: this.height === true ? defaultY : this.height
        }
      } else {
        return this.scroll
      }
    },

    internalWidth () {
      if (_.isEmpty(this.internalColumns)) {
        return true
      } else if (this.width === true) {
        return _.reduce(
          this.internalColumns,
          (result, curr) => {
            return result + parseInt(_.get(curr, 'width', _.get(curr, 'minWidth', _.get(curr, 'maxWidth', 160))))
          },
          10
        )
      } else {
        return this.width
      }
    },

    hasDiffNew () {
      return _.some(this.internalDataSource, record => {
        return isDiffNew(record)
      })
    },

    rowClassName () {
      const attrRowClassName = getComponentAttribute(this.$attrs, 'rowClassName')
      const rowClassName = [(record, i) => (i % 2 === 1 ? 'striped' : null)]
      if (!_.isNil(attrRowClassName)) {
        rowClassName.push(attrRowClassName)
      }

      if (this.showDiffTag) {
        rowClassName.push((record, i) => this.getDiffNewRowClassName(record, i))
      }

      return (record, i) => {
        return _.chain(rowClassName)
          .map(value => {
            return value(record, i)
          })
          .filter(value => {
            return !_.isNil(value)
          })
          .join(' ')
          .value()
      }
    },

    hasSummary () {
      return !_.isNil(this.internalSummary)
    },

    isShowLocalPagenation () {
      return this.hasSummary && this.isLocalDataSource && this.internalPagnation
    },

    tableFormModel () {
      if (!this.pagination) {
        return this.internalData
      }

      const start = (this.internalPagnation.current - 1) * this.internalPagnation.pageSize
      const end = start + this.internalPagnation.pageSize

      return _.slice(this.internalData, start, end)
    },

    internalRowSelection () {
      if (this.rowSelection === false) {
        return undefined
      } else if (this.rowSelection === true) {
        const selectedColumn = _.head(this.internalColumns)
        let tableRowSelectionProps = {}
        if (_.get(selectedColumn, 'key') === 'selection-column') {
          tableRowSelectionProps = {
            columnWidth: _.get(selectedColumn, 'width'),
            columnTitle: _.get(selectedColumn, 'title')
          }
        }
        return _.assign({}, this.tableSelection.props, tableRowSelectionProps)
      } else {
        return this.rowSelection
      }
    },

    isSummaryChange () {
      return [this.hasSummary, this.internalPageData.total, this.internalColumns]
    }
  },

  watch: {
    isSummaryChange: {
      handler () {
        if (!this.hasSummary || this.internalPageData.total <= 0) {
          return
        }

        nextTick(() => {
          const td = _.invoke(this.table, '$el.querySelector', 'table>tbody>tr:last-child>td:first-child')
          if (td) {
            td.textContent = t('common.summary')
          }
        })
      },
      immediate: true
    }
  },

  methods: {
    attachDefault2Columns (useDefault, tableColumns, findBy, defaultColumn, defaultPosition) {
      if (useDefault) {
        let findColumn = _.find(tableColumns, findBy)
        if (_.isNil(findColumn)) {
          findColumn = {}
          tableColumns.splice(defaultPosition, 0, findColumn)
        }

        _.defaults(findColumn, defaultColumn)
      }
    },

    updatePagination (pagination) {
      const refTable = this.$refs.table.getTable()
      refTable.scrollToFirstRow()
      const tableArguments = refTable.prepareParamsArguments(_.defaults({ sPagination: pagination }, refTable.$data))

      _.assign(this.localPagination, _.get(tableArguments, '0'))

      this.$emit('change', ...tableArguments)
    },

    handleLocalPaginationChange (page, pageSize) {
      this.updatePagination(
        _.defaults(
          {
            current: page,
            pageSize
          },
          this.internalPagnation
        )
      )
    },

    handleLocalShowSizeChange (current, size) {
      this.updatePagination(
        _.defaults(
          {
            current,
            pageSize: size
          },
          this.internalPagnation
        )
      )
    },

    renderFooter () {
      if (
        !this.isShowLocalPagenation ||
        _.isNil(this.internalPagnation, 'total') ||
        this.internalPagnation.total <= 0
      ) {
        return undefined
      }

      return () => {
        return h(resolveComponent('a-pagination'), {
          class: 'ant-table-pagination',
          ...this.internalPagnation,
          onChange: this.handleLocalPaginationChange,
          onShowSizeChange: this.handleLocalShowSizeChange
        })
      }
    },

    handleTableChange (pagination) {
      if (this.isShowLocalPagenation || !this.internalPagnation) {
        return
      }
      _.assign(this.localPagination, _.pick(pagination, ['current', 'pageSize']))
    },

    renderDataTable () {
      return h(
        resolveComponent('i-table'),
        {
          ...this.$attrs,
          ref: 'table',
          class: this.editable ? 'form-label-hide' : '',
          rowKey:
            this.rowKey ||
            (record => {
              let id = _.get(record, '__air8RowId')
              if (_.isNil(id)) {
                id = this.generatorRowKey()
                _.set(record, '__air8RowId', id)
              }
              return id
            }),
          dataSource: this.internalDataSource,
          columns: this.internalColumns,
          scroll: this.internalScroll,
          pagination: this.isShowLocalPagenation ? false : this.internalPagnation,
          rowClassName: this.rowClassName,
          wrapBrackets: this.wrapBrackets,
          rowSelection: this.internalRowSelection,
          onChange: this.handleTableChange
        },
        _.assign(
          {
            [this.actionKey]: scope => this.renderTableRowActions(scope),
            footer: this.renderFooter()
          },
          this.$slots
        )
      )
    },

    renderTableRowActions (scope) {
      if (_.get(scope, 'record._summary')) {
        return ''
      } else {
        return <c0 builder="TableInnerActions" config={this.tableRowActions} scope={scope}></c0>
      }
    },

    renderForm () {
      return (
        <i-form ref="form" model={this.tableFormModel}>
          {this.renderDataTable()}
        </i-form>
      )
    },

    getForm () {
      return this.$refs.form
    },

    getDiffNewRowClassName (record, index) {
      if (isDiffNew(record)) {
        return 'table-diff-tag'
      } else if (this.hasDiffNew) {
        return 'table-diff-tag-no'
      } else {
        return ''
      }
    },

    exportExcel (fileName) {
      exportExcelFromArray(this.internalData, this.columns, fileName, {
        summary: !_.isEmpty(this.internalData) && !_.isNil(this.internalSummary) ? this.internalSummary : undefined,
        sequenceKey: this.sequenceKey,
        useSequence: this.useSequence,
        actionKey: this.actionKey
      })
    }
  },

  render () {
    return (
      <div class="air8-table">
        {this.$slots.extra ? <div>{this.$slots.extra()}</div> : ''}
        {this.hasForm ? this.renderForm() : this.renderDataTable()}
      </div>
    )
  }
})
</script>

<style lang="less" scoped>
.air8-table {
  /deep/ .ant-table-wrapper .mark::before {
    color: #ff4d4f;
    content: '*';
    margin-right: 4px;
  }

  /deep/ .form-label-hide {
    .ant-form-item {
      .ant-form-item-label {
        display: none;
      }
    }
  }

  /deep/ .air8-form-item {
    margin-bottom: 0px;
  }

  /deep/ .ant-table-footer {
    padding: 0px;
    border: none;
    background: none;
    display: flex;
    justify-content: flex-end;
    margin-top: 1px;
  }

  .ant-table-pagination.ant-pagination {
    float: none;
  }
}
</style>
