<template>
  <span :class="classNameProps">
    <slot name="addonBefore"></slot>
    <empty-data v-if="isUseEmptyImage"></empty-data>
    <template v-else-if="tooltip">
      <air8-auto-tooltip
        :tooltipType="tooltip"
        :getPopupContainer="getPopupContainer"
        :value="internalText"
      ></air8-auto-tooltip>
    </template>
    <template v-else>{{ internalText }}</template>
    <slot name="addonAfter"></slot>
  </span>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import formatter from '@/components/air8/formatter'
import CategoryFactory from './category/CategoryFactory'

function optionsFormatter (value, options, labelField, valueField) {
  return _.get(
    _.find(options, (option) => {
      return option[valueField] === value
    }),
    labelField
  )
}

export default defineComponent({
  name: 'Air8Label',

  aliasName: ['C2', 'a8-d-label'],

  props: {
    modelValue: [String, Number, Boolean, Object, Array],
    options: Array,
    optionValueProp: {
      type: String,
      default: 'value'
    },
    optionLabelProp: {
      type: String,
      default: 'label'
    },
    dictName: String,
    category: String,
    currency: String,
    formatter: [String, Function],
    formatterArguments: Array,
    useEmptyImage: Boolean,
    tooltip: [Boolean, String],
    getPopupContainer: Function,
    textAlign: {
      type: String,
      default: 'left'
    },
    joinValue: Boolean,
    splitValueChar: {
      type: String,
      default: ','
    }
  },

  data () {
    return {
      internalOptions: []
    }
  },

  computed: {
    internalFormatters () {
      const list = []
      if (!_.isNil(this.options)) {
        list.push((value) => {
          return optionsFormatter(value, this.options, this.optionLabelProp, this.optionValueProp)
        })
      } else if (!_.isEmpty(this.dictName)) {
        list.push((value) => {
          if (this.joinValue) {
            return formatter.dict(_.split(value, this.splitValueChar), this.dictName)
          } else {
            return formatter.dict(value, this.dictName)
          }
        })
      } else if (!_.isEmpty(this.internalOptions)) {
        list.push((value) => {
          return optionsFormatter(value, this.internalOptions, this.optionLabelProp, this.optionValueProp)
        })
      } else if (!_.isEmpty(this.currency)) {
        list.push((value) => {
          return formatter.money(value, this.currency)
        })
      }
      const formatterFunction = this.formatterFunction
      if (!_.isNil(formatterFunction)) {
        list.push(formatterFunction)
      }
      return list
    },

    formatterFunction () {
      const args = this.formatterArguments || []
      if (_.isString(this.formatter)) {
        return (value) => {
          return formatter[this.formatter](value, ...args)
        }
      } else if (_.isFunction(this.formatter)) {
        return (value) => {
          return this.formatter(value, ...args)
        }
      } else {
        return undefined
      }
    },

    internalText () {
      if (_.isNil(this.internalFormatters)) {
        return this.modelValue
      } else {
        return _.reduce(this.internalFormatters, (text, formatterFunc) => {
          return formatterFunc(text)
        }, this.modelValue)
      }
    },

    isUseEmptyImage () {
      if (_.isNil(this.modelValue)) {
        return this.useEmptyImage
      } else if (_.isString(this.modelValue) && _.isEmpty(this.modelValue)) {
        return this.useEmptyImage
      } else {
        return false
      }
    },

    classNameProps () {
      return {
        'air8-label': true,
        'air8-label__text-align-right': this.textAlign === 'right'
      }
    }
  },

  created () {
    if (!_.isEmpty(this.category)) {
      this.getOptions(this.modelValue)
    }
  },

  methods: {
    getOptions (params) {
      return CategoryFactory.getInstance(this.category)
        .getOptions(params)
        .then((options) => {
          this.internalOptions = options
        })
    }
  }
})
</script>
<style lang="less" scoped>
.air8-label {
  word-break: break-all;
}
.air8-label__text-align-right {
  text-align: right;
  width: 100%;
  display: inline-block;
}
</style>
