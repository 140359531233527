import { getCurrentInstance, inject, provide } from 'vue'

const ParentContextKey = Symbol('parentContextKey')

export function injectParent () {
  const parent = inject(ParentContextKey, null)
  const current = getCurrentInstance()
  provide(ParentContextKey, current)

  return {
    parent
  }
}
