import BuilderFactory from '@/components/air8/builder/BuilderFactory'
import Rules from '@/components/air8/rules/index'
import _ from 'lodash'

const IS_REQUIRED = {
  NO: '0',
  YES: '1',
  HIDE: '2'
}

export function isEditableItem (item: any) {
  return item.editable !== false
}

export function isVisiable (item: any) {
  return String(item.require) !== IS_REQUIRED.HIDE
}

export function isRequired (item: any) {
  return String(item.require) === IS_REQUIRED.YES
}

export function addRequiredRules (item: any, dateType?: string) {
  if (!isRequired(item)) {
    return {}
  }

  return {
    rules: _.concat([Rules.requiredRule(dateType)], item.rules || [])
  }
}

export function transformJson (config: any, scope: any) {
  const builder = BuilderFactory.getInstance('DynamicConfigBuilder', config, scope)
  return builder.getCurrentConfig()
}
