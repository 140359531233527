import _ from 'lodash'

export default class Factory <T> {
  private config: {[key:string]: any} = {}
  private instanceList: {[key:string]: T} = {}
  private option: any = {}

  constructor (config: {[key:string]: any}, singleInstance = false, defaultInstanceType?: string) {
    this.config = config
    this.option = {
      singleInstance,
      defaultInstance: _.isEmpty(defaultInstanceType) ? undefined : _.get(this.config, defaultInstanceType as string)
    }
  }

  private getInstanceClass (className: string): any {
    const instanceClass:any = _.get(this.config, className, this.option.defaultInstance)
    if (_.isNil(instanceClass)) {
      throw new Error(`unknow className(${className})`)
    } else {
      return instanceClass
    }
  }

  private createInstance (className: string, args:any[]): T {
    const InstanceClass = this.getInstanceClass(className)
    return new InstanceClass(...args)
  }

  private getSingleInstance (className: string, args: any[]): T {
    let instance = this.instanceList[className]
    if (_.isNil(instance)) {
      instance = this.createInstance(className, args)
      this.instanceList[className] = instance
    }
    return instance
  }

  public getInstance (className: string, ...args: any[]): T {
    if (this.option.singleInstance) {
      return this.getSingleInstance(className, args)
    } else {
      return this.createInstance(className, args)
    }
  }
}
