import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'
import { useAuth } from '@/plugins/auth/src'
export default class TableInnerActionsBuilder extends DefaultBuilder {
  private $auth:any;
  initConfig (currentConfig: any): any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }
    this.$auth = useAuth().auth
    const result = _.chain(currentConfig)
      .map(actionConfig => this.initAction(actionConfig))
      .value()
    return result
  }

  private initAction (actionConfig: any): any {
    if (_.isNil(actionConfig)) {
      return actionConfig
    }

    return _.defaults(actionConfig, {
      $if: (scope:any) => {
        if (!_.isEmpty(actionConfig.$auth)) {
          const $auth = _.castArray(actionConfig.$auth)
          if (_.every($auth, (auth: string) => !this.$auth.accessSync(auth))) {
            return false
          }
        }
        if (!_.isNil(actionConfig.$visible) && this.getFunctionValue(actionConfig.$visible, scope) !== true) {
          return false
        }
        return true
      },
      ...this.getDefaultAction()
    })
  }

  getDefaultAction (): any {
    return {
      $type: 'a-button',
      type: 'link',
      size: 'small'
    }
  }
}
