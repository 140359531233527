import _ from 'lodash'
import TableInnerColumnsBuilder from './TableInnerColumnsBuilder'
import { createFilter } from '@/config/filter'
import { createSorter } from '@/config/sorter'
import { createSum } from '@/config/sum'
import { PROP_TYPE, FILTER_TYPE } from '../constants'
import { h, resolveDynamicComponent } from 'vue'
export default class SearchTablePageTableInnerColumnBuilder extends TableInnerColumnsBuilder {
  initColumn (columnConfig:any, index:number) :any {
    columnConfig = _.clone(columnConfig)
    if (this.dealSequenceColumn(columnConfig)) {
      return columnConfig
    }
    if (this.dealActionColumn(columnConfig)) {
      return columnConfig
    }
    this.dealDictNameField(columnConfig)
    this.dealPropTypeField(columnConfig)
    this.dealFilterField(columnConfig)
    this.dealSorterField(columnConfig)
    this.dealSumerField(columnConfig)
    this.dealEditField(columnConfig)
    this.dealTextField(columnConfig)
    this.dealSummaryField(columnConfig)
    this.dealCustomRenderField(columnConfig, index)
    this.dealTitleField(columnConfig)
    return columnConfig
  }

  private dealDictNameField (columnConfig: any) {
    if (_.isEmpty(columnConfig.$dictName) && !_.isEmpty(columnConfig.ref)) {
      columnConfig.$dictName = columnConfig.ref
    }
  }

  private dealFilterField (columnConfig: any) {
    if (!this.isFilterable()) {
      return
    }

    if (
      _.isUndefined(columnConfig.$filter) &&
      (
        [PROP_TYPE.INPUT, PROP_TYPE.ENUM, PROP_TYPE.DATE].includes(columnConfig.$propType) ||
        (_.isNil(columnConfig.$propType) && !_.isNil(columnConfig.dataIndex)) // has dataIndex and no $propType
      )
    ) {
      columnConfig.$filter = true
    }

    if (columnConfig.$filter === true) {
      columnConfig.$filter = {}
    } else if (!_.isPlainObject(columnConfig.$filter)) {
      return
    }

    _.defaults(columnConfig.$filter, {
      type: _.get(FILTER_TYPE, columnConfig.$propType) || FILTER_TYPE[PROP_TYPE.INPUT],
      code: columnConfig.dataIndex,
      dictName: columnConfig.$dictName,
      field: columnConfig.dataIndex,
      local: false
    })

    _.defaults(
      columnConfig,
      createFilter(
        columnConfig.$filter.type,
        columnConfig.$filter.code,
        this.getParentScope().pageData,
        columnConfig.$filter.dictName,
        columnConfig.$filter.field,
        columnConfig.$filter.local,
        columnConfig.$filter.props
      )
    )
  }

  private dealSorterField (columnConfig: any) {
    if (!this.isSorterable()) {
      return
    }

    if (
      _.isUndefined(columnConfig.$sorter) &&
      (
        [PROP_TYPE.INPUT, PROP_TYPE.DATE, PROP_TYPE.CURRENCY, PROP_TYPE.NUMBER, PROP_TYPE.PERCENT].includes(columnConfig.$propType) ||
        (_.isNil(columnConfig.$propType) && !_.isNil(columnConfig.dataIndex)) // has dataIndex and no $propType
      )
    ) {
      columnConfig.$sorter = true
    }

    if (columnConfig.$sorter === true) {
      columnConfig.$sorter = {}
    } else if (_.isString(columnConfig.$sorter) && _.trim(columnConfig.$sorter) !== '') {
      columnConfig.$sorter = {
        field: columnConfig.$sorter
      }
    } else if (!_.isPlainObject(columnConfig.$sorter)) {
      return
    }

    _.defaults(columnConfig.$sorter, {
      field: columnConfig.dataIndex
    })

    _.defaults(columnConfig,
      createSorter(
        columnConfig.$sorter.field || columnConfig.$sorter.code,
        this.getParentScope().pageData
      )
    )
  }

  private dealSumerField (columnConfig: any) {
    if (!this.isSumerable()) {
      return
    }

    if (_.isUndefined(columnConfig.$sumer) && [PROP_TYPE.CURRENCY, PROP_TYPE.NUMBER].includes(columnConfig.$propType)) {
      columnConfig.$sumer = true
    }

    if (columnConfig.$sumer === true) {
      columnConfig.$sumer = {}
    } else if (_.isString(columnConfig.$sumer) && _.trim(columnConfig.$sumer) !== '') {
      columnConfig.$sumer = {
        field: columnConfig.$sumer
      }
    } else if (!_.isPlainObject(columnConfig.$sumer)) {
      return
    }

    _.defaults(columnConfig.$sumer, {
      title: columnConfig.title,
      field: columnConfig.dataIndex
    })

    _.merge(
      columnConfig,
      createSum(
        columnConfig.$sumer.title,
        columnConfig.$sumer.field || columnConfig.$sumer.code,
        this.getParentScope().pageData
      )
    )
  }

  dealTitleField (columnConfig: any) {
    if (this.isDisplayRequiredMark() && _.isString(columnConfig.title)) {
      const title = columnConfig.title
      columnConfig.title = () => {
        return h(resolveDynamicComponent('c0') as any, {
          config: {
            $type: 'Air8LocaleLabel',
            class: { mark: this.isColumnRequired(columnConfig) },
            wrapBrackets: this.getRootScope().wrapBrackets,
            value: title
          }
        })
      }
    }
  }

  private isFilterable (): boolean {
    return _.get(this.getRootScope(), 'filterable') !== false
  }

  private isSorterable (): boolean {
    return _.get(this.getRootScope(), 'sorterable') !== false
  }

  private isSumerable (): boolean {
    return _.get(this.getRootScope(), 'sumerable') !== false
  }
}
