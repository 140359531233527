<template>
  <air8-edit-page
    class="air8-approve-edit-page"
    ref="actionPage"
    v-bind="$attrs"
    :status="status"
    :config="config"
    :model-value="modelValue"
    :init-function="initFunction"
    :submit-function="submitFunction"
    :go-back="goBack"
  >
    <slot name="default"></slot>
    <approval-flow
      :current="approvalCurrentStep"
      :steps="approvalSteps"
      :approval-table-columns="approvalTableColumns"
      :approval-table-data-source="approvalTableDataSource"
      :workflow-params="workflowParams"
      v-model:value="internalComment"
    ></approval-flow>
  </air8-edit-page>
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8ApprovalEditPage',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: 'edit'
    },
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    },
    submitFunction: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    },
    approvalCurrentStep: Number,
    approvalSteps: Array,
    approvalTableColumns: Array,
    approvalTableDataSource: Array,
    comment: String,
    workflowParams: Object
  },

  emits: ['update:comment'],

  setup (props, { emit }) {
    const actionPage = ref()
    function getForm () {
      return actionPage.value.getForm()
    }

    const internalComment = computed({
      get: () => props.comment,
      set: (comment) => {
        emit('update:comment', comment)
      }
    })

    return {
      actionPage,
      internalComment,
      getForm
    }
  }
})
</script>
