import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-left":"8px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_tz_date = _resolveComponent("tz-date")!

  return (_openBlock(), _createBlock(_component_tz_date, _mergeProps({
    value: _ctx.date,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.date = $event))
  }, _ctx.$attrs, {
    disabledDate: _ctx.actualDisabledDateFn,
    dropdownClassName: _ctx.$style.container
  }), {
    dateRender: _withCtx(({current, today}) => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.cell, _ctx.todayCls(current, _ctx.$style), _ctx.selectedCls(current, _ctx.$style), _ctx.inMonthCls(current, today, _ctx.$style), _ctx.holidayCls(current, _ctx.$style)])
      }, [
        _createVNode(_component_a_tooltip, null, _createSlots({
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.cellText])
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.resolveDateText(current)), 1),
              (_ctx.getHoliday(current)?.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([_ctx.$style.cellTextMark])
                  }, [
                    (!_ctx.isHolidayWork(_ctx.getHoliday(current)))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "休"))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, "班"))
                  ], 2))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 2
        }, [
          (_ctx.getHoliday(current))
            ? {
                name: "title",
                fn: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getHoliday(current), (row, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, [
                        _createTextVNode(_toDisplayString(_ctx.$m.f('COUNTRY', row.countryName)) + ": " + _toDisplayString(_ctx.$lang.c(row.labelCn, row.labelEn)) + " ", 1),
                        _createElementVNode("span", _hoisted_1, [
                          (row?.type === 'J')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "休"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_3, "班"))
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              }
            : undefined
        ]), 1024)
      ], 2)
    ]),
    _: 1
  }, 16, ["value", "disabledDate", "dropdownClassName"]))
}