import _ from 'lodash'
import InstanceFactory from '@/components/air8/utils/InstanceFactory'
import BaseBuilder from './BaseBuilder'
import config from './config'
import { getComponentName } from '../utils/componentUtils'

function getBuilderConfig (configList: any[]) {
  return _.transform(configList, (result:any, { builder, comp }) => {
    result[getComponentName(comp.name)] = builder
    if (!_.isEmpty(comp.aliasName)) {
      _.chain(_.castArray(comp.aliasName))
        .each((aliasName) => {
          if (_.isEmpty(aliasName)) {
            return
          }
          result[getComponentName(aliasName)] = builder
        }).value()
    }
    return result
  }, {})
}

class BuilderFactory extends InstanceFactory<BaseBuilder> {
  public getInstance (className: string, ...args: any[]): BaseBuilder {
    className = getComponentName(className)
    return super.getInstance(className, ...args)
  }
}

const factory = new BuilderFactory(getBuilderConfig(config), false, getComponentName('default'))
export default factory
