<template>
  <c0
    :config="config"
    :scope="{ ...$attrs, ...$props }"
  ></c0>
</template>
<script>
import { defineComponent } from 'vue'
import { t } from '@/config/locale'
import { computed } from '@vue/reactivity'

export default defineComponent({
  name: 'Air8Remark',

  props: {
    name: {
      type: String,
      default: 'remark'
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const config = {
      $type: 'Air8AutoCard',
      title: t('common.remark'),
      $children: {
        $type: 'Air8FormItem',
        type: 'a-textarea',
        label: t('common.remark'),
        hideLabel: true,
        name: computed(() => props.name),
        showCount: true,
        rows: 3,
        maxlength: 500,
        'type|editable===false': 'Air8Label',
        'useEmptyImage|editable===false': true
      }
    }

    return {
      config
    }
  }
})
</script>
