import _ from 'lodash'
import { isRef, unref, VNode } from 'vue'
import BuilderFactory from './BuilderFactory'
import ConditionExpress from './ConditionExpress'
import { instanceofVNode, removeKeyField, getByKeyField } from '@/components/air8/utils/componentUtils'

const conditionExpress = new ConditionExpress()

export default abstract class BaseBuilder {
  currentConfig:any
  rootConfig:any
  rootScope:any
  parentScope: any

  constructor (currentConfig:any, rootConfig:any, rootScope:any, parentScope:any) {
    if (arguments.length === 2) {
      // currentConfig & configScope
      rootScope = rootConfig
      parentScope = rootScope
      rootConfig = currentConfig
    }
    this.rootConfig = rootConfig
    this.rootScope = rootScope
    this.parentScope = parentScope

    this.currentConfig = this.initConfig(this.preInitConfig(currentConfig))
  }

  public abstract render (): VNode[] | undefined

  initConfig (currentConfig:any):any {
    return currentConfig
  }

  preInitConfig (currentConfig:any):any {
    currentConfig = this.dealConditionExpress(currentConfig, this.getRootScope())
    currentConfig = this.convertConfigValue(currentConfig, this.getParentScope())
    currentConfig = this.sortArrayConfig(currentConfig)
    return currentConfig
  }

  dealConditionExpress (config:any, scope:any):any {
    return conditionExpress.dealConditionExpress(config, scope)
  }

  convertConfigValue (config:any, scope:any): any {
    if (_.isNil(config)) {
      return config
    }

    const result = _.chain(config)
      .castArray()
      .map(config => this.convertSingleConfigValue(config, scope))
      .value()
    if (_.isArray(config)) {
      return result
    } else {
      return _.head(result)
    }
  }

  private convertSingleConfigValue (config:any, scope:any): any {
    if (_.isNil(config) || _.isFunction(config) || instanceofVNode(config)) {
      return config
    } else if (_.isPlainObject(config)) {
      config = this.convertSingleConfigFunction2Value(config, scope)
      config = this.convertSingleConfigModelValue(config)
      return config
    } else {
      return config
    }
  }

  private overrideField (obj:any, source:any):any {
    return _.assignWith(obj, source, (objValue:any, srcValue:any) => {
      return _.isUndefined(srcValue) ? objValue : srcValue
    })
  }

  private convertSingleConfigFunction2Value (config: any, scope: any) {
    const functionFieldConfig = _.chain(getByKeyField(config, /^(\$F)+/g))
      .mapValues((value: any) => {
        return this.getFunctionValue(value, scope)
      })
      .mapKeys((value: any, key: string) => {
        return key.replaceAll(/^(\$F)+/g, '')
      }).value()
    config = this.overrideField(functionFieldConfig, removeKeyField(config, '$F'))
    return config
  }

  private convertSingleConfigModelValue (config: any) {
    const modelFieldConfig = _.chain(getByKeyField(config, /^(\$M)+/g))
      .transform((result: any, modelValue: any, key: string) => {
        key = key.replaceAll(/^(\$M)+/g, '')
        if (_.isEmpty(key)) {
          key = 'modelValue'
        }
        result[key] = modelValue

        if (isRef(modelValue)) {
          result[`onUpdate:${key}`] = (value: any) => {
            modelValue.value = value
          }
        }
        return result
      }, {})
      .value()
    config = this.overrideField(modelFieldConfig, removeKeyField(config, '$M'))
    return config
  }

  sortArrayConfig (config:any): any {
    if (_.isArray(config)) {
      return _.chain(config)
        .map((value, index) => {
          return {
            $arrayIndex: unref(_.get(value, '$arrayIndex', index)),
            data: value
          }
        }).sortBy(['$arrayIndex'])
        .map('data')
        .value()
    } else {
      return config
    }
  }

  getInstance (type: any, config?: any, parentScope?: any): BaseBuilder | undefined {
    if (_.isNil(config)) {
      return undefined
    }

    return BuilderFactory.getInstance(
      type,
      config,
      this.rootConfig, this.rootScope,
      _.isNil(parentScope) ? this.parentScope : parentScope
    )
  }

  getInstanceRender (type: any, config?: any, parentScope?: any): VNode[] | undefined {
    const builder = this.getInstance(type, config, parentScope)
    if (_.isNil(builder)) {
      return undefined
    }
    return builder.render()
  }

  getRootScope () {
    return this.getScope(this.rootScope)
  }

  getParentScope () {
    return this.getScope(this.parentScope)
  }

  getScope (scope:any) {
    scope = unref(scope)
    if (_.hasIn(scope, '$attrs') && _.hasIn(scope, '$props')) {
      return removeKeyField({ ...scope.$attrs, ...scope.$props }, 'on')
    } else {
      return scope
    }
  }

  public getCurrentConfig () {
    return this.currentConfig
  }

  getFunctionValue (value:any, ...args:any[]) {
    if (_.isFunction(value)) {
      return value(...args)
    } else {
      return value
    }
  }

  isRef (value: any) {
    return !_.isNil(value) && isRef(value)
  }

  getComponentIf (config: any, parentScope: any) {
    if (_.isNil(config)) {
      return false
    }
    return this.getFunctionValue(unref(config.$if), parentScope) !== false
  }
}
