<template>
  <c0 :config="config" />
</template>
<script>
import { defineComponent, computed } from 'vue'
import { getDefaultPopupContainer } from '@/components/air8/utils/tooltipUtils'

export default defineComponent({
  name: 'Air8Tooltip',

  inheritAttrs: false,

  setup (props, { attrs, slots }) {
    const config = computed(() => {
      return {
        $type: 'a-tooltip',
        getPopupContainer: getDefaultPopupContainer,
        overlayClassName: 'icon-tooltip-style',
        ...attrs,
        $slots: slots
      }
    })

    return {
      config
    }
  }
})
</script>
