import { getDiffValue } from '@/utils/diffUtils'
import _ from 'lodash'
import { VNode, RendererNode, RendererElement, h, computed, resolveDynamicComponent, unref } from 'vue'
import BaseBuilder from '../BaseBuilder'
import { PROP_TYPE } from '../constants'
export default class TableInnerColumnsBuilder extends BaseBuilder {
  public render (): VNode<RendererNode, RendererElement, { [key: string]: any }>[] | undefined {
    return undefined
  }

  initConfig (currentConfig: any): any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    const result = _.chain(currentConfig)
      .filter(columnConfig => this.isColumnDisplay(columnConfig))
      .map((columnConfig, index) => this.initColumn(columnConfig, index))
      .value()
    return result
  }

  isColumnDisplay (columnConfig: any): boolean {
    return !_.isNil(columnConfig) && this.getComponentIf(columnConfig, this.getParentScope())
  }

  initColumn (columnConfig: any, index: number): any {
    columnConfig = _.clone(columnConfig)

    if (this.dealSequenceColumn(columnConfig)) {
      this.dealCustomRenderField(columnConfig, index)
      return columnConfig
    }
    if (this.dealActionColumn(columnConfig)) {
      return columnConfig
    }
    this.dealPropTypeField(columnConfig)
    this.dealEditField(columnConfig)
    this.dealTextField(columnConfig)
    this.dealSummaryField(columnConfig)
    this.dealCustomRenderField(columnConfig, index)
    this.dealTitleField(columnConfig)
    return columnConfig
  }

  dealSequenceColumn (columnConfig: any): boolean {
    if (columnConfig.key !== this.getRootScope().sequenceKey) {
      return false
    }
    const sequenceKey = columnConfig.key
    const sequenceConfig = {
      $type: 'Air8Label',
      $FmodelValue: (scope: any) => {
        const text = scope.record[sequenceKey]
        if (_.isNil(text)) {
          return scope.index + 1
        } else {
          return text
        }
      }
    }
    _.defaults(columnConfig, {
      $edit: sequenceConfig,
      $text: sequenceConfig
    })
    return true
  }

  dealPropTypeField (columnConfig: any) {
    const defaultConfig: any = {}

    if (_.isNil(columnConfig.$propType)) {
      if (!_.isEmpty(columnConfig.$dictName)) {
        defaultConfig.$propType = PROP_TYPE.ENUM
      }
    } else if (columnConfig.$propType === PROP_TYPE.DATE) {
      defaultConfig.$formatter = 'date'
    } else if (columnConfig.$propType === PROP_TYPE.CURRENCY) {
      defaultConfig.$formatter = 'money'
    } else if (columnConfig.$propType === PROP_TYPE.NUMBER) {
      defaultConfig.$formatter = 'number'
    } else if (columnConfig.$propType === PROP_TYPE.PERCENT) {
      defaultConfig.$formatter = 'percent'
    } else if (columnConfig.$propType === PROP_TYPE.NORMAL_NUMBER) {
      defaultConfig.$formatter = 'normalNumber'
    }

    if (!_.isEmpty(defaultConfig)) {
      _.defaults(columnConfig, defaultConfig)
    }
  }

  dealEditField (columnConfig: any) {
    if (_.isNil(columnConfig.$edit)) {
      columnConfig.$edit = {}
    }

    if (_.isFunction(columnConfig.$edit)) {
      return
    } else {
      columnConfig.$edit = _.clone(columnConfig.$edit)
    }

    const editConfig = columnConfig.$edit

    if (!_.isNil(editConfig.$type) &&
      !['Air8FormItem', 'Air8DynamicFormItem', 'A8DFormItem'].includes(_.upperFirst(_.camelCase(editConfig.$type)))) {
      return
    }

    _.defaults(editConfig, {
      $type: 'Air8FormItem',
      label: columnConfig.title,
      category: columnConfig.$category,
      dictName: columnConfig.$dictName,
      rules: columnConfig.$rules,
      hideLabel: true,
      tinyError: true,
      textAlign: [PROP_TYPE.CURRENCY, PROP_TYPE.NUMBER, PROP_TYPE.NORMAL_NUMBER].includes(columnConfig.$propType) ? 'right' : undefined
    })
    const editName = editConfig.name || columnConfig.dataIndex
    editConfig.$Fname = (scope: any) => {
      return `${scope.index}.${editName}`
    }
    delete editConfig.name
  }

  dealTextField (columnConfig: any) {
    if (_.isNil(columnConfig.$text)) {
      columnConfig.$text = {}
    }

    if (_.isFunction(columnConfig.$text)) {
      return
    } else {
      columnConfig.$text = _.clone(columnConfig.$text)
    }
    const textConfig = columnConfig.$text

    if (!_.isNil(textConfig.$type) &&
      !['Air8Label', 'Air8DynamicFormItem', 'A8DFormItem'].includes(_.upperFirst(_.camelCase(textConfig.$type)))) {
      return
    }

    const rootScope = this.getRootScope()

    _.defaults(textConfig, {
      $type: 'Air8Label',
      category: columnConfig.$category,
      dictName: columnConfig.$dictName,
      // textAlign: [PROP_TYPE.CURRENCY, PROP_TYPE.NUMBER, PROP_TYPE.NORMAL_NUMBER].includes(columnConfig.$propType) ? 'right' : undefined,
      ...this.dealTextFormatter(columnConfig)
    })

    const textName = textConfig.name || columnConfig.dataIndex
    textConfig.$Fname = (scope: any) => {
      return `${scope.index}.${textName}`
    }
    delete textConfig.name

    _.defaults(textConfig, {
      $FmodelValue: (scope: any) => {
        if (columnConfig.dataIndex === textName) {
          return scope.text
        } else {
          return _.get(scope, `record.${textName}`)
        }
      }
    })

    if (_.get(rootScope, 'showDiffTag')) {
      _.defaults(textConfig, {
        $F$directive: (scope: any) => {
          return [
            ['diffTag', getDiffValue(scope.record, textName)]
          ]
        }
      })
    }
  }

  dealTextFormatter (columnConfig: any) {
    return {
      $Fformatter: () => (columnConfig.$formatter || columnConfig.$cellFormatter || 'empty'),
      $FformatterArguments: (rowScope: any) => {
        if (!_.isNil(columnConfig.$formatterArgumentFields)) {
          return _.chain(this.getRefValue(columnConfig.$formatterArgumentFields))
            .castArray()
            .map((field) => {
              return _.get(rowScope.record, this.getRefValue(field))
            }).value()
        }

        if (!_.isNil(columnConfig.$formatterArguments)) {
          return _.chain(this.getRefValue(columnConfig.$formatterArguments))
            .castArray()
            .map((value) => {
              return this.getRefValue(value)
            }).value()
        }

        return undefined
      }
    }
  }

  dealSummaryField (columnConfig: any) {
    if (_.isNil(columnConfig.$summary) || _.isFunction(columnConfig.$summary)) {
      return
    } else {
      columnConfig.$summary = _.clone(columnConfig.$summary)
    }
    const summaryConfig = columnConfig.$summary

    if (!_.isNil(summaryConfig.$type) && summaryConfig.$type !== 'Air8Label') {
      return
    }

    _.defaults(summaryConfig, {
      $type: 'Air8Label',
      category: columnConfig.$category,
      dictName: columnConfig.$dictName,
      textAlign: [PROP_TYPE.CURRENCY, PROP_TYPE.NUMBER, PROP_TYPE.NORMAL_NUMBER].includes(columnConfig.$propType) ? 'right' : undefined,
      $Fformatter: () => (columnConfig.$formatter || columnConfig.$cellFormatter || 'empty'),
      $FformatterArguments: (rowScope: any) => {
        if (!_.isNil(columnConfig.$formatterArgumentFields)) {
          const formatterArgumentFields = _.chain(this.getRefValue(columnConfig.$formatterArgumentFields))
            .castArray()
            .value()
          if (columnConfig.$propType === PROP_TYPE.CURRENCY && _.size(formatterArgumentFields) === 1) {
            const dataSource = _.get(this.getRootScope(), 'dataSource')
            const currencyList = _.chain(this.getRefValue(dataSource)).map(_.head(formatterArgumentFields)).uniq().value()
            return [_.size(currencyList) === 1 ? _.nth(currencyList, 0) : undefined, true]
          }
          return _.chain(formatterArgumentFields)
            .map((field) => {
              return _.get(rowScope.record, this.getRefValue(field))
            }).value()
        }

        if (!_.isNil(columnConfig.$formatterArguments)) {
          return _.chain(this.getRefValue(columnConfig.$formatterArguments))
            .castArray()
            .map((value) => {
              return this.getRefValue(value)
            }).value()
        }

        return undefined
      }
    })

    const textName = summaryConfig.name || columnConfig.dataIndex
    summaryConfig.$Fname = (scope: any) => {
      return `${scope.index}.${textName}`
    }
    delete summaryConfig.name

    _.defaults(summaryConfig, {
      $FmodelValue: (scope: any) => {
        if (columnConfig.dataIndex === textName) {
          return scope.text
        } else {
          return _.get(scope, `record.${textName}`)
        }
      }
    })
  }

  dealCustomRenderField (columnConfig: any, index: number) {
    if (!_.isNil(_.get(columnConfig, 'slots.customRender'))) {
      return
    }

    const customRender = _.get(columnConfig, 'customRender')

    columnConfig.customRender = (scope: any) => {
      return this.getDefaultCustomRender(columnConfig, index, scope, customRender)
    }
  }

  getDefaultCustomRender (columnConfig: any, index: number, cellScope: any, customRender?: any) {
    const isSummary = this.isSummaryRecord(cellScope)
    const editable = this.isEditable() &&
      this.getFunctionValue(columnConfig.$editable, cellScope) !== false &&
      !isSummary

    let config = editable ? columnConfig.$edit : columnConfig.$text

    let isCustomRender = !_.isNil(customRender)
    if (isSummary) {
      if (index === 0) {
        config = _.clone(config)
        delete config.formatter
        delete config.$Fformatter
        delete config.$FformatterArguments
        isCustomRender = false
      } else if (!_.has(cellScope.record, columnConfig.dataIndex || columnConfig.key)) {
        config = {
          $type: 'Air8Label'
        }
        config.formatter = 'blank'
        isCustomRender = false
      } else if (columnConfig.$summary) {
        config = columnConfig.$summary
        isCustomRender = false
      }
    }

    if (isCustomRender) {
      return customRender(cellScope)
    }

    const content = this.getInstanceRender('main', config, cellScope)

    if (columnConfig.$tdProps) {
      let tdProps = columnConfig.$tdProps
      if (_.isFunction(tdProps)) {
        tdProps = tdProps(cellScope)
      }
      return {
        children: content,
        props: tdProps
      }
    }

    return content
  }

  isEditable (): boolean {
    return _.get(this.getRootScope(), 'editable')
  }

  dealTitleField (columnConfig: any) {
    if (_.isString(columnConfig.title)) {
      const title = columnConfig.title
      columnConfig._defaultTitle = title
      columnConfig.title = () => {
        return h(resolveDynamicComponent('c0') as any, {
          config: {
            $type: 'Air8LocaleLabel',
            class: { mark: this.isColumnRequired(columnConfig) },
            wrapBrackets: this.getRootScope().wrapBrackets,
            value: title
          }
        })
      }
    }
  }

  isDisplayRequiredMark (): boolean {
    return _.get(this.getRootScope(), 'requiredMark')
  }

  isColumnRequired (columnConfig: any) {
    if (!this.isEditable() && !this.isDisplayRequiredMark()) {
      return false
    }
    return _.findIndex(unref(_.get(columnConfig, '$rules')), { required: true }) !== -1
  }

  dealActionColumn (columnConfig: any): boolean {
    if (columnConfig.key === this.getRootScope().actionKey) {
      _.defaults(columnConfig, {
        slots: { customRender: columnConfig.key }
      })

      if (columnConfig.$tdProps) {
        const tdProps = columnConfig.$tdProps
        _.defaults(columnConfig, {
          customRender: (scope: any) => {
            let tempTdProps = tdProps
            if (_.isFunction(tempTdProps)) {
              tempTdProps = tdProps(scope)
            }

            const actionSlot = _.get(this.getRootScope(), ['slots', columnConfig.key])
            return {
              children: actionSlot(scope),
              props: tempTdProps
            }
          }
        })
      }
      return true
    } else {
      return false
    }
  }

  isSummaryRecord (cellScope: any) {
    return _.get(cellScope, 'record._summary')
  }

  getRefValue (value: any) {
    if (this.isRef(value)) {
      return value.value
    } else {
      return value
    }
  }
}
