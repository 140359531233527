<template>
  <a-row
    class="air8-ocr-upload-fix-list"
    :gutter="[8, 6]"
  >
    <a-col
      :xs="24"
      :md="12"
      :xl="8"
      v-for="(num, index) in maxlength"
      :key="index"
    >
      <OcrUpload
        class="air8-ocr-upload-fix-list__card"
        :ref="(el) => bindUploadCardRefs(el, index)"
        :file="internalFileList[index]"
        :type="type"
        :ocr-type="ocrType"
        :uploadTitle="getUploadTitle(index)"
        :uploadDescription="getUploadDescription(index)"
        :doneTitle="getDoneTitle(index)"
        :doneDescription="getDoneDescription(index)"
        :schemaCode="getSchemaCode(index)"
        :loadingMessage="getLoadingMessage(index)"
        @update:file="handleUploadFile($event, index)"
        @update:ocr-result="handleUpdateOcrResult($event, index)"
        @file-error="handleFileError"
        @ocr-error="handleOcrError"
        @ocr-success="handleOcrSuccess"
        @update:loading="handleUpdateLoading($event, index)"
      >
      </OcrUpload>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import _ from 'lodash'
import OcrUpload from './OcrUpload.vue'

export default defineComponent({
  name: 'Air8OcrUploadFixList',
  components: {
    OcrUpload
  },

  props: {
    fileList: Array,
    type: Array,
    ocrType: Array,
    uploadTitle: [String, Array],
    uploadDescription: [String, Array],
    doneTitle: [String, Array],
    doneDescription: [String, Array],
    schemaCode: [String, Array],
    maxlength: {
      type: Number,
      default: 1
    },
    loadingMessage: [String, Array],
    debug: Boolean,
    ocrKeys: Array,
    customOcr: Boolean
  },

  emits: ['update:file', 'update:file-list', 'update:ocr-result-list', 'file-error', 'ocr-success', 'ocr-error', 'update:loading'],

  setup (props, { emit }) {
    const uploadCardRefs = ref([])
    const uploadLoadings = ref([])

    function getString (text, index, defaultText) {
      if (_.isEmpty(text)) {
        return defaultText
      } else if (_.isArray(text)) {
        return _.nth(text, index % _.size(text))
      } else {
        return text
      }
    }

    function getUploadTitle (index) {
      return getString(props.uploadTitle, index)
    }

    function getUploadDescription (index) {
      return getString(props.uploadDescription, index)
    }

    function getSchemaCode (index) {
      if (props.customOcr) {
        return undefined
      }
      return getString(props.schemaCode, index)
    }

    function getDoneTitle (index) {
      return getString(props.doneTitle, index, _.get(_.nth(props.fileList, index), 'name'))
    }

    function getDoneDescription (index) {
      return getString(props.doneDescription, index)
    }

    function getLoadingMessage (index) {
      return getString(props.loadingMessage, index)
    }

    const internalFileList = ref()
    const internalOcrResult = ref([])

    function updateOcrResultByFileList () {
      internalOcrResult.value = _.filter(internalOcrResult.value, (ocrResult) => {
        return _.findIndex(internalFileList.value, { uid: _.head(_.keys(ocrResult)) }) !== -1
      })
    }

    watch(() => props.fileList, () => {
      internalFileList.value = props.fileList || []
      updateOcrResultByFileList()
    }, { immediate: true })

    function handleUploadFile (e, index) {
      _.set(internalFileList.value, index, e)

      emit('update:file', e)
      emit('update:file-list', internalFileList.value)
    }

    function handleUpdateOcrResult (e, index) {
      updateOcrResultByFileList()
      if (_.isEmpty(props.ocrKeys)) {
        emit('update:ocr-result-list', undefined)
      }
      _.set(internalOcrResult.value, index, e)
      const ocrResult = _.extend({}, ..._.flatten(internalOcrResult.value))
      const values = _.flatten(_.values(ocrResult))
      const result = _.reduce(props.ocrKeys, (result, key) => {
        const index = _.findIndex(values, (item) => {
          return _.has(item, key)
        })
        if (index !== -1) {
          const value = _.get(values, [index, key])
          _.set(result, key, value)
          _.pullAt(values, [index])
        }
        return result
      }, {})
      emit('update:ocr-result-list', result)
    }

    function handleFileError (e) {
      emit('file-error', e)
    }

    function handleOcrError (e) {
      emit('ocr-error', e)
    }

    function handleOcrSuccess (e) {
      emit('ocr-success', e)
    }

    function updateArrayByMaxlength (arr) {
      if (props.maxlength < _.size(arr)) {
        return _.take(arr, props.maxlength)
      }
      return arr
    }

    watch(() => props.maxlength, () => {
      uploadCardRefs.value = updateArrayByMaxlength(uploadCardRefs.value)
      uploadLoadings.value = updateArrayByMaxlength(uploadLoadings.value)
    })

    function bindUploadCardRefs (ref, index) {
      uploadCardRefs.value[index] = ref
    }

    async function ocrAllFiles () {
      const ocrResult = await Promise.all(_.map(uploadCardRefs.value, (ref) => {
        return ref.ocrFile(props.schemaCode)
      }))
      const values = _.flatten(ocrResult)
      return _.reduce(props.ocrKeys, (result, key) => {
        const index = _.findIndex(values, (item) => {
          return _.has(item, key)
        })
        if (index !== -1) {
          const value = _.get(values, [index, key])
          _.set(result, key, value)
          _.pullAt(values, [index])
        }
        return result
      }, {})
    }

    function handleUpdateLoading (e, index) {
      uploadLoadings.value[index] = e

      emit('update:loading', _.some(uploadLoadings.value, Boolean))
    }

    return {
      getUploadTitle,
      getUploadDescription,
      getSchemaCode,
      getDoneTitle,
      getDoneDescription,
      getLoadingMessage,
      internalFileList,
      handleUploadFile,
      handleUpdateOcrResult,
      handleFileError,
      handleOcrError,
      handleOcrSuccess,
      bindUploadCardRefs,
      ocrAllFiles,
      handleUpdateLoading
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';
.air8-ocr-upload-fix-list__card {
  :deep(.ant-card) {
    text-align: center;
    // height: 260px;
    // max-height: 260px;
    min-height: 260px;
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-card-body {
      padding: 14px 4px 18px 4px;
      width: 100%;
      cursor: default;
      .ant-card-meta-title {
        color: inherit;
      }
      .ant-card-meta-description {
        color: inherit;
      }
    }
    &:hover {
      border-color: @color-primary;
      color: @color-primary;
    }
  }
}
</style>
