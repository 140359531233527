import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'

export default class DynamicGroupBuilder extends DefaultBuilder {
  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    currentConfig = _.clone(currentConfig)
    currentConfig.$children = _.map(currentConfig.$children, (child) => {
      return this.initChildConfig(currentConfig, child)
    })
    return currentConfig
  }

  private initChildConfig (config: any, childConfig:any):any {
    if (_.isNil(childConfig)) {
      return childConfig
    }
    return _.defaults(_.clone(childConfig), {
      $type: 'Air8DynamicFormItem'
    })
  }
}
