
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, nextTick, watch, onUpdated, useCssModule, unref } from 'vue'
import { PageData } from '@/common/page-data'
import SlotRender from '@/components/data-table/slot-render'
import { SettingOutlined } from '@ant-design/icons-vue'
import { ConfigField } from '@/components/data-table/fileds'
import draggable from 'vuedraggable'
import { useAuth } from '@/plugins/auth/src'
import _ from 'lodash'
import { t } from '@/config/locale'
import * as ExportUtils from '../../utils/exportUtils'
import { sumList } from '@/utils/number'
import TableSelection from '../../utils/tableSelection'
import { useLocal } from '@/plugins/locale'
import CardVue from './Card.vue'

export default defineComponent({
  name: 'data-table',
  components: { SlotRender, SettingOutlined, draggable, CardVue },
  props: {
    columns: {
      type: Array as PropType<any[]>,
      required: true
    },
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    nested: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    sid: {
      type: String
    },
    title: {
      type: String
    },
    pageSizeOptions: {
      type: Array
    },
    summary: [Array, Object, Function],
    showPage: {
      type: Boolean,
      default: true
    },
    editable: Boolean,
    wrapBrackets: Boolean,
    rowKey: [String, Function],
    rowClassName: Function,
    scroll: [String, Object],
    rowSelection: [Boolean, Object],
    disabledSelection: [Array, Function],
    selection: Array,
    querySize: Number,
    emptyText: {
      type: String,
      default: () => t('common.empty_data')
    }
  },

  emits: ['update:selection'],

  setup (props, context) {
    const { t } = useLocal()
    const { emit } = context

    const table = ref<any>(null)

    const configField = ref(new ConfigField(props.sid as any))
    const isFirstConfigLoading = ref(true)
    watch([() => props.sid, () => props.columns], () => {
      configField.value = new ConfigField(props.sid as any)
      if (props.sid) {
        unref(configField).pull(props.columns)
      }
      isFirstConfigLoading.value = true
    })
    watch(() => unref(configField).loading, (curr, prev) => {
      if (isFirstConfigLoading.value && !curr && prev) {
        isFirstConfigLoading.value = false
      }
    })

    const pagination = computed(() => {
      return {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total: number) => `${t('common.total', total + '')}`,
        pageSizeOptions: props.pageSizeOptions || ['20', '50', '100'],
        current: props.pageData.params.page,
        pageSize: props.pageData.params.size,
        total: props.pageData.total
      }
    })

    let idCounter = 0
    function generatorRowKey () {
      const id = ++idCounter
      if (id > Number.MAX_SAFE_INTEGER) {
        idCounter = 0
      }
      return 'air8-table_' + id
    }

    const internalRowKey = computed(() => {
      if (_.isNil(props.rowKey)) {
        return (record: any) => {
          let id = _.get(record, '__air8RowId')
          if (_.isNil(id)) {
            id = generatorRowKey()
            _.set(record, '__air8RowId', id)
          }
          return id
        }
      }
      return props.rowKey
    })

    const internalRowClassName = computed(() => {
      const rowClassName: any[] = [(record: any, i: number) => (i % 2 === 1 ? 'striped' : null)]
      if (!_.isNil(props.rowClassName)) {
        rowClassName.push(props.rowClassName)
      }

      return (record: any, i: number) => {
        return _.chain(rowClassName).map((value) => {
          return value(record, i)
        }).filter((value) => {
          return !_.isNil(value)
        }).join(' ')
          .value()
      }
    })

    const height = ref(400)

    function calTableColumnsWidth (columns: any[]) {
      return _.reduce(columns, (result, curr) => {
        return result + parseInt(_.get(curr, 'width', _.get(curr, 'minWidth', _.get(curr, 'maxWidth', 180))))
      }, 0)
    }

    const renderedColumnsWidth = computed(() => {
      return calTableColumnsWidth(renderedColumns.value) + 10
    })

    const internalScroll = computed(() => {
      let scroll: any = _.clone(props.scroll)
      if (_.isEmpty(renderedColumns.value)) {
        scroll = { x: true, y: 'fit-content' }
      } else if (props.scroll === 'auto') {
        scroll = { y: 'fit-content' }
      }

      if (_.size(props.pageData.rows) === 0) {
        scroll = { x: _.get(scroll, 'x'), y: 0 }
      }

      return _.defaults(scroll, {
        x: renderedColumnsWidth.value,
        y: height.value
      })
    })

    const renderedColumns = computed((): any[] => {
      if (!props.sid || isFirstConfigLoading.value) return props.columns
      const map = new Map(props.columns.map((v: any) => [v.key || v.dataIndex, v]))
      return unref(configField).fields.filter(v => v.checked).map(v => map.get(v.key)).filter(v => !!v)
    })

    const hasSummary = computed(() => {
      return !!props.summary
    })

    const internalSummary = computed(() => {
      if (!hasSummary.value) {
        return undefined
      }
      const summary = {
        'selection-column': t('common.summary'),
        _summary: true,
        __air8RowId: 'air8-table_summary'
      }

      let result: any = {}
      if (_.isArray(props.summary)) {
        result = _.defaults(summary, sumList(props.pageData.rows, props.summary as string[]))
      } else if (_.isFunction(props.summary)) {
        result = _.defaults(summary, props.summary(props.pageData.rows))
      } else {
        result = _.defaults(summary, props.summary)
      }

      if (_.isString(internalRowKey.value)) {
        if (_.isUndefined(_.get(result, internalRowKey.value))) {
          result[internalRowKey.value] = ''
        }
      } else if (_.isFunction(internalRowKey.value)) {
        internalRowKey.value(result)
      }
      return result
    })

    const tableSummaryCellClass = useCssModule().table_summary_cell
    // deal summary row display
    watch([hasSummary, () => props.pageData.total, renderedColumns], () => {
      if (!hasSummary.value || props.pageData.total <= 0) {
        return
      }

      nextTick(() => {
        const td = _.invoke(table.value, '$el.querySelector', 'table>tbody>tr:last-child>td:first-child')
        if (td) {
          td.innerHTML = _.template('<span class="<%= className %>"><%- summary %></span>')({
            className: tableSummaryCellClass,
            summary: t('common.summary')
          })
        }
      })
    }, { immediate: true })

    const dataSource = computed(() => {
      if (!props.summary || _.isEmpty(props.pageData.rows)) {
        return props.pageData.rows
      }
      const rows = _.concat([], props.pageData.rows, internalSummary.value)
      return rows
    })

    const internalTablePagination = computed(() => {
      if (!props.showPage || hasSummary.value) {
        return false
      }
      return pagination.value
    })

    const isShowFooterPagination = computed(() => {
      if (!props.showPage) {
        return false
      }
      return hasSummary.value && props.pageData.total > 0
    })

    // page
    const eventHandlers = {
      change (tablePagination: any, filters: any, sorter: any, { currentDataSource }: any) {
        if (_.isEmpty(tablePagination)) {
          tablePagination = pagination.value
        }

        const p = { page: Number(tablePagination.current), size: Number(tablePagination.pageSize) }
        const s = sorter?.order ? { sorter: { prop: sorter?.field, order: sorter?.order === 'ascend' ? 'asc' : 'desc' } } : { sorter: undefined }

        if (isSorterChange(s.sorter)) {
          p.page = 1
        }

        props.pageData.load(Object.assign(props.pageData.params, s as any, p))
      }
    }

    function isSorterChange (sorter: any) {
      return !_.isEqual(sorter, _.get(props.pageData, 'params.sorter'))
    }

    function updatePagination (pagination: any) {
      if (!table.value) {
        return
      }
      const refTable = (table.value as any).getTable()
      refTable.scrollToFirstRow()
      const tableArguments: any[] = refTable.prepareParamsArguments(
        _.defaults({ sPagination: pagination }, refTable.$data)
      )
      _.invoke(eventHandlers, 'change', ...tableArguments)
    }

    function handlePaginationChange (page: any, pageSize: any) {
      updatePagination(_.defaults({
        current: page,
        pageSize
      }, pagination.value))
    }

    function handleShowSizeChange (current: any, size: any) {
      updatePagination(_.defaults({
        current,
        pageSize: size
      }, pagination.value))
    }

    // export
    const { auth } = useAuth()
    const lang = auth?.principle?.user?.language?.slice(3)

    const getExportConfig = () => {
      return {
        exportConfig: {
          columnList: renderedColumns.value.map(v => {
            return {
              grp: '',
              key: v.key || v.dataIndex,
              title: t(v.title?.props?.title || v.title)
            }
          }),
          languageEnum: lang
        },
        ...(props.pageData.params || {})
      }
    }

    function getExportFileName (fileName: string) {
      if (_.isNil(fileName) && !_.isNil(props.title)) {
        return props.title
      } else {
        return fileName
      }
    }

    function getExportOption () {
      return {}
    }

    const exportExcel = (fileName: string) => {
      fileName = getExportFileName(fileName)
      return ExportUtils.exportExcel(props.pageData, renderedColumns.value, fileName, getExportOption())
    }

    const exportCsv = (fileName: string) => {
      fileName = getExportFileName(fileName)
      return ExportUtils.exportCsv(props.pageData, renderedColumns.value, fileName, getExportOption())
    }

    // height
    function computedHeight () {
      let queryHeight = 160
      if (props.querySize && props.querySize > 0 && props.querySize < 3) {
        queryHeight = 102
      } else if (props.querySize === 3) {
        queryHeight = 134
      }
      height.value = document.body.clientHeight - 52 - 32 - queryHeight - 16 - 56 - 64 - 12 - 48 - 24
    }

    onMounted(() => {
      if (props.sid) unref(configField).pull(props.columns)
      computedHeight()

      window.addEventListener('resize', computedHeight)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', computedHeight)
    })

    function getTable () {
      return table.value.getTable()
    }

    function handleChangeSelection (selection: any[]) {
      emit('update:selection', selection)
    }

    const tableSelection = new TableSelection(
      computed(() => props.rowKey),
      computed(() => props.disabledSelection),
      handleChangeSelection,
      updateCheckboxProps
    )
    watch(() => props.selection, (current, prev) => {
      if (current !== prev) {
        tableSelection.updateSelection(current)
      }
    }, { immediate: true })

    const internalRowSelection = computed(() => {
      if (props.rowSelection === false) {
        return undefined
      } else if (props.rowSelection === true) {
        const selectedColumn = _.head(props.columns)
        let tableRowSelectionProps = {}
        if (_.get(selectedColumn, 'key') === 'selection-column') {
          tableRowSelectionProps = {
            columnWidth: _.get(selectedColumn, 'width'),
            columnTitle: _.get(selectedColumn, 'title')
          }
        }

        return _.assign({}, tableSelection.props, tableRowSelectionProps)
      } else {
        return props.rowSelection
      }
    })

    watch(() => props.disabledSelection, () => {
      updateCheckboxProps()
    })

    function updateCheckboxProps () {
      if (table.value) {
        table.value.getTable().checkboxPropsCache = {}
        table.value.getTable().$forceUpdate()
      }
    }

    function equalRow (value1: any, value2: any) {
      if (_.isNil(props.rowKey)) {
        return _.isEqual(value1, value2)
      } else if (_.isString(props.rowKey)) {
        return _.isEqual(_.pick(value1, [props.rowKey]), _.pick(value2, [props.rowKey]))
      } else {
        return props.rowKey(value1) === props.rowKey(value2)
      }
    }

    watch(dataSource, () => {
      if (!props.pageData.initalData || _.isEmpty(props.selection)) {
        return
      }
      const newSelection = _.intersectionWith(dataSource.value, props.selection || [], equalRow)
      if (_.isEqualWith(newSelection, props.selection, equalRow)) {
        return
      }
      tableSelection.updateSelection(newSelection)
      handleChangeSelection(newSelection)
    }, { immediate: true })

    const isDataSourceEmpty = computed(() => {
      return _.isEmpty(props.pageData.rows)
    })

    const emptyTextShow = computed(() => {
      // return { emptyText: props.emptyText }
      return {}
    })

    return {
      pagination,
      configField,
      eventHandlers,
      renderedColumns,
      getExportConfig,
      table,
      emptyTextShow,
      handlePaginationChange,
      handleShowSizeChange,
      dataSource,
      exportExcel,
      exportCsv,
      internalTablePagination,
      isShowFooterPagination,
      internalRowKey,
      internalRowClassName,
      internalScroll,
      getTable,
      internalRowSelection,
      updateCheckboxProps,
      isDataSourceEmpty
    }
  },

  methods: {
    getForm () {
      return this.$refs.form
    }
  }
})
