
import { computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { RemoteDataCatalog, search, retrieve } from '@/config/remote-data'
import { debounce } from 'lodash'
import { useDict } from '@/plugins/dict'

export default defineComponent({
  name: 'dict-select',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      required: true
    }
  },
  emits: ['update:value', 'change'],
  setup (props, context) {
    const state = reactive<any>({
      data: []
    })

    const val = computed<any>({
      get: () => props.value,
      set: (v: any) => context.emit('update:value', v)
    })

    const change = (value:any, optins:any) => {
      context.emit('change', value)
    }

    onMounted(() => {
      const { m } = useDict()
      state.data = m.t(props.name)
    })

    return {
      ...toRefs(state),
      val,
      change
    }
  }
})
