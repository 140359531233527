import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { style: {"margin-left":"10px","font-size":"18px"} }
const _hoisted_2 = { style: {"text-align":"right","padding":"4px 8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_air8_locale_label = _resolveComponent("air8-locale-label")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_slot_render = _resolveComponent("slot-render")!
  const _component_i_table = _resolveComponent("i-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_i_form = _resolveComponent("i-form")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nested ? 'div' : 'CardVue'), null, {
    default: _withCtx(() => [
      (!_ctx.nested)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.$style.header, 'air8-search-list-page__data-table-header'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.title, 'air8-search-list-page__data-table-header__title'])
            }, [
              _renderSlot(_ctx.$slots, "header-title", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.title || _ctx.$t('common.result')), 1)
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style['header-action-bar'], 'air8-search-list-page__header-action-bar-maxlength'])
            }, [
              _renderSlot(_ctx.$slots, "extra", {
                getExportConfig: _ctx.getExportConfig,
                exportExcel: _ctx.exportExcel,
                exportCsv: _ctx.exportCsv
              }),
              (_ctx.sid)
                ? (_openBlock(), _createBlock(_component_a_dropdown, {
                    key: 0,
                    trigger: "click"
                  }, {
                    overlay: _withCtx(() => [
                      _createVNode(_component_a_menu, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_spin, {
                            spinning: _ctx.configField.updating || _ctx.configField.loading
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_draggable, {
                                modelValue: _ctx.configField.fields,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => _ctx.configField.sort(value)),
                                group: "people",
                                "item-key": "key"
                              }, {
                                item: _withCtx(({element}) => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_a_menu_item, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                                        }, [
                                          _createVNode(_component_a_switch, {
                                            size: "small",
                                            checked: element.checked,
                                            "onUpdate:checked": (checked) => _ctx.configField.update(element, checked)
                                          }, null, 8, ["checked", "onUpdate:checked"]),
                                          _createVNode(_component_air8_locale_label, {
                                            style: {"margin-left":"8px"},
                                            wrapBrackets: false,
                                            value: element.title
                                          }, null, 8, ["value"])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["spinning"]),
                          _createVNode(_component_a_menu_divider),
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_a_button, {
                              size: "small",
                              onClick: _cache[2] || (_cache[2] = () => _ctx.configField.reset()),
                              loading: _ctx.configField.updating || _ctx.configField.loading
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.reset')), 1)
                              ]),
                              _: 1
                            }, 8, ["loading"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_1, [
                        _createVNode(_component_SettingOutlined)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.body])
      }, [
        _createVNode(_component_a_spin, {
          spinning: _ctx.pageData.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_i_form, {
              ref: "form",
              model: _ctx.dataSource
            }, {
              default: _withCtx(() => [
                _createVNode(_component_i_table, _mergeProps({ ref: "table" }, _ctx.$attrs, {
                  columns: _ctx.renderedColumns,
                  "data-source": _ctx.dataSource,
                  locale: _ctx.emptyTextShow,
                  pagination: _ctx.internalTablePagination
                }, _toHandlers(_ctx.eventHandlers), {
                  class: [_ctx.$style.table],
                  "wrap-brackets": _ctx.wrapBrackets,
                  "row-key": _ctx.internalRowKey,
                  "row-selection": _ctx.internalRowSelection,
                  "row-class-name": _ctx.internalRowClassName,
                  scroll: _ctx.internalScroll
                }), _createSlots({ _: 2 }, [
                  _renderList(_ctx.$slots, (s, name) => {
                    return {
                      name: name,
                      fn: _withCtx((props) => [
                        _createVNode(_component_slot_render, {
                          s: s(props)
                        }, null, 8, ["s"])
                      ])
                    }
                  })
                ]), 1040, ["columns", "data-source", "locale", "pagination", "class", "wrap-brackets", "row-key", "row-selection", "row-class-name", "scroll"]),
                (_ctx.isShowFooterPagination)
                  ? (_openBlock(), _createBlock(_component_a_pagination, _mergeProps({
                      key: 0,
                      class: "ant-table-pagination"
                    }, _ctx.pagination, {
                      onChange: _ctx.handlePaginationChange,
                      onShowSizeChange: _ctx.handleShowSizeChange
                    }), null, 16, ["onChange", "onShowSizeChange"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["spinning"])
      ], 2)
    ]),
    _: 3
  }))
}