import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_number = _resolveComponent("a-input-number")!

  return (_openBlock(), _createBlock(_component_a_input_number, _mergeProps({
    value: _ctx.val,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.val = $event)),
    formatter: _ctx.formatter || _ctx.defaultFormatter,
    parser: _ctx.parser
  }, _ctx.$attrs), null, 16, ["value", "formatter", "parser"]))
}