<template>
  <a-card class="air8-card">
    <template
      #title
      v-if="isShowTitle"
    >
      <slot name="title">
        <i-span class="air8-card__title">{{ this.internalTitle }}</i-span>
        <div class="air8-card__subTitle">
          <slot name="subTitle">
          </slot>
        </div>
      </slot>
    </template>
    <template #extra>
      <slot name="extra"></slot>
    </template>
    <template #default>
      <slot></slot>
    </template>
  </a-card>
</template>
<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8Card',

  aliasName: 'T3',

  props: {
    title: [String, Function],
    hideTitle: Boolean
  },

  computed: {
    internalTitle () {
      if (_.isFunction(this.title)) {
        return this.title()
      } else {
        return this.title
      }
    },

    isShowTitle () {
      return !this.hideTitle
    }
  }
})
</script>
<style lang="less" scoped>
.air8-card {
  & > :deep(.ant-card-head) {
    display: flex;
    .ant-card-head-wrapper {
      width: 100%;
    }
    .ant-card-head-title {
      display: flex;
      align-items: center;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .ant-card-extra {
      .ant-btn + .ant-btn {
        margin: 0 0 0 12px;
      }
    }
  }

  .air8-card__subTitle {
    margin: 0px 20px;
    display: inline-block;
    font-size: 14px;
    opacity: 0.8;

    /deep/ span {
      white-space: normal;
      word-break: break-all;
    }
  }

  &.air8-card__requried {
    .air8-card__title {
      &::after {
        content: '*';
        color: #ff5630;
      }
    }
  }
}
</style>
