import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.btnDrop])
  }, [
    _createVNode(_component_a_dropdown, { trigger: _ctx.trigger }, {
      overlay: _withCtx(() => [
        _renderSlot(_ctx.$slots, "items")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_button, { type: _ctx.type }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            _createVNode(_component_CaretDownOutlined)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      _: 3
    }, 8, ["trigger"])
  ], 2))
}