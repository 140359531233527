import _ from 'lodash'
import { unref, VNode } from 'vue'
import BaseBuilder from '../BaseBuilder'

export default class MainBuilder extends BaseBuilder {
  public render (): VNode[] | undefined {
    if (_.isNil(this.currentConfig)) {
      return undefined
    }
    const flattenNodes = _.chain(this.currentConfig)
      .castArray()
      .map((config) => {
        config = unref(config)
        if (_.isNil(config)) {
          return undefined
        }
        return this.getInstanceRender(unref(config.$type), config)
      })
      .flatten()
      .value() as (VNode[] | undefined)

    return _.filter(flattenNodes, (node: any) => {
      return !_.isNil(node)
    })
  }
}
