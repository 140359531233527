
import { computed, defineComponent, PropType, provide, reactive, ref } from 'vue'

export interface LayoutDetailColumn {
  prop: string;
  label: string;
  formatter?: (value: any, data?: any) => string
}

export default defineComponent({
  name: 'layout-detail',
  props: {
    columns: {
      type: Array as PropType<LayoutDetailColumn[]>,
      default: () => []
    },
    dataSource: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    }
  },
  setup (props) {
    const resolveText = (item: LayoutDetailColumn) => {
      const v = props?.dataSource && props?.dataSource[item.prop]
      return item.formatter ? item.formatter(v, props?.dataSource) : v
    }
    return {
      resolveText
    }
  }
})

