import { t } from '@/config/locale'
import { useHttp } from '@/plugins/http/src/index'
import { message } from 'ant-design-vue'

export function download (url: string, data: any, method: 'get' | 'post' = 'post') {
  const { http } = useHttp()
  const timeout = 60 * 60 * 1000
  const isStream = url.includes('/sysAttachment/downloadBatch')
  const request = method === 'post' ? http.post(url, data, { responseType: 'blob', timeout } as any) : http.get(url, { params: data, responseType: 'blob', timeout })
  const handler = message.loading(t('common.exporting'))
  if (!isStream) {
    return request.then(({ data, headers }) => {
      const link = document.createElement('a')
      const blob = new Blob([data])
      const disposition = headers['content-disposition']
      const fileName = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
      link.download = fileName
      link.href = window.URL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return true
    }).finally(() => {
      if (handler) handler()
    })
  } else {
    return http.post(url, data).then((data) => {
      const link = document.createElement('a')
      link.href = '/api/air8-company/sysAttachment/batchDownloadByKey?objectKey=' + encodeURIComponent(data)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return true
    }).finally(() => {
      if (handler) handler()
    })
  }
}

export function downloadFromUrl (url: string) {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
