import { useHttp } from '@/plugins/http/src/index'
import _ from 'lodash'
const base = '/api/air8-ocr-gw/ocr'

function delay (timer: number) {
  if (timer <= 0) {
    return
  }
  return new Promise((resolve) => {
    _.delay(() => resolve(true), timer)
  })
}

async function retryHttpRequest (http: any, reTryCode: number, reTryNum: number, timer: number): Promise<any> {
  try {
    return await http()
  } catch (e: any) {
    if (_.get(e, 'data.code') !== reTryCode) {
      throw e
    }
    reTryNum--
    if (reTryNum < 0) {
      throw e
    }
    await delay(timer)
    return await retryHttpRequest(http, reTryCode, reTryNum, timer)
  }
}

class HttpConnectPool {
  private poolSize = 0
  private http: any
  private current = 0
  private pools: any[] = [] as any[]
  constructor (poolSize: number) {
    const { http } = useHttp()
    this.http = http
    this.poolSize = poolSize
    this.current = 0
    this.pools = []
  }

  execute (func: any) {
    return new Promise((resolve, reject) => {
      this.pools.push({
        func,
        resolve,
        reject
      })
      this.executePool()
    })
  }

  private async executePool () {
    if (this.current >= this.poolSize) {
      return
    }
    this.current++
    const item = _.head(_.pullAt(this.pools, [0]))
    if (_.isNil(item)) {
      this.current--
      return
    }

    try {
      const result = await item.func()
      this.current--
      item.resolve(result)
    } catch (e) {
      this.current--
      item.reject(e)
    }

    await this.executePool()
  }

  async request (...args: any) {
    return this.execute(() => this.http.request(...args))
  }

  async get (...args: any) {
    return this.execute(() => this.http.get(...args))
  }

  async delete (...args: any) {
    return this.execute(() => this.http.delete(...args))
  }

  async head (...args: any) {
    return this.execute(() => this.http.head(...args))
  }

  async post (...args: any) {
    return this.execute(() => this.http.post(...args))
  }

  async put (...args: any) {
    return this.execute(() => this.http.put(...args))
  }

  async patch (...args: any) {
    return this.execute(() => this.http.patch(...args))
  }
}

let httpConnectPool: HttpConnectPool

function initHttpConnectPool () {
  if (_.isNil(httpConnectPool)) {
    httpConnectPool = new HttpConnectPool(3)
  }

  return httpConnectPool
}

export function getOcrResult (params: any, showError = false) {
  const httpConnectPool = initHttpConnectPool()
  return retryHttpRequest(() => httpConnectPool.post(`${base}/standard`, params, { showError } as any), 400, 1, 10000)
}
