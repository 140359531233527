import _ from 'lodash'
import { useInjectForm } from 'ant-design-vue/es/form/context'
import { computed, ref, VNode, isVNode, mergeProps } from 'vue'

type StringRegExp = string | RegExp

export const getByKeyField = (obj: any, prefix: StringRegExp = '$'): any => {
  return _.pickBy(obj, (value, key) => {
    if (_.isString(prefix)) {
      return key.startsWith(prefix)
    } else {
      return !_.isEmpty(key.match(prefix))
    }
  })
}

export const removeKeyField = (obj: any, prefix: StringRegExp = '$'): any => {
  return _.omitBy(obj, (value, key) => {
    if (_.isString(prefix)) {
      return key.startsWith(prefix)
    } else {
      return !_.isEmpty(key.match(prefix))
    }
  })
}

export function instanceofType (obj: any, typeFields: string[]): boolean {
  return _.isPlainObject(obj) && _.difference(typeFields, _.keysIn(obj)).length === 0
}

export const instanceofVNode = (obj: any): boolean => {
  return isVNode(obj)
}

export const injectForm = function (): any {
  const context: any = useInjectForm()
  const model = computed(() => {
    return context.model.value
  })
  return {
    context,
    model
  }
}

const PAGEDATA_FIELDS = ['list', 'pageNum', 'pageSize', 'total']
export const instanceofPageData = (obj: any): boolean => {
  return instanceofType(obj, PAGEDATA_FIELDS)
}

export const mergeClassName = (...classNames: (string | string[] | any)[]): any => {
  if (_.isEmpty(classNames)) {
    return {}
  }

  const { class: result } = mergeProps(..._.map(classNames, (item: any) => {
    return { class: item }
  }))

  return result
}

export function promiseAllInOrder (promises: Promise<any>[]): Promise<any[]> {
  if (_.isEmpty(promises)) {
    return Promise.all(promises)
  }

  return new Promise((resolve, reject) => {
    const results: any[] = []
    const errors: any[] = []
    let count = promises.length

    _.each(promises, (promise, index) => {
      promise.then(result => {
        results[index] = result
      }, error => {
        errors[index] = error
      }).finally(() => {
        count--
        if (count === 0) {
          if (_.isEmpty(errors)) {
            resolve(results)
          } else {
            reject(_.find(errors, (error) => !_.isNil(error)))
          }
        }
      })
    })
  })
}

export const validateForms = async (...forms: any[]): Promise<boolean> => {
  if (_.isEmpty(forms)) {
    return Promise.resolve(true)
  }

  const promises = _.map(forms, (form: any) => {
    if (_.isNil(form)) {
      return Promise.resolve(true)
    }
    return _.invoke(form, 'validate').then((result: any) => result,
      (error: any) => {
        error.form = form
        return Promise.reject(error)
      })
  })

  try {
    const results = await promiseAllInOrder(promises)
    return _.every(results, (result: any) => !!result)
  } catch (error) {
    _.invoke(error, 'form.scrollToField', [_.get(error, 'errorFields.0.name')])
    return Promise.reject(error)
  }
}

export const validateCustomForms = async (...forms: any[]): Promise<boolean> => {
  if (_.isEmpty(forms)) {
    return Promise.resolve(true)
  }

  const promises = _.map(forms, (form: any) => {
    if (_.isNil(form)) {
      return Promise.resolve(true)
    }
    return _.invoke(form, 'validate')
  })

  const results = await promiseAllInOrder(promises)
  return _.every(results, (result: any) => !!result)
}

export const validateFormsQueue = async (...forms: any[]): Promise<boolean> => {
  if (_.isEmpty(forms)) {
    return Promise.resolve(true)
  }

  try {
    for (const form of forms) {
      if (_.isNil(form)) {
        continue
      }
      await _.invoke(form, 'validate').then((result: any) => result,
        (error: any) => {
          error.form = form
          return Promise.reject(error)
        })
    }
    return true
  } catch (error) {
    _.invoke(error, 'form.scrollToField', [_.get(error, 'errorFields.0.name')])
    return Promise.reject(error)
  }
}

export const removeAllKeyField = (value: any, prefix: StringRegExp = /^[$_]/g): any => {
  if (_.isNil(value)) {
    return value
  } else if (_.isArray(value)) {
    return _.map(value, (item: any) => {
      return removeAllKeyField(item, prefix)
    })
  } else if (_.isPlainObject(value)) {
    let obj = removeKeyField(value, prefix)
    obj = _.mapValues(obj, (objValue: any) => {
      return removeAllKeyField(objValue, prefix)
    })
    return obj
  } else {
    return value
  }
}

export const getComponentAttribute = (attrs: any, attributeName: string): any => {
  if (_.isEmpty(attrs) || _.isEmpty(attributeName)) {
    return undefined
  }

  attributeName = _.camelCase(attributeName)
  const key = _.find(_.keys(attrs), (attr: string) => _.camelCase(attr) === attributeName)
  if (_.isNil(key)) {
    return undefined
  } else {
    return _.get(attrs, key)
  }
}

export const simpleCreateComponent = (vNode: VNode): any => {
  if (_.isNil(vNode)) {
    return undefined
  }

  const component = {}
  // props, attrs?, methods, data
  _.assign(component, _.get(vNode, 'props'), _.get(vNode, 'type.methods'), _.invoke(vNode, 'type.data'))

  // computed
  _.each(_.keys(_.get(vNode, 'type.computed')), (computeField) => {
    let init = false
    let value: any
    Object.defineProperty(component, computeField, {
      get: () => {
        if (init) {
          return value
        }
        value = _.get(vNode, `type.computed.${computeField}`).apply(component)
        init = true
        return value
      }
    })
  })

  // created
  const created = _.get(vNode, 'type.created')
  if (_.isFunction(created)) {
    created.apply(component)
  }
  return component
}

export function getComponentName (name: string) {
  if (_.isNil(name)) {
    return name
  }
  return _.upperFirst(_.camelCase(name))
}
