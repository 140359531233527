<template>
  <air8-action-page
    class="air8-view-page"
    ref="actionPage"
    v-bind="$attrs"
    :status="status"
    :config="config"
    :model-value="modelValue"
    :init-function="initFunction"
    :go-back="goBack"
  >
  <template v-slot:default v-if="$slots.default">
    <slot name="default"></slot>
  </template>
  </air8-action-page>
</template>
<script>
import { defineComponent, ref } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8ViewPage',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: 'detail'
    },
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    }
  },

  setup () {
    const actionPage = ref()
    function getForm () {
      return actionPage.value.getForm()
    }

    return {
      actionPage,
      getForm
    }
  },

  methods: {
    setLoading (loading) {
      if (this.actionPage) {
        this.actionPage.setLoading(loading)
      }
    }
  }
})
</script>
