

import { computed, defineComponent, reactive, ref, watch, onMounted, toRefs, PropType, h } from 'vue'
import { t } from '@/config/locale'
import store from '@/store'

function getNestedValue (obj: any, path: (string | number)[], fallback?: any): any {
  const last = path.length - 1

  if (last < 0) return obj === undefined ? fallback : obj

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback
    }
    obj = obj[path[i]]
  }

  if (obj == null) return fallback

  let ret = obj[path[last]]

  if (Object.prototype.toString.call(ret) === '[object Object]') ret = ret?.label

  return ret === undefined ? fallback : ret
}

interface Item {
    type: 'text' | 'comp';
    content?: string;
    name?: string;
}

const regex = /([^{}]*){([^{}]+)}([^{}]*)/g

export default defineComponent({
  name: 'locale',
  components: { },
  props: {
    path: {
      type: String as PropType<string>
    }
  },
  setup (props, { emit, slots }) {
    const items = computed<Item[]>(() => {
      const locale = (store.state as any).app.locale as Record<string, any>
      const value = getNestedValue(locale, (props.path || '').split('.').filter(v => !!v))

      const ret: Item[] = []

      let s = regex.exec(value || '')

      while (s) {
        const [t, l, m, r] = s
        if (l) ret.push({ type: 'text', content: l })
        if (m) {
          const [name, content] = (m || '').split(':')
          ret.push({ type: 'comp', name, content })
        }
        if (r) ret.push({ type: 'text', content: r })
        s = regex.exec(value)
      }
      return ret
    })

    return () => {
      const children = items.value.map(v => {
        if (v.type === 'text') {
          return h('span', v.content)
        } else {
          const c = slots[v.name as string]
          return c && c({ content: v.content })
        }
      })
      return h('span', {}, children)
    }
  }
})
