<template>
  <div class="air8-multiline">
    <template v-if="isEmpty">
      <slot name="empty"><span>{{ empty }}</span></slot>
    </template>
    <template v-else>
      <span class="air8-multiline__line"
        v-for="(item, index) in internalValue"
        :key="index"
      >
        {{ item }}
      </span>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8MultiLine',

  props: {
    empty: {
      type: String,
      default: '-'
    },
    value: [Array, String]
  },

  computed: {
    internalValue () {
      let value = this.value
      if (_.isString(value)) {
        value = value.split(',')
      }

      return _.chain(value)
        .castArray()
        .filter((item) => {
          return !_.isNil(item) && item !== ''
        })
        .value()
    },

    isEmpty () {
      return _.isEmpty(this.internalValue)
    }
  }
})
</script>
<style lang="less" scoped>
.air8-multiline {
  display: flex;
  flex-direction: column;
}
</style>
