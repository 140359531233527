import { App } from 'vue'
import store from '@/store'
import dayjs from 'dayjs'
import moment from 'moment'
import _ from 'lodash'

const serverTimeZone = 480

function plusTime (d: Date, sourceTimeZone: number, targetTimeZone: number) {
  const offset = sourceTimeZone - targetTimeZone
  let time = d.getTime()
  time += offset * 60000
  return new Date(time)
}

function displayLocalTimeAsServerZone (d: Date) {
  return plusTime(d, serverTimeZone, 0 - new Date().getTimezoneOffset())
}

// to local
const l = (timestamp: string | number) => {
  if (!timestamp) return null
  let d
  if (typeof timestamp === 'string') {
    d = new Date(timestamp.replace(/-/g, '/')) // non UTC
  } else if (typeof timestamp === 'number') {
    d = new Date(timestamp) // UTC
    d = displayLocalTimeAsServerZone(d)
  } else {
    return null
  }

  const timeZone = (store?.state as any)?.app?.timeZone || '+8'
  const offset = (isNaN(timeZone) ? 8 : Number(timeZone)) - 8
  return new Date(d.getTime() + (1000 * 3600 * offset))
}

// to server
const s = (date: Date, mode?: string) => {
  if (mode && mode !== 'ss') {
    const day = new Date(date.getFullYear(), date.getMonth(), date.getDate()) // local time
    date = mode === 'S' ? day : new Date(new Date(day).getTime() + 24 * 60 * 60 * 1000 - 1) // local time
  }

  const timeZone = (store?.state as any)?.app?.timeZone || '+8'
  const offset = (isNaN(timeZone) ? 8 : Number(timeZone)) - 8
  return moment(date.getTime() - (1000 * 3600 * offset)).format(mode === 'ss' ? 'YYYY-MM-DD HH:mm:ss' : 'yyyy-MM-DD')
}

// format
const f = (timestamp: string | number | moment.Moment, mode: 'D' | 'T' = 'T') => {
  let d
  if (timestamp instanceof moment) {
    d = (timestamp as moment.Moment).toDate()
  } else if (['string', 'number'].includes(typeof timestamp)) {
    d = l(timestamp as (string | number))
  } else {
    return timestamp
  }
  if (!d) return ''
  const timeFormat = (store?.state as any)?.app?.timeFormat
  return dayjs(d.getTime()).format(mode === 'D' ? timeFormat : timeFormat + ' HH:mm:ss')
}

// to server for date-picker
const as = (d: any, mode = 'T') => {
  return s(d.toDate(), mode)
}

// to local for date-picker
const al = (v: string) => {
  return moment(l(v))
}

// iso
const iso = (date: Date) => {
  const timeZone = (store?.state as any)?.app?.timeZone || '+8'
  const offset = (isNaN(timeZone) ? 8 : Number(timeZone)) - 8
  return new Date(date.getTime() - (1000 * 3600 * offset)).toISOString()
}

const tz = {
  l,
  s,
  f,
  as,
  al
}

export const useTimeZone = () => {
  return { tz }
}

export default function (app: App) {
  app.config.globalProperties.$tz = tz
}
