<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8DictionarySelect',

  aliasName: 'C3',

  props: {
    dictName: String,
    getPopupContainer: Function
  },

  computed: {
    options () {
      return _.cloneDeep(this.$m(this.dictName))
    }
  },

  render () {
    return h(resolveComponent('Air8Select'), {
      ...this.$attrs,
      getPopupContainer: this.getPopupContainer,
      labelProp: 'value',
      valueProp: 'key',
      empty2Nil: true,
      options: this.options
    }, this.$slots)
  }
})
</script>
