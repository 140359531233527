<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'
import BuilderFactory from '@/components/air8/builder/BuilderFactory'

export default defineComponent({
  name: 'Air8DynamicTable',
  aliasName: 'a8-d-table',

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    sequenceKey: {
      type: String,
      default: '_seq'
    },
    actionKey: {
      type: String,
      default: 'action'
    }
  },

  computed: {
    scope () {
      return { ...this.$attrs, ...this.$props }
    },

    internalColumns () {
      const columns = _.clone(this.columns)
      const builder = BuilderFactory.getInstance('DynamicTableInnerColumns', columns, this.scope)
      return builder.getCurrentConfig()
    }
  },

  methods: {
    getForm () {
      return this.$refs.table.getForm()
    }
  },

  render () {
    return h(resolveComponent('Air8Table'), {
      ...this.$attrs,
      ref: 'table',
      columns: this.internalColumns,
      sequenceKey: this.sequenceKey,
      actionKey: this.actionKey
    }, this.$slots)
  }
})
</script>
