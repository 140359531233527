import DefaultBuilder from './impl/DefaultBuilder'
import MainBuilder from './impl/MainBuilder'
import ColumnLayoutBuilder from './impl/ColumnLayoutBuilder'
import ColumnLayout from '../containers/column-layout/index.vue'
import FormItemBuilder from './impl/FormItemBuilder'
import FormItem from '../components/form/FormItem.vue'
import DynamicGroup from '../backendDynamic/components/group/index.vue'
import DynamicFormItem from '../backendDynamic/components/formItem/index.vue'

import TableColumnBuilder from './impl/TableInnerColumnsBuilder'
import TableInnerActionsBuilder from './impl/TableInnerActionsBuilder'
import SearchTablePageTableInnerExtraActionsBuilder from './impl/SearchTablePageTableInnerExtraActionsBuilder'
import SearchTablePageTableInnerColumnBuilder from './impl/SearchTablePageTableInnerColumnBuilder'
import SearchTablePageQueryConditionBuilder from './impl/SearchTablePageQueryConditionBuilder'
import CustomerGroupActionButtonBuilder from './impl/CustomerGroupActionButtonBuilder'
import DynamicGroupBuilder from './impl/DynamicGroupBuilder'
import DynamicFormItemBuilder from './impl/DynamicFormItemBuilder'
import DynamicTableInnerColumnsBuilder from './impl/DynamicTableInnerColumnsBuilder'
import DynamicConfigBuilder from './impl/DynamicConfigBuiler'
import ExportTableInnerColumnsBuilder from './impl/ExportTableInnerColumnsBuilder'

export default [{
  builder: ColumnLayoutBuilder, comp: ColumnLayout
}, {
  builder: FormItemBuilder, comp: FormItem
}, {
  builder: TableColumnBuilder, comp: { name: 'TableInnerColumns' }
}, {
  builder: TableInnerActionsBuilder, comp: { name: 'TableInnerActions' }
}, {
  builder: SearchTablePageTableInnerColumnBuilder, comp: { name: 'SearchTablePageTableInnerColumn' }
}, {
  builder: SearchTablePageQueryConditionBuilder, comp: { name: 'SearchTablePageQueryCondition' }
}, {
  builder: SearchTablePageTableInnerExtraActionsBuilder, comp: { name: 'SearchTablePageTableInnerExtraActionsBuilder' }
}, {
  builder: CustomerGroupActionButtonBuilder, comp: { name: 'CustomerGroupActionButtonBuilder' }
}, {
  builder: MainBuilder, comp: { name: 'main' }
}, {
  builder: DefaultBuilder, comp: { name: 'default' }
}, {
  builder: DynamicGroupBuilder, comp: DynamicGroup
}, {
  builder: DynamicFormItemBuilder, comp: DynamicFormItem
}, {
  builder: DynamicTableInnerColumnsBuilder, comp: { name: 'DynamicTableInnerColumns' }
}, {
  builder: DynamicConfigBuilder, comp: { name: 'DynamicConfigBuilder' }
}, {
  builder: ExportTableInnerColumnsBuilder, comp: { name: 'ExportTableInnerColumns' }
}]
