import { NavigationGuard } from 'vue-router'
import Vue, { getCurrentInstance } from 'vue'
import NP from 'nprogress'
import { useAuth } from '@/plugins/auth/src'

const whiteList = ['/login', '/error/**'] // ant style wildcat

const frontGuard: NavigationGuard = function (to, from, next) {
  NP.start()
  const { auth } = useAuth()
  // 白名单或未设置权限， 不验证
  if (onWhiteList(to.path) || (!to.meta?.pid)) {
    next()
    return
  }
  // 权限控制
  auth.access(to.meta?.pid as string).then((pass: boolean) => {
    if (pass || to.meta?.pid === '/') {
      if (['', '/'].includes(to.path)) {
        if (auth.accessSync('/home')) {
          next('/home')
        } else if (auth.accessSync('/org/company/detail')) {
          next('/org/company/detail')
        } else {
          next({ path: '/error/403' })
        }
      } else {
        next()
      }
    } else {
      next({ path: '/' })
      NP.done()
    }
  }).catch(({ data }) => {
    const code = data?.code
    if (code === 1101 || code === 1100) {
      window.location.assign('/login')
    }
    NP.done()
  })
}

function onWhiteList (path: string) {
  const convertRegEx = (expr: string) => {
    return new RegExp('^' +
      (expr || '')
        .replace(/\*\*/g, '#__#')
        .replace(/\*/g, '[^/]*')
        .replace(/#__#/g, '.*') +
      '$')
  }
  return whiteList.some(v => convertRegEx(v).test(path))
}

export default frontGuard
