

import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'dict-iterator',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const options = store.getters['dictionary/options']
    const rows = computed(() => options(props.name))
    return {
      rows
    }
  }
})
