
import { defineComponent, onMounted, ref, reactive, computed, PropType, watch, Ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { PageData, toRequest } from '@/common/page-data'
import { useTimeZone } from '@/plugins/time-zone'
import moment from 'moment'
import { pick } from 'lodash'

export default defineComponent({
  name: 'month-control',
  components: { SearchOutlined },
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    visible: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const value: Ref<any[]> = ref([])

    const picker = ref<any>(false)

    const range = reactive<any>({
      max: undefined,
      min: undefined
    })

    const onSearch = () => {
      const params = toRequest(Object.assign({}, props.pageData.params, { dateFilterByField: props.code }))
      props.pageData.queryFn(params as any).then(data => {
        const { tz } = useTimeZone()
        const item = (data?.rows || [])[0]
        const max = item?.maxTime ? tz.al(item?.maxTime) : undefined
        const min = item?.minTime ? tz.al(item?.minTime) : undefined
        range.max = max
        range.min = min
      })
      console.log(range)
      // options.value = [{ value: keyword }]
    }

    const disabledDate = (d: any) => {
      const max = range.max && moment(range.max)
      const min = range.min && moment(range.min)
      if (min && d.isBefore(min.startOf('day'))) return true
      if (max && d.isAfter(max.endOf('day'))) return true
      return false
    }

    const onReset = () => {
      value.value = []
      props.pageData.unfilter(props.code)
      props.visible.value = false
    }

    const onQuery = () => {
      if (value.value.length < 1) {
        onReset()
      } else {
        props.pageData.filter({ name: props.code, value: value.value })
        props.visible.value = false
      }
    }

    const format = computed(() => {
      return 'YYYY-MM'
    })

    const normalizeDate = (value: any[]) => {
      const [s, e] = value || []
      const sp = s ? s.startOf('month') : undefined
      const ep = e ? e.endOf('month') : undefined
      return [sp, ep]
    }

    const onChange = (v: any[]) => {
      value.value = normalizeDate(v)
    }

    const onPanelChange = (v: any[], mode: string[]) => {
      value.value = normalizeDate(v)
    }

    const onOk = () => {
      if (picker.value) {
        picker.value = false
      }
    }

    const openChange = (val: boolean) => {
      picker.value = !!val
    }

    watch(props.visible as any, () => {
      if (props.visible.value) {
        value.value = props.pageData.params?.filters?.find(v => v.name === props.code)?.value || []
        onSearch()
      }
    })

    return {
      value,
      onSearch,
      onQuery,
      onReset,
      disabledDate,
      format,
      onChange,
      onPanelChange,
      onOk,
      picker,
      openChange
    }
  }
})
