<template>
  <div class="air8-group-actions">
    <c0
      :config="internalActionsConfig"
      :scope="{ ...$attrs, ...$props}"
    ></c0>
  </div>
</template>
<script>
import { computed, defineComponent, ref, isRef, unref } from 'vue'
import _ from 'lodash'
import { useRouter } from 'vue-router'
import { t } from '@/config/locale'
import BuilderFactory from '../../builder/BuilderFactory'

export default defineComponent({
  name: 'Air8GroupActions',
  inheritAttrs: false,
  props: {
    actionsConfig: Array,
    goBack: [Boolean, Number, String, Function, Object]
  },

  setup (props, { attrs }) {
    const actionLoading = ref(false)
    const router = useRouter()

    async function invokeFunction (method, loading, ...methodsArguments) {
      if (!_.isFunction(method)) {
        return
      }

      _.set(loading, 'value', true)
      try {
        await method(...methodsArguments)
      } catch (e) {
        console.error(e)
      }
      _.set(loading, 'value', false)
    }

    function invokeAction (action, ...actionArguments) {
      invokeFunction(action, actionLoading, ...actionArguments)
    }

    function invokeGoBack () {
      const action = props.goBack
      if (action === true) {
        router.go(-1)
      } else if (_.isNumber(action)) {
        router.go(action)
      } else if (_.isFunction(action)) {
        invokeFunction(action)
      } else if (!_.isEmpty(action)) {
        router.push(action)
      }
    }

    function handleClickAction (action) {
      invokeAction(action)
    }

    function handleClickGoBack () {
      invokeGoBack()
    }

    const hasGoBackAction = computed(() => {
      return !_.isNil(props.goBack) && props.goBack !== false
    })

    const internalActionsConfig = computed(() => {
      const builder = BuilderFactory.getInstance('CustomerGroupActionButtonBuilder', props.actionsConfig, { ...props, ...attrs })
      const actionsConfig = builder.getCurrentConfig()
      const config = _.map(actionsConfig, (action) => {
        return {
          onClick: () => {
            handleClickAction(isRef(action.action) ? action.action.value : action.action)
          },
          $type: 'a-button',
          ..._.omit(action, ['action', 'customLoading']),
          loading: computed(() => {
            return actionLoading.value || unref(_.get(action, 'customLoading', false))
          })
        }
      })
      if (hasGoBackAction.value) {
        config.push({
          $type: 'a-button',
          onClick: () => {
            handleClickGoBack()
          },
          $children: t('common.back')
        })
      }
      return config
    })

    return {
      actionLoading,
      internalActionsConfig,
      invokeGoBack
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-group-actions {
  text-align: center;

  /deep/ .ant-btn {
    padding-left: 24px;
    padding-right: 24px;
    margin: 0px 16px;

    &.air8-btn-warning {
      background-color: @color-warning;
      border-color: @color-warning;
      color: white;
    }
    &.air8-btn-danger {
      background-color: @color-danger;
      border-color: @color-danger;
      color: white;
    }
    &.air8-btn-success {
      background-color: @color-success;
      border-color: @color-success;
      color: white;
    }
  }
}
</style>
