
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { CaretDownOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'dropdown-select',
  components: { CaretDownOutlined },
  props: {
    selectOptions: Array,
    trigger: {
      type: String,
      default: 'hover'
    },
    type: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: 'Create Invoice'
    }
  },
  setup () {
    return {}
  }
})
