<template>
  <div>
    <a-space
      class="air8-select-other__editable-container"
      v-if="editable"
    >
      <air8-form-item
        type="Air8DictionarySelect"
        :name="selectName"
        :hide-label="true"
        v-bind="$attrs"
        :dictName="dictName"
        :placeholder="placeholder"
        :rules="selectRules"
        :disabled="disabled"
        :getPopupContainer="getPopupContainer"
      ></air8-form-item>
      <air8-form-item
        v-if="otherInputVisiable"
        type='a-input'
        :name="otherName"
        :hide-label="true"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :rules="internalOtherRules"
        :disabled="disabled"
      >
      </air8-form-item>
    </a-space>
    <air8-form-item
      v-else
      type="Air8Label"
      :hide-label="true"
      :name="otherInputVisiable ? otherName : selectName"
    >
    </air8-form-item>
  </div>
</template>
<script>
import { defineComponent, computed, watch } from 'vue'
import { injectForm } from '@/components/air8/utils/componentUtils'
import _ from 'lodash'
import Rules from '@/components/air8/rules/index'

export default defineComponent({
  name: 'Air8SelectOther',

  props: {
    selectName: String,
    placeholder: String,
    otherName: String,
    otherValue: {
      type: String,
      default: 'OTHER'
    },
    dictName: String,
    maxlength: Number,
    selectRules: Array,
    otherRules: Array,
    editable: Boolean,
    disabled: Boolean,
    getPopupContainer: Function
  },

  setup (props) {
    const form = injectForm()
    const otherInputVisiable = computed(() => {
      return _.get(form.model.value, props.selectName) === props.otherValue
    })

    watch(otherInputVisiable, (curr, prev) => {
      if (curr !== prev) {
        _.set(form.model.value, props.otherName, undefined)
      }
    })

    const internalOtherRules = computed(() => {
      const otherRules = []
      if (_.findIndex(props.selectRules, { required: true }) !== -1) {
        otherRules.push(Rules.required)
      }

      if (_.isEmpty(props.otherRules)) {
        return otherRules
      } else {
        return _.concat(otherRules, props.otherRules)
      }
    })

    return {
      form,
      otherInputVisiable,
      internalOtherRules
    }
  }
})
</script>
<style lang="less" scoped>
.air8-select-other__editable-container {
  width: 100%;
  & > :deep(.ant-space-item) {
    flex-grow: 1;
    width: 40%;
  }

  & > :deep(.ant-space-item + .ant-space-item) {
    width: 100%;
  }
}
</style>
