import { PageData } from '@/common/page-data/index'
import { createVNode, ref, Ref, VNode } from '@vue/runtime-core'
import SumControl from './controls/sum/index.vue'

export function createSum (title: string, code: string, pageData?: PageData<any>, params?: any) {
  if (!pageData) return { title }
  return {
    title: createVNode(SumControl, { pageData, code, title, params })
  }
}
