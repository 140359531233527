import { DictMap } from '@/config/dictionary/options'
import { Module } from 'vuex'

class DictionaryState {
  map: DictMap = {}
}

const app: Module<DictionaryState, unknown> = {
  namespaced: true,
  state: new DictionaryState(),
  mutations: {
    setDictionary (state: DictionaryState, map: DictMap = {}) {
      state.map = map
    }
  },
  getters: {
    options: (state: DictionaryState) => (name: string) => {
      return state.map[name]
    }
  },
  actions: {}
}

export default app
