<template>
  <c0 :config="config" :scope="{ ...$attrs, ...$props }"></c0>
</template>
<script>
import { defineComponent, computed, markRaw } from 'vue'
import { validateForms } from '@/components/air8/utils/componentUtils'
import { getAccountBankList } from '@/api/company'
import _, { lte } from 'lodash'
import Rules from '@/components/air8/rules/index.js'
import BankSelect from '../companyReceiveAccount/bankSelect.vue'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'CompanyReceiveAccountList',

  props: {
    value: [Array],
    isCollection: {
      type: Boolean,
      default: false
    },
    companyCodeList: [String, Array],
    currency: String,
    editable: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => [Rules.required]
    },
    useSequence: {
      type: Boolean,
      default: true
    },
    arrayName: {
      type: String,
      default: 'bankAccounts'
    },
    title: {
      type: String,
      default: () => t('page.repayment.repay.apply.bank.collection')
    },
    hide: Boolean,
    feeAmountList: Array
  },

  emits: ['update:value'],

  data () {
    return {
      currentBankList: [], // model data
      bankSourceList: undefined, // list
      oldSelectData: undefined, // 用户选取的数据
      debounceUpdateBankAccounts: _.debounce(this.updateBankAccounts, 100),
      table: undefined,
      config: this.getConfig(),
      fundSource: { // 资金来源
        INTEREST: 'INTEREST', // 利息
        COLLECTION: 'COLLECTION'
      }
    }
  },

  computed: {
    companyAndCurrency () {
      return {
        companyCodeList: this.companyCodeList,
        currency: this.currency
      }
    }
  },

  watch: {
    companyAndCurrency: {
      handler () {
        this.debounceUpdateBankAccounts()
      },
      immediate: true
    },

    value: {
      handler () {
        this.currentBankList = this.setSelectedOptions(this.value)
      },
      immediate: true
    }
  },

  methods: {
    setSelectedOptions (data) {
      return _.map(data, (item) => {
        return {
          ...item,
          _bankName: item.bankName || '',
          _ids: item.id
        }
      })
    },
    async queryBankAccountsByCompanyList (data) {
      // 如果string，转成数组
      let list = ''
      if (_.isString(data)) {
        list = [data]
      } else {
        list = data || []
      }
      if (_.size(list) < 1) {
        this.clearDataSource()
        return
      }

      try {
        const res = await getAccountBankList({ companyCodes: list })
        if (_.size(this.companyCodeList) < 1) {
          this.clearDataSource()
          return
        }
        this.settingTableData(res, list)
        return
      } catch (err) {
        this.clearDataSource()
      }
    },

    clearDataSource () {
      this.oldSelectData = []
      this.currentBankList = []
      this.bankSourceList = []
      this.$emit('update:value', this.currentBankList)
    },

    settingTableData (res, list) {
      this.oldSelectData = _.cloneDeep(this.currentBankList)
      let oldData = []
      if (_.size(this.oldSelectData) > 0) {
        oldData = _.cloneDeep(this.oldSelectData)
        this.oldSelectData = []
      }

      this.bankSourceList = _.map(list, (c, index) => {
        let found = _.cloneDeep(_.find(res, { code: c }))
        if (found) {
          _.set(found, this.arrayName, this.setSelectOptions(found[this.arrayName], found.code, index))
        } else { // 数据为空时，页面显示空
          found = { code: c }
          _.set(found, this.arrayName, [{ _id: '' }])
        }
        return found
      })

      // model绑定的数据
      this.currentBankList = _.map(this.bankSourceList, (item, index) => {
        const found = _.find(oldData, { companyCode: item.code })
        const info = this.checkoutDefaultItem(found, item, index)
        return _.isEmpty(_.get(found, 'bankCode')) ? info : this.mappingBankAccount(found, found.companyCode, index)
      })
    },

    checkoutDefaultItem (found, item) {
      return _.get(found, '_ids') ? { ..._.get(item, `${this.arrayName}[0]`), id: found._ids } : _.get(item, `${this.arrayName}[0]`)
    },

    async updateBankAccounts () {
      if (!this.editable || (_.isEmpty(this.companyCodeList) && _.isEmpty(this.currency))) {
        return
      }
      if (_.isEmpty(this.companyCodeList)) {
        this.clearDataSource()
        return
      }

      await this.queryBankAccountsByCompanyList(this.companyCodeList)
      this.setDefaultBankAccount()
    },

    setSelectOptions (data, code, index) {
      const list = []
      _.each(data, (k) => {
        // 获取启用的 && 复合当前currency的数据
        if ((k.status === 'ENABLE') && (k.currency === this.currency)) {
          list.push(this.mappingBankAccount(k, code, index))
        }
      })
      if (_.size(list) < 1) {
        list.push({ })
      }
      return list
    },

    handleUpdateValue (value, index) {
      if (!_.isEmpty(value)) {
        this.currentBankList[index] = this.setValueId(value, index)
        this.currentBankList = _.clone(this.currentBankList)
      } else {
        this.currentBankList[index] = {}
      }
      this.$emit('update:value', this.currentBankList)
    },

    setValueId (value, index) {
      const changeValue = _.clone(value)
      const id = _.get(this.currentBankList, `${index}._ids`)
      if (id) {
        _.set(changeValue, 'id', id)
      }
      return changeValue
    },

    isEmptyAccount (account) {
      if (_.get(account, 'payeeBankName')) {
        return _.isEmpty(_.pick(account, ['payeeName', 'payeeBankName', 'payeeBankNameEn', 'payeeAcctNo', 'payeeBankNo', 'payeeCurrency']))
      }
      return _.isEmpty(_.pick(account, ['accountName', 'bankName', 'bankNameEn', 'bankAccount', 'bankCode', 'cnaps', 'currency']))
    },

    generatorBankAccountId (account) {
      if (this.isEmptyAccount(account)) {
        return ''
      }
      if (_.get(account, 'payeeBankName')) {
        return `${account.payeeName}|${account.payeeBankName}|${account.payeeBankNameEn}|${account.payeeAcctNo}|${account.payeeBankNo}|${account.payeeCurrency}`
      }
      return `${account.accountName}|${account.bankName}|${account.bankNameEn}|${account.bankAccount}|${account.bankCode}|${account.currency}`
    },

    mappingBankAccount (account, code, index) {
      if (this.isEmptyAccount(account)) {
        return account
      }
      let bankName = _.get(account, this.$lang.c('bankName', 'bankNameEn'))
      if (_.isEmpty(bankName)) {
        bankName = _.get(account, 'bankNameEn')
      }
      let fundSource = this.fundSource.COLLECTION
      if (_.size(this.feeAmountList) > 0) {
        fundSource = this.feeAmountList[index]
      }
      const params = {
        ...account,
        cnaps: account.cnaps ? account.cnaps : '',
        _id: this.generatorBankAccountId(account),
        _bankName: bankName,
        companyCode: code,
        bankAccountId: account.id || '',
        fundSource
      }
      return _.omit(params, ['id'])
    },

    selectFirstBankAccount (item, index) {
      this.handleChangeBank(item._id, index)
    },

    setDefaultBankAccount () {
      _.each(this.currentBankList, (item, index) => {
        if (_.isEmpty(this.bankSourceList)) {
          this.handleChangeBank(undefined, index)
          return
        }
        this.selectFirstBankAccount(item, index)
      })
    },

    handleChangeBank (value, index) {
      const found = _.find(_.get(this.bankSourceList, `${index}.${this.arrayName}`), { _id: value })
      if (!_.isEmpty(found)) {
        this.handleUpdateValue(found, index)
      }
    },

    async validate () {
      let result = false
      result = await validateForms(
        _.invoke(this.table, 'getForm')
      )
      return result
    },

    generateFunderRecipientBankAccountEditor (label) {
      return {
        type: markRaw(BankSelect),
        name: '_id',
        optionLabelProp: label,
        optionValueProp: '_id',
        $Foptions: (scope) => {
          const num = _.findIndex(this.bankSourceList, { code: _.get(scope, 'record.companyCode') })
          return _.get(this.bankSourceList, `${num < 0 ? null : num}.${this.arrayName}`)
        },
        $FonChange: (rowScope) => {
          return (event) => this.handleChangeBank(event, rowScope.index)
        },
        rules: this.rules
      }
    },

    lt (name, ...ags) {
      return this.$t('page.repayment.info.' + name, ...ags)
    },

    getConfig () {
      const baseColumns = [
        {
          dataIndex: 'accountName',
          title: this.lt('columns.acName'),
          $edit: this.generateFunderRecipientBankAccountEditor('accountName')
        },
        {
          dataIndex: '_bankName',
          title: this.lt('columns.bankName'),
          width: 340,
          $edit: this.generateFunderRecipientBankAccountEditor('_bankName')
        },
        {
          dataIndex: 'bankAccount',
          title: this.lt('columns.bankNo'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankAccount')
        },
        {
          dataIndex: 'bankCode',
          title: this.lt('columns.swiftCode'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankCode')
        },
        {
          dataIndex: 'currency',
          title: this.lt('columns.bankCurrency'),
          $editable: false,
          $dictName: 'CURRENCY'
        }
      ]
      const collectionColumns = [
        {
          dataIndex: 'accountName',
          title: this.lt('columns.acName'),
          $edit: this.generateFunderRecipientBankAccountEditor('accountName')
        },
        {
          dataIndex: '_bankName',
          title: this.lt('columns.bankName'),
          width: 340,
          $edit: this.generateFunderRecipientBankAccountEditor('_bankName')
        },
        {
          dataIndex: 'bankAccount',
          title: this.lt('columns.bankNo'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankAccount')
        },
        {
          dataIndex: 'bankCode',
          title: this.lt('columns.swiftCode'),
          $edit: this.generateFunderRecipientBankAccountEditor('bankCode')
        },
        {
          dataIndex: 'cnaps',
          title: this.lt('columns.cnaps'),
          $edit: this.generateFunderRecipientBankAccountEditor('cnaps')
        },
        {
          dataIndex: 'currency',
          title: this.lt('columns.bankCurrency'),
          $editable: false,
          $dictName: 'CURRENCY'
        }
      ]
      return {
        $if: () => !this.hide,
        $type: 'Air8Card',
        title: computed(() => this.title),
        $children: [{
          $type: 'Air8Table',
          ref: (ref) => {
            this.table = ref
          },
          model: computed(() => this.currentBankList),
          editable: computed(() => this.editable),
          useSequence: computed(() => this.useSequence),
          pagination: false,
          columns: this.isCollection ? collectionColumns : baseColumns
        }]
      }
    }
  }
})
</script>
