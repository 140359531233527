export const YES_NO = {
  YES: 1,
  NO: 0
}

export const YES_NO2 = {
  YES: true,
  NO: false
}

export const WORKFLOW_APPROVAL_STATUS = {
  PASSED: 'PASSED',
  REFUSED: 'REFUSED',
  REJECTED: 'REJECTED',
  IN_APPROVE: 'IN_APPROVE'
}

export const WORKFLOW_APPROVAL_STATUS_IN_LIST = {
  INIT: 'INIT', // 驳回
  PROCESS: 'PROCESS', // 发起处理
  FAIL: 'FAIL', // 处理失败
  SUCCESS: 'SUCCESS' // 处理成功
}

export const WORKFLOW_PROCESS_TYPE = {
  CONFIG_FINANCE_INFO_APPROVAL: 'LC000764',
  FINANCE_WORKFLOW_DEFINATION: 'LC000770',
  FINANCE_ORDER_APPROVAL: 'LC000771',
  CHANGE_INFO_APPROVAL: 'LC000772',
  REPAYMENT_APPROVAL: 'LC000804',
  BALANCE_DISTRIBUTION_APPROVAL: 'LC000801',
  SUPPLIER_REFUND_APPROVAL: 'LC000809',
  PAYMENT_APPROVAL: 'LC000819',
  PAYMENT_BATCH_APPROVAL: 'LC000820',
  BUYER_PAYMENT_APPROVAL: 'LC000821'
}

export const FINANCE_PRODUCT_ID = {
  ARP_WITH_LIMITED_RECOURSE: '1', // 应收账款融资-无追索（装运后）
  ARP_WITH_RECOURSE: '2', // 应收账款融资-有追索（装运后）
  PRE_SHIPMENT_LOAN: '3', // 采购订单融资
  ARP_CONFIRMATION_RAISED_BEFORE_SHIPMENT: '4' // 应收账款融资承诺
}

export const ASSOCIATE_INVOICE_SOURCE_TYPE = {
  INFLOW_APPLY: 'INFLOW_APPLY',
  BATCH_REPAYMENT: 'BATCH_REPAYMENT'
}

export const INFLOW_METHOD = {
  AIR8_INFLOW: 'Air8_INFLOW',
  LEDGER: 'LEDGER'
}

export const IF_QUERY_VERSION = {
  YES: 'YES',
  NO: 'NO'
}

export const REFINITIV_SUBMIT_GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  UNSPECIFIED: 'UNSPECIFIED'
}

export const ALL_KEY = 'ALL'

export const ATTACHEMENT_OCR_TYPE = {
  COMPANY_BL: 'COMPANY_BL',
  PERSONAL_LICENSE: 'PERSONAL_LICENSE'
}

export const SHAREHOLDER_JOB = {
  DIRECTOR: 'DIRECTOR',
  SHAREHOLDER: 'SHAREHOLDER',
  LEGAL_REPRESENTATIVE: 'LEGAL_REPRESENTATIVE'
}

export const BANK_ACCT_STATUS = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  PENDING_ACTIVATE: 'PENDING_ACTIVATE'
}

export const AGING_REPORT_COMPANY_ROLE = {
  SUPPLIER: 'SUPPLIER',
  BUYER: 'BUYER'
}

export const COMPANY_STATUS = {
  IN_KYC: 'IN_KYC'
}

export const MAX_PAGE_SIZE = 20000

export const REFINITIV_TYPE = {
  COMPANY: 'COMPANY',
  KEY_PERSON: 'KEY_PERSON',
  SHAREHOLDER_CORP: 'SHAREHOLDER_CORP'
}

export const NODE_ID = {
  START: '_START',
  END: '结束' // value is ID
}

export const YES_STRING = {
  YES: '1',
  NO: '0'
}

export const TITLE = {
  BD: 'BD'
}
