
import { computed, defineComponent, onMounted, onUnmounted, onUpdated, ref } from 'vue'

export default defineComponent({
  name: 'layout-block',
  props: {
  },
  setup (props: any) {
    return {
    }
  }
})

