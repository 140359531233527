import { useHttp } from '@/plugins/http/src/index'
import store from '@/store'
import { useDict } from '@/plugins/dict'
export type RemoteDataCatalog = 'area' | 'company' | 'contractTemplate' | 'funder' | 'bankName'| 'bankCode'| 'buyer' | 'supplier' | 'product'

const { m } = useDict()

export interface RemoteDataItem {
  code: string;
  name: string;
  parentCode?:string;
  data?: any;
}

export interface Loader {
  (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string): Promise<RemoteDataItem[]>
}

const bankLoader = (params?: any) => {
  const { http } = useHttp()
  return http.post('/api/air8-company/backend/dict/bank', params)
}

const areaLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string) => {
  const { http } = useHttp()
  const params = {
    [lang === 'zh-CN' ? 'nameCn' : 'nameEn']: keyword,
    parentCode,
    code
  }
  return http.post('/api/air8-company/area/list', params).then(data => {
    return (data || []).map((v: any) => {
      return {
        code: v.code,
        name: lang === 'zh-CN' ? v.nameCn : v.nameEn,
        parentCode: v.parentCode,
        data: v
      }
    })
  })
}

const companyLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string) => {
  const { http } = useHttp()
  const params = {
    // [lang === 'zh-CN' ? 'nameCn' : 'nameEn']: keyword,
    // parentCode,
    // code
    codeOrName: keyword
  }
  return http.post('/api/air8-company/backend/company/simpleSearch', params).then(data => {
    return (data || []).map((v: any) => {
      return {
        code: v.code,
        name: v.name,
        parentCode: null,
        data: v
      }
    })
  })
}

const contractTemplateLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string) => {
  const { http } = useHttp()
  const params = {
    // [lang === 'zh-CN' ? 'nameCn' : 'nameEn']: keyword,
    // parentCode,
    // code
    name: keyword,
    pageNum: 1,
    pageSize: 20
  }
  return http.post('/api/air8-contract/template/queryPage', params).then(data => {
    return (data?.list || []).map((v: any) => {
      return {
        code: v.id,
        name: v.name,
        parentCode: null,
        data: v
      }
    })
  })
}

const queryCompaniesByRole = (role: string, keyword?: string, params?: Record<string, any>) => {
  const { http } = useHttp()
  const req = {
    // [lang === 'zh-CN' ? 'nameCn' : 'nameEn']: keyword,
    // parentCode,
    role,
    codeOrName: keyword,
    ...(params || {})
  }
  return http.post('/api/air8-company/backend/company/simpleSearch', req).then(data => {
    return (data || []).map((v: any) => {
      return {
        code: v.code,
        name: v.name,
        parentCode: null,
        data: v
      }
    })
  })
}

const bankNameLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string, params?: any) => {
  const p: any = {}
  if (lang === 'zh-CN') p.combBankNameLocal = keyword
  else p.combBankName = keyword
  return bankLoader(p).then(data => {
    return (data || []).map((v: any) => {
      const name = (lang === 'zh-CN' ? v.combBankNameLocal : v.combBankName) || v.combBankName
      return {
        code: v.swiftCode,
        name,
        data: { code: v.swiftCode, name }
      }
    })
  })
}

const bankCodeLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string, params?: any) => {
  const p: any = { swiftCode: keyword }
  return bankLoader(p).then(data => {
    return (data || []).map((v: any) => {
      const name = (lang === 'zh-CN' ? v.combBankNameLocal : v.combBankName) || v.combBankName
      return {
        code: v.swiftCode,
        name: v.swiftCode,
        data: { code: v.swiftCode, name }
      }
    })
  })
}
// 资金方
const funderLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string) => {
  return queryCompaniesByRole('FUNDER', keyword)
}
// 买方
const buyerLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string, params?: Record<string, any>) => {
  return queryCompaniesByRole('BUYER', keyword, params)
}
// 供应商
const supplierLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string) => {
  return queryCompaniesByRole('SUPPLIER', keyword)
}

// 融资产品
const financeProductLoader = (catalog: RemoteDataCatalog, lang: 'zh-CN' | 'en-US', code?: string, keyword?: string, parentCode?: string, params?: any) => {
  const { http } = useHttp()
  return http.get('/api/air8-financial-transaction/product/list', { params: { name: keyword, pageNum: 1, pageSize: 20 } }).then(res => {
    return (res?.list || []).map((v: any) => {
      return {
        code: v.id,
        name: m.f('FINANCE_PRODUCT_NAME', v.id),
        data: v
      }
    })
  })
}

export const loaderMap: Record<RemoteDataCatalog, Loader> = {
  area: areaLoader,
  company: companyLoader,
  contractTemplate: contractTemplateLoader,
  funder: funderLoader,
  buyer: buyerLoader,
  supplier: supplierLoader,
  product: financeProductLoader,
  bankName: bankNameLoader,
  bankCode: bankCodeLoader
}

export function search (catalog: RemoteDataCatalog, keyword?: string, parentCode?: string, params?: Record<string, any>): Promise<RemoteDataItem[]> {
  const lang = (store.state as any)?.app?.locale?.lang || 'en-US'
  const loader = loaderMap[catalog]
  if (!loader) throw new Error('loader of [' + catalog + '] not found')
  return (loader as any)(catalog, lang, undefined, keyword, parentCode, params)
}

export function retrieve (catalog: RemoteDataCatalog, code: string, parentCode?: string): Promise<RemoteDataItem> {
  const lang = (store.state as any)?.app?.locale?.lang || 'en-US'
  const loader = loaderMap[catalog]
  if (!loader) throw new Error('loader of [' + catalog + '] not found')
  return loader(catalog, lang, code, undefined, parentCode).then(data => data && data[0])
}
