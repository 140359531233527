<template>
  <div class="ant-card ant-card-bordered">
    <div class="ant-card-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Air8SearchListPageDataTableCard'
})
</script>
