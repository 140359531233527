
import { defineComponent, ref, computed, watch } from 'vue'
import _ from 'lodash'
import { PageData, PageQueryFn } from '@/common/page-data'
import QueryConditionsGroup from './QueryConditionsGroup.vue'
import DataTableGroup from './DataTableGroup.vue'
import BuilderFactory from '@/components/air8/builder/BuilderFactory'
import Formatter from '@/components/air8/formatter'

export default defineComponent({
  name: 'Air8SearchListPage',

  aliasName: 'P1',

  components: { QueryConditionsGroup, DataTableGroup },

  props: {
    sid: {
      type: String
    },
    title: {
      type: String
    },
    columns: {
      type: Array,
      default: () => []
    },
    queryConditions: {
      type: Array,
      default: () => []
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    extraActions: {
      type: Array,
      default: () => []
    },
    tableRowActions: {
      type: Array,
      default: () => []
    },
    useSequence: Boolean,
    sequenceKey: String,
    actionKey: {
      type: String,
      default: 'action'
    },
    query: {
      type: [PageData, Function]
    },
    defaultParams: {
      type: Object
    },
    scroll: {
      type: [String, Object]
    },
    nested: Boolean,
    queryColSpan: Number,
    editable: Boolean,
    summary: [Array, Object, Function],
    showPage: {
      type: Boolean,
      default: true
    },
    rowClassName: Function,
    tableNested: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    sorterable: {
      type: Boolean,
      default: true
    },
    sumerable: {
      type: Boolean,
      default: true
    },
    wrapBrackets: {
      type: Boolean,
      default: true
    },
    allowQueryConditionInRouter: {
      type: Array
    },
    rowKey: [String, Function],
    pageSizeOptions: Array,
    rowSelection: [Boolean, Object],
    disabledSelection: [Array, Function],
    selection: Array,
    nestedType: String,
    defaultQuery: Object,
    hideQueryCondition: Boolean,
    showFilterTag: Boolean,
    requiredMark: Boolean,
    emptyText: String
  },

  emits: ['update:selection', 'query'],

  setup (props: any, context: any) {
    const pageData = ref(getPageData())
    function getPageData () {
      if (props.query instanceof PageData) {
        return props.query
      } else if (_.isFunction(props.query)) {
        const queryFunc: PageQueryFn<any> = (params: any) => {
          return props.query(params).then((data: any) => {
            return {
              page: _.get(data, 'pageNum', _.get(data, 'page')) || 1,
              size: _.get(data, 'pageSize', _.get(data, 'size')) || 20,
              total: _.get(data, 'total', _.get(data, 'totalNum')) || 0,
              rows: _.get(data, 'list', _.get(data, 'items', _.get(data, 'list.list', _.get(data, 'rows')))) || []
            }
          })
        }
        return new PageData(queryFunc, props.defaultQuery)
      }
      return props.query
    }

    watch([() => props.query, () => props.defaultQuery], () => {
      pageData.value = getPageData()
    })

    const scope = computed(() => ({
      ...props,
      ...context.attrs
    }))

    const internalColumns = ref(getInternalColumn())

    function getInternalColumn () {
      return _.clone(props.columns)
    }

    const internalQueryConditions = ref(getInternalQueryConditions())

    function getInternalQueryConditions () {
      if (_.isEmpty(props.queryConditions) && _.isEmpty(context.slots['query-conditions'])) {
        const builder = BuilderFactory.getInstance('SearchTablePageQueryCondition', props.columns, scope.value)
        return builder.getCurrentConfig()
      } else {
        return props.queryConditions
      }
    }

    watch([() => props.queryConditions, () => props.columns, () => scope.value], () => {
      const queryConditions = getInternalQueryConditions()
      if (!_.isEqualWith(queryConditions, internalQueryConditions.value)) {
        internalQueryConditions.value = queryConditions
      }
    })

    const internalTableColumns = computed(() => getInternalTableColumns())

    function getInternalTableColumns () {
      if (_.isEmpty(props.tableColumns)) {
        return props.columns
      } else {
        return props.tableColumns
      }
    }

    function reload () {
      return pageData.value.reload()
    }

    const queryConditionGroup = ref(null)

    function resetParams () {
      if (queryConditionGroup.value) {
        (queryConditionGroup.value as any).resetParams()
      }
    }

    const lastConditionQuery = ref({})
    function handleQuery (params: any) {
      lastConditionQuery.value = params
      context.emit('query', params)
    }

    function initLoad () {
      return load({})
    }
    const querySize = computed(() => _.size(internalQueryConditions.value))

    function load (params: any) {
      return pageData.value.load(params)
    }

    function loadLastQuery () {
      return load(lastConditionQuery.value)
    }

    return {
      pageData,
      internalColumns,
      internalQueryConditions,
      internalTableColumns,
      reload,
      queryConditionGroup,
      resetParams,
      handleQuery,
      querySize,
      initLoad,
      load,
      loadLastQuery
    }
  },

  computed: {
    allowRouterQuery () {
      if (_.isEmpty(this.allowQueryConditionInRouter)) {
        return this.$route.query
      }

      return _.pick(this.$route.query, this.allowQueryConditionInRouter as string[])
    },

    queryConditionNames () {
      return _.map(this.internalQueryConditions, (condition: any) => condition.prop)
    },

    internalParams () {
      if (this.nested || this.tableNested) {
        return undefined
      }
      let params = this.allowRouterQuery
      if (_.isEmpty(params)) {
        params = this.defaultParams
      }
      return _.pick(params, this.queryConditionNames)
    },

    dataTableGroupSlotKeys () {
      return _.keys(_.omit(this.$slots, ['query-conditions']))
    },

    internalFilters () {
      if (this.nested || this.tableNested) {
        return undefined
      }
      let params = this.allowRouterQuery
      if (_.isEmpty(params)) {
        params = this.defaultParams
      }
      return _.omit(params, _.concat(['isEqual'], this.queryConditionNames))
    },

    internalOnceParams () {
      if (this.nested || this.tableNested) {
        return undefined
      }

      let params = this.allowRouterQuery
      if (_.isEmpty(params)) {
        params = this.defaultParams
      }
      return _.pick(params, ['isEqual'])
    },

    selectedFilters () {
      const filter = _.get(this.pageData, 'params.filters', [])
      return _.map(filter, (item) => {
        const col = this.getColumnsByFilter(item.name)
        const name = _.get(col, 'title')
        let value = item.value
        switch (_.get(col, '$propType')) {
          case 'date':
            value = `${this.$tz.f(_.get(item.value, '0'), 'D')} - ${this.$tz.f(_.get(item.value, '1'), 'D')}`
            break
          case 'enum':
            value = Formatter.dict(value, _.get(col, '$dictName'))
            break
          default:
            value = _.replace(value, /\*(.*)/g, '$1')
            value = _.replace(value, /(.*)\*/g, '$1')
        }

        return {
          label: `${name} : ${value}`,
          value: item
        }
      })
    },

    internalShowFilterTag () {
      return this.showFilterTag && !_.isEmpty(this.selectedFilters)
    }
  },

  methods: {
    getTable () {
      return (this.$refs.tableGroupRef as any).getTable()
    },

    getDataTable () {
      return (this.$refs.tableGroupRef as any).getDataTable()
    },

    getTableForm () {
      return (this.$refs.tableGroupRef as any).getForm()
    },

    getTableRealColumns () {
      return _.get(this.$refs.tableGroupRef, 'internalTableColumns')
    },

    handleUpdateSelection (e: any[]) {
      this.$emit('update:selection', e)
    },

    getColumnsByFilter (filterName: string) {
      const columns = this.getTableRealColumns()
      return _.find(columns, (col: any) => {
        return _.get(col, '$filter.field') === filterName || _.get(col, 'dataIndex') === filterName
      })
    },

    handleCloseFilterTag (filter: any) {
      this.pageData.unfilter(filter.name)
    }
  }
})
