<template>
  <air8-string-input-number
    class="air8-string-money"
    v-bind="$attrs"
    :value="value"
    :min="min"
    :max="max"
    :min-precision="precision"
    :max-precision="precision"
    :is-use-group-seperator="true"
    @update:value="handleUpdateValue"
    @blur="handleBlur"
    @change="handleChange"
    @focus="handleFocus"
  >
  </air8-string-input-number>
</template>

<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'Air8StringMoney',

  props: {
    value: [String, Number],
    max: [String, Number],
    min: {
      type: [String, Number],
      default: 0
    },
    currency: String
  },

  emits: ['update:value', 'blur', 'change', 'focus'],

  computed: {
    precision () {
      return this.currency === 'JPY' ? 0 : 2
    }
  },

  methods: {
    handleUpdateValue (e) {
      this.$emit('update:value', e)
    },

    handleBlur () {
      this.$emit('blur')
    },

    handleChange (e) {
      this.$emit('change', e)
    },

    handleFocus () {
      this.$emit('focus')
    }
  }
})
</script>
