import { useLocal } from '@/plugins/locale'
import { useDict } from '@/plugins/dict'
import { useTimeZone } from '@/plugins/time-zone'

export function dateFormatter (value: any) {
  const { tz } = useTimeZone()
  return tz.f(value, 'D')
}

export function numberFormatter (value: any, precision = 2, unit?: 'D' | any) {
  if ([undefined, null].includes(value)) return '-'
  const { t } = useLocal()
  const map: any = {
    D: t('common.day')
  }
  const u = unit ? (map[unit] || unit) : ''
  const reg1 = /(\d)(?=(\d{3})+(\.))/g
  const reg2 = /(\d)(?=(\d{3})+($))/g
  const v = Number(value).toFixed(precision)
  const hasDot = (v + '').includes('.')
  return v.replace(hasDot ? reg1 : reg2, '$1,') + u
}

export function precentFormatter (value: any, precision = 2) {
  return Number(value).toFixed(precision) + '%'
}

export function dictFormatter (value: any, name: string) {
  const { m } = useDict()
  return m.f(name, value)
}

export function suffixFormatter (value: any, suffix: 'D' | 'P' | string) {
  if ([null, undefined, ''].includes(value)) return '-'
  const { t } = useLocal()
  const map: any = {
    D: t('common.day'),
    P: '%'
  }
  const f = map[suffix] || suffix
  return `${value}${f}`
}

/**
 * 文本格式化
 * 如果为空，将转化为 -
 * @param value
 * @returns
 */
export function textFormatter (value: any) {
  if (!value) return '-'
  return value
}
