
import { Module } from 'vuex'

export class AppState {
  collapsed = false // 菜单是否展开

  locale: Record<string, any> = {}

  timeZone = '+8'

  timeFormat = 'YYYY/MM/DD'

  flag = 0

  compatibleTimeFormat = 'YYYY/MM/DD'
}

const app: Module<AppState, unknown> = {
  namespaced: true,
  state: new AppState(),
  mutations: {
    setCollapsed (state: AppState, collapsed: boolean) {
      state.collapsed = collapsed
    },
    setLocale (state: AppState, locale: Record<string, any>) {
      state.locale = locale
    },
    setTimeZone (state: AppState, timeZone: string) {
      state.timeZone = timeZone
    },
    setTimeFormat (state: AppState, timeFormat: string) {
      state.timeFormat = timeFormat
    },
    setJump (state: AppState, flag: number) {
      state.flag = flag
    },
    setCompatibleTimeFormat (state: AppState, timeFormat: string) {
      state.compatibleTimeFormat = timeFormat.replace(/-/g, '/')
    }
  },
  getters: {},
  actions: {}
}

export default app
