import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!

  return _withDirectives((_openBlock(), _createBlock(_component_a_col, { span: 8 }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.wrap]),
            ref: "wrapEle"
          }, [
            _createVNode(_component_a_input, {
              value: _ctx.form[_ctx.prop],
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form[_ctx.prop] = $event)),
              placeholder: _ctx.$t('common.placeholder_input', _ctx.label)
            }, null, 8, ["value", "placeholder"]),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.label]),
              ref: "labelEle"
            }, _toDisplayString(_ctx.label), 3)
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512)), [
    [_vShow, _ctx.visible]
  ])
}