import * as formatter from '@/common/formatter'
import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { useLocal } from '@/plugins/locale'
import { t } from '@/config/locale'
import { useTimeZone } from '@/plugins/time-zone'
import * as NumberUtils from '@/utils/number'
import { useDict } from '@/plugins/dict'

function dateFormatterEmpty (value: any, empty = '-') {
  if (_.isNil(value) || value === '') {
    return empty
  } else {
    return formatter.dateFormatter(value) as string
  }
}

function timeFormatterEmpty (value: any, empty = '-') {
  if (_.isNil(value) || value === '') {
    return empty
  } else {
    if (!value) return '-'
    const { tz } = useTimeZone()
    return tz.f(value, 'T')
  }
}

export function dictFormatter (value: any, name: string, defaultText?: any) {
  const { m } = useDict()
  return m.fd(name, value, defaultText)
}

const DEFAULT_FORMAT_OPTION = {
  // string to prepend
  prefix: '',
  // decimal separator
  decimalSeparator: '.',
  // grouping separator of the integer part
  groupSeparator: ',',
  // primary grouping size of the integer part
  groupSize: 3,
  // secondary grouping size of the integer part
  secondaryGroupSize: 0,
  // grouping separator of the fraction part
  fractionGroupSeparator: ' ',
  // grouping size of the fraction part
  fractionGroupSize: 0,
  // string to append
  suffix: ''
}

function numberFormatterEmpty (value: any, precision = 2, unit?: 'D' | any, empty = '-', formatOption?: any) {
  if (_.isNil(value) || value === '') {
    return empty
  } else {
    const { t } = useLocal()
    const map: any = {
      D: t('common.day')
    }
    const u = unit ? (map[unit] || unit) : ''

    const num = new BigNumber(value)
    if (num.isNaN()) {
      return value + u
    }
    if (!_.isEmpty(formatOption)) {
      formatOption = _.defaults(_.clone(formatOption), DEFAULT_FORMAT_OPTION)
    }
    return num.toFormat(precision, formatOption) + u
  }
}

function numberFormatterMaxPrecisionEmpty (value: any, maxPrecision?: number, empty = '-', formatOption?: any) {
  if (_.isNil(value) || value === '') {
    return empty
  }

  const num = new BigNumber(value) as any
  if (num.isNaN()) {
    return value
  }

  const decimalPlaces = num.dp()

  if (!_.isEmpty(formatOption)) {
    formatOption = _.defaults(_.clone(formatOption), DEFAULT_FORMAT_OPTION)
  }

  if (!_.isNil(maxPrecision) && !_.isNil(decimalPlaces) && decimalPlaces > maxPrecision) {
    return num.toFormat(maxPrecision, formatOption)
  } else {
    return num.toFormat(formatOption)
  }
}

function numberFormatterPrecisionEmpty (value: any, precision?: number, integerNotPrecision = false, empty = '-') {
  if (_.isNil(value) || value === '') {
    return empty
  }

  const num = new BigNumber(value)
  if (num.isNaN()) {
    return value
  }

  if (integerNotPrecision && num.isInteger()) {
    return num.toFormat(0)
  } else {
    return num.toFormat(precision)
  }
}

// OPTIMIZE
class Formatter {
  public blank () {
    // use for table summary, that column not need to summary
    return ''
  }

  public empty (value: any, empty = '-') {
    return _.isNil(value) || value === '' ? empty : value
  }

  public date (value: any) {
    return dateFormatterEmpty(value)
  }

  public time (value: any, empty = '-') {
    return timeFormatterEmpty(value, empty)
  }

  public dict (value: any, dictName: any, defaultValue?: any) {
    if (_.isArray(value)) {
      if (_.isEmpty(value)) {
        return this.empty(undefined)
      }
      return _.join(_.map(value, (item: any) => {
        return dictFormatter(item, dictName)
      }), ',')
    } else if (_.isNil(value) || value === '') {
      return this.empty(undefined)
    } else {
      return dictFormatter(value, dictName, defaultValue)
    }
  }

  public dictWithEmpty (value: any, dictName: any, empty = '-') {
    if (_.isArray(value)) {
      if (_.isEmpty(value)) {
        return this.empty(undefined, empty)
      }
      return _.join(_.map(value, (item: any) => {
        return formatter.dictFormatter(item, dictName)
      }), ',')
    } else if (_.isNil(value) || value === '') {
      return this.empty(undefined, empty)
    } else {
      return formatter.dictFormatter(value, dictName)
    }
  }

  public currency (value: any, currencyType = '') {
    if (_.isNil(value) || value === '') {
      return this.empty(undefined)
    } else if (_.isEmpty(currencyType)) {
      return numberFormatterEmpty(value, 2, false)
    } else {
      const precision = currencyType === 'JPY' ? 0 : 2
      return `${formatter.dictFormatter(currencyType, 'CURRENCY')} ${numberFormatterEmpty(value, precision, false)}`
    }
  }

  public minCurrency (value: any, currencyType = '', min = 0) {
    if (NumberUtils.isNumber(value) && Number(value) < min) {
      value = min
    }
    return this.currency(value, currencyType)
  }

  public money (value: any, currencyType = '') {
    if (_.isNil(value) || value === '') {
      return this.empty(undefined)
    } else if (_.isEmpty(currencyType)) {
      return numberFormatterEmpty(value, 2, false)
    } else {
      const precision = currencyType === 'JPY' ? 0 : 2

      return numberFormatterEmpty(value, precision, false)
    }
  }

  public minMoney (value: any, currencyType = '', min = 0) {
    if (NumberUtils.isNumber(value) && Number(value) < min) {
      value = min
    }
    return this.money(value, currencyType)
  }

  public displayMoney (value: any, currencyType = '', display = true) {
    if (!display) {
      return this.empty(undefined)
    }
    return this.money(value, currencyType)
  }

  public displayMoneyWhenHasCurrencyType (value: any, currencyType: string, isSummary = false) {
    return this.displayMoney(value, currencyType, !isSummary || !_.isEmpty(currencyType))
  }

  public displayCurrency (value: any, currencyType = '', display = true) {
    if (!display) {
      return this.empty(undefined)
    }
    return this.currency(value, currencyType)
  }

  public number (value: any) {
    return numberFormatterMaxPrecisionEmpty(value)
  }

  public normalNumber (value: any) {
    return numberFormatterMaxPrecisionEmpty(value, undefined, undefined, { groupSeparator: '' })
  }

  public precent (value: any, num = 2) {
    return numberFormatterEmpty(value, num, '%', undefined, { groupSeparator: '' })
  }

  public percent (value: any, num = 2) {
    return numberFormatterEmpty(value, num, '%', undefined, { groupSeparator: '' })
  }

  public exchange (value: any) {
    return numberFormatterPrecisionEmpty(value, 4, true)
  }

  public exchangeWithCurrency (currencyPrefix: any, exchangePrefix: any, currencySuffix: any, exhcnageSuffix: any) {
    if (_.isNil(currencyPrefix) || _.isNil(exchangePrefix) || _.isNil(currencySuffix) || _.isNil(exhcnageSuffix)) {
      return this.empty(undefined)
    }
    if (currencyPrefix === currencySuffix) {
      return '-'
    } else {
      return `${this.exchange(exchangePrefix)}:${this.exchange(exhcnageSuffix)}`
    }
  }

  public exchangeWithCurrencyNew (value:any, currencyPrefix: any, exchangePrefix: any, currencySuffix: any, exhcnageSuffix: any) {
    if (_.isEmpty(currencyPrefix) || _.isNil(exchangePrefix) || _.isEmpty(currencySuffix) || _.isNil(exhcnageSuffix)) {
      return this.empty(undefined)
    }
    if (currencyPrefix === currencySuffix) {
      return '-'
    } else {
      return `${this.exchange(exchangePrefix)}:${this.exchange(exhcnageSuffix)}`
    }
  }

  public exchangeWithCurrencyType (value:any, currencyPrefix: any, exchangePrefix: any, currencySuffix: any, exhcnageSuffix: any) {
    if (_.isNil(currencyPrefix) || _.isNil(exchangePrefix) || _.isNil(currencySuffix) || _.isNil(exhcnageSuffix)) {
      return this.empty(undefined)
    }
    if (currencyPrefix === currencySuffix) {
      return '-'
    } else {
      return `${this.exchange(exchangePrefix)} ${formatter.dictFormatter(currencyPrefix, 'CURRENCY')} = ${this.exchange(exhcnageSuffix)} ${formatter.dictFormatter(currencySuffix, 'CURRENCY')}`
    }
  }

  public supplierName (value: any, supplierCode: any) {
    if (_.isEmpty(supplierCode) && !_.isNil(value)) {
      return t('common.unallocateSupplier')
    } else {
      return this.empty(value)
    }
  }

  public precisionNumber (value: any, precision: number) {
    return numberFormatterEmpty(value, precision)
  }

  public mutilDictionary (value: any, name: string) {
    if (_.isNil(value) || value === '') {
      return this.empty(undefined)
    } else {
      return _.chain(value).split(',').map((text) => formatter.dictFormatter(text, name)).join(',').value()
    }
  }

  public rate (value: any, precision = 2) {
    return numberFormatterEmpty(value, precision, undefined, undefined, { groupSeparator: '' })
  }

  public baseShowRateType (rate: string, type: string, baseType: string, value: string|number) {
    // rate-利率类型、 type-浮动利率类型、baseType-基准利率类型、value-利率年化
    if (_.isNil(rate) || rate === '') {
      return this.empty(undefined)
    } else if (rate === 'FLOATING') {
      // 利率类型：cof+1.00%
      const sign = type === 'UP' ? '+' : '-'
      return `${formatter.dictFormatter(baseType, 'BASE_RATE_TYPE')} ${sign} ${this.precent(value)}`
    } else {
      return formatter.dictFormatter(rate, 'RATE_TYPE')
    }
  }

  public industry (value: string) {
    if (_.isNil(value) || value === '') {
      return this.empty(undefined)
    } else {
      return `${value} | ${formatter.dictFormatter(value, 'SUPPLIER_CATEGORY')}`
    }
  }
}

export default _.defaults(new Formatter(), formatter)
