import { App } from 'vue'
import Auth, { AuthOptions } from './Auth'

let auth: Auth

export const useAuth = () => {
  return { auth }
}

export default function install (app: App, options: AuthOptions) {
  auth = new Auth(options)
  app.config.globalProperties.$auth = auth
}
