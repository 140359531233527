<template>
  <a-radio-group
    v-bind="internalProps"
    :options="internalOptions"
    :value="value"
  >
    <template v-if="doubleClickCancel">
      <slot>
        <a-radio
          :ref="setRadioRef"
          v-for="(item, i) in internalOptions"
          :key="i"
          :value="item.value"
          @click="handleClickOption($event, item)"
        >{{ item.label }}</a-radio>
      </slot>
    </template>
  </a-radio-group>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { useDict } from '@/plugins/dict'
const { m } = useDict()

export default defineComponent({
  name: 'Air8RadioGroup',
  aliasName: 'a8-d-radio-group',
  inheritAttrs: false,
  props: {
    doubleClickCancel: Boolean,
    value: [Boolean, Number, String, Object],
    dictName: String
  },

  data () {
    return {
      radios: []
    }
  },

  computed: {
    internalProps () {
      if (this.doubleClickCancel) {
        return _.omit(this.$attrs, ['options'])
      } else {
        return this.$attrs
      }
    },

    internalOptions () {
      let options = this.$attrs.options
      if (_.isNil(options) && !_.isEmpty(this.dictName)) {
        options = m.t(this.dictName)
      }
      return _.map(options, (item) => {
        if (_.isString(item)) {
          return { label: item, value: item }
        } else {
          return item
        }
      })
    }
  },

  methods: {
    setRadioRef (el) {
      this.radios.push(el)
    },

    handleClickOption (event, item) {
      if (event.target.tagName.toLowerCase() === 'input' && this.value === item.value) {
        this.$emit('update:value', null)
      }
    }
  }
})
</script>
