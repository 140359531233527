import pages, { PageInstance } from '@/config/page'
import { Principle, LoginParams, Authority } from './src/Auth'
import AuthPlugin from './src'
import { App } from 'vue'
import { DictMap } from '@/config/dictionary/options'
import { localDictMap, merge } from '@/config/dictionary'
import store from '@/store'
import JSEncrypt from 'jsencrypt'
import { loadLocale } from '@/api/locale'
import { mapping } from '@/config/page/perm-map'
import { changeRole } from '@/api/home'

const encryptor = new JSEncrypt()
encryptor.setPublicKey(process.env?.VUE_APP_PUB_KEY)

// 是否在开发环境开启 本地全量权限
const enabledLocalAuthorities = true

function genLocalAuthorities (pages: PageInstance[]): Authority[] {
  const ret: Authority[] = []
  pages.forEach(v => {
    ret.push({ pid: v.path })
    if (v.children && v.children?.length > 0) ret.push(...genLocalAuthorities(v.children))
  })
  return ret
}

export function checkRole (user: any) {
  const url = new URL(location.href)
  const role = url.searchParams.get('__role')
  if (role && role.toUpperCase() !== user?.companyRole) {
    url.searchParams.delete('__role')
    changeRole(role.toUpperCase()).then(() => {
      location.assign(url.href)
    })
  }
}

export default function (app: App) {
  app.use(AuthPlugin, {
    principleLoader: function (): Promise<Principle> {
      const http = app.config.globalProperties.$http
      const all = [
        http.get('/api/air8-financial-transaction/air8PoolFinanceOrder/client/getCompanyBusinessType'),
        http.get('/api/air8-auth-center/userClient/queryUserInfoByUserId'),
        http.get('/api/air8-auth-center/permission/menus'),
        http.get('/api/air8-company/clientend/company/detail'),
        http.post('/api/air8-company/backend/dict/list', {}),
        http.post('/api/air8-company/area/list', { type: 'COUNTRY' })]
      return Promise.all(all).then(([companyBusinessType, user, perms = [], companyBaseInfo, dict, country]: any[]) => {
        checkRole(user)
        return Promise.all([loadUserSetting(user)]).then(() => {
          initDictionary(dict, user?.language, country)
          localStorage.setItem('_lang', user?.language || 'en-US')
          localStorage.setItem('authentication-userid', JSON.stringify({ userid: user.id }))
          localStorage.setItem('poolActive', companyBusinessType)

          return {
            user: {
              username: user.id,
              cname: user.userName,
              ...user
            },
            companyBaseInfoDetail: companyBaseInfo,
            roles: [], // (authority.roles || []).map((v: any) => Object.assign({}, { roleCode: v.code, roleName: v.roleName })),
            // authorities: (enabledLocalAuthorities && process.env.NODE_ENV === 'development')
            //   ? genLocalAuthorities(pages) : normalizeAuthorities(authority.perms)
            authorities: mapping(perms)
          }
        })
      })
    },
    logoutHandler: function (): Promise<string> {
      const http = app.config.globalProperties.$http
      const params = {
        token: JSON.parse(localStorage.getItem('authentication') || '{}')?.token,
        appId: process.env.VUE_APP_APP_ID
      }
      return http.delete('/api/air8-auth-center/login/logout', { params })
    },
    loginHandler: function (params: LoginParams): Promise<any> {
      const http = app.config.globalProperties.$http
      const req = {
        userName: encryptor.encrypt(params.username),
        password: encryptor.encrypt(params.password),
        loginName: params.loginName,
        type: '1'
      }
      return http.post('/api/air8-auth-center/login', req)
    }
  })

  function normalizeAuthorities (perms: any[] = [], parent?: Authority): Authority[] {
    const ret: Authority[] = []
    perms.forEach(v => {
      const item = {
        pid: (parent?.pid || '') + '/' + v.code
      }
      ret.push(item)
      if (v.children?.length > 0) ret.push(...normalizeAuthorities(v.children, item))
    })
    return ret
  }
}

function initDictionary (dictionary: any[] = [], lang = 'en-US', countryList: any[]) {
  const remoteMap: DictMap = {}
  dictionary.forEach((v: any) => {
    const name = v.grp
    if (!remoteMap[name]) remoteMap[name] = []
    remoteMap[name].push({
      key: v.dicKey,
      value: lang === 'en-US' ? v.enValue : v.cnValue,
      name,
      description: v.description,
      status: true,
      seq: 1
    })
  })
  const map:any = merge(remoteMap, localDictMap)
  map.COUNTRY = countryList.map(v => {
    return {
      key: v.code,
      value: lang === 'en-US' ? v.nameEn : v.nameCn
    }
  })
  store.commit('dictionary/setDictionary', map)
  console.log('dict: ', map)
}

const LANG = ['zh-CN', 'en-US']

function loadUserSetting (user: any = {}): Promise<any> {
  const lang = LANG.includes(user?.language) ? user?.language : 'zh-CN'
  const timeZone = user?.timeZone || '+8'
  const timeFormat = user?.timeFormat || 'YYYY/MM/DD'
  Object.assign(user, { timeFormat })
  const localeFn = () => loadLocale(lang)
  return localeFn().then(locale => {
    store.commit('app/setLocale', locale)
    store.commit('app/setTimeZone', timeZone)
    store.commit('app/setTimeFormat', timeFormat)
    store.commit('app/setCompatibleTimeFormat', timeFormat)
  }).catch(e => {
    console.error(e)
    return Promise.reject(e)
  })
}
