<template>
  <a-row
    class="air8-ocr-upload-list"
    :gutter="[8, 6]"
  >
    <a-col
      :xs="24"
      :md="12"
      :xl="8"
      v-for="(file, index) in fileList"
      :key="file.uid"
    >
      <UploadDoneCard
        class="air8-ocr-upload-list__card"
        :file="file"
        :title="getDoneTitle(index)"
        :description="getDoneDescription(index)"
        @remove="handleRemoveFile(index)"
      >
      </UploadDoneCard>
    </a-col>

    <a-col
      :xs="24"
      :md="12"
      :xl="8"
      key="OcrUploadCard"
      v-show="isShowUploadCard"
    >
      <OcrUploadCard
        ref="ocrUploadCardRef"
        class="air8-ocr-upload-list__card"
        :file-list="fileList"
        :type="type"
        :ocr-type="type"
        :title="internalUploadTitle"
        :description="internalUploadDescription"
        :maxlength="maxlength"
        :schemaCode="internalSchemaCode"
        :loadingMessage="loadingMessage"
        :multiple="true"
        @update:file="handleUploadFile"
        @update:file-list="handleUploadFileList"
        @update:ocr-result-list="handleUpdateOcrResultList"
        @file-error="handleFileError"
        @ocr-error="handleOcrError"
        @ocr-success="handleOcrSuccess"
        @update:loading="handleLoading"
      >
      </OcrUploadCard>
    </a-col>
  </a-row>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue'
import _ from 'lodash'
import UploadDoneCard from './OcrUploadDoneCard.vue'
import OcrUploadCard from './OcrUploadCard.vue'

export default defineComponent({
  name: 'Air8OcrUploadList',
  components: {
    UploadDoneCard,
    OcrUploadCard
  },

  props: {
    fileList: Array,
    type: Array,
    ocrType: Array,
    uploadTitle: [String, Array],
    uploadDescription: [String, Array],
    doneTitle: [String, Array],
    doneDescription: [String, Array],
    schemaCode: [String, Array],
    ocrKeys: Array,
    maxlength: Number,
    loadingMessage: String,
    debug: Boolean,
    customOcr: Boolean
  },

  emits: ['update:file', 'update:file-list', 'update:ocr-result-list', 'file-error', 'ocr-success', 'ocr-error', 'update:loading'],

  setup (props, { emit }) {
    const ocrUploadCardRef = ref()
    const isShowUploadCard = computed(() => {
      return _.isNil(props.maxlength) || props.maxlength > _.size(props.fileList)
    })

    function getString (text, index, defaultText) {
      if (_.isEmpty(text)) {
        return defaultText
      } else if (_.isArray(text)) {
        return _.nth(text, index % _.size(text))
      } else {
        return text
      }
    }

    const internalUploadTitle = computed(() => {
      const index = _.size(props.fileList)
      return getString(props.uploadTitle, index)
    })

    const internalUploadDescription = computed(() => {
      const index = _.size(props.fileList)
      return getString(props.uploadDescription, index)
    })

    const internalSchemaCode = computed(() => {
      if (props.customOcr) {
        return undefined
      }

      const index = _.size(props.fileList)
      return getString(props.schemaCode, index)
    })

    function getDoneTitle (index) {
      return getString(props.doneTitle, index, _.get(_.nth(props.fileList, index), 'name'))
    }

    function getDoneDescription (index) {
      return getString(props.doneDescription, index)
    }

    function handleUploadFile (e) {
      emit('update:file', e)
    }

    function handleUploadFileList (e) {
      emit('update:file-list', e)
    }

    function handleUpdateOcrResultList (e) {
      if (_.isEmpty(props.ocrKeys)) {
        emit('update:ocr-result-list', undefined)
      }
      const values = _.flatten(e)
      const result = _.reduce(props.ocrKeys, (result, key) => {
        const index = _.findIndex(values, (item) => {
          return _.has(item, key)
        })
        if (index !== -1) {
          const value = _.get(values, [index, key])
          _.set(result, key, value)
          _.pullAt(values, [index])
        }
        return result
      }, {})
      emit('update:ocr-result-list', result)
    }

    function handleFileError (e) {
      emit('file-error', e)
    }

    function handleOcrError (e) {
      emit('ocr-error', e)
    }

    function handleOcrSuccess (e) {
      emit('ocr-success', e)
    }

    function handleRemoveFile (index) {
      ocrUploadCardRef.value.remove(index)
    }

    async function ocrAllFiles () {
      const ocrResult = await ocrUploadCardRef.value.ocrAllFiles(props.schemaCode)
      const values = _.reverse(_.flatten(ocrResult))
      return _.reduce(props.ocrKeys, (result, key) => {
        const index = _.findIndex(values, (item) => {
          return !_.isNil(_.get(item, key))
        })
        if (index !== -1) {
          const value = _.get(values, [index, key])
          _.set(result, key, value)
          _.pullAt(values, [index])
        }
        return result
      }, {})
    }

    function handleLoading (e) {
      emit('update:loading', e)
    }

    return {
      ocrUploadCardRef,
      isShowUploadCard,
      internalUploadTitle,
      internalUploadDescription,
      internalSchemaCode,
      getDoneTitle,
      getDoneDescription,
      handleUploadFile,
      handleUploadFileList,
      handleUpdateOcrResultList,
      handleFileError,
      handleOcrError,
      handleOcrSuccess,
      handleRemoveFile,
      ocrAllFiles,
      handleLoading
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';
.air8-ocr-upload-list__card {
  &.ant-card,
  :deep(.ant-card) {
    text-align: center;
    // height: 260px;
    // max-height: 260px;
    min-height: 260px;
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover {
      border-color: @color-primary;
      color: @color-primary;
    }
  }

  &.ant-card :deep(.ant-card-body),
  :deep(.ant-card .ant-card-body) {
    padding: 14px 4px 18px 4px;
    width: 100%;
    cursor: default;
    .ant-card-meta-title {
      color: inherit;
    }
    .ant-card-meta-description {
      color: inherit;
    }
  }
}
</style>
