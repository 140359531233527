<script>
import { defineComponent, getCurrentInstance, inject, provide, resolveDynamicComponent, h } from 'vue'
import _ from 'lodash'
import { injectParent } from './cardService'

export default defineComponent({
  name: 'Air8AutoCard',
  inheritAttrs: false,

  setup () {
    const { parent } = injectParent()

    let cardType = 'Air8Card'
    if (!_.isNil(parent)) {
      cardType = 'Air8InnerCard'
    }

    return {
      cardType
    }
  },

  render () {
    return h(resolveDynamicComponent(this.cardType), this.$attrs, this.$slots)
  }
})
</script>
