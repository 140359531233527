
import { defineComponent, onMounted, ref, reactive, computed, PropType, watch, Ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { PageData, toRequest } from '@/common/page-data'
import { useTimeZone } from '@/plugins/time-zone'
import moment from 'moment'

export default defineComponent({
  name: 'date-control',
  components: { SearchOutlined },
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    visible: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const value: Ref<any[]> = ref([])

    const range = reactive<any>({
      max: undefined,
      min: undefined
    })

    const onSearch = () => {
      const params = toRequest(Object.assign({}, props.pageData.params, { dateFilterByField: props.code }))
      props.pageData.queryFn(params as any).then(data => {
        const { tz } = useTimeZone()
        const item = (data?.rows || [])[0]
        const max = item?.maxTime ? tz.al(item?.maxTime) : undefined
        const min = item?.minTime ? tz.al(item?.minTime) : undefined
        range.max = max
        range.min = min
      })
      // options.value = [{ value: keyword }]
    }

    const disabledDate = (d: any) => {
      const max = range.max && moment(range.max)
      const min = range.min && moment(range.min)
      if (min && d.isBefore(min.startOf('day'))) return true
      if (max && d.isAfter(max.endOf('day'))) return true
      return false
    }

    const onReset = () => {
      value.value = []
      props.pageData.unfilter(props.code)
      // eslint-disable-next-line vue/no-mutating-props
      props.visible.value = false
    }

    const onQuery = () => {
      if (value.value.length < 1) {
        onReset()
      } else {
        props.pageData.filter({ name: props.code, value: value.value })
        // eslint-disable-next-line vue/no-mutating-props
        props.visible.value = false
      }
    }

    watch(props.visible as any, () => {
      if (props.visible.value) {
        value.value = props.pageData.params?.filters?.find(v => v.name === props.code)?.value || []
        onSearch()
      }
    })

    return {
      value,
      onSearch,
      onQuery,
      onReset,
      disabledDate
    }
  }
})
