import { t } from '@/config/locale'

const lc = (name: string, ...args: any[]) => t(`page.fund.loan_batch.pages.detail.${name}`, ...args)

function getSteps () {
  return [
    { title: lc('step1') },
    { title: lc('step2') }
  ]
}

function getAuditSort () {
  return [
    { title: t('common.approval_time'), dataIndex: 'updateTime', $formatter: 'time' },
    { title: t('common.approval_user'), dataIndex: 'assigneeName' },
    { title: t('common.approval_status'), dataIndex: 'operation', $dictName: 'WORKFLOW_NORMAL_ACTION' },
    { title: t('common.approval_comment'), dataIndex: 'auditComment' }
  ]
}

export default function getConfig () {
  return {
    steps: getSteps(),
    auditSort: getAuditSort()
  }
}
