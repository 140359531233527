
import { dateFormatter, dictFormatter, numberFormatter, suffixFormatter } from '@/common/formatter'
import { defineComponent, PropType } from 'vue'
import { LayoutDetailColumn } from '../layout-detail/index.vue'

interface FieldItem extends LayoutDetailColumn {
  type?: string,
  dictKey: string
}

export default defineComponent({
  props: {
    fields: {
      type: Array as PropType<FieldItem[]>,
      default: () => []
    },
    dataSource: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    }
  },
  setup (props) {
    const dataFormat = ({ type, prop, formatter, dictKey }: FieldItem) => {
      let v = props.dataSource[prop]
      if (!v && v !== 0) return '-'
      if (formatter) return formatter(v, props.dataSource)
      switch (type) {
        case 'date': v = dateFormatter(v)
          break
        case 'radio':
        case 'select': dictKey && (v = dictFormatter(v, dictKey))
          break
        case 'number': v = numberFormatter(v)
          break
        case 'precent': v = suffixFormatter(numberFormatter(v), 'P')
          break
        case 'day': v = suffixFormatter(v, 'D')
          break
      }
      return v
    }

    return { dataFormat }
  }
})
