<script>
import { defineComponent } from 'vue'
import BuilderFactory from '@/components/air8/builder/BuilderFactory'
import _ from 'lodash'
export default defineComponent({
  name: 'Air8ConfigComp',
  aliasName: 'C0',

  inheritAttrs: false,

  props: {
    config: [String, Function, Object, Array],
    scope: Object,
    builder: String
  },

  render () {
    const builder = BuilderFactory.getInstance(this.builder || _.get(this.config, '$type', 'main'), this.config, this.scope)
    return builder.render()
  }
})
</script>
