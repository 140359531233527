
import { defineComponent, computed } from 'vue'
import draggable from 'vuedraggable'
import { Empty } from 'ant-design-vue'

export default defineComponent({
  name: 'drag-table',
  components: { draggable },
  props: {
    columns: {
      type: Array
    },
    data: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props: any, { emit }: any) {
    const list = computed({
      get: () => (props.data || []),
      set: (v) => emit('update:data', v)
    })
    return { list, simpleImage: Empty.PRESENTED_IMAGE_SIMPLE }
  }
})
