<script>
import { defineComponent, h, resolveComponent } from 'vue'
import _ from 'lodash'
import { matchString } from '@/utils/match'

export default defineComponent({
  name: 'Air8Select',
  aliasName: 'a8-d-select',

  inheritAttrs: false,

  props: {
    options: Array,
    labelProp: {
      type: String,
      default: 'label'
    },
    valueProp: {
      type: String,
      default: 'value'
    },
    optionLabelProp: String,
    fullOption: Boolean,
    fullLabel: Boolean,
    revertFullOption: Boolean,
    hasAll: Boolean,
    optionValueAll: {
      type: String,
      default: 'ALL'
    },
    optionValueExclude: {
      type: String,
      default: undefined
    },
    labelInValue: Boolean,
    labelPropInValue: {
      type: String,
      default: 'label'
    },
    valuePropInValue: {
      type: String,
      default: 'value'
    },
    value: [Array, Object, String, Number],
    joinValue: Boolean,
    splitValueChar: {
      type: String,
      default: ','
    },
    filterFunction: Function,
    showSearch: Boolean,
    localSearch: {
      type: Boolean,
      default: true
    },
    filterOption: {
      type: [Boolean, Function],
      default: undefined
    },
    mode: String,
    showArrow: {
      type: Boolean,
      default: true
    },
    getPopupContainer: Function,
    empty2Nil: Boolean
  },

  emits: ['update:value', 'change', 'select'],

  computed: {
    isMultiple () {
      return this.mode === 'multiple' || this.mode === 'tag'
    },

    allOption () {
      return {
        label: this.$t('common.all'),
        orginLabel: this.$t('common.all'),
        value: this.optionValueAll,
        revertValue: this.$t('common.all'),
        key: this.optionValueAll,
        actual: {
          label: this.$t('common.all'),
          value: this.optionValueAll
        }
      }
    },

    internalOptions () {
      let options = _.map(this.options, (option) => {
        let result = {
          label: _.get(option, this.labelProp),
          orginLabel: _.get(option, this.labelProp),
          value: _.get(option, this.valueProp),
          revertValue: _.get(option, this.valueProp),
          key: _.get(option, this.valueProp),
          actual: option
        }
        result = this.updateLabel(result)

        return result
      })

      if (this.hasAll) {
        options = _.concat([this.allOption], options)
      }

      options = _.map(options, (option) => {
        return this.updateExcludeDisabled(option)
      })

      if (_.isFunction(this.filterFunction)) {
        return this.filterFunction(options)
      } else {
        return options
      }
    },

    internalOptionLabelProp () {
      if (!_.isNil(this.optionLabelProp)) {
        return `actual.${this.optionLabelProp}`
      }
      if (!this.fullOption) {
        return 'orginLabel'
      }
      if (this.fullLabel) {
        return 'label'
      }
      if (this.revertFullOption) {
        return 'revertValue'
      }
      return 'orginLabel'
    },

    isChangeEmitUpdate () {
      return this.labelInValue && this.fullOption && this.fullLabel
    },

    isValueJoin () {
      return this.isMultiple && !this.labelInValue && this.joinValue
    },

    internalValue () {
      const getValue = (item) => {
        const value = _.get(item, this.valuePropInValue)
        const labelValue = _.get(item, this.labelPropInValue)
        let label = labelValue
        if (this.hasAll && value === this.optionValueAll) {
          label = this.$t('common.all')
        } else if (this.fullOption) {
          if (this.fullLabel) {
            label = this.getFullLabel(label, value)
          } else if (this.revertFullOption) {
            label = value
          }
        }
        return {
          value,
          label: label,
          key: value
        }
      }

      if (this.isValueJoin) {
        if (_.isEmpty(this.value)) {
          return []
        } else {
          return _.split(this.value, this.splitValueChar)
        }
      } else if (this.labelInValue && !_.isNil(this.value)) {
        if (this.isMultiple) {
          return _.map(this.value, (item) => {
            return getValue(item)
          })
        } else {
          return getValue(this.value)
        }
      }
      if (this.value === '' && this.empty2Nil) {
        return undefined
      }
      return this.value
    },

    isLocalSearch () {
      return this.showSearch && this.localSearch
    },

    internalFilterOption () {
      if (!_.isNil(this.filterOption)) {
        return this.filterOption
      }
      if (this.isLocalSearch) {
        return (inputValue, option) => {
          return matchString(option.label, inputValue)
        }
      }
      return undefined
    },

    isOptionExcludeSelected () {
      if (_.isNil(this.optionValueExclude)) {
        return false
      }

      if (!this.isMultiple) {
        return this.isExcludeOption(this.getOptionValue(this.internalValue))
      }
      return _.some(this.internalValue, (selected) => {
        return this.isExcludeOption(this.getOptionValue(selected))
      })
    }
  },

  methods: {
    getOptionSlots () {
      return false
    },

    getFullLabel (label, value) {
      if (value === this.optionValueAll) {
        return label
      }

      if (this.revertFullOption) {
        return `${value} | ${label}`
      }
      return `${label} | ${value}`
    },

    updateLabel (option) {
      if (!this.fullOption) {
        return option
      }
      option.label = this.getFullLabel(option.label, option.value)
      return option
    },

    updateInternalValue (value) {
      if (this.isValueJoin) {
        if (_.isEmpty(value)) {
          value = ''
        } else {
          value = _.join(value, this.splitValueChar)
        }
      } else if (this.labelInValue && !_.isNil(value)) {
        if (this.isMultiple) {
          return _.map(value, (item) => {
            return {
              key: item.key,
              [this.valuePropInValue]: item.value,
              [this.labelPropInValue]: item.label
            }
          })
        } else {
          return {
            key: value.key,
            [this.valuePropInValue]: value.value,
            [this.labelPropInValue]: value.label
          }
        }
      }
      return value
    },

    emitValue (value) {
      this.$emit('update:value', this.updateInternalValue(value))
    },

    emitChange (value, options) {
      this.$emit('change', this.updateInternalValue(value), options)
    },

    emitSelect (value, options) {
      this.$emit('select', this.updateInternalValue(value), options)
    },

    handleUpdateValue (value) {
      value = this.removeOtherValueWhenExcludeOptionSelected(value)
      if (this.isChangeEmitUpdate) {
        return
      }
      this.emitValue(value)
    },

    getEventOptions (options) {
      if (_.isNil(options)) {
        return undefined
      }
      const result = _.chain(options).castArray().map('actual').value()
      if (_.isArray(options)) {
        return result
      } else {
        return _.head(result)
      }
    },

    getOptionValue (value) {
      if (this.labelInValue) {
        return _.get(value, 'value')
      } else {
        return value
      }
    },

    handleChangeOption (value, options) {
      value = this.removeOtherValueWhenExcludeOptionSelected(value)
      options = this.removeOtherOptionWhenExcludeOptionSelected(options)
      let currentValue = value
      if (this.isChangeEmitUpdate) {
        if (_.isNil(options)) {

        } else if (this.isMultiple) {
          currentValue = _.map(options, (option) => {
            return {
              value: option.value,
              label: option.orginLabel,
              key: option.value
            }
          })
        } else {
          currentValue = {
            value: options.value,
            label: options.orginLabel,
            key: options.value
          }
        }
        this.emitValue(currentValue)
      }
      this.emitChange(currentValue, this.getEventOptions(options))
    },

    removeOtherValueWhenExcludeOptionSelected (value) {
      if (!this.isMultiple || _.isNil(this.optionValueExclude)) {
        return value
      }
      const excludeValue = _.find(value, (item) => {
        return this.isExcludeOption(this.getOptionValue(item))
      })
      if (!_.isEmpty(excludeValue)) {
        return [excludeValue]
      }
      return value
    },

    removeOtherOptionWhenExcludeOptionSelected (options) {
      if (!this.isMultiple || _.isNil(this.optionValueExclude)) {
        return options
      }
      const excludeOption = _.find(options, (option) => {
        return this.isExcludeOption(option.value)
      })
      if (!_.isEmpty(excludeOption)) {
        return [excludeOption]
      }
      return options
    },

    isExcludeOption (value) {
      return !_.isNil(this.optionValueExclude) && value === this.optionValueExclude
    },

    updateExcludeDisabled (option) {
      option.disabled = this.isMultiple && this.isOptionExcludeSelected && !this.isExcludeOption(option.value)
      return option
    },

    handleSelect (value, options) {
      value = this.removeOtherValueWhenExcludeOptionSelected(value)
      options = this.removeOtherOptionWhenExcludeOptionSelected(options)
      let currentValue = value
      if (this.isChangeEmitUpdate) {
        if (_.isNil(options)) {

        } else if (this.isMultiple) {
          currentValue = _.map(options, (option) => {
            return {
              value: option.value,
              label: options.orginLabel,
              key: option.value
            }
          })
        } else {
          currentValue = {
            value: options.value,
            label: options.orginLabel,
            key: options.value
          }
        }
      }
      this.emitSelect(currentValue, this.getEventOptions(options))
    }
  },

  render () {
    return h(resolveComponent('a-select'), {
      ...this.$attrs,
      getPopupContainer: this.getPopupContainer,
      mode: this.mode,
      showArrow: this.showArrow,
      options: this.internalOptions,
      optionLabelProp: this.internalOptionLabelProp,
      labelInValue: this.labelInValue,
      value: this.internalValue,
      showSearch: this.showSearch,
      filterOption: this.internalFilterOption,
      'onUpdate:value': (value) => this.handleUpdateValue(value),
      onChange: (value, options) => this.handleChangeOption(value, options),
      onSelect: (value, options) => this.handleSelect(value, options)
    }, this.$slots)
  }
})
</script>
