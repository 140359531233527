import { useHttp } from '@/plugins/http/src/index'
import { download } from '@/utils/download'
import { fromResp, toRequest } from '@/common/page-data'
import { t } from '@/config/locale'

const base = '/api'

const get = (path: any, params?: any) => {
  const { http } = useHttp()
  return http.get(base + path, { params })
}

const post = (path: any, data?: any, cfg?: any) => {
  const { http } = useHttp()
  return http.post(base + path, data, cfg)
}

// 首页-该账号所有绑定的用户信息
export function getCompany () {
  return get('/air8-auth-center/login/all')
}

// 首页-登录选择用户
export function chooseCompany (p: any) {
  return post('/air8-auth-center/login/choose', p)
}

// 首页-公司交易信息汇总
export function summaryCompany () {
  return post('/air8-financial-transaction/financeOrder/client/summaryCompany')
}

// 首页-查询公司实名状态、个人实名等情况
export function personInfo () {
  return post('/air8-company/clientend/companyAuth/personInfo')
}

export function changeRole (role: string) {
  return get(`/air8-auth-center/login/change/${role}`)
}
