import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "air8-files air8-table-list-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListView = _resolveComponent("ListView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListView, _mergeProps({ "file-list": _ctx.list }, _ctx.$attrs, { showIcon: _ctx.showIcon }), null, 16, ["file-list", "showIcon"])
  ]))
}