
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import getConfig from './config'
import Rules from '../../rules'
import { queryWorkflowHistory } from '@/api/workflow'
import _ from 'lodash'

export default defineComponent({
  name: 'ApprovalFlow',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    current: Number,
    steps: Array,
    approvalTableColumns: Array,
    approvalTableDataSource: Array,
    required: Boolean,
    value: String,
    workflowParams: Object
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const commentForm = ref()

    const comment = reactive({
      comment: computed<any>({
        get: () => props.value,
        set: (v: any) => {
          emit('update:value', v)
        }
      })
    })

    const commentRules = computed(() => {
      return props.required ? [{ required: true, validator: () => Promise.resolve() }] : [{ required: false }]
    })

    const config = getConfig()

    const currentStep = computed(() => {
      return props.current ? Number(props.current) : 0
    })

    const stepsText = computed(() => {
      return props.steps ? props.steps : config.steps
    })

    const internalApprovalTableColumns = computed(() => {
      return props.approvalTableColumns ? props.approvalTableColumns : config.auditSort
    })

    function getForm () {
      return commentForm.value
    }

    const workflowInfo = ref(undefined as any)
    async function getWorkflowHistory (workflowParams: any) {
      if (_.isEmpty(workflowParams)) {
        return
      }
      return await queryWorkflowHistory(workflowParams)
    }

    watch(() => props.workflowParams, () => {
      workflowInfo.value = undefined
      getWorkflowHistory(props.workflowParams).then((result) => {
        workflowInfo.value = result
      })
    }, {
      immediate: true
    })

    const internalApprovalTableDataSource = computed(() => {
      if (_.isNil(props.approvalTableDataSource)) {
        return _.get(workflowInfo.value, 'detailDTOS', [])
      } else {
        return props.approvalTableDataSource
      }
    })

    return {
      commentForm,
      comment,
      commentRules,
      currentStep,
      stepsText,
      internalApprovalTableColumns,
      internalApprovalTableDataSource,
      workflowInfo,
      getForm,
      getWorkflowHistory
    }
  }
})
