<template>
  <div class="air8-third-inner-card">
    <section-title class="air8-third-inner-card__title">
      <template #title>
        <slot name="title">
          <i-span>{{ this.internalTitle }}</i-span>
          <div class="air8-thrid-inner-card__subTitle">
            <slot name="subTitle">
            </slot>
          </div>
        </slot>
      </template>
      <template #extra>
        <slot name="extra"></slot>
      </template>
    </section-title>
    <div class="air8-third-inner-card__body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8ThirdInnerCard',

  props: {
    title: [String, Function]
  },

  computed: {
    internalTitle () {
      if (_.isFunction(this.title)) {
        return this.title()
      } else {
        return this.title
      }
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-third-inner-card {
  padding-left: 12px;
  padding-right: 12px;
  .air8-third-inner-card__title {
    margin-bottom: 0px;
    padding-left: 0px;

    .air8-card__subTitle {
      margin: 0px 8px;
    }

    &::after {
      display: none;
    }
  }

  /deep/ .air8-third-inner-card__title {
    & > div:first-child {
      border-radius: 2px;
      padding: 2px 10px;
      border: 1px solid @border-color;
      background: @color-primary;
      color: #fff;
      height: 32px;
    }
  }

  .air8-third-inner-card__body {
    border: 1px solid @border-color;
    padding: 8px 12px;
  }
}

.air8-third-inner-card + .air8-third-inner-card {
  margin-top: 12px;
}
</style>
