<template>
  <c0 :config="internalConfig"></c0>
</template>
<script>
import { computed, defineComponent, inject, markRaw, ref } from 'vue'
import _ from 'lodash'
import { getGroupItem } from './GroupItem'

export default defineComponent({
  name: 'Air8BackendConfigureGroup',

  inheritAttrs: false,

  props: {
    children: Array,
    model: Object,
    card: {
      type: Boolean,
      default: true
    },
    fromCountry: String,
    getPopupContainer: Function
  },

  setup (props, { attrs }) {
    const formRef = ref()
    const lang = inject('lang')

    const configOptions = computed(() => {
      return {
        fromCountry: props.fromCountry,
        getPopupContainer: props.getPopupContainer
      }
    })

    function getContent () {
      return {
        $type: 'i-form',
        ref: formRef,
        model: props.model,
        $children: {
          $type: 'Air8ColumnLayout',
          formLayout: 'horizontal',
          $children: _.chain(props.children)
            .map((child) => {
              return getGroupItem(child, lang, props.model, configOptions.value)
            })
            .flatten()
            .filter((item) => !_.isNil(item))
            .value()
        }
      }
    }

    const internalConfig = computed(() => {
      return {
        $type: props.card ? 'Air8AutoCard' : 'div',
        title: lang(attrs.label),
        $children: getContent()
      }
    })

    const form = computed(() => {
      return formRef.value
    })

    function validate (...args) {
      return form.value.validate(...args)
    }

    function validateFields (...args) {
      return form.value.validateFields(...args)
    }

    function scrollToField (...args) {
      return form.value.scrollToField(...args)
    }

    function resetFields (...args) {
      return form.value.scrollToField(...args)
    }

    function clearValidate (...args) {
      return form.value.scrollToField(...args)
    }

    return {
      formRef,
      internalConfig,
      form,
      validate,
      validateFields,
      scrollToField,
      resetFields,
      clearValidate
    }
  }
})
</script>
