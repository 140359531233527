import httpInstaller from './http'
import authInstaller from './auth'
import antdvInstaller from './antdv'
import svgInstaller from './svg'
import localInstaller from './locale'
import dictInstaller from './dict'
import tzInstaller from './time-zone'
import { App } from 'vue'

export default function (app: App) {
  httpInstaller(app)
  authInstaller(app)
  antdvInstaller(app)
  svgInstaller(app)
  localInstaller(app)
  dictInstaller(app)
  tzInstaller(app)
}
