export type PageType = 'router' | 'menu' | 'opt'

export interface PageConfig {

  name: string

  label: string

  type: PageType[]

  children?: PageConfig[]

  pid?: string // 自定义pid

  router?: {
    path?: string // 自定义路由路径 支持动态参数 (${name} ${path})

    nested?: boolean // 是否嵌套路由

    redirect?: any // 重定向

    name?: string // 自定义路由名称
  }

  belong?: string
}

export interface PageInstance {
  name: string

  label: string

  type: PageType[]

  children?: PageInstance[]

  path: string

  parent?: PageInstance

  pid?: string // 自定义pid

  router?: {
    path?: string // 解析过的path

    nested?: boolean // 是否嵌套路由

    redirect?: any // 重定向

    name?: string // 自定义路由名称
  }

  belong?: string
}

const options: PageConfig[] = [
  // {
  //   name: 'dashboard',
  //   label: 'page.dashboard',
  //   type: ['router', 'menu']
  // },
  {
    name: 'home',
    label: 'page.home',
    type: ['router', 'menu']
  },
  {
    name: 'org',
    label: 'page.org.label',
    type: ['menu'],
    children: [
      {
        name: 'company',
        label: 'page.org.company.label',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}', redirect: { name: 'companyDetail' } },
        children: [
          {
            name: 'detail',
            label: 'page.org.company.detail.label',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}', name: 'companyDetail' }
          },
          {
            name: 'edit',
            label: 'page.org.company.edit.label',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}', name: 'companyEdit' }
          }
        ]
      },
      {
        name: 'account',
        label: 'page.authority.account.label',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' },
        children: [
          {
            name: 'add',
            label: 'page.authority.account.add',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}' }
          },
          {
            name: 'edit',
            label: 'page.authority.account.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          },
          {
            name: 'detail',
            label: 'page.authority.account.detail',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          }
        ]
      },
      {
        name: 'info',
        label: 'page.org.info',
        type: ['menu', 'router'],
        children: [
          {
            name: 'add-intent',
            label: 'page.org.info.add_intent',
            type: ['router']
          },
          {
            name: 'edit-intent',
            label: 'page.org.info.edit_intent',
            type: ['router']
          }
        ]
      }
    ]
  },
  {
    name: 'confirmation',
    label: 'page.confirmation.label',
    type: ['menu'],
    children: [
      {
        name: 'invoiceConfirmation',
        label: 'page.confirmation.invoiceConfirmation',
        type: ['menu', 'router'],
        children: [
          {
            name: 'detail',
            label: 'common.detail',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          }
        ]
      },
      {
        name: 'deduction-report',
        label: 'page.confirmation.deductionReport',
        type: ['menu', 'router']
      }
    ]
  },
  {
    name: 'contract',
    label: 'page.contract.label',
    type: ['menu'],
    children: [
      {
        name: 'info',
        label: 'page.contract.info',
        type: ['menu', 'router']
      }
    ]
  },
  {
    name: 'trading',
    label: 'page.trading.label',
    type: ['menu'],
    children: [
      {
        name: 'purchase',
        label: 'page.trading.purchase',
        type: ['menu', 'router'],
        children: [
          {
            name: 'add',
            label: 'page.trading.purchase.add',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}', name: 'purchaseAdd' }
          },
          {
            name: 'details',
            label: 'page.trading.purchase.details',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'purchaseDetail' }
          },
          {
            name: 'edit',
            label: 'page.trading.purchase.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'purchaseEdit' }
          },
          {
            name: 'draft',
            label: 'page.trading.purchase.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'purchaseDraft' }
          },
          {
            name: 'update',
            label: 'page.trading.purchase.up',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'purchaseUpdate' }
          },
          {
            name: 'upload',
            label: 'page.trading.purchase.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'purchaseUpload' }
          }
        ]
      },
      {
        name: 'invoice',
        label: 'page.trading.invoice',
        type: ['menu', 'router'],
        children: [
          {
            name: 'add',
            label: 'page.trading.invoice.add',
            type: ['router']
          },
          {
            name: 'details',
            label: 'page.trading.invoice.details',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}', name: 'billDetail' }
          },
          {
            name: 'edit',
            label: 'page.trading.invoice.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          },
          {
            name: 'upload',
            label: 'page.trading.invoice.upload',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          }
        ]
      }
    ]
  },
  {
    name: 'finance',
    label: 'page.finance',
    type: ['menu'],
    children: [
      {
        name: 'finance-order',
        label: 'page.finance.finance-order',
        type: ['menu', 'router'],
        children: [
          {
            name: 'add',
            label: 'page.finance.finance-order.add',
            type: ['router']
          },
          {
            name: 'detail',
            label: 'page.finance.finance-order.detail',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          },
          {
            name: 'edit',
            label: 'page.finance.finance-order.edit',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          },
          {
            name: 'update',
            label: 'page.finance.finance-order.route.update',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'planUpdate' }
          },
          {
            name: 'arp',
            label: 'page.finance.finance-order.route.arp',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/${name}/:id', name: 'toARP' }
          },
          {
            name: 'confirm',
            label: 'page.finance.finance-order.confirm',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          }
        ]
      },
      {
        name: 'dbsFinance',
        label: 'page.finance.dbsFinance',
        type: ['menu', 'router']
      },
      {
        name: 'poolFactoring',
        label: 'page.finance.poolFactoring',
        type: ['menu', 'router']
      },
      {
        name: 'account',
        label: 'page.finance.account',
        type: ['menu', 'router']
      },
      {
        name: 'interest',
        label: 'page.finance.interest',
        type: ['menu', 'router']
      }

    ]
  },
  {
    name: 'payment',
    label: 'page.payment.label',
    type: ['menu'],
    children: [
      {
        name: 'management',
        label: 'page.payment.management.label',
        type: ['menu', 'router']
      },
      {
        name: 'financing',
        label: 'page.payment.financing.title',
        type: ['menu', 'router']
      },
      {
        name: 'release-refund',
        label: 'page.payment.release_refund.label',
        type: ['menu', 'router']
      }
    ]
  },
  {
    name: 'repayment',
    label: 'page.repayment.label',
    type: ['menu'],
    children: [
      // 买家还款明细
      {
        name: 'repaymentDetail',
        label: 'page.repayment.detail.label',
        type: ['menu', 'router']
      },
      {
        name: 'details',
        label: 'page.repayment.details.label',
        type: ['menu', 'router']
      },
      {
        name: 'management',
        label: 'page.repayment.management.label',
        type: ['menu', 'router']
      }
    ]
  },
  // {
  //   name: 'fund',
  //   label: 'page.fund.label',
  //   type: ['menu'],
  //   children: [
  //     {
  //       name: 'loan',
  //       label: 'page.fund.loan.label',
  //       type: ['menu', 'router']
  //     },
  //     {
  //       name: 'batch',
  //       label: 'page.fund.loan_batch.label',
  //       type: ['menu', 'router']
  //     },
  //     {
  //       name: 'balance-management',
  //       label: 'page.fund.balance_management.label',
  //       type: ['menu', 'router']
  //     },
  //     {
  //       name: 'overage',
  //       label: 'page.fund.overage.label',
  //       type: ['menu', 'router'],
  //       children: [
  //         {
  //           name: 'detail',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router']
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           // router: { path: '${path}/:id/${name}' }
  //         },
  //         {
  //           name: 'balance',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router'],
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           router: { path: '${path}/:id/${name}' }
  //         }
  //       ]
  //     },
  //     {
  //       name: 'supplier-management',
  //       label: 'page.fund.supplier_management.label',
  //       type: ['menu', 'router']
  //     },
  //     {
  //       name: 'supplier-detail',
  //       label: 'page.fund.supplier_detail.label',
  //       type: ['menu', 'router'],
  //       children: [
  //         {
  //           name: 'detail',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router'],
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           router: { path: '${path}/:id/${name}' }
  //         },
  //         {
  //           name: 'balance',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router']
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           // router: { path: '${path}/:id/${name}' }
  //         }
  //       ]
  //     },
  //     {
  //       name: 'buyer-management',
  //       label: 'page.fund.buyer_management.label',
  //       type: ['menu', 'router']
  //     },
  //     {
  //       name: 'buyer-detail',
  //       label: 'page.fund.buyer_detail.label',
  //       type: ['menu', 'router'],
  //       children: [
  //         {
  //           name: 'detail',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router'],
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           router: { path: '${path}/:id/${name}' }
  //         },
  //         {
  //           name: 'balance',
  //           label: 'page.fund.overage.columns.detail',
  //           type: ['router'],
  //           /* eslint-disable-next-line no-template-curly-in-string */
  //           router: { path: '${path}/:id/${name}' }
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    name: 'user-center',
    label: 'page.user-center.label',
    type: ['router'],
    children: [
      {
        name: 'modify',
        label: 'page.user-center.modify',
        type: ['router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      }
    ]
  },
  {
    // 实名认证
    name: 'realname',
    label: 'page.real-name.label',
    type: ['router'],
    /* eslint-disable-next-line no-template-curly-in-string */
    router: { path: '${path}/${name}', name: 'verify' },
    children: [
      {
        name: 'verify-bank',
        label: 'realname.Bank_information_validation',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'verify-money',
        label: 'realname.title.verificationMoney',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'verify-success',
        label: 'realname.CertificationSuccess',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'verify-Personal',
        label: 'realname.title.verifyname',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}', name: 'verifyPersonal' }
      },
      {
        name: 'personal-success',
        label: 'realname.title.verifyname',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'personal-error',
        label: 'realname.title.verifyname',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      }
    ]
  },
  // 财务凭证
  {
    name: 'proof',
    label: 'page.proof.label',
    type: ['menu'],
    /* eslint-disable-next-line no-template-curly-in-string */
    router: { path: '${path}/${name}', name: 'proof' },
    children: [
      {
        name: 'subledgers',
        label: 'page.proof.subledgers',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'bank',
        label: 'page.proof.bank',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'templet',
        label: 'page.proof.templet',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' },
        children: [
          {
            name: 'detail',
            label: 'page.proof.templet.detail',
            type: ['router'],
            /* eslint-disable-next-line no-template-curly-in-string */
            router: { path: '${path}/:id/${name}' }
          }
        ]
      },
      {
        name: 'collect',
        label: 'page.proof.collect',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      },
      {
        name: 'e_one',
        label: 'page.proof.e_one',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      }
    ]
  },
  {
    name: 'report',
    label: 'page.report.label',
    type: ['menu'],
    /* eslint-disable-next-line no-template-curly-in-string */
    router: { path: '${path}/${name}', name: 'proof' },
    children: [
      {
        name: 'amortization',
        label: 'page.report.amortization',
        type: ['menu', 'router'],
        /* eslint-disable-next-line no-template-curly-in-string */
        router: { path: '${path}/${name}' }
      }
    ]
  },
  {
    name: 'information',
    label: 'page.information.label',
    type: ['router']
  }
]

export default options
