<template>
  <air8-action-page
    class="air8-approve-view-page"
    ref="actionPage"
    v-bind="$attrs"
    :status="status"
    :config="config"
    :model-value="modelValue"
    :init-function="initFunction"
    :go-back="goBack"
  >
    <slot name="default"></slot>
    <approval-flow
      :approval-table-columns="approvalTableColumns"
      :approval-table-data-source="approvalTableDataSource"
      :workflow-params="workflowParams"
      :editable="false"
    ></approval-flow>
  </air8-action-page>
</template>
<script>
import { defineComponent, ref } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8ApprovalViewPage',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: 'detail'
    },
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    },
    approvalTableColumns: Array,
    approvalTableDataSource: Array,
    workflowParams: Object
  },

  setup () {
    const actionPage = ref()
    function getForm () {
      return actionPage.value.getForm()
    }

    return {
      actionPage,
      getForm
    }
  }
})
</script>
