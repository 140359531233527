<script>
import { defineComponent, resolveComponent, h, Text, watch } from 'vue'
import _ from 'lodash'
import store from '@/store'
import { isUseLocaleTools } from '@/plugins/locale-tools/index'

export default defineComponent({
  name: 'Air8LocaleLabel',
  aliasName: ['iSpan'],

  props: {
    wrapBrackets: Boolean,
    value: [String, Array],
    localeable: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getContext () {
      const context = _.invoke(this.$slots, 'default')
      if (_.isArray(context) && context.length === 1 && _.get(context, '0.type') === Text) {
        return _.trim(_.get(context, '0.children'))
      } else {
        return undefined
      }
    },

    getInternalValue () {
      if (_.isNil(this.value)) {
        return this.getContext()
      } else {
        return this.value
      }
    },

    isLocaleKey () {
      const internalValue = this.getInternalValue()
      return !_.isNil(internalValue) && (!_.isString(internalValue) || this.$te(internalValue))
    },

    getLocaleKey () {
      const internalValue = this.getInternalValue()
      if (this.isLocaleKey()) {
        return _.isArray(internalValue) ? _.nth(internalValue, 0) : internalValue
      } else if (/^([_A-Za-z0-9]+\.)+[_A-Za-z0-9]+$/g.test(internalValue)) {
        return internalValue
      } else {
        return ''
      }
    },

    getLocaleValue () {
      const internalValue = this.getInternalValue()

      if (this.isLocaleKey()) {
        const localeKey = _.isArray(internalValue) ? _.nth(internalValue, 0) : internalValue
        return this.$t(localeKey, ..._.tail(_.castArray(internalValue)))
      } else {
        return internalValue
      }
    },

    getChildAttributes (localeKey, localeValue) {
      const attrs = { ...this.$attrs }
      if (!isUseLocaleTools()) {
        return attrs
      }
      if (!this.localeable) {
        attrs['data-locale-ignore'] = true
        return attrs
      }

      if (!_.isEmpty(localeKey)) {
        attrs['data-locale-key'] = localeKey
      }
      if (!_.isEmpty(localeValue)) {
        attrs['data-locale-value'] = localeValue
      }
      return attrs
    }
  },

  render () {
    const localeKey = this.getLocaleKey()
    const localeValue = this.getLocaleValue()
    const attrs = this.getChildAttributes(localeKey, localeValue)

    if (this.wrapBrackets) {
      return h(resolveComponent('Air8WrapBracketsText'), {
        ...attrs,
        value: localeValue
      })
    } else {
      return h('span', attrs, [localeValue])
    }
  }
})
</script>
