<template>
  <div class="air8-progress">
     <air8-tooltip :title="tip">
       <a-progress
        type="line"
        :percent="percentNumber"
        size="small"
        :showInfo="false"
        :strokeWidth="width"
        trailColor="#ddd"
        :strokeColor="settingColors()" />
    </air8-tooltip>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'

export default defineComponent({
  name: 'Air8InfoProgress',

  props: {
    tipTitle: {
      type: String,
      default: null
    },
    value: String,
    percentNum: Number,
    width: {
      type: Number,
      default: 7
    },
    percentSource: {
      type: Array,
      default: () => ([
        { num: 20, color: '#F56C6C' },
        { num: 40, color: '#e6a23c' },
        { num: Number.POSITIVE_INFINITY, color: '#67C23A' }
      ])
    }
  },

  setup (props) {
    const tip = computed(() => { // toolTip-title
      if (!_.isNil(props.value) && !_.isNil(props.percentNum)) {
        return t('common.progress.strength', props.value, Math.floor(props.percentNum))
      } else if (!_.isNil(props.tipTitle)) {
        return props.tipTitle
      }
      return ''
    })

    const percentNumber = computed(() => { // percent
      if (isNaN(Number(props.percentNum))) {
        return 0
      }
      return Number(props.percentNum)
    })

    function settingColors () { // progress color
      return _.find(props.percentSource, (value) => {
        return percentNumber.value <= value.num
      }).color
    }

    return {
      ...props,
      tip,
      percentNumber,
      settingColors
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';
</style>
