
import { useAuth } from '@/plugins/auth/src'
import { useTimeZone } from '@/plugins/time-zone'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'tz-date',
  props: {
    value: {}
  },
  emits: ['update:value', 'change'],
  setup (props: any, { emit }) {
    const { auth } = useAuth()
    const { tz } = useTimeZone()

    const format = computed(() => {
      return auth?.principle?.user?.timeFormat
    })

    const date = computed(() => {
      if (!props.value) return null
      return tz.al(props.value)
    })

    const onChange = (val: any) => {
      const v = val && tz.as(val)
      emit('update:value', v)
      emit('change', v)
    }

    return {
      format,
      date,
      onChange
    }
  }
})

