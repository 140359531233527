
import { defineComponent, onMounted, ref, reactive, computed, PropType, watch, Ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { PageData, toRequest } from '@/common/page-data'

export default defineComponent({
  name: 'input-control',
  components: { SearchOutlined },
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    visible: {
      type: Object,
      required: true
    },
    loadOnDropDownVisible: Boolean
  },
  setup (props) {
    const options = ref<any[]>([])
    const value = ref('')

    const onSearch = (keyword: string) => {
      const params = Object.assign({}, toRequest(props.pageData.params), { filterByField: props.code }, { filterFields: { [props.code]: (keyword || '').trim() + '*' } }, { page: 1, size: 20000 })
      props.pageData.queryFn(params as any).then(data => {
        options.value = (data?.rows || []).filter(v => !!v).map(v => ({ value: v[props.code] }))
      })
      // options.value = [{ value: keyword }]
    }

    const onReset = () => {
      value.value = ''
      props.pageData.unfilter(props.code)
      // eslint-disable-next-line vue/no-mutating-props
      props.visible.value = false
    }

    const onQuery = () => {
      if (!value.value) {
        onReset()
      } else {
        props.pageData.filter({ name: props.code, value: '*' + (value.value || '').trim() })
        // eslint-disable-next-line vue/no-mutating-props
        props.visible.value = false
      }
    }

    watch(props.visible as any, () => {
      if (props.visible.value) {
        value.value = props.pageData.params?.filters?.find(v => v.name === props.code)?.value || ''
        value.value = value.value.replace(/^\*/g, '')

        if (props.loadOnDropDownVisible) {
          onSearch(value.value)
        }
      }
    })

    return {
      value,
      options,
      onSearch,
      onQuery,
      onReset
    }
  }
})
