import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'nprogress/nprogress.css'
import './styles/index.less'
import pluginsInstaller from './plugins'
import componentsInstaller from './components'
import _ from 'lodash'

const app = createApp(App)
pluginsInstaller(app)
componentsInstaller(app)
app.use(store).use(router).mount('#app')

// error handle
app.config.errorHandler = console.log

_.set(window, 'appVersion', process.env.VUE_APP_BUILD_VERSION)
console.log(process.env.VUE_APP_BUILD_VERSION)
