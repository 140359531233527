<template>
  <div class="air8-info-icon-tip">
    <a-popover :getPopupContainer="getPopupContainerProp">
      <template v-slot:content>
        <slot name="content">
          <span>{{ tip }}</span>
        </slot>
      </template>
      <InfoCircleOutlined class="air8-info-icon-tip__icon" />
    </a-popover>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'Air8InfoIconTip',

  components: {
    InfoCircleOutlined
  },

  props: {
    tip: String,
    getPopupContainer: Function
  },

  computed: {
    getPopupContainerProp () {
      if (_.isNil(this.getPopupContainer)) {
        return () => {
          return _.get(document.getElementsByClassName('air8-popup__container'), '0')
        }
      }
      return this.getPopupContainer
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-info-icon-tip {
  display: inline-block;
}

.air8-info-icon-tip__icon {
  color: @color-primary;
  font-size: 16px;
}
</style>
