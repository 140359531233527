
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { ossPreviewPicture, ossRequest } from '@/common/oss'
import { notification } from 'ant-design-vue'
import { t } from '@/config/locale'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default defineComponent({
  name: 'upload-picture',
  props: {
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: 'file'
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: Number,
      default: 1
    },
    title: {
      type: String
    },
    accept: {
      type: Array
    },
    type: {
      type: String,
      default: 'picture-card'
    },
    example: {
      type: String,
      default: '/images/reset-logo.png'
    }

  },
  emits: ['change'],
  setup (props, { attrs, emit }) {
    const uploadRef = ref()

    const fileList = ref<any>([])

    const acceptList = ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF']

    const preview = reactive({
      image: '',
      visible: false
    })

    const accepts = computed(() => {
      const list: any = props.accept || acceptList
      const result = list.reduce((memo:string, current: string, index: number) => (memo += index ? (',.' + current) : ('.' + current)), '')
      return result
    })

    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      preview.image = file.url || file.preview
      preview.visible = true
    }

    const handleBeforeUpload = (f: any, fl: any) => {
      if (f.type === 'application/x-zip-compressed' && f.size > 100 * 1024 * 1024) {
        notification.error({ message: t('common.upload.zip_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (f.type !== 'application/x-zip-compressed' && f.size > 20 * 1024 * 1024) {
        notification.error({ message: t('common.upload.file_maxsize_tip') })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (fileList.value.length + fl.length > props.maxlength) {
        if (fl[fl.length - 1].uid === f.uid) {
          notification.error({ message: t('common.upload.file_limit_tip', String(props.maxlength)) })
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    }

    const handleChange = ({ file }:any) => {
      if (file.status === 'done') {
        const { response: { data } } = file
        // eslint-disable-next-line vue/no-mutating-props
        props.value.push(data)
      } else if (file.status === 'removed') {
        const uid = file?.response?.data?.objectKey || file?.uid
        const i = props.value.findIndex(({ objectKey }:any) => objectKey === uid)
        // eslint-disable-next-line vue/no-mutating-props
        i >= 0 && props.value.splice(i, 1)
      }
      emit('change', { file })
    }

    const getPreviewUrl = (file: any, i:number) => {
      ossPreviewPicture(file.objectKey).then((data) => {
        fileList.value[i].url = data
      })
    }

    watch(() => props.value, (val) => {
      fileList.value = (val || []).map((file:any, i:number) => {
        getPreviewUrl(file, i)
        return { status: 'done', uid: file.objectKey, name: file.fileName }
      })
    }, { immediate: true })

    return {
      uploadRef,
      fileList,
      ossRequest,
      attrs,
      accepts,
      ...toRefs(preview),
      handleBeforeUpload,
      handleChange,
      handlePreview
    }
  }
})
