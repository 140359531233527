import pages, { PageInstance } from '@/config/page'
import { RouteRecordRaw } from 'vue-router'

const configRoutes = genConfigRoutes(pages)

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    // redirect: '/org/company/detail',
    meta: { pid: '/' },
    children: [
      ...configRoutes
    ]
  },
  // DON'T CHANGE BELOW
  {
    path: '/error/:code',
    name: 'error',
    props: ({ params = {} }) => ({ ...params }),
    component: () => import('@/views/error/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/reset-pwd',
    name: 'reset-pwd',
    component: () => import('@/views/reset-pwd/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

console.info('routes: ', routes)

export default routes

function genConfigRoutes (pages: PageInstance[]): RouteRecordRaw[] {
  return traverse(pages)

  function traverse (pages: PageInstance[], parent?: RouteRecordRaw): RouteRecordRaw[] {
    const ret: RouteRecordRaw[] = []
    pages.forEach(v => {
      let item
      if (v.type.includes('router')) {
        item = {
          path: v.router?.path || v.path,
          name: v.router?.name || v.path,
          props: ({ params = {}, query = {} }) => ({ ...params, ...query }),
          component: () => import(`@/views/pages${v.path}/index.vue`),
          meta: { pid: v.pid || v.path, page: v },
          redirect: v.router?.redirect
        }
        if (v.router?.nested && parent) {
          if (!parent.children) parent.children = []
          parent.children.push(item)
        } else {
          ret.push(item)
        }
      }
      const children = (Array.isArray(v.children) && v.children.length > 0) ? traverse(v.children, item) : []
      if (children.length > 0) ret.push(...children)
    })
    return ret
  }
}
