import { useHttp } from '@/plugins/http/src'
import { useLocal } from '@/plugins/locale'

const BASE = '/api/air8-company'
const COMPANY_API = `${BASE}/clientend/company`

// 获取公司详情
export function queryCompanyDetail () {
  const { http } = useHttp()
  return http.get(`${COMPANY_API}/detail`)
}

// 获取国家列表
export function queryCountryList () {
  const { http } = useHttp()
  return http.post(`${BASE}/area/list`, { type: 'COUNTRY' })
}

// 校验公司名称
export function checkCompanyName (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/checkCompName`, params, { showError: false })
}

// 检验商业登记号
export function checkBizNo (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/checkBizRegNo`, params, { showError: false })
}

// director提交
export function submitOfDirector (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/editDirector`, params)
}

// 供应商提交
export function submitOfSupplier (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/editSupplier`, params)
}

// 买方提交
export function submitOfBuyer (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/editBuyer`, params)
}

// 资金方提交
export function submitOfFunder (params: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/editFunder`, params)
}

// 查询实名状态
export function queryPersonInfo () {
  const { http } = useHttp()
  return http.post(`${BASE}/clientend/companyAuth/personInfo`)
}

// 获取银行
export function queryBank (params?: any) {
  const { http } = useHttp()
  return http.post('/api/air8-company/backend/dict/bank', params)
}

// 根据国别获取附件列表
export function queryAttachListByCountry (params?: any) {
  const { http } = useHttp()
  return http.post(`${COMPANY_API}/listWithOther`, params)
}
