import _ from 'lodash'
import BigNumber from 'bignumber.js'

export const isNumber = function (value: any) : boolean {
  return !_.isNil(value) && !(_.trim(value) === '') && !(new BigNumber(value)).isNaN()
}

export const toNumber = function (value: any) : number {
  return toBigNumber(value).toNumber()
}

export const toBigNumber = function (value: any) : BigNumber {
  if (!(value instanceof BigNumber)) {
    value = new BigNumber(value)
  }

  return value.isNaN() ? new BigNumber(0) : value
}

export const toFixedNumber = function (value: any, precision?: number) : number {
  if (_.isNil(precision)) {
    return toNumber(value)
  }
  return toNumber(toBigNumber(value).toFixed(precision))
}

export const sumList = function (list: any[], fields: string | string[], precision?: number) : number | any {
  if (_.isEmpty(fields)) {
    return undefined
  }
  const sumFields = _.castArray(fields)
  let sumResult:any = _.reduce(list, (result:any, item) => {
    _.each(sumFields, field => {
      result[field] = toBigNumber(result[field]).plus(toBigNumber(_.get(item, field)))
    })
    return result
  }, {})
  sumResult = _.mapValues(sumResult, (value: any) => {
    return toFixedNumber(value, precision)
  })

  if (_.isArray(fields)) {
    return sumResult
  } else {
    return _.get(sumResult, fields)
  }
}

export const gt = function (num1: any, num2: any) : boolean {
  return toBigNumber(num1).gt(toBigNumber(num2))
}

export const gte = function (num1: any, num2: any) : boolean {
  return toBigNumber(num1).gte(toBigNumber(num2))
}

export const lt = function (num1: any, num2: any) : boolean {
  return toBigNumber(num1).lt(toBigNumber(num2))
}

export const lte = function (num1: any, num2: any) : boolean {
  return toBigNumber(num1).lte(toBigNumber(num2))
}

export const eq = function (num1: any, num2: any) : boolean {
  return toBigNumber(num1).eq(toBigNumber(num2))
}

export const isZero = function (num: any) : boolean {
  return eq(num, 0)
}

export const min = function (...nums: any[]) : number {
  if (!_.isEmpty(nums)) {
    nums = _.map(nums, (num) => toNumber(num))
  }
  return Math.min(...nums)
}

export const max = function (...nums: any[]) : number {
  if (!_.isEmpty(nums)) {
    nums = _.map(nums, (num) => toNumber(num))
  }
  return Math.max(...nums)
}

export const plus = function (num1: any, num2: any, precision?:number) : number {
  num1 = toBigNumber(num1)
  num2 = toBigNumber(num2)
  return toFixedNumber(num1.plus(num2), precision)
}

export const minus = function (num1: any, num2: any, precision?:number) : number {
  num1 = toBigNumber(num1)
  num2 = toBigNumber(num2)
  return toFixedNumber(num1.minus(num2), precision)
}

export const times = function (num1: any, num2: any, precision?:number) : number {
  num1 = toBigNumber(num1)
  num2 = toBigNumber(num2)
  return toFixedNumber(num1.times(num2), precision)
}

export const div = function (num1: any, num2: any, precision?:number) : number {
  num1 = toBigNumber(num1)
  num2 = toBigNumber(num2)
  return toFixedNumber(num1.div(num2), precision)
}

export const exhange = function (money: any, ratioSource: any, ratioTarget: any, precision?:number): number | undefined {
  money = toBigNumber(money)
  ratioSource = toBigNumber(ratioSource)
  ratioTarget = toBigNumber(ratioTarget)
  if (ratioSource.eq(0)) {
    return undefined
  }
  return toFixedNumber(money.times(ratioTarget).div(ratioSource), precision)
}
