import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createSlots as _createSlots, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd5e66b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "air8-search-list-page__tag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_query_conditions_group = _resolveComponent("query-conditions-group")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_data_table_group = _resolveComponent("data-table-group")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'air8-search-list-page': true, 'air8-search-list-page__nested': _ctx.nested || _ctx.tableNested})
  }, [
    _withDirectives(_createVNode(_component_query_conditions_group, {
      ref: "queryConditionGroup",
      "page-data": _ctx.pageData,
      "query-conditions": _ctx.internalQueryConditions,
      params: _ctx.internalParams,
      filters: _ctx.internalFilters,
      nested: _ctx.nested,
      "nested-type": _ctx.nestedType,
      span: _ctx.queryColSpan,
      onQuery: _ctx.handleQuery,
      onceParams: _ctx.internalOnceParams
    }, {
      default: _withCtx(() => [
        (_ctx.$slots['query-conditions'])
          ? _renderSlot(_ctx.$slots, "query-conditions", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["page-data", "query-conditions", "params", "filters", "nested", "nested-type", "span", "onQuery", "onceParams"]), [
      [_vShow, !_ctx.hideQueryCondition]
    ]),
    (_ctx.nested || _ctx.tableNested)
      ? _renderSlot(_ctx.$slots, "extra", { key: 0 }, undefined, true)
      : _createCommentVNode("", true),
    (_ctx.internalShowFilterTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFilters, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              key: index,
              color: "processing",
              closable: "",
              onClose: _withModifiers(($event: any) => (_ctx.handleCloseFilterTag(item.value)), ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["onClose"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_data_table_group, {
      class: "air8-search-list-page__data-table",
      ref: "tableGroupRef",
      sid: _ctx.sid,
      title: _ctx.title,
      "table-columns": _ctx.internalTableColumns,
      "page-data": _ctx.pageData,
      "extra-actions": _ctx.extraActions,
      "table-row-actions": _ctx.tableRowActions,
      scroll: _ctx.scroll,
      "use-sequence": _ctx.useSequence,
      "sequence-key": _ctx.sequenceKey,
      nested: _ctx.nested || _ctx.tableNested,
      editable: _ctx.editable,
      summary: _ctx.summary,
      "show-page": _ctx.showPage,
      "row-class-name": _ctx.rowClassName,
      filterable: _ctx.filterable,
      sorterable: _ctx.sorterable,
      sumerable: _ctx.sumerable,
      "wrap-brackets": _ctx.wrapBrackets,
      rowKey: _ctx.rowKey,
      emptyText: _ctx.emptyText,
      pageSizeOptions: _ctx.pageSizeOptions,
      "row-selection": _ctx.rowSelection,
      "disabled-selection": _ctx.disabledSelection,
      selection: _ctx.selection,
      "query-size": _ctx.querySize,
      "required-mark": _ctx.requiredMark,
      "onUpdate:selection": _ctx.handleUpdateSelection
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.dataTableGroupSlotKeys, (name) => {
        return {
          name: name,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
          ])
        }
      })
    ]), 1032, ["sid", "title", "table-columns", "page-data", "extra-actions", "table-row-actions", "scroll", "use-sequence", "sequence-key", "nested", "editable", "summary", "show-page", "row-class-name", "filterable", "sorterable", "sumerable", "wrap-brackets", "rowKey", "emptyText", "pageSizeOptions", "row-selection", "disabled-selection", "selection", "query-size", "required-mark", "onUpdate:selection"])
  ], 2))
}