
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from 'vue'
import { PageData } from '@/common/page-data'
import SlotRender from './slot-render'
import { t } from '@/config/locale'
import { SettingOutlined } from '@ant-design/icons-vue'
import { ConfigField } from './fileds'
import draggable from 'vuedraggable'
import { useAuth } from '@/plugins/auth/src'

export default defineComponent({
  name: 'data-table',
  components: { SlotRender, SettingOutlined, draggable },
  props: {
    columns: {
      type: Array as PropType<any[]>,
      required: true
    },
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    nested: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    sid: {
      type: String
    },
    title: {
      type: String
    },
    hidenKeys: {
      type: Array as PropType<string[]>
    },
    hidenKeysVersion: {
      type: String as PropType<string>

    }
  },
  setup (props, context) {
    const configField:any = reactive(new ConfigField(props.sid as any))

    const pagination = computed(() => {
      return {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total: number) => `${t('common.total', total + '')}`,
        pageSizeOptions: ['20', '50', '100'],
        current: props.pageData.params.page,
        pageSize: props.pageData.params.size,
        total: props.pageData.total
      }
    })

    const height = ref(400)

    const attrs = computed(() => {
      return {
        rowClassName: (record: any, i: number) => (i % 2 === 1 ? 'striped' : null),
        rowKey: (record: any, i: number) => 'table_' + i,
        ...context.attrs,
        scroll: Object.assign({ x: 2200, y: props.pageData.rows.length > 0 ? height.value : 0 }, context.attrs?.scroll || {})
      }
    })

    const eventHandlers = {
      change (pagination: any, filters: any, sorter: any, { currentDataSource }: any) {
        const p = { page: Number(pagination.current), size: Number(pagination.pageSize) }
        const s = sorter?.order ? { sorter: { prop: sorter?.field, order: sorter?.order === 'ascend' ? 'asc' : 'desc' } } : { sorter: undefined }
        props.pageData.load(Object.assign(props.pageData.params, s as any, p))
      }
    }

    const renderedColumns = computed(() => {
      if (!props.sid) return props.columns
      const map = new Map(props.columns.map((v: any) => [v.key || v.dataIndex, v]))
      return configField.fields.filter((v:any) => v.checked).map((v:any) => map.get(v.key)).filter((v:any) => !!v)
    })

    const { auth } = useAuth()
    const lang = auth?.principle?.user?.language?.slice(3)

    const getExportConfig = () => {
      return {
        exportConfig: {
          columnList: renderedColumns.value.map((v:any) => {
            return {
              grp: '',
              key: v.key,
              title: v.title?.props?.title || v.title
            }
          }),
          languageEnum: lang
        },
        ...(props.pageData.params || {})
      }
    }

    const computedHeight = () => {
      height.value = document.body.clientHeight - 60 - 40 - 32 - 160 - 16 - 56 - 64 - 68
      console.log(height.value)
    }

    onMounted(() => {
      if (props.sid) {
        const userCode = auth?.principle?.user?.userCode
        const canBatch = localStorage.getItem(`${userCode}_${props.sid}__hidenKeysVersion`) !== props.hidenKeysVersion
        if (props.hidenKeys && canBatch) {
          configField.batchUpdate(props.columns, props.hidenKeys as any, false)
          localStorage.setItem(`${userCode}_${props.sid}__hidenKeysVersion`, props.hidenKeysVersion as any)
        } else {
          configField.pull(props.columns)
        }
      }
      computedHeight()

      window.addEventListener('resize', computedHeight)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', computedHeight)
    })

    return {
      pagination,
      attrs,
      configField,
      eventHandlers,
      renderedColumns,
      getExportConfig
    }
  }
})
