
import { defineComponent, onMounted, ref, reactive, computed, PropType, watch, Ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { PageData, toRequest } from '@/common/page-data'
import { useDict } from '@/plugins/dict'

export default defineComponent({
  name: 'checkbox-control',
  props: {
    pageData: {
      type: Object as PropType<PageData<any>>,
      required: true
    },
    code: {
      type: String as PropType<string>,
      required: true
    },
    dictName: {
      type: String,
      required: true
    },
    field: {
      type: String
    },
    visible: {
      type: Object,
      required: true
    },
    local: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const options = ref<any[]>([])
    const value = ref([])

    const onSearch = () => {
      const { m } = useDict()
      const params = toRequest(Object.assign({}, props.pageData.params, { filterByField: props.code } /*, { filterFields: { [props.code]: '*' } } */))
      options.value = []
      if (!props.local) {
        props.pageData.queryFn(params as any).then(data => {
          options.value = (m(props.dictName) || []).filter((w: any) => {
            return !!(data?.rows || []).find((v: any) => {
              const code = v ? String(v[props.field || props.code]) : undefined
              return code && w.key === code
            })
          })
        })
      } else {
        options.value = m(props.dictName)
      }
    }

    const onReset = () => {
      value.value = []
      props.pageData.unfilter(props.code)
      props.visible.value = false
    }

    const onQuery = () => {
      if (value.value?.length < 1) {
        onReset()
      } else {
        props.pageData.filter({ name: props.code, value: value.value })
        props.visible.value = false
      }
    }

    watch(props.visible as any, () => {
      if (props.visible.value) {
        value.value = props.pageData.params?.filters?.find(v => v.name === props.code)?.value || ''
        onSearch()
      }
    })

    return {
      value,
      options,
      onSearch,
      onQuery,
      onReset
    }
  }
})
