import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.content])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.action])
        }, [
          _renderSlot(_ctx.$slots, "action")
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}