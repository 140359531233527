<script>
import { defineComponent, h, resolveComponent, resolveDynamicComponent, ref } from 'vue'
import _ from 'lodash'
import { removeKeyField, injectForm, mergeClassName, getComponentAttribute } from '@/components/air8/utils/componentUtils'
import { getDiffValue, isDiffNew } from '@/utils/diffUtils'

export default defineComponent({
  name: 'Air8FormItem',

  aliasName: 'C1',

  inheritAttrs: false,

  props: {
    type: [String, Object],
    name: [String, Number, Array],
    prop: [String, Number, Array],
    label: String,
    rules: Array,
    placeholder: String,
    getPopupContainer: Function,
    hideLabel: Boolean,
    itemLabel: String,
    tinyError: Boolean,
    validateFirst: {
      type: Boolean,
      default: true
    },
    wrapBrackets: {
      type: Boolean,
      value: true
    },
    showDiffTag: Boolean,
    itemProps: Object
  },

  setup () {
    const form = injectForm()
    const componentName = ref('')
    return {
      model: form.model,
      componentName
    }
  },

  computed: {
    isSelectComponent () {
      switch (this.componentName) {
        case 'Air8DictionarySelect':
        case 'ASelect':
          return getComponentAttribute(this.$attrs, 'showSearch') !== true
        case 'Air8CategorySelect':
          return getComponentAttribute(this.$attrs, 'showSearch') === false
        case 'Air8CheckboxGroup':
          return true
        default:
          return false
      }
    },

    localeLabel () {
      if (this.$te(this.label)) {
        return this.$t(this.label)
      } else {
        return this.label
      }
    },

    internalPlaceholder () {
      if (_.isNil(this.placeholder) && !_.isEmpty(this.localeLabel)) {
        const placeholder = this.isSelectComponent ? this.$t('common.please_select', this.localeLabel) : this.$t('common.please_input', this.localeLabel)
        return placeholder
      }
      return this.placeholder || ''
    },

    internalRules () {
      return _.map(this.rules, (rule) => {
        if (rule.label === true) {
          rule = {
            ...rule,
            label: this.localeLabel
          }
        }

        // eslint-disable-next-line no-template-curly-in-string
        if (rule.required || rule.placeholder === true) {
          return {
            ...rule,
            placeholder: this.internalPlaceholder
          }
        }
        return {
          ...rule,
          message: this.$t(rule.message)
        }
      })
    },

    modelName () {
      let name = this.name
      if (_.isNil(name)) {
        name = this.prop
      }
      return name
    },

    internalName () {
      const name = this.modelName
      if (!_.isString(name)) {
        return name
      } else if (_.includes(name, '.')) {
        return _.hasIn(this.model, [name]) ? name : _.split(name, '.')
      }
      return name
    },

    inputProps () {
      const props = removeKeyField(this.$attrs, /^(on.+|class|style)$/g)
      props.placeholder = this.internalPlaceholder
      return _.assign(props, this.itemProps)
    },

    inputEvents () {
      return _.pickBy(this.$attrs, (value, key) => {
        return key.startsWith('on')
      })
    }
  },

  methods: {
    isComponentHasProp (component, prop) {
      if (_.isNil(component)) {
        return false
      }
      const props = _.get(component, 'props')
      let hasProps = false
      if (_.isNil(props)) {
        hasProps = false
      } else if (_.isArray(props)) {
        hasProps = _.findIndex(props, (item) => {
          if (_.isNil(item)) {
            return false
          } else if (_.isString(item)) {
            return item === prop
          } else {
            return false
          }
        }) !== -1
      } else {
        hasProps = _.has(props, prop)
      }

      if (!hasProps && component.extends) {
        hasProps = this.isComponentHasProp(component.extends, prop)
      }
      return hasProps
    },

    getVModelProp (component) {
      if (this.isComponentHasProp(component, 'checked')) {
        return {
          checked: _.get(this.model, this.modelName),
          'onUpdate:checked': (val) => {
            _.set(this.model, this.modelName, val)
          }
        }
      } else if (this.isComponentHasProp(component, 'modelValue')) {
        return {
          modelValue: _.get(this.model, this.modelName),
          'onUpdate:modelValue': (val) => {
            _.set(this.model, this.modelName, val)
          }
        }
      } else {
        return {
          value: _.get(this.model, this.modelName),
          'onUpdate:value': (val) => {
            _.set(this.model, this.modelName, val)
          }
        }
      }
    },

    getPopupContainerProps (component) {
      if (this.isComponentHasProp(component, 'getPopupContainer')) {
        if (_.isFunction(this.getPopupContainer)) {
          return {
            getPopupContainer: this.getPopupContainer
          }
        } else {
          return {
            getPopupContainer: () => {
              return _.get(document.getElementsByClassName('air8-popup__container'), '0', this.$refs.container)
            }
          }
        }
      } else {
        return {}
      }
    },

    setModelValue (value) {
      _.set(this.model, this.modelName, value)
    },

    renderFormItem () {
      let component = this.type
      if (_.isString(this.type)) {
        component = resolveDynamicComponent(this.type)
      }

      let componentName = 'component'
      if (!_.isEmpty(_.get(component, 'name'))) {
        componentName = _.get(component, 'name')
      } else if (_.isString(this.type)) {
        componentName = _.upperFirst(_.camelCase(this.type))
      }
      this.componentName = componentName

      const className = mergeClassName(this.$attrs.class, {
        'air8-form-item': true,
        'form-label-hide': this.hideLabel,
        'form-error-tiny': this.tinyError,
        [`air8-form-item__${this.componentName}`]: true
      })

      return h(resolveComponent('i-form-item'), {
        class: className,
        name: this.internalName,
        rules: this.internalRules,
        colon: !_.isEmpty(this.label),
        validateFirst: this.validateFirst,
        label: this.label,
        wrapBrackets: this.wrapBrackets
      }, {
        default: () => {
          return [
            this.renderInput(component),
            _.invoke(this.$slots, 'after'),
            this.renderPopupContainer(component)
          ]
        }
      })
    },

    renderInput (component) {
      return h(
        component,
        {
          ...this.getVModelProp(component),
          ...this.inputProps,
          ...this.inputEvents,
          ...this.getPopupContainerProps(component),
          label: this.itemLabel
        },
        _.omit(this.$slots, ['after'])
      )
    },

    renderPopupContainer (component) {
      if (_.isEmpty(component)) {
        return ''
      }
      return (
        <div ref="container" class="air8-form-item__container"></div>
      )
    },

    renderDiffDirective (formItem) {
      if (!this.showDiffTag) {
        return formItem
      }
      let diffTagValue = getDiffValue(this.model, this.modelName)
      if (_.isEmpty(diffTagValue)) {
        diffTagValue = isDiffNew(this.model, this.modelName)
      }
      return (<div v-diff-tag={diffTagValue}>{formItem}</div>)
    }
  },

  render () {
    return this.renderDiffDirective(this.renderFormItem())
  }
})
</script>
<style lang="less" scoped>
.air8-form-item {
  &.ant-form-item {
    // margin-bottom: 16px;
    & > :deep(.ant-form-item-label > label) {
      color: #738094;
    }
  }

  &.form-label-hide.ant-form-item {
    & > :deep(.ant-form-item-label) {
      display: none;
    }
  }

  &.form-error-tiny {
    margin-bottom: 0px;
  }

  .air8-form-item__container {
    position: relative;
  }
}

.ant-form-inline .air8-form-item.ant-form-item {
  margin-bottom: 0px;
}

.ant-form-horizontal .air8-form-item {
  &.air8-form-item__Air8Label,
  &.air8-form-item__Air8MoneyLabel {
    align-items: center;
  }

  & > :deep(.ant-form-item-label) {
    max-width: calc(100% - 187px);
    min-width: 80px;
    text-align: left;

    label {
      max-width: 100%;
      min-height: 32px;
      height: auto;

      & > span {
        white-space: normal;
        word-break: break-all;
      }
    }
  }

  &:not(.form-label-hide) > :deep(.ant-form-item-control) {
    max-width: calc(100% - 80px);
  }

  &.air8-form-item__Air8ExchangeRate {
    & > :deep(.ant-form-item-label) {
      max-width: calc(100% - 220px);
    }
  }

  &.air8-form-item__Air8CategorySelect,
  &.air8-form-item__Air8CompanyCodeNameSelect {
    flex-wrap: nowrap;
  }

  &.air8-form-item__Air8DictionarySelect {
    & > :deep(.ant-form-item-label) {
      max-width: calc(100% - 252px);
    }
  }

  &.air8-form-item__Air8SelectOther {
    & > :deep(.ant-form-item-label) {
      max-width: calc(100% - 252px);
    }

    // & > :deep(.ant-form-item-control) {
    //   max-width: calc(100% - 126px);
    // }
  }
}
</style>
