
import { computed, defineComponent } from 'vue'
import { EyeOutlined, DownloadOutlined, FilePdfFilled, CloseOutlined, LoadingOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { ossDownload, ossPreview } from '@/common/oss'
import { Empty } from 'ant-design-vue'
import SlotExtra from './slot-extra'

export default defineComponent({
  name: 'file-list',
  props: {
    fileList: {
      type: Array,
      required: true,
      default: () => ([])
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    allowDeleteHistory: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: true
    },
    canDeleteFn: {
      type: Function
    }
  },
  emits: ['update:fileList', 'handleManualRemove', 'handleRemove'],
  components: { FilePdfFilled, EyeOutlined, DownloadOutlined, DeleteOutlined, SlotExtra },
  setup (props, { emit }) {
    const extNameList = computed(() => {
      const res:string[] = []
      props.fileList.forEach((i:any) => {
        const name = i?.name?.split('.')
        res.push(name?.[name.length - 1]?.toUpperCase())
      })
      return res
    })

    const handlePreview = (f:any) => {
      ossPreview(f)
    }

    const handleDownload = (f:any) => {
      ossDownload(f)
    }

    const handleDelete = (f:any) => {
      if (f.status !== 'done') {
        emit('handleManualRemove', f)
      }
      emit('handleRemove', f)
    }

    const handleCanDelete = (file: any) => {
      if (props.canDeleteFn) return props.canDeleteFn(file)
      return file?.id ? props.allowDeleteHistory : true
    }

    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      extNameList,
      handlePreview,
      handleDownload,
      handleCanDelete,
      handleDelete
    }
  }
})
