<template>
  <span class="air8-wrap-brackets-text">
    <span class="air8-wrap-brackets-text__content air8-wrap-brakets-text__mutil" v-if="isMultiLine">
      <template
        v-for="(text, index) in internalTexts"
        :key="index"
      >
        <air8-wrap-brackets-text-label
          class="air8-wrap-brakets-text__text"
          :value="text"
        >
        </air8-wrap-brackets-text-label>
      </template>
    </span>
    <template v-else>
      {{ value }}
    </template>
  </span>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import Air8WrapBracketsTextLabel from './label.vue'
export default defineComponent({
  name: 'Air8WrapBracketsText',
  components: {
    Air8WrapBracketsTextLabel
  },

  props: {
    value: String
  },

  computed: {
    internalTexts () {
      if (_.isEmpty(this.value)) {
        return [this.value]
      }

      const reg = /^([^()（）]+)([(（]+[^()（）]*[)）]+)$/g
      const result = reg.exec(this.value)
      if (_.isNil(result)) {
        return [this.value]
      }
      return [result[1], result[2]]
    },

    isMultiLine () {
      return _.size(this.internalTexts) > 1
    }
  }
})
</script>
<style lang="less" scoped>
.air8-wrap-brackets-text {
  max-width: 100%;
  display: inline-flex;

  .air8-wrap-brackets-text__content {
    max-width: 100%;
  }
}

.air8-wrap-brakets-text__text {
  display: block;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}
.air8-wrap-brakets-text__mutil {
  display: inline-block;
  text-align: center;
}
</style>
