import store from '@/store'

export function t (path: string, ...variables: string[]) {
  const locale = (store.state as any).app.locale
  return translate(locale, path, ...variables)
}

function translate (context: Record<string, any>, path: string, ...params: string[]) {
  const str: string = getObjectValueByPath(context, path, null)

  if (!str) return path

  return replace(str, params)

  function getObjectValueByPath (obj: any, path: string, fallback?: any): any {
    if (obj == null || !path || typeof path !== 'string') return fallback
    if (obj[path] !== undefined) return obj[path]
    path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    path = path.replace(/^\./, '') // strip a leading dot
    return getNestedValue(obj, path.split('.'), fallback)

    function getNestedValue (obj: any, path: (string | number)[], fallback?: any): any {
      const last = path.length - 1

      if (last < 0) return obj === undefined ? fallback : obj

      for (let i = 0; i < last; i++) {
        if (obj == null) {
          return fallback
        }
        obj = obj[path[i]]
      }

      if (obj == null) return fallback

      let ret = obj[path[last]]

      if (Object.prototype.toString.call(ret) === '[object Object]') ret = ret?.label

      return ret === undefined ? fallback : ret
    }
  }

  function replace (str: string, params: unknown[]) {
    return str.replace(/\{(\d+)\}/g, (match: string, index: string) => {
      /* istanbul ignore next */
      return String(params[+index])
    })
  }
}
