
import { queryCompanyList } from '@/api/common'
import _ from 'lodash'
import { computed, defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    role: {
      type: String
    },
    isCode: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => ({})
    },
    queryFn: {
      type: Function
    }
  },
  emits: ['change', 'update:value'],
  setup (props, { emit }) {
    const val = computed(() => props.value)

    const options = ref<Array<any>>([])
    const fetching = ref<boolean>(false)

    const queryFn = props.queryFn || queryCompanyList

    const handleSearch = _.debounce((key) => {
      fetching.value = true
      const params = {
        ...props.query,
        role: props.role?.toUpperCase(),
        [props.isCode ? 'code' : 'name']: key
      }
      queryFn(params).then((data: any) => {
        options.value = (data || []).map((v: any) => {
          return {
            value: props.isCode ? v.code : v.name,
            label: props.isCode ? v.code : v.name,
            data: v
          }
        })
        fetching.value = false
      })
    }, 500)

    const handleChange = (v: string, it: any) => {
      emit('update:value', v, it)
      emit('change', v, it)
    }

    onMounted(() => handleSearch(''))

    return {
      val,
      options,
      fetching,
      handleSearch,
      handleChange
    }
  }
})
