import options, { PageConfig, PageInstance } from './options'

export const pages: PageInstance[] = normalize(options)

console.log('pages', pages)

export default pages

export * from './options'

function normalize (options: PageConfig[]): PageInstance[] {
  return traverse(options, undefined)

  function traverse (options: PageConfig[], parent?: PageInstance): PageInstance[] {
    return options.map(v => {
      const item = {
        name: v.name,
        label: v.label,
        type: v.type,
        children: undefined,
        path: (parent?.path || '') + '/' + v.name,
        parent: parent,
        pid: v.pid,
        router: normalizeRouter(v, parent),
        belong: v.belong
      }
      Object.assign(item, { children: v.children ? traverse(v.children, item) : undefined })
      return item
    })
  }

  function normalizeRouter (option: PageConfig, parent?: PageInstance) {
    if (!option?.router?.path) return undefined
    const ctx = [parent?.path || '', option.name]
    const fn = (expr: string) => {
      /* eslint-disable-next-line no-new-func */
      return new Function('path', 'name', 'return ' + '`' + expr + '`')
    }
    const extra = {
      path: fn(option.router.path)(...ctx)
    }
    return Object.assign({}, option?.router || {}, extra)
  }
}
