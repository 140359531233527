
import { useHttp } from '@/plugins/http/src/index'
const base = '/api/air8-financial-transaction/backend/workflow'

export function queryWorkflowHistory (params: any) {
  params = params || {}
  const { http } = useHttp()
  return http.post(`${base}/history`, params)
}

export function queryAllNodes (params: any) {
  params = params || {}
  const { http } = useHttp()
  return http.get('/api/air8-work-flow/api/query/client/def/nodes', { params })
}
