export interface DictConfig {
  key: string | number;

  value: string;

  name: string;

  status: boolean;

  parentName?: string;

  seq?: number;
}

export interface DictIntance {
  key: string | number;

  value: string;

  name: string;

  status: boolean;

  seq?: number;

  parent?: DictIntance;

  children?: DictIntance[];

  description?: string;
}

export type DictMap = Record<string, DictIntance[]>

/**
 * 说明：本地字典请使用"_"做前缀，以区别服务端字典。 注意国际化文字需要用key表示
 */
const options: DictConfig[] = [
  // yesOrNo
  { name: '_yesOrNo', key: 'Y', value: '是', status: true },
  { name: '_yesOrNo', key: 'N', value: '否', status: true },
  { name: '_yesOrNo', key: 'U', value: '未知', status: false },
  // country
  { name: '_country', key: 'ZH', value: '中国', status: true },
  { name: '_country', key: 'US', value: '美国', status: true },
  // lang
  { name: '_lang', key: 'zh-CN', value: '中文简体', status: true },
  { name: '_lang', key: 'en-US', value: 'English', status: true },
  // timeZone
  { name: '_timeZone', key: '0', value: '中央时区', status: true },
  { name: '_timeZone', key: '+1', value: '东一区', status: true },
  { name: '_timeZone', key: '+2', value: '东二区', status: true },
  { name: '_timeZone', key: '+3', value: '东三区', status: true },
  { name: '_timeZone', key: '+4', value: '东四区', status: true },
  { name: '_timeZone', key: '+5', value: '东五区', status: true },
  { name: '_timeZone', key: '+6', value: '东六区', status: true },
  { name: '_timeZone', key: '+7', value: '东七区', status: true },
  { name: '_timeZone', key: '+8', value: '东八区', status: true },
  { name: '_timeZone', key: '+9', value: '东九区', status: true },
  { name: '_timeZone', key: '+10', value: '东十区', status: true },
  { name: '_timeZone', key: '+11', value: '东十一区', status: true },
  { name: '_timeZone', key: '12', value: '十二区', status: true },
  { name: '_timeZone', key: '-1', value: '西一区', status: true },
  { name: '_timeZone', key: '-2', value: '西二区', status: true },
  { name: '_timeZone', key: '-3', value: '西三区', status: true },
  { name: '_timeZone', key: '-4', value: '西四区', status: true },
  { name: '_timeZone', key: '-5', value: '西五区', status: true },
  { name: '_timeZone', key: '-6', value: '西六区', status: true },
  { name: '_timeZone', key: '-7', value: '西七区', status: true },
  { name: '_timeZone', key: '-8', value: '西八区', status: true },
  { name: '_timeZone', key: '-9', value: '西九区', status: true },
  { name: '_timeZone', key: '-10', value: '西十区', status: true },
  { name: '_timeZone', key: '-11', value: '西十一区', status: true },
  // 用户状态
  { name: '_userStatus', key: '', value: '所有', status: true },
  { name: '_userStatus', key: 'unActive', value: '未激活', status: true },
  { name: '_userStatus', key: 'active', value: '已激活', status: true },
  { name: '_userStatus', key: 'stop', value: '已停用', status: true },
  // 公司角色
  { name: '_companyRole', key: 'all', value: '所有', status: true },
  { name: '_companyRole', key: 'supplier', value: '供应商', status: true },
  { name: '_companyRole', key: 'buyer', value: '买方', status: true },
  { name: '_companyRole', key: 'capital', value: '资金方', status: true },

  { name: '_frequency', key: 'once', value: '一次', status: true },
  { name: '_frequency', key: 'half', value: '半小时', status: true },
  { name: '_frequency', key: 'an', value: '一小时', status: true },
  { name: '_frequency', key: 'two', value: '两小时', status: true },

  { name: '_fundSideDateBase', key: 360, value: '360', status: true },
  { name: '_fundSideDateBase', key: 365, value: '365', status: true },

  { name: '_purchaseOrderStatus', key: 'FINANCED', value: '已融资', status: true },
  { name: '_purchaseOrderStatus', key: 'SETTLED', value: '已结清', status: true },
  { name: '_purchaseOrderStatus', key: 'OVERDUE', value: '已逾期', status: true },

  { name: '_valueSymbol', key: 'NULL', value: '空', status: true },
  { name: '_valueSymbol', key: '-', value: '-', status: true },

  { name: '_counterparty', key: 'supplierCode', value: '供应商', status: true },
  { name: '_counterparty', key: 'buyerCode', value: '买方', status: true }
]

export default options
