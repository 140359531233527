import _ from 'lodash'
import { useDict } from '@/plugins/dict'
import { BANK_ACCT_STATUS } from '@/common/constants'
import { useLocal } from '@/plugins/locale'
export default {
  // only new add bank card (no KYC)
  isBankPassKYC (bankAccount: any): boolean {
    return _.get(bankAccount, 'canEnable', false)
  },

  // status is enabled
  isBankEnabled (bankAccount: any): boolean {
    return _.get(bankAccount, 'status') === BANK_ACCT_STATUS.ENABLE
  },

  // status is disabled
  isBankDisabled (bankAccount: any): boolean {
    return !this.isBankEnabled(bankAccount)
  },

  isBankPendingActivate (bankAccount: any): boolean {
    if (_.isNil(bankAccount)) {
      return false
    }
    return bankAccount.canEnable !== true && !this.isBankEnabled(bankAccount)
  },

  // set status is enabled
  setBankEnabled (bankAccount: any): void {
    _.set(bankAccount, 'status', 'ENABLE')
  },

  // set status is disabled
  setBankDisabled (bankAccount: any): void {
    _.set(bankAccount, 'status', 'DISABLE')
  },

  getFullBankStatus (bankAccount: any): string {
    if (this.isBankPendingActivate(bankAccount)) {
      return BANK_ACCT_STATUS.PENDING_ACTIVATE
    } else {
      return _.get(bankAccount, 'status')
    }
  },

  getFullBankStatusDictionaryKeyValue () {
    const { m } = useDict()
    const { t } = useLocal()
    return [
      { key: BANK_ACCT_STATUS.PENDING_ACTIVATE, value: t('common.pending_activate') },
      ...m('BANK_ACCT_STATUS')
    ]
  },

  getFullBankStatusDictionary () {
    const { m } = useDict()
    const { t } = useLocal()
    return [
      { value: BANK_ACCT_STATUS.PENDING_ACTIVATE, label: t('common.pending_activate') },
      ...m.t('BANK_ACCT_STATUS')
    ]
  }
}
