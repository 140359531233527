import options, { DictConfig, DictMap, DictIntance } from './options'

// 本地字典
export const localDictMap = normalize(options)

/**
 * 说明：暂不实现层级字典，即 parent、children 没有值，将来有需要请自行实现
 * @param options
 * @returns
 */
export function normalize (options: DictConfig[]): DictMap {
  const map: DictMap = {}
  options.forEach(v => {
    if (!map[v.name]) map[v.name] = []
    map[v.name].push(transform(v))
  })
  return map

  function transform (option: DictConfig): DictIntance {
    const { key, name, value, status, seq } = option
    return { key, name, value, status, seq }
  }
}

export function merge (d1: DictMap, d2: DictMap): DictMap {
  return Object.assign({}, d1, d2)
}
