<template>
    <div>
        <span :class="v ? (v === 'PEP' && status === 'INACTIVE') ? $style.pepText : $style.text : ''" v-for="(v, i) in dataSource" :key="i" @mousemove="onMouseOver(v)">
            <air8-tooltip v-if="v">
              <span>{{v}}</span>
              <template #title >
                <div v-if="['INACTIVE'].includes(status) && v === 'PEP'">
                  <span>{{lt('pep')}}</span>  <br>
                  <span>{{lt('inactive')}}</span>
                </div>
                <div v-else-if="['ACTIVE'].includes(status) && v === 'PEP'">
                  <span>{{lt('pep')}}</span>  <br>
                  <span>{{lt('active')}}</span>
                </div>
                <span v-else>{{tipText}}</span>
              </template>
            </air8-tooltip>
            <span v-else>{{'-'}}</span>
        </span>
    </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { t } from '@/config/locale'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8Tag',

  props: {
    value: [String, Array],
    status: String
  },

  setup (props) {
    const lt = (name, ...args) => t(`page.org.company.kyc.refinitiv.details.${name}`, ...args)
    const tipText = ref()

    const enumType = {
      SIC: lt('sic'),
      S: lt('s'),
      RE: lt('re'),
      PEP: lt('pep'),
      OB: lt('ob'),
      LE: lt('le')
    }

    const dataSource = computed(() => {
      if (_.isString(props.value)) {
        return props.value.split(',')
      }
      return props.value
    })

    const onMouseOver = (v) => {
      tipText.value = enumType[v]
    }

    return {
      lt,
      tipText,
      dataSource,
      onMouseOver
    }
  }
})
</script>
<style lang="less" module>
@import "~@/styles/vars.less";
    .text {
        margin-right: 5px;
        color: #fff;
        background-color: #1890ff;
        padding: 0 5px;
        border-radius: 3px;
    }
    .pepText {
        margin-right: 5px;
        color: #fff;
        background-color: #1890ff;
        padding: 0 5px;
        border-radius: 3px;
        OPACITY: 0.4
    }
    .tooltip{
     :global {
      .ant-tooltip-inner{
        color: rgba(0, 0, 0, 0.85) !important;
        background-color: #fff !important;
      }
      .ant-tooltip-arrow{
       .ant-tooltip-arrow-content{
        background-color: #fff !important;
      }
    }
  }
}

</style>
