import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'
import { PROP_TYPE } from '../constants'

export const CONDITION_INPUT = {
  INPUT: 'form-input',
  SELECT: 'form-select',
  SEARCH_SELECT: 'form-search-select',
  SELECT_MUTIL: 'form-select-multiple',
  SELECT_OPTIONS: 'form-select-options',
  DATE: 'form-date'
}

export default class TableBuilder extends DefaultBuilder {
  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }
    const result = _.chain(currentConfig)
      .filter((columnConfig) => {
        return (
          !_.isNil(columnConfig) && (
            columnConfig.$query === true ||
            _.isNumber(columnConfig.$query) ||
            _.isPlainObject(columnConfig.$query)
          )
        )
      })
      .map((columnConfig) => this.initCondition(columnConfig))
      .sortBy(['$position'])
      .value()

    return result
  }

  private initCondition (columnConfig:any):any {
    if (_.isNil(columnConfig)) {
      return columnConfig
    }
    columnConfig = _.clone(columnConfig)
    this.dealDictNameField(columnConfig)
    this.dealQueryNameField(columnConfig)
    this.dealPropTypeField(columnConfig)
    this.dealQueryField(columnConfig)
    return columnConfig.$query
  }

  private dealDictNameField (columnConfig: any) {
    if (_.isEmpty(columnConfig.$dictName)) {
      columnConfig.$dictName = columnConfig.ref
    }
  }

  private dealQueryNameField (columnConfig: any) {
    if (_.isEmpty(columnConfig.$queryName)) {
      columnConfig.$queryName = columnConfig.dataIndex
    }
  }

  private dealPropTypeField (columnConfig: any) {
    if (!_.isNil(columnConfig.$propType)) {
      return
    }
    const defaultConfig:any = {}
    if (!_.isEmpty(columnConfig.$dictName)) {
      defaultConfig.$propType = PROP_TYPE.ENUM
    }
    _.defaults(columnConfig, defaultConfig)
  }

  private dealQueryField (columnConfig:any):any {
    let query = columnConfig.$query
    if (query === true) {
      query = {
        $position: 0
      }
    } else if (_.isNumber(query)) {
      query = {
        $position: query
      }
    } else {
      query = _.clone(query)
    }
    columnConfig.$query = query

    switch (columnConfig.$propType) {
      case PROP_TYPE.DATE:
        _.defaults(query, {
          $type: CONDITION_INPUT.DATE
        })
        break
      case PROP_TYPE.ENUM:
        _.defaults(query, {
          $type: CONDITION_INPUT.SELECT
        })
        break
      default:
        _.defaults(query, {
          $type: CONDITION_INPUT.INPUT,
          label: columnConfig.title,
          prop: columnConfig.$queryName
        })
    }

    switch (query.$type) {
      case CONDITION_INPUT.INPUT:
      case CONDITION_INPUT.DATE:
        _.defaults(query, {
          label: columnConfig.title,
          prop: columnConfig.$queryName
        })
        break
      case CONDITION_INPUT.SELECT:
      case CONDITION_INPUT.SEARCH_SELECT:
      case CONDITION_INPUT.SELECT_MUTIL:
        _.defaults(query, {
          label: columnConfig.title,
          prop: columnConfig.$queryName,
          name: columnConfig.$dictName
        })
        break
      case CONDITION_INPUT.SELECT_OPTIONS:
        _.defaults(query, {
          label: columnConfig.title,
          prop: columnConfig.$queryName
        })
        break
    }

    return query
  }
}
