<script>
import { array2Object, initObjectByPath } from '@/utils/objectUtils'
import { defineComponent, resolveComponent, h, watch, unref } from 'vue'
import { injectForm } from '@/components/air8/utils/componentUtils'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8DynamicUploadList',
  aliasName: 'a8-d-upload-list',

  props: {
    fields: Array
  },

  computed: {
    title () {
      return array2Object(this.fields, 'name', 'label')
    },

    modelValue () {
      return _.pick(this.model, _.keys(this.title))
    }
  },

  setup (props) {
    const form = injectForm()
    const model = form.model

    watch(() => props.fields, () => {
      _.each(props.fields, (field) => {
        initObjectByPath(unref(model), field.name)
        if (_.isNil(_.get(unref(model), field.name))) {
          _.set(model.value, field.name, [])
        }
      })
    }, {
      immediate: true
    })

    return {
      model
    }
  },

  render () {
    return h(resolveComponent('UploadList'), {
      ...this.$attrs,
      title: this.title,
      modelValue: this.modelValue
    }, this.$slots)
  }
})
</script>
