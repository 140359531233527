<template>
  <upload
    v-bind="$attrs"
    :value="value"
  >
    <template
      v-for="(func, name) in $slots"
      #[name]="scope"
    >
      <slot
        :name="name"
        v-bind="scope"
      ></slot>
    </template>
  </upload>
</template>
<script>
import { defineComponent, onMounted, watch } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'UploadListUpload',
  inheritAttrs: false,

  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  emits: ['change', 'update:value'],

  setup (props, { emit }) {
    watch(() => props.value, (curr, prev) => {
      emit('update:value', props.value)
      emit('change', props.value)
    }, {
      // deep: true
    })

    return {}
  }
})
</script>
