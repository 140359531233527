import { ref } from 'vue'
export interface LoginParams {
  username: string;
  password: string;
  code?: string;
  loginName: string;
}

export interface User {
  username: string;
  cname: string;
  [key: string]: any;
}

export interface Role {
  roleName: string;
  roleCode: string;
  [key: string]: any;
}

export interface Authority {
  pid: string;
  [key: string]: any;
}

export interface Principle {
  user: User;
  roles: Role[];
  authorities: Authority[];
}
export interface AuthOptions {
  principleLoader?: () => Promise<Principle>;

  logoutHandler?: () => Promise<string>;

  loginHandler?: (params: LoginParams) => Promise<any>;
}

export default class Auth {
  constructor (options: AuthOptions = {}) {
    this.options = options
  }

  options: AuthOptions

  principle: Principle | null = null

  get username (): string {
    return this.principle?.user?.username || ''
  }

  get cname (): string {
    return this.principle?.user?.cname || ''
  }

  get roles (): Role[] {
    return this.principle?.roles || []
  }

  get authorities (): Authority[] {
    return this.principle?.authorities || []
  }

  get authenticated (): boolean {
    return !!this.principle
  }

  hasAnyRole (...roles: string[]): boolean {
    return roles.some(v => this.roles.some(w => w.roleCode === v))
  }

  hasAllRole (...roles: string[]): boolean {
    return roles.every(v => this.roles.some(w => w.roleCode === v))
  }

  // 登录
  login (params: LoginParams): Promise<any> {
    if (!this.options?.loginHandler) throw new Error('please specific loginHandler')
    return this.options?.loginHandler(params).then(data => {
      const token = data?.token || ''
      localStorage.setItem('authentication', JSON.stringify({ token }))
      return data
    })
  }

  // 前往登录页面
  toLogin (data: string, logout = false) {
    this.principle = null // 删除认证实体
    const url = logout ? '/login' : `/login?backUrl=${encodeURIComponent(location.href)}`
    window.location.assign(url)
  }

  // 控制访问
  access (pid: string): Promise<boolean> {
    if (this.authenticated) {
      const has = this.authorities.some(v => v.pid === pid)
      return Promise.resolve(has)
    } else {
      return new Promise((resolve, reject) => {
        this.loadPrinciple().then(() => {
          const has = this.authorities.some(v => v.pid === pid)
          resolve(has)
        }).catch((e: Error) => {
          reject(e)
        })
      })
    }
  }

  // 同步控制访问
  accessSync (pid: string):boolean {
    const poolActive = localStorage.getItem('poolActive')
    if (poolActive === 'DBS_POOL_FACTORING') {
      return this.authorities.filter((item:any) => !item.pid.startsWith('/finance/finance-order') && !item.pid.startsWith('/finance/poolFactoring') && !item.pid.startsWith('/finance/account') && !item.pid.startsWith('/finance/interest')).some(v => v.pid === pid)
    } else if (poolActive === 'AIR8_POOL_FACTORING') {
      return this.authorities.filter((item:any) => !item.pid.startsWith('/finance/finance-order') && !item.pid.startsWith('/finance/dbsFinance') && !item.pid.startsWith('/payment') && !item.pid.startsWith('/repayment')).some(v => v.pid === pid)
    } else {
      return this.authorities.filter((item:any) => !item.pid.startsWith('/finance/dbsFinance') && !item.pid.startsWith('/finance/poolFactoring') && !item.pid.startsWith('/finance/account') && !item.pid.startsWith('/finance/interest')).some(v => v.pid === pid)
    }
    // if (poolActive) {
    //   return this.authorities.filter((item:any) => !item.pid.startsWith('/finance/finance-order')).some(v => v.pid === pid)
    // } else {
    //   return this.authorities.filter((item:any) => !item.pid.startsWith('/finance/dbsFinance')).some(v => v.pid === pid)
    // }
  }

  // 退出
  logout () {
    if (!this.options?.logoutHandler) throw new Error('please specific logoutHandler')
    this.options.logoutHandler().then(data => {
      this.toLogin(data, true)
    })
  }

  // 加载用户信息
  load (refresh = false): Promise<Principle> {
    if (this.authenticated && !refresh) return Promise.resolve(this.principle as Principle)
    return this.loadPrinciple()
  }

  get isDirector () {
    return (this.principle?.user?.userRoles || '').includes('Director')
  }

  private loadPrinciple (): Promise<Principle> {
    if (this.options?.principleLoader) {
      return this.options.principleLoader().then(data => {
        this.principle = data
        return this.principle
      })
    }
    throw Error('please specific PrincipleLoader')
  }
}
