import { useTimeZone } from '@/plugins/time-zone'
import _ from 'lodash'
export interface Sorter {
  prop: string
  order: 'desc' | 'asc'
}

export interface Filter {
  name: string
  value: any
}

export interface Pageable {
  page: number

  size: number

  sorter?: Sorter

  filters?: Filter[]
}

export interface PageQueryParams extends Pageable {

  [name: string]: any
}

export interface PageResponse<T> {
  page: number

  size: number

  total: number

  rows: T[]
}

export type PageQueryFn<T> = (params: PageQueryParams) => Promise<PageResponse<T>>

const defaultQueryParams = { page: 1, size: 20 }

export const toRequest = (params: PageQueryParams): Record<string, any> => {
  const filters: any = {}
  const data: any = Object.assign({}, params, { pageNum: params.page, pageSize: params.size })
  if (params.filters) {
    const filters: any = {}
    params.filters.forEach(v => {
      filters[v.name] = v.value
    })
    data.filterFields = mapping(filters)
  }
  if (params.sorter) {
    data.orderByField = params.sorter?.prop
    data.isDesc = params.sorter.order === 'desc'
  }
  delete data.page
  delete data.size
  delete data.filters
  delete data.sorter
  return data

  function mapping (params: Record<string, any> = {}) {
    const ret: Record<string, any> = {}
    const { tz } = useTimeZone()
    Object.entries(params).forEach(([k, v]) => {
      if (Array.isArray(v) && isDate(v[0])) {
        const mode: string[] = ['S', 'E']
        ret[k] = v.map((w, i) => tz.s(w.toDate(), mode[i]))
        ret[k + 'Begin'] = tz.s(v[0].toDate(), 'S')
        ret[k + 'End'] = tz.s(v[1].toDate(), 'E')
      } else if (Array.isArray(v) && (_.isNumber(v[0]) || _.isNumber(v[1]))) {
        ret[k] = v
        ret[k + 'Begin'] = v[0]
        ret[k + 'End'] = v[1]
      } else if (isDate(v)) {
        ret[k] = tz.s(v.toDate())
      } else {
        ret[k] = typeof v === 'string' ? v.trim() : v
      }
    })
    return ret
    function isDate (obj: any) {
      return Object.prototype.toString.call(obj) === '[object Object]' && obj?._isAMomentObject
    }
  }
}

export function fromResp<T> (ret: Promise<any>): Promise<PageResponse<T>> {
  return ret.then(data => {
    return {
      page: data.pageNum || data.page,
      size: data.pageSize || data.size,
      total: data.total || data.totalNum,
      rows: data.list || data.items || data.list?.list || []
    }
  })
}

export class PageData<T> {
  constructor (queryFn?: PageQueryFn<T>, dp?: any) {
    if (queryFn) this.queryFn = queryFn
    if (dp) {
      this.defaultQueryParams = dp
      Object.assign(this.params, this.defaultQueryParams)
    }
  }

  total = 0

  loading = false

  defaultQueryParams = defaultQueryParams

  params: PageQueryParams = Object.assign({}, this.defaultQueryParams)

  rows: T[] = []

  hasMore = true

  empty = false

  hasError = false

  queryFn!: PageQueryFn<T>

  sumQueue: Array<{ prop: string; value: number }> = []

  initalData = false

  _load (params?: Partial<PageQueryParams>, reserved = false) {
    this.initalData = true

    if (params) this.params = Object.assign({}, this.defaultQueryParams, params)
    this.loading = true
    this.sumQueue = []
    return this.queryFn(this.params).then(data => {
      const { page, size } = data
      Object.assign(this.params, this.defaultQueryParams, { page, size })
      this.total = data?.total
      this.rows = reserved ? merge(this.rows, data?.rows) : data?.rows
      this.hasMore = (page + 1) * size > this.total
      this.empty = data?.rows.length < 1
      this.hasError = false
    }).catch(e => {
      if (!reserved) this.rows = []
      this.hasError = true
      throw e
    }).finally(() => {
      this.loading = false
    })

    function merge (source: any[] = [], target: any[] = [], key = 'id') {
      const set = new Set(source.map(v => v.id))
      return [...source, ...target.filter(v => !set.has(v.id))]
    }
  }

  load (params: Partial<PageQueryParams> = {}) {
    this._load(params)
  }

  sort (params: Partial<PageQueryParams> = {}) {
    this._load(Object.assign({}, this.params, this.defaultQueryParams, params))
  }

  filter (...filter: Filter[]) {
    const filters = this.params?.filters || []
    const addFilters: Filter[] = []
    filter.forEach(v => {
      const item = filters.find(w => w.name === v.name)
      if (item) Object.assign(item, v)
      else addFilters.push(v)
    })
    filters.push(...addFilters)
    this.params.filters = filters

    const { prop, order } = this.params
    this._load(Object.assign({ ...this.params, ...this.defaultQueryParams, prop, order }))
  }

  unfilter (...filter: string[]) {
    const filters = this.params?.filters || []
    filter.forEach(v => {
      const idx = filters.findIndex(w => w.name === v)
      if (idx >= 0) filters.splice(idx, 1)
    })
    const { prop, order } = this.params
    this._load(Object.assign({ ...this.params, ...this.defaultQueryParams, prop, order }))
  }

  gotoPage (page = 0) {
    this._load(Object.assign({}, this.params, { page: page }))
  }

  loadNext () {
    if (!this.hasMore || this.hasError || this.loading) return
    this._load(Object.assign({}, this.params, { page: this.params.page === 1 ? 1 : this.params.page + 1 }), true)
  }

  reload (reset = false) {
    if (reset) {
      this.params.page = 1
    }
    return this._load()
  }
}
