<template>
  <div
    class="air8-action-page"
    ref="actionPage"
    v-bind="parentClass"
  >
    <a-spin
      :spinning="loading"
      :tip="loadingTip"
      :style="spinStyle"
    >
      <air8-detail-page
        ref="detailPage"
        v-bind="$attrs"
        :status="status"
        :config="config"
        :model-value="modelValue"
      ></air8-detail-page>
      <slot name="default"></slot>
      <air8-group-actions
        class="air8-edit-page__footer"
        :actions-config="actionsConfig"
        :go-back="goBack"
        ref="actions"
      ></air8-group-actions>
    </a-spin>
  </div>
</template>
<script>
import { defineComponent, onMounted, watch, ref, computed, onUnmounted } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8ActionPage',
  inheritAttrs: false,
  props: {
    class: [String, Array, Object],
    style: [String, Object],
    status: String,
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    actionsConfig: Array,
    goBack: [Boolean, Number, String, Function, Object],
    loadingTip: String,
    loadingFixed: Boolean
  },

  setup (props) {
    const actionPage = ref()
    const detailPage = ref()
    const actions = ref()
    const loading = ref(false)
    const actionLoading = ref(false)
    function getForm () {
      return detailPage.value.getForm()
    }

    async function invokeFunction (method, loading, ...methodsArguments) {
      if (!_.isFunction(method)) {
        return
      }

      _.set(loading, 'value', true)
      try {
        await method(...methodsArguments)
      } catch (e) {
        console.error(e)
      }
      _.set(loading, 'value', false)
    }

    function setLoading (value) {
      loading.value = value
    }

    function init () {
      invokeFunction(props.initFunction, loading)
    }

    function invokeGoBack () {
      actions.value.invokeGoBack()
    }

    function invokeAction (action, ...actionArguments) {
      actions.value.invokeAction(action, ...actionArguments)
    }

    onMounted(() => {
      if (props.loadingFixed) {
        bindSpinScrollEvent()
      }
      init()
    })

    onUnmounted(() => {
      if (props.loadingFixed) {
        unbindSpinScrollEvent()
      }
    })

    watch(() => props.loadingFixed, () => {
      if (props.loadingFixed) {
        bindSpinScrollEvent()
      } else {
        unbindSpinScrollEvent()
      }
    })

    function bindSpinScrollEvent () {
      if (!actionPage.value) {
        return
      }
      actionPage.value.parentElement.addEventListener('scroll', updateSpinTopWhenScroll)
    }

    function unbindSpinScrollEvent () {
      if (!actionPage.value) {
        return
      }
      actionPage.value.parentElement.removeEventListener('scroll', updateSpinTopWhenScroll)
    }

    watch(loading, () => {
      updateSpinTopWhenScroll()
    })

    const spinTop = ref(0)
    const spinStyle = computed(() => {
      if (!props.loadingFixed || !loading.value) {
        return {}
      }
      return {
        top: spinTop.value + 'px'
      }
    })

    function updateSpinTopWhenScroll () {
      if (!actionPage.value) {
        return
      }
      spinTop.value = actionPage.value.parentElement.scrollTop
    }

    return {
      loading,
      actionLoading,
      detailPage,
      actions,
      getForm,
      setLoading,
      parentClass: {
        class: props.class,
        style: props.style
      },
      invokeAction,
      invokeGoBack,
      init,
      actionPage,
      spinStyle
    }
  }
})
</script>
<style lang="less" scoped>
@import '~@/styles/vars.less';

.air8-action-page {
  .air8-edit-page__footer {
    text-align: center;

    /deep/ .ant-btn {
      padding-left: 24px;
      padding-right: 24px;
      margin: 0px 16px;

      &.air8-btn-warning {
        background-color: @color-warning;
        border-color: @color-warning;
        color: white;
      }
      &.air8-btn-danger {
        background-color: @color-danger;
        border-color: @color-danger;
        color: white;
      }
    }
  }
}
</style>
