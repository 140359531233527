<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from 'lodash'
import { injectParent } from './cardService'

export default defineComponent({
  name: 'Air8NoneCard',
  inheritAttrs: false,

  setup () {
    injectParent()
    return {
    }
  }
})
</script>
