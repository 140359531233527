
import { computed, defineComponent, PropType, provide, reactive, ref } from 'vue'

export default defineComponent({
  name: 'input-number',
  props: {
    value: {
      required: true
    },
    formatter: {},
    suffix: {
      // default: '%'
    }
  },
  emits: ['update:value'],
  setup (props, context) {
    const val = computed<any>({
      get: () => props.value,
      set: (v: any) => {
        context.emit('update:value', v)
      }
    })

    const defaultFormatter = (value: any) => {
      if ([undefined, null].includes(value)) return value
      const reg1 = /(\d)(?=(\d{3})+(\.))/g
      const reg2 = /(\d)(?=(\d{3})+($))/g
      const hasDot = (value + '').includes('.')
      const ret = (value + '').replace(hasDot ? reg1 : reg2, '$1,')
      return ret ? (ret + (props.suffix || '')) : ret
    }

    const parser = (value: any) => {
      const ret = props.suffix ? value.replace(props.suffix, '') : value
      return ret.replace(/\$\s?|(,*)/g, '')
    }

    return {
      val,
      defaultFormatter,
      parser
    }
  }
})

