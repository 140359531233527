<script>
import { defineComponent, h, resolveComponent, watch, unref, watchEffect } from 'vue'
import Rules from '@/components/air8/rules'
import { injectForm } from '@/components/air8/utils/componentUtils'
import { initObjectByPath } from '@/utils/objectUtils'

export default defineComponent({
  name: 'Air8DynamicFormItem',
  aliasName: 'a8-d-form-item',

  props: {
    type: String,
    dataType: String,
    name: String,
    label: String,
    required: Boolean,
    editable: Boolean,
    disabled: Boolean
  },

  computed: {
    internalRules () {
      const rules = []
      if (this.required) {
        rules.push({ ...Rules.required, type: this.dataType })
      }
      return rules
    }
  },

  setup (props) {
    const form = injectForm()
    const model = form.model
    watch(() => props.name, () => {
      initObjectByPath(unref(model), props.name)
    }, {
      immediate: true
    })

    return {
      model
    }
  },

  render () {
    return h(resolveComponent('Air8FormItem'), {
      ...this.$attrs,
      type: this.type,
      name: this.name,
      label: this.label,
      rules: this.internalRules
    }, this.$slots)
  }
})
</script>
