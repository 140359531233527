<template>
  <div>
    <div v-if="isHideExchange">-</div>
    <div
      :class="{ [$style['air8-exchange-rate']]: true, [$style['air8-exchange-rate__readonly']]: !editable }"
      v-else
    >
      <air8-form-item
        ref="exchangeRatePrefixFormItem"
        :type="inputComp"
        :name="exchangeRatePrefixName"
        :label="$t('page.repayment.info.bankInfo.columns.exchangeRateInput')"
        :hide-label="true"
        :rules="internalRulesPrefix"
        :precision="4"
        :integer-not-precision="true"
        :min="0.0001"
        :formatter="inputFormatter"
        :decimal-length="4"
        @blur="handleBlurPrefix"
        @changeOnBlur="handleChangeOnBlurPrefix"
      >
      </air8-form-item>
      <air8-label
        dict-name="CURRENCY"
        :model-value="exchangeCurrencyPrefix"
      ></air8-label>
      <span :class="$style['air8-exchange-rate__equal']">=</span>
      <air8-form-item
        ref="exchangeRateSuffixFormItem"
        :type="inputComp"
        :name="exchangeRateSuffixName"
        :label="$t('page.repayment.info.bankInfo.columns.exchangeRateInput')"
        :hide-label="true"
        :rules="interanlRuleSuffix"
        :precision="4"
        :integer-not-precision="true"
        :min="0.0001"
        :formatter="inputFormatter"
        :decimal-length="4"
        @blur="handleBlurSuffix"
        @changeOnBlur="handleChangeOnBlurSuffix"
      >
      </air8-form-item>
      <air8-label
        dict-name="CURRENCY"
        :model-value="exchangeCurrencySuffix"
      ></air8-label>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { injectForm } from '@/components/air8/utils/componentUtils'

import _ from 'lodash'
export default defineComponent({
  name: 'Air8ExchangeRate',
  props: {
    exchangeRatePrefixName: String,
    exchangeRateSuffixName: String,
    exchangeCurrencyPrefix: String,
    exchangeCurrencySuffix: String,
    rulesPrefix: Array,
    rulesSuffix: Array,
    editable: {
      type: Boolean,
      default: true
    }
  },

  emits: ['blur', 'changeOnBlur'],

  setup (props, ctx) {
    const form = injectForm()
    const model = form.model

    function updateExchangeRatePrefix (value) {
      _.set(model.value, props.exchangeRatePrefixName, value)
    }

    function updateExchangeRateSuffix (value) {
      _.set(model.value, props.exchangeRateSuffixName, value)
    }

    const isHideExchange = computed(() => {
      return (_.isEmpty(props.exchangeCurrencyPrefix) ||
        _.isEmpty(props.exchangeCurrencySuffix) ||
        props.exchangeCurrencyPrefix === props.exchangeCurrencySuffix)
    })

    watch([() => props.exchangeCurrencyPrefix, () => props.exchangeCurrencySuffix], (current, prev) => {
      if (!props.editable) {
        return
      }

      if (!_.isEmpty(props.exchangeCurrencyPrefix) &&
        props.exchangeCurrencyPrefix === props.exchangeCurrencySuffix
      ) {
        updateExchangeRatePrefix(1)
        updateExchangeRateSuffix(1)
      } else if (isHideExchange.value) {
        updateExchangeRatePrefix(undefined)
        updateExchangeRateSuffix(undefined)
      } else if (!_.isEmpty(prev[0]) && prev[0] === prev[1]) {
        // current value is not same and not empty, previous currency is same currency, then clear exchange rate
        updateExchangeRatePrefix(undefined)
        updateExchangeRateSuffix(undefined)
      }
    })

    const internalRulesPrefix = computed(() => {
      if (_.isNil(props.rulesPrefix)) {
        return []
      } else {
        return props.rulesPrefix
      }
    })

    const interanlRuleSuffix = computed(() => {
      if (_.isNil(props.rulesSuffix)) {
        return []
      } else {
        return props.rulesSuffix
      }
    })

    const inputComp = computed(() => {
      if (props.editable) {
        return 'Air8InputNumber'
      } else {
        return 'Air8Label'
      }
    })

    const inputFormatter = computed(() => {
      if (props.editable) {
        return undefined
      } else {
        return 'exchange'
      }
    })

    function handleBlurPrefix () {
      ctx.emit('blur')
    }

    function handleBlurSuffix () {
      ctx.emit('blur')
    }

    function handleChangeOnBlurPrefix (e) {
      ctx.emit('changeOnBlur')
    }

    function handleChangeOnBlurSuffix (e) {
      ctx.emit('changeOnBlur')
    }

    return {
      inputComp,
      internalRulesPrefix,
      interanlRuleSuffix,
      isHideExchange,
      inputFormatter,
      handleBlurPrefix,
      handleBlurSuffix,
      handleChangeOnBlurPrefix,
      handleChangeOnBlurSuffix
    }
  }
})
</script>
<style lang="less" module>
.air8-exchange-rate {
  display: flex;
  & > :global(.air8-form-item) {
    flex: 1 1 0;
  }

  & > :global(span) {
    padding-top: 5px;
  }

  :global(.ant-input-number) {
    width: 100%;
  }

  .air8-exchange-rate__equal {
    margin: 0px 2px;
  }

  &.air8-exchange-rate__readonly {
    flex-wrap: wrap;
    margin-bottom: 16px;
    :global(.air8-form-item) {
      flex: none;
      margin-bottom: 0px;
    }
  }
}
</style>
