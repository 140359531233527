export type LocalQueryFn<T> = (params: Record<string, any>) => Promise<T[]>

export class LocalData<T> {
  constructor (queryFn?: LocalQueryFn<T>) {
    if (queryFn) this.queryFn = queryFn
  }

  loading = false

  params: Record<string, any> = {}

  rows: T[] = []

  queryFn!: LocalQueryFn<T>

  load (params: Record<string, any>) {
    this.loading = true
    this.queryFn(params).then(data => {
      this.rows = data || []
    }).finally(() => {
      this.loading = false
    })
  }

  reload () {
    this.loading = true
    this.queryFn(this.params || {}).then(data => {
      this.rows = data || []
    }).finally(() => {
      this.loading = false
    })
  }
}
