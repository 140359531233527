import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadOutlined = _resolveComponent("UploadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.title)
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.title) + ": ", 1),
          (_ctx.showTip)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('common.import.supported_types',_ctx.accept ? _ctx.accept : 'JPG、JPEG、BMP、PNG、GIF、PDF、docx、doc、xls、xlsx、zip')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "extra")
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isEdit)
      ? (_openBlock(), _createBlock(_component_a_upload, {
          key: 1,
          multiple: "",
          ref: "uploadRef",
          name: _ctx.name,
          "file-list": _ctx.fileList,
          "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => (_ctx.fileList = $event)),
          customRequest: _ctx.ossRequest,
          showUploadList: false,
          "before-upload": _ctx.handleBeforeUpload,
          onChange: _ctx.handleChange,
          accept: _ctx.type
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createVNode(_component_a_button, {
                disabled: _ctx.fileList.length >= _ctx.maxlength,
                class: _normalizeClass(_ctx.$style.mb12)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UploadOutlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('common.upload')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "class"])
            ])
          ]),
          _: 3
        }, 8, ["name", "file-list", "customRequest", "before-upload", "onChange", "accept"]))
      : _createCommentVNode("", true),
    (_ctx.showList)
      ? (_openBlock(), _createBlock(_component_List, _mergeProps({
          key: 2,
          "file-list": _ctx.fileList,
          "onUpdate:file-list": _cache[1] || (_cache[1] = ($event: any) => (_ctx.fileList = $event)),
          isEdit: _ctx.isEdit
        }, _ctx.$attrs, {
          onHandleManualRemove: _ctx.uploadRef?.handleManualRemove,
          onHandleRemove: _ctx.uploadRef?.handleRemove
        }), null, 16, ["file-list", "isEdit", "onHandleManualRemove", "onHandleRemove"]))
      : _createCommentVNode("", true)
  ], 64))
}