import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c0 = _resolveComponent("c0")!
  const _component_layout_form = _resolveComponent("layout-form")!

  return (_openBlock(), _createBlock(_component_layout_form, {
    class: _normalizeClass(_ctx.innernalClassName),
    "page-data": _ctx.pageData,
    params: _ctx.defaultParams,
    ref: "layoutForm",
    nested: _ctx.nested,
    span: _ctx.span,
    onQuery: _ctx.handleQuery
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_c0, {
          builder: "main",
          config: _ctx.internalQueryConditions,
          scope: this
        }, null, 8, ["config"])
      ], true)
    ]),
    _: 3
  }, 8, ["class", "page-data", "params", "nested", "span", "onQuery"]))
}