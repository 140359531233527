
import { computed, defineComponent, nextTick, onMounted, onUnmounted, onUpdated, ref, watch } from 'vue'
import { LayoutFormContext, useFormInject } from '../layout-form/use-form'

export default defineComponent({
  name: 'form-search-select',
  props: {
    prop: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    defaultOptions: {
      type: Array
    }
  },
  setup (props: any) {
    const { hide, set, form, getProps } = useFormInject() as LayoutFormContext
    const visible = computed(() => !hide(props.prop))
    onMounted(() => { set(props.prop, true) })
    onUnmounted(() => { set(props.prop, false) })

    const wrapEle = ref<HTMLDivElement>()
    const labelEle = ref<HTMLDivElement>()

    const updateStyle = () => {
      const width = (labelEle.value as HTMLDivElement).offsetWidth
      const p = (wrapEle.value as HTMLDivElement).querySelector('.ant-select-selector') as HTMLInputElement
      const input = (wrapEle.value as HTMLDivElement).querySelector('.ant-select-selection-search-input') as HTMLInputElement
      if (input) input.style.paddingLeft = width + 'px'
      if (p) p.style.paddingLeft = width + 12 + 'px'
    }

    // onMounted(() => {
    //   updateStyle()
    // })

    onUpdated(() => {
      updateStyle()
    })

    return {
      form,
      visible,
      wrapEle,
      labelEle,
      ...getProps()
    }
  }
})

