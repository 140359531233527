<template>
  <air8-auto-card v-bind="$attrs">
    <template
      #subTitle
      v-if="$slots.subTitle"
    >
      <slot name="subTitle"></slot>
    </template>
    <template #extra>
      <slot name="extra"></slot>
    </template>
    <air8-column-layout
      :cols="cols"
      :form-layout="formLayout"
    >
      <slot name="default"></slot>
    </air8-column-layout>
  </air8-auto-card>
</template>
<script>
import { defineComponent, resolveComponent, h } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'Air8DynamicGroup',
  aliasName: 'a8-d-group',

  props: {
    cols: Number,
    formLayout: {
      type: String,
      default: 'horizontal'
    }
  }
})
</script>
