
import { computed, defineComponent, onMounted, nextTick, onUnmounted, onUpdated, ref } from 'vue'
import { LayoutFormContext, useFormInject } from '../layout-form/use-form'

export default defineComponent({
  name: 'form-select',
  props: {
    prop: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    hasAll: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Array,
      required: false
    }
  },
  setup (props: any) {
    const { hide, set, form, getProps } = useFormInject() as LayoutFormContext
    const visible = computed(() => !hide(props.prop))
    onMounted(() => { set(props.prop, true) })
    onUnmounted(() => { set(props.prop, false) })

    const wrapEle = ref<HTMLDivElement>()
    const labelEle = ref<HTMLDivElement>()

    const updateStyle = () => {
      const width = (labelEle.value as HTMLDivElement).offsetWidth
      const input = (wrapEle.value as HTMLDivElement).querySelector('.ant-select-selection-placeholder') as HTMLInputElement
      const tag = (wrapEle.value as HTMLDivElement).querySelector('.ant-select-multiple .ant-select-selector') as HTMLDivElement
      if (input) input.style.paddingLeft = width + 'px'
      if (tag) tag.style.paddingLeft = (width + 4) + 'px'
    }

    // onMounted(() => {
    //   updateStyle()
    // })

    const onChange = () => {
      nextTick(() => {
        updateStyle()
      })
    }

    onUpdated(() => {
      updateStyle()
    })

    return {
      form,
      visible,
      wrapEle,
      labelEle,
      onChange,
      ...getProps()
    }
  }
})

