
import { useHttp } from '@/plugins/http/src'
import { useTimeZone } from '@/plugins/time-zone'
import moment from 'moment'
import { computed, defineComponent, reactive, watch } from 'vue'

export default defineComponent({
  name: 'holiday-date',
  props: {
    value: {},
    currency: {
      type: [String, Array],
      required: true
    },
    disabledDate: {
      type: Function
    }
  },
  emits: ['update:value'],
  setup (props: any, { emit }) {
    const { http } = useHttp()
    const { tz } = useTimeZone()

    const date = computed({
      get: () => props.value,
      set: (val: any) => emit('update:value', val)
    })

    const holidayMap = reactive<Map<any, any>>(new Map())

    const resolveDateText = (d: any) => {
      return d.format('D')
    }

    const todayCls = (day: any, $style: any) => {
      const today = moment()
      return day.isSame(today, 'day') ? $style.today : ''
    }

    const selectedCls = (day: any, $style: any) => {
      if (!date.value) return ''
      const d = tz.al(date.value)
      return day.isSame(d, 'day') ? $style.selected : ''
    }

    const inMonthCls = (day: any, anchor: any, $style: any) => {
      return day.isSame(anchor, 'month') ? $style.inMonth : ''
    }

    const holidayCls = (day: any, $style: any) => {
      const items = getHoliday(day)
      return !items ? '' : (!isHolidayWork(items) ? $style.holiday : $style.holidayWork)
    }

    const getHoliday = (day: any) => {
      const d = tz.f(day, 'D')
      return holidayMap.get(d)
    }

    // 判断是否周六周日，并且CNY下是否调休
    const isDoubleDayOff = (day: any) => {
      const d = moment(day).format('d')
      if ((d === '6' || d === '0') && !getHoliday(day)) return true
      return false
    }

    const actualDisabledDateFn = (day: any) => {
      const items = getHoliday(day)
      if (isDoubleDayOff(day)) return true
      if (!isHolidayWork(items)) return true
      if (props.disabledDate) return props.disabledDate(day)
      return false
    }

    const loadHoliday = () => {
      holidayMap.clear()
      if (!props.currency) return
      http.post<any[]>('/api/air8-company/holiday/list', { currencyCode: Array.isArray(props.currency) ? props.currency : [props.currency] }).then(data => {
        data.forEach((v: any) => {
          const item = genItem(v)
          const key = item?.date
          if (!holidayMap.get(key)) holidayMap.set(key, [])
          holidayMap.get(key).push(item)
        })
      })

      function genItem (item: any) {
        return {
          date: tz.f(item?.calendarDate, 'D') as string,
          countryCode: item?.countryCode,
          countryName: item?.countryCode,
          labelCn: item?.holidayNameCn,
          labelEn: item?.holidayNameEn,
          type: item?.type
        }
      }
    }

    const isHolidayWork = (rows: any) => {
      return !(rows || []).some((v: any) => v.type === 'J')
    }

    const c = computed(() => props.currency)

    watch(c, () => {
      loadHoliday()
    }, { immediate: true })

    return {
      date,
      resolveDateText,
      todayCls,
      selectedCls,
      inMonthCls,
      holidayCls,
      getHoliday,
      actualDisabledDateFn,
      isHolidayWork
    }
  }
})

