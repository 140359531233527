export interface LocaleItem {
  stardard: string;
  zhCN: string;
  enUS: string;
}

export function flatLocale (obj: Record<string, any>, path = ''): Record<string, string> {
  const ret: Record<string, string> = {}
  Object.entries(obj).forEach(([k, v]) => {
    const p = path ? `${path}.${k}` : k
    if (Object.prototype.toString.call(v) === '[object Object]') {
      Object.assign(ret, flatLocale(v, p))
    } else {
      ret[p] = v
    }
  })
  return ret
}

export function mergeToRows (standard: Record<string, string>, zhCN: Record<string, string>, enUS: Record<string, string>) {
  return Object.keys(standard).map(k => {
    return {
      key: k,
      zhCN: (zhCN || {})[k],
      enUS: (enUS || {})[k]
    }
  })
}

export function exportRows (rows: any[], lang?: 'zhCN' | 'enUS') {
  const zhCN: Record<string, string> = {}
  const enUS: Record<string, string> = {}
  rows.forEach((v: any) => {
    zhCN[v.key] = v._zhCN || v.zhCN
    enUS[v.key] = v._enUS || v.enUS
  })
  if (lang === 'zhCN') return splitPath(zhCN)
  if (lang === 'enUS') return splitPath(enUS)
  return [splitPath(zhCN), splitPath(enUS)]
}

export function splitPath (obj: Record<string, string>): Record<string, any> {
  const context: Record<string, any> = {}
  Object.entries(obj).forEach(([k, v]) => {
    const s = k.split('.')
    let ctx = context
    s.forEach((m: string, i) => {
      if (ctx[m] === undefined) {
        if (i < s.length - 1) ctx[m] = {}
        else ctx[m] = v
      } else if (typeof ctx === 'string') {
        if (i < s.length - 1) (ctx as any)[m] = { label: ctx }
      }
      const ret = ctx[m]
      // bugfix: 兼容结构更改, 如: a: '1'  -> a: { x: '1', y: '2' }
      ctx = ((i < s.length - 1) && typeof ret === 'string') ? {} : ret
    })
  })
  return context
}

export function download (name: string, obj: any = {}) {
  const blob = new Blob([JSON.stringify(obj)])
  const link = document.createElement('a')
  link.href = window.URL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
