import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    "show-search": "",
    value: _ctx.val,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.val = $event)),
    disabled: _ctx.disabled,
    placeholder: _ctx.$t('common.search_placeholder'),
    "filter-option": false,
    "not-found-content": _ctx.fetching ? undefined : null,
    options: _ctx.options,
    onSearch: _cache[1] || (_cache[1] = val => _ctx.handleSearch(val)),
    onChange: _cache[2] || (_cache[2] = (val, opts) => _ctx.handleChange(val, opts))
  }, _createSlots({ _: 2 }, [
    (_ctx.fetching)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ])
        }
      : undefined
  ]), 1032, ["value", "disabled", "placeholder", "not-found-content", "options"]))
}