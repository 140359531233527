import { Rules } from '@/components/web/constant.js'
import { t } from '@/config/locale'
import { useLocal } from '@/plugins/locale'
import { gt, isNumber, lte } from '@/utils/number'
import _ from 'lodash'
import Formatter from '../formatter'
export default {
  ...Rules,
  requiredBoolean: { ...Rules.required, type: 'boolean' },
  requiredNumber: {
    ...Rules.required,
    type: 'number',
    validator: (rule:any, value:any) => {
      if (isNumber(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(rule.message)
      }
    }
  },
  requiredObject: { ...Rules.required, type: 'object' },
  requiredArray: {
    ...Rules.required,
    type: 'array',
    validator: (rule:any, value:any) => {
      if (!_.isEmpty(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(rule.message)
      }
    }
  },
  requiredAny: {
    ...Rules.required,
    validator: (rule:any, value:any) => {
      if (!_.isNil(value) && value !== '') {
        return Promise.resolve()
      } else {
        return Promise.reject(rule.message)
      }
    }
  },
  maxEmptyAsZero: (max:number|string|any, message?:string, trigger?: string | string[]) => {
    const { t } = useLocal()
    if (_.isNil(message)) {
      // eslint-disable-next-line no-template-curly-in-string
      message = t('common.number.checkMax', '${label}', _.isString(max) ? max : Formatter.number(max))
    }
    if (_.isString(max)) {
      max = max.replaceAll(',', '')
    }
    return {
      validator: (rule:any, value:any) => {
        if (_.isNil(max)) {
          return Promise.resolve()
        } else if (gt(value, max)) {
          // eslint-disable-next-line prefer-promise-reject-errors, no-template-curly-in-string
          return Promise.reject(message)
        } else {
          return Promise.resolve()
        }
      },
      trigger,
      label: true
    }
  },
  minNoEqualEmptyAsZero: (min:number|string|any, message?:string, trigger?: string | string[]) => {
    const { t } = useLocal()
    if (_.isNil(message)) {
      // eslint-disable-next-line no-template-curly-in-string
      message = t('common.number.checkMinNoEquals', '${label}', _.isString(min) ? min : Formatter.number(min))
    }
    if (_.isString(min)) {
      min = min.replaceAll(',', '')
    }

    return {
      validator: (rule:any, value:any) => {
        if (_.isNil(min)) {
          return Promise.resolve()
        } else if (lte(value, min)) {
          // eslint-disable-next-line prefer-promise-reject-errors, no-template-curly-in-string
          return Promise.reject(message)
        } else {
          return Promise.resolve()
        }
      },
      trigger,
      label: true
    }
  },
  accountName: () => ({
    type: 'string',
    pattern: /^\s*[\u4E00-\u9FA5A-Za-z0-9/\-?:().,'+ （）]+\s*$/g,
    message: t('common.validation.accountName')
  }),

  requiredRule (type?: string) {
    switch (type) {
      case 'boolean':
        return this.requiredBoolean
      case 'number':
        return this.requiredNumber
      case 'object':
        return this.requiredObject
      case 'array':
        return this.requiredArray
      case 'date':
        return this.Date.required
      case 'string':
        return this.required
      default:
        return this.requiredAny
    }
  }
}
