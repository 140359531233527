import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ExclamationCircleOutlined = _resolveComponent("ExclamationCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.container])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.title]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSum && _ctx.onSum(...args)), ["stop"]))
        }, [
          _createElementVNode("span", null, [
            _createVNode(_component_svg_icon, { icon: "sum" })
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
          (_ctx.params.hint)
            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                key: 0,
                title: _ctx.params.hint,
                class: _normalizeClass([_ctx.$style.hint])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ExclamationCircleOutlined)
                ]),
                _: 1
              }, 8, ["title", "class"]))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([_ctx.$style.tip])
            }, _toDisplayString(_ctx.value), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["spinning"]))
}