<template>
  <air8-action-page
    class="air8-edit-page"
    ref="actionPage"
    v-bind="$attrs"
    :status="status"
    :config="config"
    :model-value="modelValue"
    :init-function="initFunction"
    :actions-config="actionsConfig"
    :go-back="goBack"
  >
    <slot name="default"></slot>
  </air8-action-page>
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'
import { notification } from 'ant-design-vue'

export default defineComponent({
  name: 'Air8EditPage',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: 'edit'
    },
    config: [Array, Object],
    modelValue: Object,
    initFunction: Function,
    submitFunction: Function,
    submitAfterFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    }
  },

  setup (props) {
    const actionPage = ref()
    function getForm () {
      return actionPage.value.getForm()
    }

    function invokeFunction (method, ...params) {
      if (!_.isFunction(method)) {
        return
      }
      return method(...params)
    }

    function invokeGoBack () {
      actionPage.value.invokeGoBack()
    }

    async function invokeAfterAction (...params) {
      let submitAfterFunction = props.submitAfterFunction
      if (!_.isFunction(submitAfterFunction)) {
        submitAfterFunction = () => {
          notification.success({ message: t('common.notice.opt_success') })
        }
      }
      invokeFunction(submitAfterFunction, ...params)
      invokeGoBack()
    }

    const sumbitFunction = computed(() => {
      if (_.isFunction(props.submitFunction)) {
        return async (...args) => {
          const result = await props.submitFunction(...args)
          if (result === true) {
            invokeAfterAction()
          }
          return result
        }
      }

      return props.submitFunction
    })

    const actionsConfig = [{
      $children: t('common.submit'),
      action: sumbitFunction,
      type: 'primary'
    }]

    return {
      actionPage,
      getForm,
      actionsConfig
    }
  }
})
</script>
