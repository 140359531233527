<template>
  <div :class="$attrs.class">
    <c0
      :config="config"
      :scope="{ ...$attrs, ...$props }"
    ></c0>
  </div>
</template>
<script>
import { defineComponent, computed, useCssModule, markRaw, unref } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'
import { downUploadFileAll } from '@/api/finance-order'
import { useLocal } from '@/plugins/locale'
import { isDiffNew } from '@/utils/diffUtils'
import Rules from '@/components/air8/rules/index'
import UploadVue from './Upload.vue'

export default defineComponent({
  name: 'UploadTree',
  inheritAttrs: false,
  props: {
    // { files1: 'title' }
    title: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    // { files1: [{ objectKey }], files2: [{ attachemntCh, explainCh, files: [] }] }
    modelValue: {
      type: Object,
      default: () => ({})
    },
    batchFileTitle: {
      type: String,
      default: ''
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    showDiffTag: Boolean,
    formItemNamePrefix: String,
    localMessagePleaseUpload: {
      type: String,
      default: 'common.please_upload'
    },
    extendFileList: Object
  },
  setup (props, { slots, emit }) {
    const { lang } = useLocal()

    function getChildFileKey (parentKey, index) {
      return `${parentKey}_${index}`
    }

    const treeConfig = computed(() => {
      return _.mapValues(props.title, (value, key) => {
        const files = _.get(props.modelValue, key)
        const isLeaf = _.isEmpty(files) || _.has(files, '0.objectKey')
        if (isLeaf) {
          return {
            title: value
          }
        }

        const children = {}
        _.reduce(files, (result, value, index) => {
          const childKey = getChildFileKey(key, index)
          const childValue = {
            title: _.get(value, lang.c('attachmentCh', 'attachmentEn')),
            tip: _.get(value, lang.c('explainCh', 'explainEn'), ''),
            _hideUpload: _.get(value, '_hideUpload'),
            _isNew: isDiffNew(value),
            _required: _.get(value, '_required')
          }
          result[childKey] = childValue
          return result
        }, children)

        return {
          title: value,
          children
        }
      })
    })

    function getFilesValue (value, key) {
      let files = _.get(value, key)
      if (_.isNil(files)) {
        files = []
        _.set(value, key, files)
      }
      return files
    }

    const fileMap = computed(() => { // filter file obj
      return _.reduce(props.title, (result, value, key) => {
        const files = getFilesValue(props.modelValue, key)
        const isLeaf = _.isEmpty(files) || _.has(files, '0.objectKey')
        if (isLeaf) {
          result[key] = files
          return result
        }

        _.reduce(files, (result, value, index) => {
          const childKey = getChildFileKey(key, index)
          const childValue = getFilesValue(value, 'files')
          result[childKey] = childValue
          return result
        }, result)

        return result
      }, {})
    })

    const itemsList = computed(() => { // get Attachment information
      return _.chain(fileMap.value)
        .values()
        .flatten()
        .filter(_.isPlainObject)
        .value()
    })

    const downloadFileList = computed(() => {
      const extendFileList = _.chain(unref(props.extendFileList))
        .values()
        .flatten()
        .filter(_.isPlainObject)
        .value()
      return _.concat([], itemsList.value, extendFileList)
    })

    function handleClickDownload () { // download
      if (!_.isEmpty(downloadFileList.value)) {
        const fileList = _.map(downloadFileList.value, (item) => item.objectKey)
        downUploadFileAll(fileList, props.batchFileTitle)
      }
    }

    const css = useCssModule()

    function getLeafValue (key) {
      return _.get(fileMap.value, key)
    }

    function getFirstLevelLeaf (value, title) {
      return {
        $type: 'a-row',
        class: [css['air8-upload-tree__leaf-content'], 'air8-upload-tree__leaf-content'],
        $children: [
          {
            $type: 'a-col',
            span: 4,
            $children: getNodeTitle(title)
          },
          {
            $type: 'a-col',
            span: 20,
            $children: {
              $type: 'upload',
              value: value,
              accept: ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC', 'XLS', 'XLSX', 'ZIP', 'MSG'],
              isEdit: props.editable,
              allowDeleteHistory: props.allowDelete,
              showDiffTag: props.showDiffTag,
              $slots: {
                ..._.pick(slots, ['afterFileInter', 'afterFileOuter'])
              }
            }
          }
        ]
      }
    }

    function getSecondLevelLeaf (value, title, tip, config) {
      return {
        $type: 'upload',
        value: value,
        tip: false,
        title: title,
        accept: ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC', 'XLS', 'XLSX', 'ZIP', 'MSG'],
        isEdit: props.editable,
        allowDeleteHistory: props.allowDelete,
        showDiffTag: props.showDiffTag,
        hideUpload: config._hideUpload,
        required: config._required,
        $slots: {
          ..._.pick(slots, ['afterFileInter', 'afterFileOuter']),
          tip: {
            $type: 'span',
            class: [css['air8-upload-tree__leaf-tip'], 'air8-upload-tree__leaf-tip'],
            $children: tip || ''
          }
        },
        $directive: [
          ['diffTag', config._isNew]
        ]
      }
    }

    function getNodeTitle (title) {
      return {
        $type: 'div',
        $children: [{
          $type: 'i-span',
          class: [css['air8-upload-tree__title'], 'air8-upload-tree__title'],
          value: title
        }]
      }
    }

    function getNode (nodeConfig, children) {
      return {
        $type: 'a-row',
        class: [css['air8-upload-tree__node-content'], 'air8-upload-tree__node-content'],
        $children: [
          {
            $type: 'a-col',
            span: 4,
            $children: getNodeTitle(_.get(nodeConfig, 'title'))
          },
          {
            $type: 'a-col',
            span: 20,
            $children: _.map(_.keys(children), (leafKey) => {
              const leafConfig = _.get(children, leafKey)
              return getTree(leafKey, leafConfig)
            })
          }
        ]
      }
    }

    function getTree (key, treeConfig, firstLevel) {
      const children = _.get(treeConfig, 'children')
      const isLeaf = _.isNil(children)
      if (isLeaf) {
        const leafValue = getLeafValue(key)
        const leafTitle = _.get(treeConfig, 'title')
        if (firstLevel) {
          return getFirstLevelLeaf(leafValue, leafTitle)
        } else {
          const leafTip = _.get(treeConfig, 'tip')
          const name = _.split(props.formItemNamePrefix ? props.formItemNamePrefix + '_' + key : key, '_')
          name.push('files')
          if (props.editable) {
            return {
              $type: 'i-form-item',
              class: css['air8-upload-tree__tiny-error'],
              name,
              rules: treeConfig._required ? [{
                ...Rules.requiredArray,
                message: t('common.please_upload', treeConfig.title)
              }] : [],
              $children: [
                getSecondLevelLeaf(leafValue, leafTitle, leafTip, treeConfig)
              ]
            }
          } else {
            return getSecondLevelLeaf(leafValue, leafTitle, leafTip, treeConfig)
          }
        }
      } else {
        return getNode(treeConfig, children)
      }
    }

    function getConfig () {
      return {
        $type: 'Air8AutoCard',
        title: t('common.attachment_info'),
        $slots: {
          subTitle: {
            $type: 'span',
            class: [css['air8-upload-tree__tip'], 'air8-upload-tree__tip'],
            $children: t('common.import.supported_types', 'JPG、JPEG、BMP、PNG、GIF、PDF、docx、doc、xls、xlsx、zip、msg')
          },
          extra: {
            $type: 'a-button',
            $if: !props.editable,
            $Fdisabled: () => _.isEmpty(itemsList.value),
            size: 'sm',
            onClick: handleClickDownload,
            $children: t('common.downloadBatch')
          }
        },
        $F$children: () => {
          return _.chain(_.keys(treeConfig.value))
            .map((v) => {
              return getTree(v, _.get(treeConfig.value, v), true)
            }).value()
        }
      }
    }

    const config = getConfig()

    return {
      config,
      fileMap,
      itemsList,
      treeConfig
    }
  }
})
</script>
<style lang="less" module>
@import '~@/styles/vars.less';
.air8-upload-tree__title {
  &::after {
    content: ':';
  }
}

.air8-upload-tree__tip {
  color: #999;
  line-height: 24px;
  margin-bottom: 4px;
  font-size: 12px;
  margin-left: 4px;
}

.air8-upload-tree__node-content {
  .air8-upload-tree__leaf-tip {
    color: @color-danger;
  }
}

.air8-upload-tree__node-content + .air8-upload-tree__leaf-content {
  margin-top: 24px;
}

.air8-upload-tree__tiny-error:global(.air8-locale-form-item) {
  margin-bottom: 0px;
}
</style>
