<template>
  <Air8OcrUploadList
    v-bind="$attrs"
    :type="type"
    :schemaCode="undefined"
  ></Air8OcrUploadList>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Air8FileUploadList',

  props: {
    type: {
      type: Array,
      default: () => ['JPG', 'JPEG', 'BMP', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC', 'XLS', 'XLSX', 'ZIP', 'MSG']
    }
  }
})
</script>
