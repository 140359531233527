<template>
  <div class="air8-approve-actions">
    <air8-group-actions
      :actions-config="actionsConfig"
      :go-back="goBack"
      ref="actions"
    ></air8-group-actions>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import _ from 'lodash'
import { t } from '@/config/locale'
import { WORKFLOW_APPROVAL_STATUS } from '@/common/constants'
import { notification } from 'ant-design-vue'

export default defineComponent({
  name: 'Air8ApprovalActions',
  props: {
    approvalPassFunction: Function,
    approvalDenyTip: String,
    approvalDenyFunction: Function,
    approvalRejectText: String,
    approvalRejectDialogWidth: {
      type: [Number, String],
      default: 412
    },
    approvalRejectFunction: Function,
    approvalBeforeFunction: Function,
    approvalAfterFunction: Function,
    goBack: {
      type: [Boolean, Number, String, Function, Object],
      default: true
    },
    passDisabled: Boolean,
    hideDeny: Boolean
  },

  setup (props) {
    const actions = ref()
    function invokeFunction (method, ...params) {
      if (!_.isFunction(method)) {
        return
      }
      return method(...params)
    }
    async function invokeAfterAction (...params) {
      let approvalAfterFunction = props.approvalAfterFunction
      if (!_.isFunction(approvalAfterFunction)) {
        approvalAfterFunction = () => {
          notification.success({ message: t('common.notice.opt_success') })
        }
      }
      invokeFunction(approvalAfterFunction, ...params)
      invokeGoBack()
    }

    function invokeGoBack () {
      actions.value.invokeGoBack()
    }

    const actionsConfig = [{
      disabled: !!props.passDisabled,
      key: WORKFLOW_APPROVAL_STATUS.PASSED,
      delay: 900,
      $children: t('common.approval_pass'),
      type: 'primary',
      beforeAction: computed(() => props.approvalBeforeFunction),
      action: computed(() => props.approvalPassFunction),
      afterAction: invokeAfterAction
    }, {
      $if: computed(() => !props.hideDeny),
      key: WORKFLOW_APPROVAL_STATUS.REJECTED,
      delay: 900,
      buttonType: 'warning',
      tip: computed(() => {
        if (_.isEmpty(props.approvalDenyTip)) {
          return t('common.tipsDeny')
        }
        return props.approvalDenyTip
      }),
      $children: t('common.approval_deny'),
      beforeAction: computed(() => props.approvalBeforeFunction),
      action: computed(() => props.approvalDenyFunction),
      afterAction: invokeAfterAction
    }, {
      key: WORKFLOW_APPROVAL_STATUS.REFUSED,
      delay: 900,
      buttonType: 'modal',
      danger: true,
      type: 'primary',
      dialogTitle: t('common.tipsReject'),
      dialogWidth: computed(() => props.approvalRejectDialogWidth),
      dialogContext: computed(() => props.approvalRejectText),
      $children: t('common.approval_reject'),
      beforeAction: computed(() => props.approvalBeforeFunction),
      action: computed(() => props.approvalRejectFunction),
      afterAction: invokeAfterAction
    }]

    return {
      actions,
      actionsConfig
    }
  }
})
</script>
