
import { defineComponent, h, ref, resolveComponent } from 'vue'
import _ from 'lodash'
import { Filter, PageData } from '@/common/page-data'

export const CONDITION_INPUT = {
  INPUT: 'form-input',
  SELECT: 'form-select',
  SEARCH_SELECT: 'form-search-select',
  SELECT_MUTIL: 'form-select-multiple',
  DATE: 'form-date'
}

export default defineComponent({
  name: 'QueryConditionsGroup',
  props: {
    queryConditions: {
      type: Array,
      default: () => []
    },
    pageData: {
      type: PageData
    },
    defaultParams: {
      type: Object
    },
    params: {
      type: Object
    },
    filters: {
      type: Object
    },
    nested: Boolean,
    span: Number,
    onceParams: Object,
    nestedType: {
      type: String,
      default: 'inline'
    }
  },

  emits: ['query'],

  setup (props) {
    const layoutForm: any = ref(null)
    const internalParams = ref(props.params)
    const internalFilters = ref(props.filters)
    const internalOnceParams = ref(props.onceParams)
    return {
      layoutForm,
      internalParams,
      internalFilters,
      internalOnceParams
    }
  },

  computed: {
    internalQueryConditions () {
      return _.sortBy(this.queryConditions, ['$position'])
    },

    paramsAndFilters () {
      return {
        params: this.params,
        filters: this.filters
      }
    },

    innernalClassName () {
      return {
        'query-condition-group': true,
        'query-condition-group__nested_inline': this.nested && this.nestedType === 'inline',
        'query-condition-group__nested_none': this.nested && this.nestedType === 'none'
      }
    }
  },

  watch: {
    paramsAndFilters () {
      // when default params changed, update the conditions and reload again
      this.internalParams = _.clone(this.params)
      this.internalFilters = _.clone(this.filters)
      this.doParamsFilterQuery()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.doParamsFilterQuery()
    })
  },

  methods: {
    doParamsFilterQuery () {
      if (_.isNil(this.layoutForm) || _.isNil(this.pageData)) {
        return
      }

      this.layoutForm.form = this.internalParams || {}
      const filters: Partial<Filter>[] | undefined = _.transform(this.internalFilters as any, (result: Partial<Filter>[], value: any, key: any) => {
        result.push({ name: key, value: value })
      }, [])

      let params = this.layoutForm.getParams()
      if (!_.isEmpty(filters)) {
        params = { ...params, filters: filters }
      }
      if (!_.isEmpty(this.internalOnceParams)) {
        params = { ...params, ...this.internalOnceParams }
      }
      this.pageData.load(params)

      this.internalParams = undefined
      this.internalFilters = undefined
      this.internalOnceParams = undefined
    },

    doQuery () {
      if (this.layoutForm) {
        this.layoutForm.onQuery()
      }
    },

    resetParams () {
      if (this.layoutForm) {
        this.layoutForm.onReset()
      }
    },

    handleQuery (params: any) {
      this.$emit('query', params)
    }
  }
})
