import _ from 'lodash'
import DefaultBuilder from './DefaultBuilder'
import { MAP_DATE_TYPE_FIELD_TYPE, FIELD_TYPE, DATA_TYPE } from '../constants'
import { getComponentName } from '../../utils/componentUtils'

const MAP_FIELD_TYPE_READONLY_COMPONENT = {
  [FIELD_TYPE.TEXT]: 'Air8Label',
  [FIELD_TYPE.SELECT]: 'Air8Label',
  [FIELD_TYPE.RADIO_GROUP]: 'Air8Label',
  [FIELD_TYPE.TEXTAREA]: 'Air8Label',
  [FIELD_TYPE.TEXT]: 'Air8Label',
  [FIELD_TYPE.SELECT]: 'Air8Label',
  [FIELD_TYPE.RADIO_GROUP]: 'Air8Label',
  [FIELD_TYPE.TEXTAREA]: 'Air8Label',
  [FIELD_TYPE.NUMBER]: 'Air8Label',
  [FIELD_TYPE.NORMAL_NUMBER]: 'Air8Label',
  [FIELD_TYPE.CURRENCY]: 'Air8MoneyLabel',
  [FIELD_TYPE.DATE]: 'Air8Label',
  [FIELD_TYPE.PERCENT]: 'Air8Label'
}

export default class DynamicFormItemBuilder extends DefaultBuilder {
  initConfig (currentConfig:any):any {
    if (_.isNil(currentConfig)) {
      return currentConfig
    }

    currentConfig = _.clone(currentConfig)

    currentConfig.type = this.getConfigType(currentConfig)

    const defaultConfig = this.getDefaultConfig(currentConfig)

    currentConfig = _.assign(defaultConfig, currentConfig)

    return this.preInitConfig(currentConfig)
  }

  private getDefaultConfig (config: any) {
    const defaultConfig: any = {
      wrapBrackets: true
    }

    if (config.type === 'Air8CategorySelect') {
      switch (config.category) {
        case 'bankName':
        case 'bankSwiftCode':
          defaultConfig.filterOption = false
          break
        case 'funder':
        case 'buyer':
        case 'supplier':
        case 'funderNoDataAuth':
          defaultConfig.fullOption = true
          defaultConfig.filterBySearch = true
      }
    } else if (config.type === 'Air8DictionarySelect') {
      defaultConfig.showArrow = true
      if (config.dataType === DATA_TYPE.STRING && ['multiple', 'tag'].includes(config.mode)) {
        defaultConfig.joinValue = true
      }
      if (config.dataType === DATA_TYPE.ARRAY) {
        defaultConfig.mode = 'multiple'
      }
    } else if (config.type === 'Air8Label') {
      defaultConfig.formatter = 'empty'
    }
    return defaultConfig
  }

  private getConfigEditableType (config: any) {
    let type = getComponentName(config.type)
    if (_.isNil(type)) {
      if (!_.isEmpty(config.dictName)) {
        return 'Air8DictionarySelect'
      }
      if (!_.isEmpty(config.category)) {
        return 'Air8CategorySelect'
      }

      type = _.get(MAP_DATE_TYPE_FIELD_TYPE, config.dataType, FIELD_TYPE.TEXT)
    }

    return type
  }

  private getConfigReadonlyType (config: any) {
    let type = getComponentName(config.type)
    if (_.isNil(type)) {
      if (!_.isEmpty(config.dictName)) {
        return 'Air8Label'
      }
      if (!_.isEmpty(config.category)) {
        return 'Air8Label'
      }

      type = _.get(MAP_DATE_TYPE_FIELD_TYPE, config.dataType, FIELD_TYPE.TEXT)
    }

    return _.get(MAP_FIELD_TYPE_READONLY_COMPONENT, type, type)
  }

  private getConfigType (config: any) {
    return _.get(config, 'editable', false) ? this.getConfigEditableType(config) : this.getConfigReadonlyType(config)
  }
}
